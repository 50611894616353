import { createHashHistory, createMemoryHistory } from "@remix-run/router";

let a;
if (typeof window === "undefined") {
  a = createMemoryHistory({ v5Compat: true });
} else {
  a = createHashHistory({ v5Compat: true });
}

export const history = a;
