"use client";
import { Group } from "fabric";
import * as fabric from "fabric";

export class FabricCustomGroup extends Group {
  public static caieType = "erase" as "erase" | "draw";

  transform(ctx: CanvasRenderingContext2D) {
    super.transform(ctx);
    if (FabricCustomGroup.caieType === "draw") {
      ctx.globalCompositeOperation = "source-over";
    }
  }
}
