"use client";
import { devtools, persist, StorageValue } from "zustand/middleware";

import { isInDev, prepareMessageForSend, trimTopic } from "../utils";

import Locale from "../locales";
import { showToast } from "../components/ui-lib";
import { ModelType } from "./config";
import { createEmptyMask, Mask } from "./mask";
import { StoreKey } from "../constant";
import {
  apiClientTypeByUsageType,
  ClientApi,
  RequestMessage,
} from "../client/api";
import { ChatControllerPool } from "../client/controller";
import { prettyObject } from "../utils/format";
import { estimateTokenLength } from "../utils/token";
import { useUserStore } from "@/app/store/user";
import * as localforage from "localforage";
import { mdTransform } from "@/app/utils/MarkDownFilePlugin";
import { useAccessStore } from "@/app/store/access";
import axios, { AxiosProgressEvent } from "axios";
import { v4 } from "uuid";
import { UploadFileType, useUploadFileStore } from "@/app/store/uploadFiles";
import {
  ChatEnums,
  ChatImageOperation,
  ImageUploadOptions,
} from "@/app/enums/chat";
import { freeze, produce } from "immer";
import { shared } from "use-broadcast-ts";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import { LayoutType } from "@/app/enums/layoutType";
import type {} from "@redux-devtools/extension";
import { create } from "zustand";
import { matchErrorCode } from "@/app/client/errorCode";

export type ChatMessageFile = {
  fileName: string;
  file_id: number;
  fileType?: UploadFileType;
};

export type ChatMessage = RequestMessage & {
  date: string;
  streaming?: boolean;
  isError?: boolean;
  id?: number;
  model?: ModelType;
  // 弃用
  file_ids?: Array<number>;
  // 是否已处理，undefined/0 未处理，<0 不用处理，>0已处理
  // 目前已知处理：
  // 1: markdown directive 文件上传处理，已废弃，仅用于旧数据兼容
  // 3: 文件上传2.0 需要在消息列表里附加文件信息
  isTransformed?: number | undefined;
  transformedContent?: string;
  // 对话列表展示用
  file_list?: ChatMessageFile[];
  messageUUID?: string;
  error_code?: number;
};

export function createMessage(override: Partial<ChatMessage>): ChatMessage {
  return {
    id: Date.now(),
    date: new Date().toLocaleString(),
    role: "user",
    content: "",
    ...override,
  };
}

export interface ChatStat {
  tokenCount: number;
  wordCount: number;
  charCount: number;
}

export interface ChatSession {
  id: number;
  topic: string;

  memoryPrompt: string;
  messages: ChatMessage[];
  stat: ChatStat;
  lastUpdate: number;
  lastSummarizeIndex: number;
  clearContextIndex?: number;

  mask: Mask;
  uid: string;
  lang?: string;
  conversationId?: string;
  fileCount?: number;
  pendingFileList?: FileUploadPendingItem[];
  currentModel?: number;
  imageOptions?: ImageUploadOptions;
  pptOptions?: ChatEnums.PPT.Options;
  stableDiffusionOptions?: ChatEnums.StableDiffusion.Options;
  wanxOptions?: ChatEnums.Wanx.Options;
  wordArtOptions?: ChatEnums.WordArt.Options;
  picWishOptions?: ChatEnums.PicWish.Option;
  shouldUseSearch?: boolean;
  // obsolete
  mindMapOptions?: ChatEnums.MindMap.Options;
}

export type FileUploadPendingItem = {
  uploadId?: number;
  uuid: string;
  fileName: string;
  file?: File;
  fileType?: UploadFileType;
  imageUrl?: string;
  filePath?: string;
};

export const DEFAULT_TOPIC = Locale.Store.DefaultTopic;
export const DEFAULT_TOPIC_V2 = "新对话";
export const BOT_HELLO: ChatMessage = {
  ...createMessage({
    role: "system",
    content: Locale.Store.BotHello,
  }),
  date: "",
};

function createEmptySession(): ChatSession {
  return {
    id: Date.now() + Math.random(),
    topic:
      useLayoutConfigStore.getState().type === LayoutType.Layout_v2
        ? DEFAULT_TOPIC_V2
        : DEFAULT_TOPIC,
    memoryPrompt: "",
    messages: [],
    stat: {
      tokenCount: 0,
      wordCount: 0,
      charCount: 0,
    },
    lastUpdate: Date.now(),
    lastSummarizeIndex: 0,

    mask: createEmptyMask(),
    uid: useUserStore.getState().id ? useUserStore.getState().id : "",
    lang: "Simplified_Chinese",
    conversationId: v4(),
    fileCount: 0,
    pendingFileList: [],
    currentModel: useUserStore.getState().usage_type ?? 0,
  };
}

interface ChatStore {
  sessions: ChatSession[];
  currentSessionIndex: number;
  currentConversationId?: string;
  globalId: number;
  clearSessions: () => void;
  moveSession: (from: number, to: number) => void;
  selectSession: (index: number) => void;
  selectSessionWithConversationId: (conversationId: string) => void;
  newSession: (mask?: Mask) => ChatSession;
  newSessionWithModel: (model: number) => string | undefined;
  deleteSession: (index: number) => void;
  currentSession: (conversationId?: string) => ChatSession | null;
  onNewMessage: (message: ChatMessage) => void;
  onUserInput: (content: string, conversationId?: string) => Promise<void>;
  summarizeSession: (conversationId?: string) => void;
  updateStat: (message: ChatMessage) => void;
  updateCurrentSession: (
    updater: (session: ChatSession) => void,
    conversationId?: string,
  ) => void;
  updateMessage: (
    sessionIndex: number,
    messageIndex: number,
    updater: (message?: ChatMessage) => void,
  ) => void;
  resetSession: () => void;
  getMessagesWithMemory: (conversationId?: string) => ChatMessage[];
  getMemoryPrompt: (conversationId?: string) => ChatMessage;

  clearAllData: () => void;
  updateLangForSession: (index: number, lang: string) => void;
  uploadChatFile: (
    file: File,
    conversationId: string,
    onSuccess?: (filename: string, fileId: number) => void,
    onError?: (error: Error) => void,
    onUpload?: (progress: AxiosProgressEvent) => void,
  ) => void;
  deleteChatFile: (
    fileId: number,
    onSuccess?: () => void,
    onError?: (error: Error) => void,
  ) => void;
  listChatFile: (
    conversationId: string,
    onSuccess?: (fileList: { file_id: number; fileName: string }[]) => void,
    onError?: (e: Error) => void,
  ) => void;
  updateSessionFileList: (
    prop: {
      fileList: { file_id: number; fileName: string }[];
      complete?: () => void;
    },
    conversationId?: string,
  ) => void;
  updateSessionPendingList: (
    fileList: {
      uploadId?: number;
      uuid: string;
      fileName: string;
      file?: File;
      fileType?: UploadFileType;
      imageUrl?: string;
      filePath?: string;
    }[],
  ) => void;
}

function countMessages(msgs: ChatMessage[]) {
  return msgs.reduce((pre, cur) => pre + estimateTokenLength(cur.content), 0);
}

export const useChatStore = create<ChatStore>()(
  devtools(
    persist(
      shared(
        (set, get) =>
          ({
            balance_3_5: 50,
            balance_4_0: 20,
            usage_type: 0,
            sessions: [createEmptySession()],
            currentSessionIndex: 0,
            globalId: 0,

            clearSessions() {
              set(() => ({
                sessions: [createEmptySession()],
                currentSessionIndex: 0,
              }));
            },

            selectSession(index: number) {
              const session = get().sessions.at(index);
              if (!session) {
                set({
                  currentSessionIndex: index,
                });
                return;
              }
              let conversationId = session.conversationId;
              let shouldUpdateConversationId = false;
              if (!conversationId) {
                conversationId = v4();
                shouldUpdateConversationId = true;
              }
              set((state) => {
                if (shouldUpdateConversationId) {
                  const neoSession = produce(state.sessions, (sessions) => {
                    sessions.at(index)!.conversationId = conversationId;
                  });
                  return {
                    currentSessionIndex: index,
                    sessions: neoSession,
                    currentConversationId: conversationId,
                  };
                } else {
                  return {
                    currentSessionIndex: index,
                    currentConversationId: conversationId,
                  };
                }
              });
            },

            selectSessionWithConversationId: (conversationId: string) => {
              let sessionIndex = -1;
              get().sessions.find((it, index) => {
                if (it.conversationId === conversationId) {
                  sessionIndex = index;
                  return true;
                }
                return false;
              });
              if (sessionIndex >= 0) {
                set({
                  currentSessionIndex: sessionIndex,
                  currentConversationId: conversationId,
                });
              }
            },

            moveSession(from: number, to: number) {
              set((state) => {
                const { sessions, currentSessionIndex: oldIndex } = state;

                // move the session
                const newSessions = [...sessions];
                const session = newSessions[from];
                newSessions.splice(from, 1);
                newSessions.splice(to, 0, session);

                // modify current session id
                let newIndex = oldIndex === from ? to : oldIndex;
                if (oldIndex > from && oldIndex <= to) {
                  newIndex -= 1;
                } else if (oldIndex < from && oldIndex >= to) {
                  newIndex += 1;
                }

                return {
                  currentSessionIndex: newIndex,
                  sessions: newSessions,
                };
              });
            },

            newSession(mask) {
              const session = createEmptySession();

              set(() => ({ globalId: get().globalId + 1 }));
              session.id = get().globalId;
              session.uid = useUserStore.getState().id;
              if (mask) {
                session.mask = { ...mask };
                session.topic = mask.name;
              }

              set((state) => ({
                currentSessionIndex: 0,
                sessions: [session].concat(state.sessions),
                currentConversationId: session.conversationId,
              }));
              return freeze(session, true);
            },

            newSessionWithModel(model) {
              const neoSession = createEmptySession();

              neoSession.currentModel = model;

              set({
                sessions: [neoSession, ...get().sessions],
                currentSessionIndex: 0,
                currentConversationId: neoSession.conversationId,
              });
              return neoSession.conversationId;
            },

            deleteSession(index) {
              const deletingLastSession = get().sessions.length === 1;
              const deletedSession = get().sessions.at(index);

              if (!deletedSession) return;

              const sessions = get().sessions.slice();
              sessions.splice(index, 1);

              const currentIndex = get().currentSessionIndex;
              let nextIndex = Math.min(
                currentIndex - Number(index < currentIndex),
                sessions.length - 1,
              );

              if (deletingLastSession) {
                nextIndex = 0;
                sessions.push(createEmptySession());
              }

              // for undo delete action
              const restoreState = {
                currentSessionIndex: get().currentSessionIndex,
                sessions: get().sessions.slice(),
              };

              set(() => ({
                currentSessionIndex: nextIndex,
                sessions: sessions.slice(),
              }));

              const timeout = setTimeout(() => {
                const pendingList = deletedSession.pendingFileList;
                if (!pendingList || pendingList.length <= 0) {
                  return;
                }
                const uuidLists = pendingList.map((it) => it.uuid);
                if (uuidLists) {
                  useUploadFileStore
                    .getState()
                    .deleteChatFileWithUUIDs(uuidLists);
                }
                const fileIds = pendingList
                  .filter((it) => it.uploadId && it.uploadId > 0)
                  .map((it) => it.uploadId!);
                fileIds.forEach((it) => {
                  try {
                    useChatStore.getState().deleteChatFile(it!);
                  } catch (error) {
                    console.warn(error);
                  }
                });
              }, 6000);

              showToast(
                Locale.Home.DeleteToast,
                {
                  text: Locale.Home.Revert,
                  onClick() {
                    set(() => restoreState);
                    clearTimeout(timeout);
                  },
                },
                5000,
              );
            },

            currentSession(conversationId?: string) {
              let index = get().currentSessionIndex;
              let currentConversationId = get().currentConversationId;
              if (conversationId) {
                currentConversationId = conversationId;
              }
              const sessions = get().sessions;
              let shouldUpdateIndex = false;

              if (!currentConversationId) {
                // fallback to currentIndex
                if (index < 0 || index >= get().sessions.length) {
                  index = 0;
                  shouldUpdateIndex = true;
                }
                const session = get().sessions.at(index)!;
                set(() => {
                  if (shouldUpdateIndex) {
                    return {
                      currentConversationId: session.conversationId,
                      currentSessionIndex: index,
                    };
                  }
                  return {
                    currentConversationId: session.conversationId,
                  };
                });
                return session;
              }

              const session = sessions.find((it, i) => {
                if (it.conversationId === currentConversationId) {
                  if (
                    it.uid &&
                    it.uid.length > 0 &&
                    it.uid !== useUserStore.getState().id
                  ) {
                    return false;
                  }
                  if (i !== index && !conversationId) {
                    index = i;
                    shouldUpdateIndex = true;
                  }
                  return true;
                }
                return false;
              });

              if (shouldUpdateIndex) {
                set({ currentSessionIndex: index });
              }

              if (!session) {
                return null;
              }
              return session;
            },

            onNewMessage(message) {
              get().updateCurrentSession((session) => {
                session.messages = session.messages.concat();
                session.lastUpdate = Date.now();
              });
              get().updateStat(message);
              // get().summarizeSession();
              useUserStore.getState().getUserInfo();
            },

            async onUserInput(content, conversationId) {
              const session = get().currentSession(conversationId);
              if (!session) {
                let currentConversationId = get().currentConversationId;
                if (conversationId) {
                  currentConversationId = conversationId;
                }
                console.warn("unknown conversationId: ", currentConversationId);
                return;
              }
              const messageLength = session.messages.length + 2;
              let neoSession = session;
              let language = neoSession.lang;
              if (!language) {
                language = "Simplified_Chinese";
              }
              let fileList = neoSession.pendingFileList;
              if (!fileList) {
                fileList = [];
              }
              fileList = fileList
                .filter(
                  (it) =>
                    it.fileType === UploadFileType.ASSISTANT ||
                    it.fileType === undefined,
                )
                .filter((it) => it.uploadId !== undefined && it.uploadId > 0);
              const filteredList = fileList.map((it) => {
                return {
                  fileName: it.fileName,
                  file_id: it.uploadId!,
                  fileType: it.fileType,
                  filePath: it.filePath,
                  imageUrl: it.imageUrl,
                  uuid: it.uuid,
                };
              });
              const modelConfig = {
                ...neoSession.mask.modelConfig,
                lang: language,
              };

              let userMessage: ChatMessage = createMessage({
                role: "user",
                content,
              });

              const trans = mdTransform(content);
              console.log(trans);
              userMessage = createMessage({
                role: "user",
                ...trans,
              });
              if (session.currentModel === 9) {
                // 佐糖
                const picWishFile = session.pendingFileList?.find(
                  (it) => it.fileType === UploadFileType.PICWISH_IMAGE_FILE,
                );
                if (!!content && content.trim().length > 0) {
                  userMessage = createMessage({
                    ...userMessage,
                    content: `![](${content})`,
                  });
                } else if (!!picWishFile) {
                  userMessage = createMessage({
                    ...userMessage,
                    content: `![](${picWishFile.filePath})`,
                  });
                }
              }

              if (neoSession.currentModel === 2) {
                if (
                  neoSession.imageOptions!.operation ===
                  ChatImageOperation.EDITING
                ) {
                  const originalImage = fileList.find(
                    (it) => it.fileType === UploadFileType.DALL_E_ORIGINAL,
                  )?.imageUrl;
                  const maskImage = fileList.find(
                    (it) => it.fileType === UploadFileType.DALL_E_MASK,
                  )?.imageUrl;
                  if (originalImage !== undefined && maskImage !== undefined) {
                    userMessage.content = `![](${encodeURI(originalImage)}) ![](${encodeURI(maskImage)}) ${userMessage.content}`;
                  }
                } else if (
                  neoSession.imageOptions!.operation ===
                  ChatImageOperation.VARIATION
                ) {
                  const originalImage = fileList.find(
                    (it) => it.fileType === UploadFileType.DALL_E_ORIGINAL,
                  )?.imageUrl;
                  if (originalImage !== undefined) {
                    userMessage.content = `![](${encodeURI(originalImage)}) ${userMessage.content}`;
                  }
                }
              }
              // stable diffusion
              if (neoSession.currentModel === 6) {
                if (
                  neoSession.stableDiffusionOptions!.operation ===
                    ChatEnums.StableDiffusion.Operation.EDIT ||
                  neoSession.stableDiffusionOptions!.operation ===
                    ChatEnums.StableDiffusion.Operation.VARIATION
                ) {
                  const originalImage = fileList.find(
                    (it) => it.fileType === UploadFileType.STABLE_DIFFUSION,
                  )?.imageUrl;
                  if (originalImage !== undefined) {
                    userMessage.content = `![](${encodeURIComponent(originalImage)}) ${userMessage.content}`;
                  }
                }
              }
              if (filteredList.length > 0) {
                if (userMessage.file_list !== undefined) {
                  const map: Map<
                    number,
                    { file_id: number; fileName: string; uuid?: string }
                  > = new Map();
                  userMessage.file_list.forEach((it) => {
                    if (it.fileType === UploadFileType.ASSISTANT) {
                      map.set(it.file_id, it);
                    }
                  });
                  filteredList.forEach((it) => {
                    if (it.fileType === UploadFileType.ASSISTANT) {
                      map.set(it.file_id!, it);
                    }
                  });

                  userMessage.file_list = [...map.values()];

                  let fileIds = userMessage.file_ids;
                  if (fileIds === undefined) {
                    fileIds = [];
                  }

                  const keys = [...map.keys()];
                  keys.forEach((it) => {
                    if (!fileIds!.includes(it)) {
                      fileIds!.push(it);
                    }
                  });
                  userMessage.file_ids = fileIds;

                  map.clear();
                } else {
                  userMessage.file_list = filteredList;
                  const fileIds: number[] = [];
                  filteredList.forEach((it) => {
                    if (
                      it.file_id > 0 &&
                      it.fileType === UploadFileType.ASSISTANT
                    ) {
                      fileIds.push(it.file_id);
                    }
                  });
                  userMessage.file_ids = fileIds;
                }
              }

              const userMessageFileCount = userMessage.file_list!.filter(
                (it) => it.fileType === UploadFileType.ASSISTANT,
              ).length;
              neoSession = produce(neoSession, (session) => {
                session.fileCount! += userMessageFileCount;
              });

              const botMessage: ChatMessage = createMessage({
                role: "assistant",
                streaming: true,
                id: userMessage.id! + 1,
                model: modelConfig.model,
              });

              const systemInfo = createMessage({
                role: "system",
                content: `IMPORTANT: You are a virtual assistant powered by the ${
                  modelConfig.model
                } model, now time is ${new Date().toLocaleString()}}`,
                id: botMessage.id! + 1,
              });

              // get recent messages
              const systemMessages = [];
              // if user define a mask with context prompts, wont send system info
              if (neoSession.mask.context.length === 0) {
                systemMessages.push(systemInfo);
              }

              const recentMessages = get().getMessagesWithMemory();
              const sendMessages = systemMessages.concat(
                recentMessages.concat(userMessage),
              );
              const sessionIndex = get().currentSessionIndex;
              const messageIndex = session.messages.length + 1;

              const sessionFileCount = session.fileCount!;
              // save user's and bot's message
              get().updateCurrentSession((session) => {
                session.messages = session.messages.concat([
                  userMessage,
                  botMessage,
                ]);
                session.fileCount = sessionFileCount;
              }, session.conversationId);

              const mappedSendMessages = prepareMessageForSend(sendMessages);
              // make request
              console.log("[User Input] ", mappedSendMessages);
              const api = new ClientApi(
                apiClientTypeByUsageType(
                  useUserStore.getState().usage_type,
                  conversationId,
                ),
              );

              api.llm.chat({
                messages: mappedSendMessages,
                config: { ...modelConfig, stream: true },
                conversationId: session.conversationId,
                onUpdate(message) {
                  let neoMessage = { ...session.messages.at(-1)! };
                  neoMessage = produce(neoMessage, (neoMessage) => {
                    neoMessage.streaming = true;
                    if (message) {
                      neoMessage.content = message;
                    }
                  });
                  get().updateCurrentSession((session) => {
                    session.messages = session.messages.with(
                      messageLength - 1,
                      neoMessage,
                    );
                  }, session.conversationId);
                },
                onFinish(message, additionalInfo) {
                  const neoBotMessage = produce(botMessage, (botMessage) => {
                    botMessage.streaming = false;
                    if (message) {
                      botMessage.content = message;
                      botMessage.messageUUID = additionalInfo?.messageUUID;
                      botMessage.date = new Date().toLocaleString();
                    }
                  });
                  if (message) {
                    get().updateCurrentSession((session1) => {
                      session1.messages = session1.messages.with(
                        messageLength - 1,
                        neoBotMessage,
                      );
                    }, session.conversationId);
                    get().onNewMessage(neoBotMessage);
                  }
                  ChatControllerPool.remove(
                    sessionIndex,
                    neoBotMessage.id ?? messageIndex,
                  );
                },
                onError(error, additionalInfo) {
                  const isAborted = error.name === "AbortError";
                  const neoBotMessage = produce(botMessage, (botMessage) => {
                    botMessage.content =
                      "\n\n" +
                      prettyObject({
                        error: true,
                        message: error.message,
                      });
                    botMessage.streaming = false;
                    botMessage.date = new Date().toLocaleString();
                    botMessage.isError = !isAborted;
                    if (
                      additionalInfo &&
                      additionalInfo.code !== undefined &&
                      !isNaN(additionalInfo.code)
                    ) {
                      botMessage.error_code = additionalInfo.code;
                      const errorContent = matchErrorCode(additionalInfo.code);
                      if (errorContent.additionalContent) {
                        botMessage.content =
                          errorContent.display +
                          "  \n```\n" +
                          error.message +
                          "\n```";
                      } else {
                        botMessage.content = errorContent.display;
                      }
                    }
                  });
                  const neoUserMessage = produce(userMessage, (userMessage) => {
                    userMessage.isError = !isAborted;
                  });
                  get().updateCurrentSession((session) => {
                    session.messages = session.messages
                      .with(messageLength - 1, neoBotMessage)
                      .with(messageLength - 2, neoUserMessage);
                  }, session.conversationId);
                  ChatControllerPool.remove(
                    sessionIndex,
                    neoBotMessage.id ?? messageIndex,
                  );

                  console.error("[Chat] failed ", error);
                },
                onController(controller) {
                  // collect controller for stop/retry
                  ChatControllerPool.addController(
                    sessionIndex,
                    botMessage.id ?? messageIndex,
                    controller,
                  );
                },
              });
            },

            getMemoryPrompt(conversationId) {
              const session = get().currentSession(conversationId);

              return {
                role: "system",
                content:
                  (session?.memoryPrompt.length ?? -1) > 0
                    ? Locale.Store.Prompt.History(session?.memoryPrompt ?? "")
                    : "",
                date: "",
              } as ChatMessage;
            },

            getMessagesWithMemory(conversationId) {
              const session = get().currentSession(conversationId);
              if (!session) {
                return [];
              }
              const modelConfig = session.mask.modelConfig;

              // wont send cleared context messages
              const clearedContextMessages = session.messages.slice(
                session.clearContextIndex ?? 0,
              );
              const messages = clearedContextMessages.filter(
                (msg) => !msg.isError,
              );
              const n = messages.length;

              const context = session.mask.context.slice();

              // long term memory
              if (
                modelConfig.sendMemory &&
                session.memoryPrompt &&
                session.memoryPrompt.length > 0
              ) {
                const memoryPrompt = get().getMemoryPrompt();
                context.push(memoryPrompt);
              }

              // get short term and unmemoried long term memory
              const shortTermMemoryMessageIndex = Math.max(
                0,
                n - modelConfig.historyMessageCount,
              );
              const longTermMemoryMessageIndex = session.lastSummarizeIndex;
              const mostRecentIndex = Math.max(
                shortTermMemoryMessageIndex,
                longTermMemoryMessageIndex,
              );
              const threshold = modelConfig.compressMessageLengthThreshold * 2;

              // get recent messages as many as possible
              const reversedRecentMessages = [];
              for (
                let i = n - 1, count = 0;
                i >= mostRecentIndex && count < threshold;
                i -= 1
              ) {
                const msg = messages[i];
                if (!msg || msg.isError) continue;
                count += msg.content.length;
                reversedRecentMessages.push(msg);
              }

              // concat
              const recentMessages = context.concat(
                reversedRecentMessages.reverse(),
              );

              return recentMessages;
            },

            updateMessage(
              sessionIndex: number,
              messageIndex: number,
              updater: (message?: ChatMessage) => void,
            ) {
              const sessions = get().sessions;
              const neoSessions = produce(sessions, (sessions) => {
                const session = sessions.at(sessionIndex);
                const messages = session?.messages;
                updater(messages?.at(messageIndex));
              });
              set(() => ({ sessions: neoSessions }));
            },

            resetSession() {
              get().updateCurrentSession((session) => {
                session.messages = [];
                session.memoryPrompt = "";
              });
            },

            summarizeSession(conversationId) {
              const session = get().currentSession(conversationId);
              if (!session) {
                return;
              }
              // remove error messages if any
              const messages = prepareMessageForSend(session.messages);

              // should summarize topic after chating more than 50 words
              const SUMMARIZE_MIN_LEN = 50;
              if (
                session.topic === DEFAULT_TOPIC &&
                countMessages(messages) >= SUMMARIZE_MIN_LEN
              ) {
                const topicMessages = messages.concat(
                  createMessage({
                    role: "user",
                    content: Locale.Store.Prompt.Topic,
                  }),
                );
                const api = new ClientApi(
                  apiClientTypeByUsageType(
                    useUserStore.getState().usage_type,
                    conversationId,
                  ),
                );
                api.llm.chat({
                  messages: topicMessages,
                  config: {
                    model: "gpt-3.5-turbo",
                  },
                  onFinish(message) {
                    get().updateCurrentSession(
                      (session) =>
                        (session.topic =
                          message.length > 0
                            ? trimTopic(message)
                            : DEFAULT_TOPIC),
                    );
                  },
                });
              }

              const modelConfig = session.mask.modelConfig;
              const summarizeIndex = Math.max(
                session.lastSummarizeIndex,
                session.clearContextIndex ?? 0,
              );
              let toBeSummarizedMsgs = messages
                .filter((msg) => !msg.isError)
                .slice(summarizeIndex);

              const historyMsgLength = countMessages(toBeSummarizedMsgs);

              if (historyMsgLength > (modelConfig?.max_tokens ?? 4000)) {
                const n = toBeSummarizedMsgs.length;
                toBeSummarizedMsgs = toBeSummarizedMsgs.slice(
                  Math.max(0, n - modelConfig.historyMessageCount),
                );
              }

              // add memory prompt
              toBeSummarizedMsgs.unshift(get().getMemoryPrompt());

              const lastSummarizeIndex = session.messages.length;

              console.log(
                "[Chat History] ",
                toBeSummarizedMsgs,
                historyMsgLength,
                modelConfig.compressMessageLengthThreshold,
              );

              if (
                historyMsgLength > modelConfig.compressMessageLengthThreshold &&
                modelConfig.sendMemory
              ) {
                const api = new ClientApi(
                  apiClientTypeByUsageType(
                    useUserStore.getState().usage_type,
                    conversationId,
                  ),
                );
                api.llm.chat({
                  messages: toBeSummarizedMsgs.concat({
                    role: "system",
                    content: Locale.Store.Prompt.Summarize,
                    date: "",
                  }),
                  config: { ...modelConfig, stream: true },
                  onUpdate(message) {
                    session.memoryPrompt = message;
                  },
                  onFinish(message) {
                    console.log("[Memory] ", message);
                    session.lastSummarizeIndex = lastSummarizeIndex;
                  },
                  onError(err) {
                    console.error("[Summarize] ", err);
                  },
                });
              }
            },

            updateStat(message) {
              get().updateCurrentSession((session) => {
                session.stat.charCount += message.content.length;
                // TODO: should update chat count and word count
              });
            },

            updateCurrentSession(updater, conversationId) {
              const sessions = get().sessions;
              const index = get().currentSessionIndex;
              const neoSessions = produce(sessions, (sessions) => {
                if (conversationId) {
                  const currentSession = sessions.find(
                    (it) => it.conversationId === conversationId,
                  );
                  if (currentSession) {
                    updater(currentSession);
                    return;
                  }
                }
                updater(sessions[index]);
              });
              set(() => ({ sessions: neoSessions }));
            },

            clearAllData() {
              localStorage.clear();
              void localforage.clear();
              location.reload();
            },

            updateLangForSession(index, lang) {
              const sessions = get().sessions;
              const currentSession = sessions[index];
              currentSession.lang = lang;
              sessions[index] = currentSession;
              set(() => ({ sessions }));
            },

            uploadChatFile(file, conversationId, onSuccess, onError, onUpload) {
              throw new Error(
                "obsolete method, use <code>useUploadFileStore.uploadFileWithProgress()</code> instead",
              );
            },

            deleteChatFile(fileId, onSuccess, onError) {
              const form = new FormData();
              form.set("id", "" + fileId);
              axios
                .post("/v1/asst/conversation/file/delete", form, {
                  headers: {
                    Authorization: `Bearer ${useAccessStore.getState().token}`,
                  },
                })
                .then((resp) => {
                  console.log(resp);
                  if (!resp.data) {
                    if (onError) {
                      onError(
                        new Error(
                          "response data empty, response code: " + resp.status,
                        ),
                      );
                    }
                    return;
                  }
                  if (resp.data.code !== 0) {
                    if (onError) {
                      onError(new Error(resp.data.msg));
                    }
                    return;
                  }
                  if (onSuccess) {
                    onSuccess();
                  }
                })
                .catch((error) => {
                  if (onError) {
                    onError(error);
                  }
                });
            },

            listChatFile(conversationId, onSuccess, onError) {
              axios
                .get(
                  `/v1/asst/conversation/file?conversation_id=${conversationId}`,
                  {
                    headers: {
                      Authorization: `Bearer ${useAccessStore.getState().token}`,
                    },
                  },
                )
                .then((resp) => {
                  console.log(resp);
                  if (!resp.data) {
                    if (onError) {
                      onError(
                        new Error(
                          "response body empty, http status " + resp.status,
                        ),
                      );
                    }
                    return;
                  }
                  if (resp.data.code !== 0) {
                    if (onError) {
                      onError(new Error(resp.data.msg));
                    }
                    return;
                  }
                  if (onSuccess) {
                    onSuccess(
                      resp.data.data.map((it: { id: any; file_name: any }) => {
                        return {
                          file_id: it.id,
                          fileName: it.file_name,
                        };
                      }),
                    );
                  }
                })
                .catch((error) => {
                  console.error(error);
                  if (onError) {
                    onError(error);
                  }
                });
            },

            updateSessionFileList(prop, conversationId) {
              const session = get().currentSession(conversationId);

              if (!session) {
                return;
              }

              // 传参过滤，传过来的列表是直接从接口响应里获取的原始数据
              const filteredList = prop.fileList.map((it) => {
                return {
                  file_id: it.file_id,
                  fileName: it.fileName,
                };
              });

              const fileIdList = filteredList.map((it) => it.file_id);

              let shouldUpdateMessages = false;

              const updatedSessionMessages = session.messages.map((it) => {
                if (it.isError || it.role !== "user") {
                  return it;
                }

                const neoIt = produce(it, (it) => {
                  const fileList = it.file_list!;
                  it.file_list = fileList.map((item) => {
                    if (
                      item.file_id > 0 &&
                      !fileIdList.includes(item.file_id)
                    ) {
                      shouldUpdateMessages = true;
                      return produce(item, (item) => {
                        item.file_id = -item.file_id;
                      });
                    }
                    return item;
                  });

                  const fileIds = it.file_ids!;
                  it.file_ids = fileIds.map((item) => {
                    if (item > 0 && !fileIdList.includes(item)) {
                      item = -item;
                      shouldUpdateMessages = true;
                    }
                    return item;
                  });
                });

                return neoIt;
              });

              console.log("updatedSessionMessages", updatedSessionMessages);

              if (shouldUpdateMessages) {
                get().updateCurrentSession((session1) => {
                  session1.messages = updatedSessionMessages;
                  session1.fileCount = filteredList.length;
                }, session.conversationId);
              }
            },

            updateSessionPendingList(fileList) {
              const filteredFileList = fileList.map((it) => {
                return {
                  fileName: it.fileName,
                  uploadId: it.uploadId,
                  uuid: it.uuid,
                  fileType: it.fileType ?? UploadFileType.ASSISTANT,
                  imageUrl: it.imageUrl,
                  filePath: it.filePath,
                };
              });
              get().updateCurrentSession((session) => {
                session.pendingFileList = fileList;
              });
            },
          }) as ChatStore,
        {
          name: "chat_channel",
        },
      ),
      {
        name: StoreKey.Chat,
        version: 8,
        migrate(persistedState, version) {
          const state = persistedState as any;
          const newState = JSON.parse(JSON.stringify(state)) as ChatStore;

          if (version < 2) {
            newState.globalId = 0;
            newState.sessions = [];

            const oldSessions = state.sessions;
            for (const oldSession of oldSessions) {
              const newSession = createEmptySession();
              newSession.topic = oldSession.topic;
              newSession.messages = [...oldSession.messages];
              newSession.mask.modelConfig.sendMemory = true;
              newSession.mask.modelConfig.historyMessageCount = 4;
              newSession.mask.modelConfig.compressMessageLengthThreshold = 1000;
              newState.sessions.push(newSession);
            }
          }

          if (version <= 4) {
            const oldSessions = newState.sessions;
            const uid = useUserStore.getState().id;
            const neoSessions = [];
            for (const oldSession of oldSessions) {
              oldSession.uid = uid;
              neoSessions.push(oldSession);
            }
            newState.sessions = neoSessions;
          }

          if (version <= 6) {
            const oldSessions = newState.sessions;
            const neoSessions = [];
            for (const session of oldSessions) {
              if (!session.conversationId || session.conversationId === "") {
                session.conversationId = v4();
              }
              let shouldCountFile = false;
              let fileCount = session.fileCount;
              if (fileCount === undefined) {
                session.fileCount = 0;
                fileCount = 0;
                shouldCountFile = true;
              }

              if (session.messages && session.messages.length > 0) {
                const transformedMessages = session.messages.map((it: any) => {
                  if (it.isError || it.role !== "user") {
                    return it;
                  }
                  if (
                    it.isTransformed === undefined ||
                    Math.abs(it.isTransformed) <= 1
                  ) {
                    const transformed = mdTransform(it.content);
                    const result: ChatMessage = { ...it, ...transformed };
                    if (shouldCountFile && result.file_list) {
                      result.file_list.forEach((item) => {
                        if (item.file_id > 0) {
                          fileCount!++;
                        }
                      });
                    }

                    return result;
                  }

                  if (shouldCountFile && it.file_list) {
                    it.file_list.forEach((item: any) => {
                      if (item.file_id > 0) {
                        fileCount!++;
                      }
                    });
                  }

                  return it;
                });

                session.messages = transformedMessages;
              }

              if (session.pendingFileList === undefined) {
                session.pendingFileList = [];
              }

              if (shouldCountFile) {
                session.fileCount = fileCount;
              }

              if (session.currentModel === undefined) {
                session.currentModel = 0;
              }

              neoSessions.push(session);
            }

            newState.sessions = neoSessions;
          }

          if (version <= 7) {
            const oldSessions = newState.sessions;
            const neoSessions = [];
            for (const oldSession of oldSessions) {
              const messages = [];
              for (const message of oldSession.messages) {
                if (message.file_list && message.file_list.length > 0) {
                  message.file_list = message.file_list.map((it) => {
                    return { ...it, fileType: UploadFileType.ASSISTANT };
                  });
                }
                messages.push(message);
              }
              oldSession.messages = messages;
              neoSessions.push(oldSession);
            }

            newState.sessions = neoSessions;
          }

          return newState;
        },
        storage: {
          async getItem(name): Promise<StorageValue<ChatStore> | null> {
            const localStorageData = window.localStorage.getItem(name);
            let localStorageJson = null;
            if (localStorageData != null) {
              localStorageJson = JSON.parse(localStorageData);
            }
            const indexedDbData = await localforage.getItem(name);
            if (!indexedDbData && localStorageData) {
              await localforage.setItem(name, localStorageJson);
            } else if (
              indexedDbData &&
              localStorageJson &&
              // @ts-ignore
              indexedDbData.version <= 5
            ) {
              await localforage.setItem(name + "_backup", indexedDbData);
              await localforage.removeItem(name);
              await localforage.setItem(name, localStorageJson);
            }
            return await localforage.getItem(name);
          },
          async setItem(name, storageValue) {
            await localforage.setItem(
              name,
              JSON.parse(JSON.stringify(storageValue)),
            );
          },
          async removeItem(name): Promise<void> {
            console.log("chat remove all items");
            return await localforage.removeItem(name);
          },
        },
      },
    ),
    {
      enabled: isInDev(),
      name: StoreKey.Chat,
    },
  ),
);
