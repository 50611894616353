"use client";

import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import {
  NavLink,
  Outlet,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NewsPath, NewsRoutes, Path } from "@/app/constant";
import { formatRelativeCustom, useMobileScreen } from "@/app/utils";
import { useAppConfig } from "@/app/store";
import { useVirtualizer } from "@tanstack/react-virtual";
import { NewsListItemData, useNewsStore } from "@/app/store/news";
import { NewsType } from "@/app/enums/newsTypes";
import dynamic from "next/dynamic";
import { Loading } from "@/app/components/home";

const HtmlContentDisplay = dynamic(
  () =>
    import("../../html").then((it) => ({
      default: it.HtmlContentDisplay,
    })),
  {
    loading: () => <Loading noLogo={true}></Loading>,
    ssr: false,
  },
);

export const NewsPageEntry = function () {
  const navigate = useNavigate();
  const isMobileScreen = useMobileScreen();

  const clickToNewsPage = useCallback(() => {
    navigate(Path.News);
    if (isMobileScreen) {
      useAppConfig.setState({ showChatList: false });
    }
  }, [navigate, isMobileScreen]);

  return (
    <>
      <div
        css={css`
          margin-left: 20px;
          margin-right: 20px;
          content: " ";
          height: 1px;
          overflow: hidden;
          background-color: var(--hover-color);
          margin-bottom: 4px;
        `}
      ></div>
      <div
        css={css`
          margin-left: 10px;
          margin-right: 10px;
          display: flex;
          width: calc(100% - 20px);
          box-sizing: border-box;
          padding-left: 12px;
          padding-right: 12px;
          height: 40px;
          cursor: pointer;
          margin-bottom: 8px;
          border-radius: 4px;

          :hover {
            background-color: var(--hover-color);
          }
        `}
        onClick={clickToNewsPage}
      >
        <FontAwesomeIcon
          icon={faNewspaper}
          width={20}
          height={20}
          css={css`
            margin-top: auto;
            margin-bottom: auto;
          `}
        ></FontAwesomeIcon>
        <span
          css={css`
            display: block;
            font-size: 14px;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 8px;
          `}
        >
          资讯
        </span>
      </div>
    </>
  );
};

export const NewsPage = function () {
  const isMobileScreen = useMobileScreen();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
        width: 100%;
        max-width: 840px;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
      `}
    >
      <div
        css={css`
          display: flex;
          height: 40px;
          margin-top: 20px;
        `}
      >
        <NewsNavLink subPath={NewsPath.Tech} title={"科技"} />
      </div>
      <div
        css={css`
          display: flow-root;
          flex: 1;
          overflow: hidden;
        `}
      >
        <Outlet></Outlet>
      </div>
    </div>
  );
};

const NewsNavLink = function (props: { subPath: string; title: string }) {
  const match = useMatch(Path.News + props.subPath);

  return (
    <div
      css={[
        css`
          margin-left: 10px;
          margin-right: 10px;
          height: 40px;
          padding-left: 20px;
          padding-right: 20px;
          border-radius: 20px;
          color: var(--text-primary);
        `,
        !!match &&
          css`
            background-color: var(--primary);
          `,
      ]}
    >
      <NavLink
        to={Path.News + props.subPath}
        css={css`
          text-decoration: none;
          text-decoration-color: var(--text-primary);
          color: var(--text-primary);
          display: block;
          height: 100%;
          font-size: 16px;
          line-height: 40px;
        `}
      >
        {props.title}
      </NavLink>
    </div>
  );
};

export const NewsListBody = function (props: { type: NewsType }) {
  const newsStore = useNewsStore();
  const virtualParentRef = useRef<HTMLDivElement>(null);
  const items = useMemo(() => {
    return newsStore.data.get(NewsType.News) ?? [];
  }, [newsStore]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [page, setPage] = useState(1);
  const pageRef = useRef(0);

  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement() {
      return virtualParentRef.current;
    },
    estimateSize() {
      return 75;
    },
    enabled: true,
    scrollPaddingEnd: 50,
  });

  useEffect(() => {
    if (
      useNewsStore.getState().data.size <= 0 ||
      !useNewsStore.getState().data.get(NewsType.News)
    ) {
      if (pageRef.current <= 0) {
        pageRef.current += 1;
      } else {
        return;
      }
      useNewsStore
        .getState()
        .fetchNewsList(NewsType.News, 1)
        .then((it) => {
          void useNewsStore.getState().updateData((data) => {
            data.set(NewsType.News, it);
          });
        });
    }
  }, []);

  const virtualItems = virtualizer.getVirtualItems();

  useEffect(() => {
    const lastItem = virtualItems.at(-1);
    if (!lastItem) {
      return;
    }
    if (lastItem.index >= items.length - 1 && hasNextPage) {
      if (pageRef.current >= page + 1) {
        return;
      }
      pageRef.current += 1;
      useNewsStore
        .getState()
        .fetchNewsList(NewsType.News, page + 1)
        .then((it) => {
          void useNewsStore.getState().updateData((data) => {
            const arr = data.get(NewsType.News)!;
            arr.push(...it);
            data.set(NewsType.News, arr);
            if (it.length > 0) {
              setPage(page + 1);
            } else {
              setHasNextPage(false);
              pageRef.current -= 1;
            }
          });
        });
    }
  }, [virtualItems, items, hasNextPage, page]);

  return (
    <div
      css={css`
        display: flex;
        margin-top: 12px;
        height: 100%;
        label: news-list-body;
      `}
    >
      <div
        css={css`
          position: relative;
          height: 100%;
          width: 100%;
          max-height: 100%;
          overflow-y: auto;
        `}
        ref={virtualParentRef}
      >
        <div
          css={css`
            position: relative;
            width: 100%;
          `}
          style={{ height: virtualizer.getTotalSize() }}
        >
          <div
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
            `}
            style={{
              transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
            }}
          >
            {virtualItems.map((item, index) => {
              return (
                <div
                  key={item.key}
                  ref={virtualizer.measureElement}
                  data-index={item.index}
                  style={{ display: "flow-root" }}
                >
                  <NewsListItem
                    key={item.key}
                    data={items.at(item.index)}
                    type={NewsType.News}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const NewsListItem = function (props: {
  data?: NewsListItemData;
  type: NewsType;
}) {
  let url = Path.News + NewsRoutes.Details;
  url += `?type=news_source_1&date=${encodeURIComponent(props.data?.date ?? "")}&category=${encodeURIComponent(props.data?.category ?? "")}&subCategory=${encodeURIComponent(props.data?.subCategory ?? "")}&id=${encodeURIComponent(props.data?.id ?? "")}`;

  return (
    <>
      <NavLink
        to={url}
        css={css`
          display: flex;
          flex-direction: column;
          text-decoration: none;
          color: var(--text-primary);
          margin-top: 16px;
          margin-bottom: 16px;
        `}
        target={"_blank"}
      >
        <div
          css={css`
            display: -webkit-box;
            font-size: 28px;
            color: var(--text-primary);
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.3;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {props.data?.title}
        </div>
        <div
          css={css`
            margin-top: 8px;
          `}
        >
          <span
            css={css`
              font-size: 14px;
              color: var(--text-secondary);
            `}
          >
            {formatRelativeCustom(
              parseInt(props.data?.timestamp ?? Date.now().toString()),
            )}
          </span>
        </div>
      </NavLink>
    </>
  );
};

export const NewsDetailPage = function () {
  const [searchParams, setSearchParams] = useSearchParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const source = searchParams.get("type") ?? "";
  const id = searchParams.get("id") ?? "";
  const category = searchParams.get("category") ?? "";
  const subCategory = searchParams.get("subCategory") ?? "";
  const date = searchParams.get("date") ?? "";

  useEffect(() => {
    if (title.length <= 0) {
      useNewsStore
        .getState()
        .fetchNewsDetail({
          type: NewsType.News,
          id: id,
          category: category,
          subCategory: subCategory,
          date: date,
        })
        .then(({ title, content }) => {
          if (title.length > 0) {
            setTitle(title);
          }
          if (content.length > 0) {
            setContent(content);
          }
        });
    }
  }, [source, id, category, subCategory, title, date]);

  return (
    <>
      <div
        css={css`
          width: 100%;
          height: 100%;
          display: flow-root;
          overflow-y: scroll;
        `}
      >
        <div
          css={css`
            width: 100%;
            max-width: 840px;
            box-sizing: border-box;
            padding-left: 20px;
            padding-right: 20px;
            margin-left: auto;
            margin-right: auto;
            display: flow-root;
            height: auto;
          `}
        >
          <div
            css={css`
              width: 100%;
              height: auto;
              display: flow-root;
            `}
          >
            <div
              css={css`
                margin-top: 12px;
                width: 100%;
                font-size: 24px;
                line-height: 1.3;
                color: var(--text-primary);
                max-width: 624px;
                margin-right: auto;
                margin-left: auto;
              `}
            >
              {title}
            </div>
            <div
              css={css`
                display: flow-root;
                margin-top: 12px;
              `}
            >
              <HtmlContentDisplay content={content}></HtmlContentDisplay>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
