"use client";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";
import {
  DEFAULT_CONFIG,
  Theme,
  useAccessStore,
  useAppConfig,
  useChatStore,
} from "@/app/store";
import { UploadFileType, useUploadFileStore } from "@/app/store/uploadFiles";
import { showToast } from "@/app/components/ui-lib";
import { history } from "@/app/utils/history";
import { LAST_INPUT_KEY, Path } from "@/app/constant";
import { eventBus } from "@/app/utils/eventBus";
import { EventBus } from "@/app/enums/eventBusName";
import { useUserStore } from "@/app/store/user";
import { useWindowSizeWithCallback } from "@/app/utils";
import { v4 } from "uuid";
import { useShallow } from "zustand/react/shallow";
import isEqual from "react-fast-compare";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import { LayoutType } from "@/app/enums/layoutType";
import { useSiteType } from "@/app/store/siteType";
import { SiteType } from "@/app/enums/siteType";
import { ConversationIdContext } from "@/app/contexts/chatIdContext";

const onLineSearchSwitchWidth = 1040;
const onLineSearchSwitchWidthV2 = 640;

export const useSubmit = function () {
  const conversationId = useContext(ConversationIdContext);
  const [, startTransition] = useTransition();

  const doSubmit = useCallback(
    (userInput: string, ignoreEmptyInput = false) => {
      if (userInput.trim() === "" && !ignoreEmptyInput) return;
      if (!useAccessStore.getState().isAuthorized()) {
        showToast("尚未登录", {
          text: "去登录",
          onClick() {
            history.replace(Path.Auth);
          },
        });
        return;
      }

      startTransition(() => {
        const session = useChatStore.getState().currentSession(conversationId);
        eventBus.emit(EventBus.Global.Status.LOADING, true);
        useChatStore
          .getState()
          .onUserInput(userInput, conversationId)
          .then(() => {
            eventBus.emit(EventBus.Global.Status.LOADING, false);
            useUploadFileStore
              .getState()
              .updatePendingFileList([], session!.id);
            eventBus.emit(EventBus.Global.ChatBody.Scroll.SCROLL_BOTTOM);
          });
        localStorage.setItem(LAST_INPUT_KEY, userInput);
        eventBus.emit(EventBus.PromptInput.Action.FOCUS);
      });
    },
    [conversationId],
  );

  return doSubmit;
};

export const useHandleUsageTypeChange = function (props?: {
  shouldNotSaveStore?: boolean;
}): [string, (value: string) => void] {
  const conversationId = useContext(ConversationIdContext);
  const userStore = useUserStore();
  const config = useAppConfig();
  const chatStore = useChatStore();
  // const [balance, setBalance] = useState(0);
  const [usageTypeValue, setUsageTypeValue] = useState(
    chatStore.currentSession(conversationId)?.currentModel?.toString() ?? "0",
  );

  const [, startTransition] = useTransition();

  const session = chatStore.currentSession(conversationId);

  const intUsageTypeValue = parseInt(usageTypeValue);

  if (
    session?.currentModel !== undefined &&
    session.currentModel !== intUsageTypeValue &&
    !isNaN(intUsageTypeValue) &&
    !props?.shouldNotSaveStore
  ) {
    setUsageTypeValue(session.currentModel.toString());
  }

  // 使用模型切换
  const handleUsageTypeChange = useCallback(
    (value: string) => {
      console.log("选项变化了,开始设置", userStore);
      const usageType = parseInt(value);
      console.log("usageType", usageType);
      if (!props?.shouldNotSaveStore) {
        userStore.updateUsageType(usageType);
        chatStore.updateCurrentSession((session) => {
          session.currentModel = usageType;
        }, conversationId);
      }
      startTransition(() => {
        if (usageType == 0) {
          // setBalance(userStore.balance3_5 || 0);
          setUsageTypeValue("0");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) => (config.modelConfig = DEFAULT_CONFIG.model3_5Config),
            );
          }
          return;
        }
        if (usageType == 1) {
          // setBalance(userStore.balance4_0 || 0);
          setUsageTypeValue("1");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) => (config.modelConfig = DEFAULT_CONFIG.model4_0Config),
            );
          }
          return;
        }
        if (usageType == 2) {
          // setBalance(userStore.balance_dall_e || 0);
          setUsageTypeValue("2");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) => (config.modelConfig = DEFAULT_CONFIG.model4_0Config),
            );
          }
          return;
        }
        if (usageType == 3) {
          // setBalance(userStore.balance4_0 || 0);
          setUsageTypeValue("3");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) => (config.modelConfig = DEFAULT_CONFIG.model4_0Config),
            );
          }
          return;
        }
        if (usageType == 4) {
          // setBalance(userStore.balance3_5 || 0);
          setUsageTypeValue("4");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) =>
                (config.modelConfig = DEFAULT_CONFIG.model_ernie_3_5_config),
            );
          }
          return;
        }
        if (usageType == 5) {
          // setBalance(userStore.balance4_0 || 0);
          setUsageTypeValue("5");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) =>
                (config.modelConfig = DEFAULT_CONFIG.model_qwen_3_5_config),
            );
          }
          return;
        }
        if (usageType == 6) {
          // setBalance(userStore.balance4_0 || 0);
          setUsageTypeValue("6");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) => (config.modelConfig = DEFAULT_CONFIG.model4_0Config),
            );
          }
          return;
        }
        if (usageType == 7) {
          // setBalance(userStore.balance4_0 || 0);
          setUsageTypeValue("7");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) => (config.modelConfig = DEFAULT_CONFIG.model4_0Config),
            );
          }
          return;
        }
        if (usageType == 8) {
          // setBalance(userStore.balance_dall_e || 0);
          setUsageTypeValue("8");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) =>
                (config.modelConfig = DEFAULT_CONFIG.model_qwen_3_5_config),
            );
          }
          return;
        }
        if (usageType == 9) {
          // setBalance(userStore.balance_dall_e || 0);
          setUsageTypeValue("9");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) => (config.modelConfig = DEFAULT_CONFIG.model4_0Config),
            );
          }
          return;
        }
        if (usageType == 10) {
          // setBalance(userStore.balance3_5 || 0);
          setUsageTypeValue("10");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) => (config.modelConfig = DEFAULT_CONFIG.model3_5Config),
            );
          }
          return;
        }
        if (usageType == 11) {
          // setBalance(userStore.balance4_0 || 0);
          setUsageTypeValue("11");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) => (config.modelConfig = DEFAULT_CONFIG.model4_0Config),
            );
          }
          return;
        }
        if (usageType == 12) {
          setUsageTypeValue("12");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) => (config.modelConfig = DEFAULT_CONFIG.model3_5Config),
            );
          }
          return;
        }
        if (usageType == 13) {
          setUsageTypeValue("13");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) => (config.modelConfig = DEFAULT_CONFIG.model4_0Config),
            );
          }
          return;
        }
        if (usageType == 14) {
          setUsageTypeValue("14");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) => (config.modelConfig = DEFAULT_CONFIG.model4_0Config),
            );
          }
          return;
        }
        if (usageType == 15) {
          setUsageTypeValue("15");
          if (!props?.shouldNotSaveStore) {
            config.update(
              (config) => (config.modelConfig = DEFAULT_CONFIG.model4_0Config),
            );
          }
        }
        return;
      });
    },
    [userStore, config, chatStore, conversationId, props?.shouldNotSaveStore],
  );

  return [usageTypeValue, handleUsageTypeChange];
};
export const useSearchWiderLayout = function () {
  let initialWindowWidth = 0;
  if (typeof window !== "undefined") {
    initialWindowWidth = window.innerWidth;
  }
  const layout = useLayoutConfigStore();

  const [searchLayoutWider, setSearchLayoutWider] = useState(
    initialWindowWidth >
      (layout.type === LayoutType.Layout_v1
        ? onLineSearchSwitchWidth
        : onLineSearchSwitchWidthV2),
  );

  useWindowSizeWithCallback([
    (width) => {
      const currentState =
        width >
        (layout.type === LayoutType.Layout_v1
          ? onLineSearchSwitchWidth
          : onLineSearchSwitchWidthV2);
      if (currentState !== searchLayoutWider) {
        setSearchLayoutWider(currentState);
      }
    },
  ]);

  return searchLayoutWider;
};

export const useUpdatePendingFileByType = function (props?: {
  onSuccess?: () => void;
  onError?: (e: Error) => void;
}) {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)),
  );
  const propsRef = useRef(props);
  if (!isEqual(propsRef.current, props)) {
    propsRef.current = props;
  }

  const updatePendingFileByType = useCallback(
    function (file: File, fileType: UploadFileType, replace = true) {
      const pendingList = useUploadFileStore
        .getState()
        .getPendingFileList(session!.id);
      const uuid = v4();
      const fileItem = pendingList.find((it) => it.fileType === fileType);
      if (fileItem && replace) {
        const neoPendingList = pendingList.map((it) => {
          if (it.uuid === fileItem.uuid) {
            it.file = file;
            it.uploadId = -1;
            it.uuid = uuid;
            it.filePath = "";
            it.imageUrl = "";
            it.fileName = "";
          }
          return it;
        });
        useUploadFileStore
          .getState()
          .updatePendingFileList(neoPendingList, session!.id);
      } else {
        const neoPendingList = [
          {
            uuid,
            fileType,
            file,
            fileName: "",
            uploadId: -1,
          },
          ...pendingList,
        ];
        useUploadFileStore
          .getState()
          .updatePendingFileList(neoPendingList, session!.id);
      }
      useUploadFileStore.getState().uploadFileWithProgress({
        uuid,
        fileType,
        conversationId: session!.conversationId!,
        onSuccess(fileName, fileId, imageUrl, filePath) {
          const neoPendingList = useUploadFileStore
            .getState()
            .getPendingFileList(session!.id);
          const mappedList = neoPendingList.map((it) => {
            if (it.uuid === uuid) {
              it.fileName = fileName;
              it.uploadId = fileId;
              it.imageUrl = imageUrl;
              it.filePath = filePath;
              it.uuid = v4();
            }
            return it;
          });
          useUploadFileStore
            .getState()
            .updatePendingFileList(mappedList, session!.id);
          if (propsRef.current?.onSuccess) {
            propsRef.current.onSuccess();
          }
        },
        onError(error) {
          if (propsRef.current?.onError) {
            propsRef.current.onError(error);
          }
        },
      });
    },
    [session],
  );

  return updatePendingFileByType;
};

export const useDeleteByType = function () {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)),
  );
  const uploadFilesStore = useUploadFileStore();

  const deleteByType = useCallback(
    (type: UploadFileType) => {
      const pendingList = session!.pendingFileList!;
      const item = pendingList.find((it) => it.fileType === type);
      if (!item) {
        return;
      }
      const neoPendingList = pendingList.filter((it) => it.uuid !== item.uuid);
      uploadFilesStore.updatePendingFileList(neoPendingList, session!.id);
      if (item.uploadId !== undefined && item.uploadId > 0) {
        useChatStore.getState().deleteChatFile(item.uploadId);
      }
    },
    [session, uploadFilesStore],
  );

  return deleteByType;
};

export const useCurrentTheme = function () {
  const theme = useAppConfig((state) => state.theme);
  const [currentTheme, setCurrentTheme] = useState(
    theme === Theme.Auto ? Theme.Light : theme,
  );

  useEffect(() => {
    const darkModeMatch = window.matchMedia("(prefers-color-scheme: dark)");
    const themeChangeListener = function () {
      if (
        theme === Theme.Auto &&
        (currentTheme === Theme.Dark) !== darkModeMatch.matches
      ) {
        setCurrentTheme(darkModeMatch.matches ? Theme.Dark : Theme.Light);
      }
    };
    darkModeMatch.addEventListener("change", themeChangeListener);
    if (theme === Theme.Auto) {
      themeChangeListener();
    } else if (
      (theme === Theme.Light || theme === Theme.Dark) &&
      theme !== currentTheme
    ) {
      setCurrentTheme(theme);
    }
    return () => {
      darkModeMatch.removeEventListener("change", themeChangeListener);
    };
  }, [currentTheme, theme]);

  return currentTheme;
};

export const useModelSelectOptions = function () {
  const site = useSiteType();
  const userStore = useUserStore();

  const modelOptions = useMemo(() => {
    let modelOptions: { value: string; label: string; chatOnly: boolean }[] =
      [];
    if (
      site.siteType === SiteType.UNDETERMINED ||
      site.siteType === SiteType.NORMAL ||
      site.siteType === SiteType.DEV ||
      site.siteType === SiteType.ISSUER ||
      site.siteType === SiteType.PAPER666
    ) {
      modelOptions = [
        { value: "0", label: "GPT-3.5", chatOnly: true },
        { value: "1", label: "GPT-4.0", chatOnly: true },
        { value: "2", label: "文生图", chatOnly: false },
      ];
      if (userStore.role === 2) {
        modelOptions.push(
          { value: "3", label: "CAIE-助手", chatOnly: false },
          { value: "4", label: "文心一言", chatOnly: true },
          { value: "5", label: "通义千问", chatOnly: true },
          { value: "6", label: "Stable Diffusion", chatOnly: false },
          { value: "7", label: "智能PPT", chatOnly: false },
          { value: "8", label: "通义万相", chatOnly: false },
          { value: "9", label: "佐糖", chatOnly: false },
          // { value: "10", label: "CAIE-搜索" },
        );
      } else if ([3, 4, 5].includes(userStore.role)) {
        modelOptions.push({
          value: "7",
          label: "智能PPT",
          chatOnly: false,
        });
      }
      if (userStore.vip_type === 3) {
        if (modelOptions.find((it) => it.value === "7") === undefined) {
          modelOptions.push({
            value: "7",
            label: "智能PPT",
            chatOnly: false,
          });
        }
      }
      modelOptions.push({
        value: "11",
        label: "思维导图",
        chatOnly: false,
      });
      if (userStore.role === 2) {
        modelOptions.push(
          {
            value: "12",
            label: "Gemini",
            chatOnly: true,
          },
          {
            value: "13",
            label: "Claude",
            chatOnly: true,
          },
          {
            value: "14",
            label: "CAIE-O1-Preview",
            chatOnly: true,
          },
          {
            value: "15",
            label: "CAIE-O1-mini",
            chatOnly: true,
          },
        );
      }
    } else if (site.siteType === SiteType.TRAIN) {
      modelOptions = [
        { value: "2", label: "文生图1", chatOnly: false },
        { value: "4", label: "文心一言", chatOnly: true },
        { value: "5", label: "通义千问", chatOnly: true },
        { value: "6", label: "文生图2", chatOnly: false },
        { value: "7", label: "智能PPT", chatOnly: false },
        // { value: "8", label: "通义万相" },
        { value: "9", label: "佐糖", chatOnly: false },
        { value: "11", label: "思维导图", chatOnly: true },
      ];
    } else if (site.siteType === SiteType.EXAM) {
      modelOptions = [
        { value: "2", label: "文生图1", chatOnly: false },
        { value: "4", label: "文心一言", chatOnly: true },
        { value: "5", label: "通义千问", chatOnly: true },
        // { value: "6", label: "文生图2", chatOnly: false },
        { value: "7", label: "智能PPT", chatOnly: false },
        // { value: "8", label: "通义万相", chatOnly: false },
        { value: "9", label: "佐糖", chatOnly: false },
        { value: "11", label: "思维导图", chatOnly: false },
      ];
    }
    return modelOptions;
  }, [site.siteType, userStore.role, userStore.vip_type]);

  return modelOptions;
};
