"use client";
import { Analytics } from "@vercel/analytics/react";

import { Home } from "./components/home";

import { getServerSideConfig } from "./config/server";

import {
  legacyLogicalPropertiesTransformer,
  StyleProvider,
} from "@ant-design/cssinjs";
import "core-js/full";
import { enableMapSet } from "immer";
import { ConfigProvider } from "antd";

enableMapSet();

const serverConfig = getServerSideConfig();

export default function App() {
  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        components: {
          Button: {
            textHoverBg: "var(--hover-color)",
          },
        },
      }}
    >
      <StyleProvider
        hashPriority="high"
        transformers={[legacyLogicalPropertiesTransformer]}
      >
        <Home />
        {serverConfig?.isVercel && <Analytics />}
      </StyleProvider>
    </ConfigProvider>
  );
}
