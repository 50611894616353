"use client";
import { create } from "zustand";
import { LayoutType } from "@/app/enums/layoutType";
import { getLayoutConfig } from "@/app/utils/getLayoutType";

export type LayoutConfigStore = {
  type: LayoutType;
  updateLayout: (layout: LayoutType) => void;
};

export const useLayoutConfigStore = create<LayoutConfigStore>(
  (setState, getState, store) => {
    let result = {
      type: LayoutType.Layout_v1,
      updateLayout: (layout: LayoutType) => {
        setState({ type: layout });
      },
    } as LayoutConfigStore;
    result.type = getLayoutConfig();
    return result;
  },
);
