"use client";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Path, StoreKey } from "../constant";
import storage from "@/app/store/localStorage";
import { useNavigate } from "react-router-dom";

import { message } from "antd";
import { useAccessStore } from "@/app/store/access";
import { history } from "@/app/utils/history";
import { createWithEqualityFn } from "zustand/traditional";
import isEqual from "react-fast-compare";
import { jsonResponseProcess } from "@/app/utils/responseProcess";
import { getCurrentClient } from "@/app/enums/client";

const cache = storage<string>();
const TOKEN_STORAGE_KEY = "key_asst_token";

export interface PayControlStore {
  payCode: string; // 支付二维码
  token: string;
  orderId: string;
  lenovoPayUrl?: string;

  fetchPayCode(
    amount: string,
    platform: string,
    onError?: (msg: string) => void,
  ): void; // 获取支付二维码
  fetchPayStatus(): void; // 检查支付状态
}

export const usePayStore = createWithEqualityFn<PayControlStore>()(
  persist(
    (set, get) => ({
      payCode: "",
      token: "",
      orderId: "",
      lenovoPayUrl: "",
      // 获取支付二维码
      fetchPayCode(
        amount: string,
        platform: string,
        onError?: (msg: string) => void,
      ) {
        const formData = new FormData();
        formData.append("type", "4");
        formData.append("recharge_amount", amount);
        formData.append("platform", platform);
        formData.append("client", getCurrentClient());
        fetch("/v1/asst/order", {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${useAccessStore.getState().token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("返回的数据是", data);
            console.log("返回的数据是2", data.code);
            console.log("返回的数据是3", data.code == 0);
            console.log("返回的数据是4", data.qrcode);
            if (data.code === 0) {
              set(() => ({
                payCode: data.qrcode,
                orderId: data.order_id,
                lenovoPayUrl: data.pay_data ?? "",
              }));
            } else {
              console.log(data.msg);
              if (onError) {
                onError(data.msg || "获取支付信息失败,请稍后再试");
              }
              void jsonResponseProcess(data);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            if (onError) {
              onError("获取支付信息失败,请稍后再试");
            }
          });
      },
      // 获取支付状态
      fetchPayStatus() {
        const orderId = get().orderId;
        if (!orderId) {
          return;
        }
        // 使用fetch发起POST请求
        fetch(`/v1/asst/order?id=${orderId}&op=check`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${useAccessStore.getState().token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            if (data.code == 0) {
              message.success("支付成功");
              history.push(Path.Chat);
              return;
            }
          }) // 在控制台输出响应数据，你可以根据需要进行处理
          .catch((error) => console.error("Error:", error));
      },
    }),
    {
      name: StoreKey.Pay,
      version: 1,
    },
  ),
  isEqual,
);
