"use client";
import { persist } from "zustand/middleware";
import { StoreKey } from "../constant";
import storage from "@/app/store/localStorage";
import { useAccessStore } from "@/app/store/access";
import { createWithEqualityFn } from "zustand/traditional";
import isEqual from "react-fast-compare";
import { jsonResponseProcess } from "@/app/utils/responseProcess";

const cache = storage<string>();
export interface UserStore {
  id: string; // 用户id
  pg_uid: string; // 论坛uid
  create_time: string; // 创建日期
  vip_type: number; // vip类型,0:非会员,1:年度会员,2:月度会员
  balance3_5: number; // 用户3.5剩余次数
  balance4_0: number; // 用户4.0剩余次数
  balance_dall_e: number;
  usage_type: number; // 使用模型类型,0:3.5,1:4.0
  token: number;
  balance_vip_3_text_generation: number;
  balance_vip_3_text_to_image: number;
  balance_vip_3_image_update: number;
  balance_vip_3_ppt: number;
  balance_asst: number;
  paper_app_token: number;
  // 用户权限
  role: number;

  getUserInfo(): void;

  updateBalance: (
    balance3_5: number,
    balance4_0: number,
    token: number,
    balance_dall_e: number,
  ) => void;
  updateUsageType: (usageType: number) => void;
}

export const useUserStore = createWithEqualityFn<UserStore>()(
  persist(
    (set) => ({
      id: "",
      pg_uid: "",
      create_time: "",
      vip_type: 0,
      balance3_5: 0,
      balance4_0: 0,
      balance_dall_e: 0,
      usage_type: 0,
      token: 0,
      role: 0,
      balance_vip_3_image_update: 0,
      balance_vip_3_text_generation: 0,
      balance_vip_3_text_to_image: 0,
      balance_vip_3_ppt: 0,
      balance_asst: 0,
      paper_app_token: 0,
      updateBalance(
        balance3_5: number,
        balance4_0: number,
        token,
        balance_dall_e,
      ) {
        set({
          balance3_5: balance3_5,
          balance4_0: balance4_0,
          balance_dall_e: balance_dall_e,
          token: token,
        });
      },
      updateUsageType(usageType: number) {
        set({
          usage_type: usageType,
        });
      },
      getUserInfo(): void {
        console.log("开始获取用户信息");
        fetch(`/v1/asst/user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${useAccessStore.getState().token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            if (data.code == 0) {
              const userInfo = {
                id: data.data.id,
                pg_uid: data.data.pg_uid,
                create_time: data.data.create_time,
                vip_type: data.data.vip_type,
                balance3_5: data.data.balance3_5,
                balance4_0: data.data.balance4_0,
                balance_dall_e: data.data.balance_dall_e ?? 0,
                token: data.data.tokens,
                role: data.data.role,
                balance_vip_3_text_generation:
                  data.data.balance_vip_3_text_generation ?? 0,
                balance_vip_3_image_update:
                  data.data.balance_vip_3_image_update ?? 0,
                balance_vip_3_text_to_image:
                  data.data.balance_vip_3_text_to_image ?? 0,
                balance_vip_3_ppt: data.data.balance_vip_3_ppt ?? 0,
                balance_asst: data.data.balance_asst ?? 0,
                paper_app_token: data.data.paper_app_token ?? 0,
              };
              set({ ...userInfo });
            } else {
              void jsonResponseProcess(data);
            }
          }) // 在控制台输出响应数据，你可以根据需要进行处理
          .catch((error) => console.error("Error:", error));
      },
    }),

    {
      name: StoreKey.User,
      version: 5,
      migrate(state, version) {
        if (version <= 1) {
          // @ts-ignore
          state.token = 0;
        }
        if (version <= 2) {
          // @ts-ignore
          state.role = 0;
        }
        if (version <= 3) {
          // @ts-ignore
          state.balance_dall_e = 0;
        }
        if (version <= 4) {
          // @ts-ignore
          state.balance_vip_3_text_generation = 0;
          // @ts-ignore
          state.balance_vip_3_text_to_image = 0;
          // @ts-ignore
          state.balance_vip_3_image_update = 0;
        }
        console.log(state);
        return state as UserStore;
      },
    },
  ),
  isEqual,
);
