"use client";

import { match } from "ts-pattern";
import { useChannelStore } from "@/app/store/channel";
import { Channel } from "@/app/enums/channel";
import { useMemo } from "react";

export enum PaymentPlatform {
  ALIPAY = "alipay",
  WECHAT = "wxpay",
  LENOVO = "lxpay",
  UNKNOWN = "unknown",
}

export const matchPaymentPlatform = function (
  platform: string,
): PaymentPlatform {
  return match(platform)
    .with("alipay", () => PaymentPlatform.ALIPAY)
    .with("wxpay", () => PaymentPlatform.WECHAT)
    .with("lxpay", () => PaymentPlatform.LENOVO)
    .otherwise(() => PaymentPlatform.UNKNOWN);
};

const availablePaymentPlatforms = function (
  channel: Channel,
): PaymentPlatform[] {
  return match(channel)
    .with(Channel.NORMAL, () => {
      return [PaymentPlatform.ALIPAY, PaymentPlatform.WECHAT];
    })
    .with(Channel.LENOVO, () => {
      return [PaymentPlatform.LENOVO];
    })
    .otherwise(() => {
      return [];
    });
};

export const getAvailablePaymentPlatforms = function () {
  const channel = useChannelStore.getState().channel;
  return availablePaymentPlatforms(channel);
};

export const usePaymentPlatforms = function () {
  const channel = useChannelStore().channel;

  return useMemo(() => {
    return availablePaymentPlatforms(channel);
  }, [channel]);
};

export const scanCodeTips = function (platform: PaymentPlatform) {
  return match(platform)
    .with(PaymentPlatform.ALIPAY, () => "支付宝扫描支付码")
    .with(PaymentPlatform.WECHAT, () => "微信扫描支付码")
    .with(PaymentPlatform.LENOVO, () => "微信或支付宝扫描支付码")
    .otherwise(() => "");
};
