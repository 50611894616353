"use client";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { getHeaders } from "@/app/client/api";
import { useAccessStore } from "@/app/store/access";
import { createWithEqualityFn } from "zustand/traditional";
import isEqual from "react-fast-compare";

export type ExamUploadFile = {
  id: string;
  url: string;
  file_name: string;
  create_time: string;
  file_size: string;
};

export interface ExamUploadFilesStore {
  uploadFileList: ExamUploadFile[];
  uploadFile: (
    file: File,
    options?: {
      onError?: (error: Error) => void;
      onSuccess?: () => void;
    },
  ) => Promise<void>;
  getFileList: () => Promise<ExamUploadFile[]>;
  deleteFile: (
    id: string,
    options?: {
      onSuccess?: () => void | Promise<void>;
      onError?: (error: Error) => void | Promise<void>;
    },
  ) => Promise<void>;
}

export const useExamUploadFilesStore =
  createWithEqualityFn<ExamUploadFilesStore>()(
    immer((setState, getState, store) => {
      return {
        uploadFileList: [],
        uploadFile: async (file: File, options) => {
          let formData = new FormData();
          formData.append("token", useAccessStore.getState().token);
          if (file.type === "text/plain") {
            formData.append("text", await file.text());
          } else {
            formData.append("uploadFile", file);
          }
          fetch("/p/exam/answer", {
            method: "POST",
            body: formData,
          })
            .then((res) => res.json())
            .then((resp) => {
              console.log(resp);
              if (resp.code === 0) {
                if (options?.onSuccess) {
                  options.onSuccess();
                }
                return;
              }
              let error = new Error(resp.msg);
              if (options?.onError) {
                options.onError(error);
              }
            })
            .catch((err) => {
              console.log(err);
              if (options && options.onError) {
                options.onError(err);
              }
            });
        },
        async getFileList() {
          return new Promise((resolve, reject) => {
            fetch("/p/exam/answer", {
              method: "GET",
              headers: getHeaders(),
            })
              .then((res) => res.json())
              .then((resp) => {
                console.log(resp);
                if (resp.code === 0) {
                  setState({ uploadFileList: resp.data });
                  resolve(resp.data);
                  return;
                }
                let error = new Error(resp.msg);
                reject(error);
              })
              .catch((err) => {
                reject(err);
              });
          });
        },
        async deleteFile(id: string, options) {
          let formData = new FormData();
          formData.append("token", useAccessStore.getState().token);
          formData.append("id", id);
          fetch("/p/exam/answer_del", {
            method: "POST",
            body: formData,
          })
            .then((resp) => resp.json())
            .then((resp) => {
              console.log(resp);
              if (resp.code === 0) {
                options?.onSuccess?.();
                store.getState().getFileList();
                return;
              }
              let error = new Error(resp.msg);
              options?.onError?.(error);
            })
            .catch((e) => {
              options?.onError?.(e);
            });
        },
      };
    }),
    isEqual,
  );
