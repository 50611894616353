"use client";
import { useAccessStore } from "@/app/store";
import { useShallow } from "zustand/react/shallow";
import { useMobileScreen } from "@/app/utils";
import { css } from "@emotion/react";
import NextImage from "next/image";
import ChatLoginAvatar from "@/app/icons/layout_v2/login/login_avatar.png";
import ChatNonLoginAvatar from "@/app/icons/layout_v2/login/non_login_avatar.png";
import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import React, { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Path } from "@/app/constant";

export const LoginComponentV2 = function () {
  const isLogin = useAccessStore(useShallow((state) => state.isAuthorized()));
  const isMobileScreen = useMobileScreen();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const onOpenChange = useCallback(
    (_e: React.SyntheticEvent | null, isOpen: boolean) => {
      setDropdownOpen(isOpen);
    },
    [],
  );

  const goUser = useCallback(() => {
    navigate(Path.User);
  }, [navigate]);

  const logout = useCallback(() => {
    useAccessStore.getState().updateToken("");
    navigate(Path.Home);
  }, [navigate]);

  const login = useCallback(() => {
    if (!isLogin) {
      navigate(Path.Auth);
    }
  }, [isLogin, navigate]);

  const content = (
    <div
      css={[
        css`
          display: flex;
          cursor: pointer;
        `,
        !isMobileScreen &&
          css`
            margin-top: auto;
            margin-bottom: auto;
            height: 42px;
          `,
        isMobileScreen &&
          css`
            flex-direction: column;
          `,
      ]}
      onClick={login}
    >
      {isLogin && (
        <NextImage
          src={ChatLoginAvatar}
          alt={"登录"}
          css={[
            css`
              margin-left: auto;
              margin-right: auto;
            `,
            isMobileScreen &&
              css`
                width: 36px;
                height: 36px;
              `,
            !isMobileScreen &&
              css`
                width: 42px;
                height: 42px;
              `,
          ]}
        />
      )}
      {!isLogin && (
        <NextImage
          src={ChatNonLoginAvatar}
          alt={"用户头像"}
          css={[
            css`
              margin-left: auto;
              margin-right: auto;
            `,
            isMobileScreen &&
              css`
                width: 36px;
                height: 36px;
              `,
            !isMobileScreen &&
              css`
                width: 42px;
                height: 42px;
              `,
          ]}
        />
      )}
      {isMobileScreen && (
        <span
          css={css`
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            white-space: pre;
            color: #7a848a;
            display: block;
          `}
        >
          {isLogin ? "已登录" : "登录"}
        </span>
      )}
    </div>
  );
  let container = <>{content}</>;
  if (!isMobileScreen && isLogin) {
    container = (
      <>
        <Dropdown open={dropdownOpen} onOpenChange={onOpenChange}>
          <MenuButton
            variant="plain"
            sx={{ "--joy-palette-neutral-plainHoverBg": "var(--hover-color)" }}
          >
            {content}
            {dropdownOpen && (
              <FontAwesomeIcon
                icon={faChevronUp}
                style={{ marginLeft: "10px" }}
              />
            )}
            {!dropdownOpen && (
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ marginLeft: "10px" }}
              />
            )}
          </MenuButton>
          <Menu>
            <MenuItem>
              <div
                css={css`
                  cursor: pointer;
                  font-size: 14px;
                  color: var(--text-primary);
                `}
                onClick={goUser}
              >
                我的额度
              </div>
            </MenuItem>
            <MenuItem>
              <div
                css={css`
                  cursor: pointer;
                  font-size: 14px;
                  color: var(--text-primary);
                `}
                onClick={logout}
              >
                退出
              </div>
            </MenuItem>
          </Menu>
        </Dropdown>
      </>
    );
  }

  return container;
};
