"use client";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useTransition,
} from "react";
import { useImmer } from "use-immer";
import { ChatEnums } from "@/app/enums/chat";
import { useChatStore } from "@/app/store";
import { useDebouncedCallback } from "use-debounce";
import { produce } from "immer";
import { Input, message, Select } from "antd";
import { css } from "@emotion/react";
import { UploadFileType } from "@/app/store/uploadFiles";
import { ImageInputBlock } from "@/app/components/chatComponents/wanx";
import TextareaAutosize from "react-textarea-autosize";
import { useShallow } from "zustand/react/shallow";
import { actOnKeyDown } from "@/app/utils";
import ChatPicIcon from "@/app/icons/layout_v2/chat/pic.svg";

import {
  AdditionalSettingsPopUp,
  AdditionSettingItem,
  ChatInputAboveButtonsContainer,
  CommonPromptContainerBlock,
  CommonPromptInputBlock,
  CommonSelectBaseBlock,
} from "@/app/components/chatComponents/commonLib";
import { useBalanceStr } from "@/app/utils/userUsage";
import {
  useDeleteByType,
  useSubmit,
  useUpdatePendingFileByType,
} from "@/app/hooks/chatHooks";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import { eventBus } from "@/app/utils/eventBus";
import { EventBus } from "@/app/enums/eventBusName";
import { LayoutType } from "@/app/enums/layoutType";
import { SmallClickInput } from "@/app/components/chatComponents/v2/InputComponents";
import {
  InputMenus,
  InputMenusWrapper,
} from "@/app/components/chatComponents/v2/InputMenus";
import { MenuItem } from "@mui/joy";
import { ConversationIdContext } from "@/app/contexts/chatIdContext";

export const PicWishImageGenerateInputBlock = function () {
  const usageStr = useBalanceStr();
  const doSubmit = useSubmit();
  const [userInput, setUserInput] = useState("");
  const layout = useLayoutConfigStore();

  const submit = useCallback(
    (ignoreEmptyInput: boolean) => {
      doSubmit(userInput, ignoreEmptyInput);
      setUserInput("");
    },
    [doSubmit, userInput],
  );

  const updateUserInput = useCallback((value: string) => {
    setUserInput(value);
    useChatStore.getState().updateCurrentSession((session) => {
      session.picWishOptions!.image_url = value;
    });
  }, []);

  return (
    <CommonPromptContainerBlock usageStr={usageStr}>
      {layout.type === LayoutType.Layout_v1 && (
        <PicWishSelectOptions usageStr={usageStr} />
      )}
      {layout.type === LayoutType.Layout_v2 && (
        <ChatInputAboveButtonsContainer>
          <PicWishSelectOptions />
        </ChatInputAboveButtonsContainer>
      )}
      {layout.type === LayoutType.Layout_v1 && <PicWishImageInputBlock />}
      <PicWishPromptInputBlock
        updatePromptsInput={updateUserInput}
        submit={submit}
        inputAboveArea={<PicWishImageInputBlock />}
      >
        {layout.type === LayoutType.Layout_v2 && (
          <InputMenusWrapper left>
            <InputMenus>
              <MenuItem>
                <PicWishInputButtonOriginalImageV2 />
              </MenuItem>
            </InputMenus>
          </InputMenusWrapper>
        )}
      </PicWishPromptInputBlock>
    </CommonPromptContainerBlock>
  );
};

const PicWishSelectOptions = function (props: { usageStr?: string }) {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)!),
  );
  const [isPending, startTransition] = useTransition();
  const layout = useLayoutConfigStore();

  const picWishDebouncedStateUpdate = useDebouncedCallback(
    (options: ChatEnums.PicWish.Option) => {
      useChatStore.getState().updateCurrentSession((session1) => {
        session1.picWishOptions = options;
      }, conversationId);
    },
    100,
  );

  const [picwishOptions, updatePicwishOptions] = useImmer(
    session.picWishOptions,
  );

  useEffect(() => {
    const picWishOption = session.picWishOptions;
    if (!picWishOption) {
      const defaultOptions = {
        operation: ChatEnums.PicWish.Operation.SEGMENTATION,
        image_url: undefined,
        type: undefined,
        scale_factor: ChatEnums.PicWish.Scale.ScaleFactor.SCALE_AUTO,
        fix_face_only: ChatEnums.PicWish.Scale.FixType.FACE,
        output_type: ChatEnums.PicWish.Segmentation.OutputType.PICTURE,
        crop: ChatEnums.PicWish.Segmentation.Crop.DEFAULT,
        format: ChatEnums.PicWish.Segmentation.Format.PNG,
        bg_color: undefined,
      };
      useChatStore.getState().updateCurrentSession((session1) => {
        session1.picWishOptions = defaultOptions;
      }, conversationId);
      // picWishDebouncedStateUpdate(defaultOptions);
      updatePicwishOptions(defaultOptions);
    }
  }, [session.picWishOptions, updatePicwishOptions, conversationId]);

  const optionChange = {
    operation(value: ChatEnums.PicWish.Operation) {
      let type = picwishOptions!.type;

      if (value === ChatEnums.PicWish.Operation.SCALE) {
        if (
          type &&
          !Object.values<string>(ChatEnums.PicWish.Scale.Type).includes(type)
        ) {
          type = undefined;
        }
      } else if (value === ChatEnums.PicWish.Operation.SEGMENTATION) {
        if (
          type &&
          !Object.values<string>(ChatEnums.PicWish.Segmentation.Type).includes(
            type,
          )
        ) {
          type = undefined;
        }
      }

      updatePicwishOptions((option) => {
        option!.operation = value;
        option!.type = type;
      });
      startTransition(() => {
        picWishDebouncedStateUpdate(
          produce(picwishOptions!, (option) => {
            option.operation = value;
            option.type = type;
          }),
        );
      });
    },

    image_url(value: string | null) {
      updatePicwishOptions((option) => {
        option!.image_url = value ?? undefined;
      });
      startTransition(() => {
        picWishDebouncedStateUpdate(
          produce(picwishOptions!, (option) => {
            option.image_url = value ?? undefined;
          }),
        );
      });
    },
    type(
      value:
        | ChatEnums.PicWish.Segmentation.Type
        | ChatEnums.PicWish.Scale.Type
        | null,
    ) {
      updatePicwishOptions((options) => {
        options!.type = value ?? undefined;
      });
      startTransition(() => {
        picWishDebouncedStateUpdate(
          produce(picwishOptions!, (option) => {
            option.type = value ?? undefined;
          }),
        );
      });
    },
    scale_factor(value: ChatEnums.PicWish.Scale.ScaleFactor | null) {
      updatePicwishOptions((options) => {
        options!.scale_factor = value ?? undefined;
      });
      startTransition(() => {
        picWishDebouncedStateUpdate(
          produce(picwishOptions!, (option) => {
            option.scale_factor = value ?? undefined;
          }),
        );
      });
    },
    fix_face_only(value: ChatEnums.PicWish.Scale.FixType | null) {
      updatePicwishOptions((options) => {
        options!.fix_face_only = value ?? undefined;
      });
      startTransition(() => {
        picWishDebouncedStateUpdate(
          produce(picwishOptions!, (option) => {
            option.fix_face_only = value ?? undefined;
          }),
        );
      });
    },
    output_type(value: ChatEnums.PicWish.Segmentation.OutputType | null) {
      updatePicwishOptions((options) => {
        options!.output_type = value ?? undefined;
      });
      startTransition(() => {
        picWishDebouncedStateUpdate(
          produce(picwishOptions!, (option) => {
            option.output_type = value ?? undefined;
          }),
        );
      });
    },
    crop(value: ChatEnums.PicWish.Segmentation.Crop | null) {
      updatePicwishOptions((options) => {
        options!.crop = value ?? undefined;
      });
      startTransition(() => {
        picWishDebouncedStateUpdate(
          produce(picwishOptions!, (option) => {
            option.crop = value ?? undefined;
          }),
        );
      });
    },
    format(value: ChatEnums.PicWish.Segmentation.Format | null) {
      updatePicwishOptions((options) => {
        options!.format = value ?? undefined;
      });
      startTransition(() => {
        picWishDebouncedStateUpdate(
          produce(picwishOptions!, (option) => {
            option.format = value ?? undefined;
          }),
        );
      });
    },
    bg_color(value: string | null) {
      updatePicwishOptions((options) => {
        options!.bg_color = value ?? undefined;
      });
      startTransition(() => {
        picWishDebouncedStateUpdate(
          produce(picwishOptions!, (option) => {
            option.bg_color = value ?? undefined;
          }),
        );
      });
    },
  };

  const optionSelects = {
    image_url: () => {
      return (
        <Input
          value={picwishOptions?.image_url}
          key="image_url"
          placeholder={"图片链接"}
          onInput={(e) => optionChange.image_url(e.currentTarget.value)}
          style={{
            height: "28px",
            width: "100%",
          }}
          styles={{
            input: {
              height: "28px",
            },
          }}
        />
      );
    },
    type: () => {
      let option: any[] | undefined = [];
      let placeholder = "";

      if (picwishOptions?.operation === ChatEnums.PicWish.Operation.SCALE) {
        option = [
          {
            value: ChatEnums.PicWish.Scale.Type.CLEAN,
            label: "通用放大清晰化",
          },
          { value: ChatEnums.PicWish.Scale.Type.FACE, label: "人像放大清晰化" },
        ];
        placeholder = "修复类型";
      } else if (
        picwishOptions?.operation === ChatEnums.PicWish.Operation.SEGMENTATION
      ) {
        option = [
          {
            value: ChatEnums.PicWish.Segmentation.Type.OBJECT,
            label: "使用物品作为前景",
          },
          {
            value: ChatEnums.PicWish.Segmentation.Type.PERSON,
            label: "使用人作为前景",
          },
          {
            value: ChatEnums.PicWish.Segmentation.Type.STAMP,
            label: "使用图章作为前景",
          },
          {
            value: ChatEnums.PicWish.Segmentation.Type.DEFAULT,
            label: "默认",
          },
        ];
        placeholder = "前景类型";
      }

      return (
        <Select
          value={picwishOptions?.type}
          popupMatchSelectWidth={true}
          placement="topLeft"
          onChange={optionChange.type}
          options={option}
          placeholder={placeholder}
          style={{
            height: "28px",
            width: "100%",
          }}
        />
      );
    },
    scale_factor: () => {
      return (
        <Select
          value={picwishOptions?.scale_factor}
          popupMatchSelectWidth={true}
          placement="topLeft"
          onChange={optionChange.scale_factor}
          placeholder="放大倍数"
          style={{ height: "28px", width: "100%" }}
          options={[
            {
              value: ChatEnums.PicWish.Scale.ScaleFactor.SCALE_1,
              label: "不放大",
            },
            {
              value: ChatEnums.PicWish.Scale.ScaleFactor.SCALE_2,
              label: "2倍放大",
            },
            {
              value: ChatEnums.PicWish.Scale.ScaleFactor.SCALE_4,
              label: "4倍放大",
            },
            {
              value: ChatEnums.PicWish.Scale.ScaleFactor.SCALE_AUTO,
              label: "自动缩放",
            },
          ]}
        />
      );
    },
    fix_face_only: () => {
      return (
        <Select
          value={picwishOptions?.fix_face_only}
          popupMatchSelectWidth={true}
          placement="topLeft"
          onChange={optionChange.fix_face_only}
          placeholder="是否只处理人脸区域"
          style={{ height: "28px", width: "100%" }}
          options={[
            {
              value: ChatEnums.PicWish.Scale.FixType.FACE,
              label: "只处理人脸",
            },
            {
              value: ChatEnums.PicWish.Scale.FixType.ALL,
              label: "处理整张图片",
            },
          ]}
        />
      );
    },
    output_type: () => {
      return (
        <Select
          value={picwishOptions?.output_type}
          popupMatchSelectWidth={true}
          placement="topLeft"
          onChange={optionChange.output_type}
          placeholder={"图片返回选项"}
          style={{
            height: "28px",
            width: "100%",
          }}
          options={[
            {
              value: ChatEnums.PicWish.Segmentation.OutputType.PICTURE,
              label: "只返回图片",
            },
            {
              value: ChatEnums.PicWish.Segmentation.OutputType.MASK,
              label: "只返回蒙版",
            },
            {
              value: ChatEnums.PicWish.Segmentation.OutputType.BOTH,
              label: "同时返回图片和蒙版",
            },
          ]}
        />
      );
    },
    crop: () => {
      return (
        <Select
          value={picwishOptions?.crop}
          popupMatchSelectWidth={true}
          placement="topLeft"
          onChange={optionChange.crop}
          style={{
            height: "28px",
            width: "100%",
          }}
          options={[
            {
              value: ChatEnums.PicWish.Segmentation.Crop.DEFAULT,
              label: "返回原始图像大小",
            },
            {
              value: ChatEnums.PicWish.Segmentation.Crop.EDGE,
              label: "裁剪至目标边缘",
            },
          ]}
        />
      );
    },
    format: () => {
      return (
        <Select
          value={picwishOptions?.format}
          popupMatchSelectWidth={true}
          placement="topLeft"
          onChange={optionChange.format}
          placeholder={"输出图片格式"}
          style={{ height: "28px", width: "100%" }}
          options={[
            { value: ChatEnums.PicWish.Segmentation.Format.JPG, label: "JPG" },
            { value: ChatEnums.PicWish.Segmentation.Format.PNG, label: "PNG" },
          ]}
        />
      );
    },
    bg_color: () => {
      return (
        <Input
          value={picwishOptions?.bg_color}
          onInput={(e) => optionChange.bg_color(e.currentTarget.value)}
          style={{
            height: "28px",
            width: "100%",
          }}
          placeholder={"背景颜色"}
          maxLength={6}
          styles={{
            input: {
              height: "28px",
            },
          }}
        />
      );
    },
  };

  let selectArea = <></>;

  // let imageFile = session.pendingFileList?.find(
  //   (it) => it.fileType === UploadFileType.PICWISH_IMAGE_FILE,
  // );

  // let imageUrl = <></>;

  // if (!imageFile) {
  //   imageUrl = optionSelects.image_url();
  // }

  if (picwishOptions?.operation === ChatEnums.PicWish.Operation.SCALE) {
    selectArea = (
      <div
        css={[
          css`
            margin-left: 10px;
          `,
          layout.type === LayoutType.Layout_v1 &&
            css`
              height: 28px;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              height: 100%;
              display: flex;
              align-items: center;
            `,
        ]}
      >
        <AdditionalSettingsPopUp>
          <AdditionSettingItem title={"类型"}>
            {optionSelects.type()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"放大倍数"}>
            {optionSelects.scale_factor()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"人脸处理"}>
            {optionSelects.fix_face_only()}
          </AdditionSettingItem>
        </AdditionalSettingsPopUp>
      </div>
    );
  } else if (
    picwishOptions?.operation === ChatEnums.PicWish.Operation.SEGMENTATION
  ) {
    let bg = <></>;

    if (picwishOptions.format === ChatEnums.PicWish.Segmentation.Format.JPG) {
      bg = (
        <AdditionSettingItem title={"背景颜色"}>
          {optionSelects.bg_color()}
        </AdditionSettingItem>
      );
    }
    selectArea = (
      <div
        css={[
          css`
            margin-left: 10px;
          `,
          layout.type === LayoutType.Layout_v1 &&
            css`
              height: 28px;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              height: 100%;
              display: flex;
              align-items: center;
            `,
        ]}
      >
        <AdditionalSettingsPopUp>
          <AdditionSettingItem title={"类型"}>
            {optionSelects.type()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"图片返回选项"}>
            {optionSelects.output_type()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"图片裁剪选项"}>
            {optionSelects.crop()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"图片类型"}>
            {optionSelects.format()}
          </AdditionSettingItem>
          {bg}
        </AdditionalSettingsPopUp>
      </div>
    );
  }

  return (
    <CommonSelectBaseBlock usageStr={props.usageStr}>
      <div
        css={[
          css`
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            min-height: 28px;
            flex-grow: 1;
            box-sizing: content-box;
            pointer-events: auto;

            &::-webkit-scrollbar {
              width: 0;
              height: 0;
            }
          `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              height: 100%;
            `,
        ]}
      >
        <Select
          value={picwishOptions?.operation}
          key={"operation"}
          style={{
            width: "120px",
            height: "28px",
            minWidth: "120px",
            marginTop:
              layout.type === LayoutType.Layout_v2 ? "auto" : undefined,
            marginBottom:
              layout.type === LayoutType.Layout_v2 ? "auto" : undefined,
          }}
          popupMatchSelectWidth={true}
          placement="topLeft"
          onChange={optionChange.operation}
          options={[
            { value: ChatEnums.PicWish.Operation.SCALE, label: "图片变清晰" },
            {
              value: ChatEnums.PicWish.Operation.SEGMENTATION,
              label: "抠图",
            },
          ]}
        />

        {selectArea}
      </div>
    </CommonSelectBaseBlock>
  );
};

const PicWishImageInputBlock = function () {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)!),
  );
  const [messages, messageContextHolder] = message.useMessage();
  const layout = useLayoutConfigStore();

  const imageFile = session.pendingFileList?.find(
    (it) => it.fileType === UploadFileType.PICWISH_IMAGE_FILE,
  );

  let imageArea = <></>;

  const imageInputBlockStyle = css`
    width: 80px;
    height: 80px;
    margin-left: 8px;
    margin-top: 10px;
  `;

  const imageInputBlockStyleV2 = css`
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  `;

  const updatePendingFileByType = useUpdatePendingFileByType({
    onError(e) {
      void messages.error(e.message);
    },
  });

  const deleteImageByType = useDeleteByType();

  useEffect(() => {
    const getBaseFile = function (file: File) {
      updatePendingFileByType(file, UploadFileType.PICWISH_IMAGE_FILE);
    };
    eventBus.on(EventBus.FileInput.ImageInput.Action.ACTION_INPUT, getBaseFile);
    return () => {
      eventBus.off(
        EventBus.FileInput.ImageInput.Action.ACTION_INPUT,
        getBaseFile,
      );
    };
  }, [updatePendingFileByType]);

  const uploadByType = useCallback(
    (file: File) => {
      updatePendingFileByType(file, UploadFileType.PICWISH_IMAGE_FILE);
    },
    [updatePendingFileByType],
  );

  const deleteByType = useCallback(() => {
    deleteImageByType(UploadFileType.PICWISH_IMAGE_FILE);
  }, [deleteImageByType]);

  if (
    (layout.type === LayoutType.Layout_v1 &&
      (!session.picWishOptions?.image_url ||
        session.picWishOptions?.image_url === "")) ||
    (layout.type === LayoutType.Layout_v2 &&
      imageFile?.imageUrl &&
      imageFile.imageUrl !== "")
  ) {
    imageArea = (
      <div
        css={[
          imageInputBlockStyle,
          layout.type === LayoutType.Layout_v2 && imageInputBlockStyleV2,
        ]}
      >
        <ImageInputBlock
          imageUrl={imageFile?.imageUrl}
          titleStr="上传图片"
          onChange={uploadByType}
          onDelete={deleteByType}
        />
      </div>
    );
  }

  return (
    <div
      css={[
        css`
          display: flex;
          margin-left: -8px;
        `,
        layout.type === LayoutType.Layout_v2 &&
          css`
            margin-left: 8px;
          `,
      ]}
    >
      {messageContextHolder}
      {imageArea}
    </div>
  );
};

const PicWishPromptInputBlock = function (
  props: React.PropsWithChildren<{
    updatePromptsInput: (str: string) => void;
    submit: (ignoreEmptyInput: boolean) => void;
    inputAboveArea?: React.ReactNode;
  }>,
) {
  const conversationId = useContext(ConversationIdContext);
  const [messages, messageContextHolder] = message.useMessage();

  const chatStore = useChatStore();

  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)!),
  );

  const layout = useLayoutConfigStore();

  const chatStoreDebouncedStateUpdate = useDebouncedCallback((url: string) => {
    chatStore.updateCurrentSession((session1) => {
      session1.picWishOptions = produce(session1.picWishOptions!, (option) => {
        option.image_url = url;
      });
    }, conversationId);
  }, 100);

  const updateInput = props.updatePromptsInput;

  const submit = function () {
    const picWishOptions = session.picWishOptions!;

    const imageFile = session.pendingFileList?.find(
      (it) => it.fileType === UploadFileType.PICWISH_IMAGE_FILE,
    );
    if (
      !imageFile &&
      (!picWishOptions.image_url || picWishOptions.image_url === "")
    ) {
      void messages.error("图片链接与图片文件不能同时为空");
      return;
    }

    if (picWishOptions.image_url && picWishOptions.image_url.length > 0) {
      try {
        new URL(picWishOptions.image_url);
      } catch {
        void messages.error("请输入有效的图片链接");
        return;
      }
    }

    if (picWishOptions.operation === ChatEnums.PicWish.Operation.SEGMENTATION) {
      if (picWishOptions.format === ChatEnums.PicWish.Segmentation.Format.JPG) {
        const hex = picWishOptions.bg_color;
        if (hex && hex.length > 0) {
          if (![3, 4, 6, 8].includes(hex.length)) {
            void messages.error("请输入有效的16进制颜色值");
            return;
          }
          if (
            !hex
              .toLowerCase()
              .split("")
              .every((it) => it.match(/[0-9a-f]/))
          ) {
            void messages.error("请输入有效的16进制颜色值");
            return;
          }
        }
      }
    }
    props.submit(true);
    updateImageUrl("");
  };

  const [imageUrl, setImageUrl] = useState("");

  const updateImageUrl = function (url: string) {
    setImageUrl(url);
    chatStoreDebouncedStateUpdate(url);
  };

  let imageUrlInputBlock = <></>;

  const imageFile = session.pendingFileList?.find(
    (it) => it.fileType === UploadFileType.PICWISH_IMAGE_FILE,
  );

  if (!imageFile) {
    imageUrlInputBlock = (
      <TextareaAutosize
        value={imageUrl}
        onChange={(e) => void updateImageUrl(e.currentTarget.value)}
        placeholder={"图片地址"}
        onKeyDown={(e) => {
          actOnKeyDown(e, { submit });
        }}
        enterKeyHint="search"
        css={css`
          resize: none;
          background-color: #f0f1f5;
          border-color: #e5e5e5;
          color: #333;
          box-sizing: border-box;
          font-size: 15px;
          padding: 7px 20px;
          border-width: 1px;
          border-radius: 4px;
          width: 100%;
        `}
      />
    );
  }

  return (
    <>
      {messageContextHolder}
      {layout.type === LayoutType.Layout_v1 && (
        <div
          css={css`
            display: flex;
            width: 100%;
            padding-top: 10px;
          `}
        >
          <div
            css={css`
              flex-grow: 1;
              flex-shrink: 1;
              min-width: 0;
            `}
          >
            {imageUrlInputBlock}
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex-shrink: 0;
              margin-left: 5px;
              margin-bottom: 4px;
            `}
          >
            <div
              css={css`
                color: white;
                width: 106px;
                height: 42px;
                border-radius: 8px;
                background-image: linear-gradient(to top, #1fd6cc, #0c74ed);
                text-align: center;
                line-height: 42px;
                margin-top: auto;
                margin-bottom: auto;
                cursor: pointer;
                user-select: none;

                :hover {
                  opacity: 0.75;
                }
              `}
              onClick={submit}
            >
              发送
            </div>
          </div>
        </div>
      )}
      {layout.type === LayoutType.Layout_v2 && (
        <CommonPromptInputBlock
          updatePrompts={updateInput}
          submit={submit}
          placeHolder={"图片地址"}
          shouldIgnoreEmptyInput
          inputAboveArea={props.inputAboveArea}
        >
          {props.children}
        </CommonPromptInputBlock>
      )}
    </>
  );
};

const PicWishInputButtonOriginalImageV2 = function () {
  const uploadByType = useUpdatePendingFileByType();

  const upload = useCallback(
    (fileList: File[]) => {
      const file = fileList[0];
      if (file) {
        uploadByType(file, UploadFileType.PICWISH_IMAGE_FILE);
      }
    },
    [uploadByType],
  );

  return (
    <SmallClickInput title={"上传图片"} accept={"image/*"} onChange={upload}>
      <ChatPicIcon
        css={css`
          width: 20px;
          height: 20px;
          margin-top: auto;
          margin-bottom: auto;

          path {
            fill: #4b5562;
          }
        `}
      />
    </SmallClickInput>
  );
};
