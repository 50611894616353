"use client";

import { Theme, useAccessStore, useAppConfig } from "@/app/store";
import { useEffect, useMemo, useState } from "react";

import styles from "./home.module.scss";

import BotIcon from "../icons/bot.svg";
import LoadingIcon from "../icons/three-dots.svg";

import { getCSSVar, useMobileScreen } from "../utils";

import dynamic from "next/dynamic";
import { NewsPath, NewsRoutes, Path, SlotID } from "../constant";
import { ErrorBoundary } from "./error";

import {
  Navigate,
  Outlet,
  Route,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
  useLocation,
} from "react-router-dom";
import { SideBar, SideBarItemList } from "./sidebar";
import { AuthPage } from "./auth";
import { PricePageV2 } from "./pricing";
import { PayPage } from "./pay";
import { PayCodePage } from "./pay-code";
import { UserPage } from "./user";
import { getClientConfig } from "../config/client";
import { useUserStore } from "@/app/store/user";
import { history } from "@/app/utils/history";
import "@fontsource-variable/noto-sans-georgian";
import "@fontsource-variable/noto-sans-sc";
import { EULA } from "@/app/components/eula";
import { FloatingButtons } from "./floatingButtons";
import { useSiteType } from "@/app/store/siteType";
import { useShallow } from "zustand/react/shallow";
import classNames from "classnames";
import { NonLoginChatDesktop } from "@/app/components/chatComponents/nonLoginChat";
import { GlobalConfigRule } from "@/app/components/config/globalConfigRule";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import { css } from "@emotion/react";
import { CAIEHeaderV2 } from "@/app/components/chatComponents/v2/globalHeader";
import { SiteType } from "@/app/enums/siteType";
import { LayoutType } from "@/app/enums/layoutType";

import { NewsType } from "@/app/enums/newsTypes";

import "../polyfill";
import { useMatchNextChannel } from "@/app/hooks/routeHooks";

export function Loading(props: { noLogo?: boolean }) {
  return (
    <div className={styles["loading-content"] + " no-dark"}>
      {!props.noLogo && <BotIcon />}
      <LoadingIcon />
    </div>
  );
}

const Settings = dynamic(
  () =>
    import("./settings").then((settings) => ({
      default: settings.Settings,
    })),
  {
    loading: () => <Loading noLogo />,
  },
);

const Chat = dynamic(
  () =>
    import("./chat").then((chat) => ({
      default: chat.Chat,
    })),
  {
    loading: () => <Loading noLogo={true} />,
    ssr: false,
  },
);

const NewChat = dynamic(
  () =>
    import("./new-chat").then((chat) => ({
      default: chat.NewChat,
    })),
  {
    loading: () => <Loading noLogo={true} />,
  },
);

const MaskPage = dynamic(
  () =>
    import("./mask").then((mask) => ({
      default: mask.MaskPage,
    })),
  {
    loading: () => <Loading noLogo />,
  },
);

const NonLoginChat = dynamic(
  () =>
    import("./chatComponents/nonLoginChat").then((it) => ({
      default: it.NonLoginChat,
    })),
  {
    loading: () => <Loading noLogo={true} />,
    ssr: false,
  },
);

const ChatRoute = dynamic(
  () =>
    import("./chat-route").then((it) => ({
      default: it.ChatRoute,
    })),
  {
    loading: () => <Loading noLogo={true} />,
    ssr: false,
  },
);

const ChatDefault = dynamic(
  () =>
    import("./chat-default").then((it) => ({
      default: it.ChatDefaultV2,
    })),
  {
    loading: () => <Loading noLogo={true} />,
    ssr: false,
  },
);

const NewsPage = dynamic(
  () =>
    import("./chatComponents/v2/news/newsComponent").then((it) => ({
      default: it.NewsPage,
    })),
  {
    loading: () => <Loading noLogo={true} />,
    ssr: false,
  },
);

const NewsListBody = dynamic(
  () =>
    import("./chatComponents/v2/news/newsComponent").then((it) => ({
      default: it.NewsListBody,
    })),
  {
    loading: () => <Loading noLogo={true} />,
    ssr: false,
  },
);

const NewsDetailPage = dynamic(
  () =>
    import("./chatComponents/v2/news/newsComponent").then((it) => ({
      default: it.NewsDetailPage,
    })),
  {
    loading: () => <Loading noLogo={true} />,
    ssr: false,
  },
);

export function useSwitchTheme() {
  const config = useAppConfig();

  useEffect(() => {
    const themeChangeListener = function (e: MediaQueryListEvent) {
      if (config.theme !== Theme.Auto) {
        return;
      }
      document.body.classList.remove("light");
      document.body.classList.remove("dark");
      if (e.matches) {
        document.body.classList.add("dark");
      } else {
        document.body.classList.add("light");
      }
    };

    const darkModeMatch = window.matchMedia("(prefers-color-scheme: dark)");

    document.body.classList.remove("light");
    document.body.classList.remove("dark");

    if (config.theme === "dark") {
      document.body.classList.add("dark");
    } else if (config.theme === "light") {
      document.body.classList.add("light");
    } else if (config.theme === "auto") {
      if (darkModeMatch.matches) {
        document.body.classList.add("dark");
      } else {
        document.body.classList.add("light");
      }
      darkModeMatch.addEventListener("change", themeChangeListener);
    }

    const metaDescriptionDark = document.querySelector(
      'meta[name="theme-color"][media*="dark"]',
    );
    const metaDescriptionLight = document.querySelector(
      'meta[name="theme-color"][media*="light"]',
    );

    if (config.theme === "auto") {
      metaDescriptionDark?.setAttribute("content", "#151515");
      metaDescriptionLight?.setAttribute("content", "#fafafa");
    } else {
      const themeColor = getCSSVar("--theme-color");
      metaDescriptionDark?.setAttribute("content", themeColor);
      metaDescriptionLight?.setAttribute("content", themeColor);
    }
    return () => {
      darkModeMatch.removeEventListener("change", themeChangeListener);
    };
  }, [config.theme]);
}

const useHasHydrated = () => {
  const [hasHydrated, setHasHydrated] = useState<boolean>(false);

  useEffect(() => {
    setHasHydrated(true);
  }, []);

  return hasHydrated;
};

const loadAsyncGoogleFont = () => {
  const linkEl = document.createElement("link");
  const proxyFontUrl = "/google-fonts";
  const remoteFontUrl = "https://fonts.googleapis.com";
  const googleFontUrl =
    getClientConfig()?.buildMode === "export" ? remoteFontUrl : proxyFontUrl;
  linkEl.rel = "stylesheet";
  linkEl.href =
    googleFontUrl +
    "/css2?family=Noto+Sans+SC:wght@300;400;700;900&display=swap";
  document.head.appendChild(linkEl);
};

function Screen() {
  const isMobileScreen = useMobileScreen();
  const accessStore = useAccessStore();
  const site = useSiteType();
  const layout = useLayoutConfigStore();

  useEffect(() => {
    useUserStore.getState().getUserInfo();
  }, []);

  const content = useMemo(() => {
    if (!accessStore.isAuthorized()) {
      if (site.siteType === SiteType.TRAIN || site.siteType === SiteType.EXAM) {
        return (
          <Routes>
            <Route path="/" element={<AuthPage />} />
            <Route path={Path.EULA} element={<EULA></EULA>} />
            <Route path="*" element={<Navigate to="/" replace={true} />} />
          </Routes>
        );
      }
      if (!isMobileScreen) {
        return (
          <Routes>
            <Route path={"/"} element={<NonLoginChatDesktop />} />
            <Route path={Path.Auth} element={<AuthPage />} />
            <Route path={Path.EULA} element={<EULA></EULA>} />
            <Route path="*" element={<Navigate to="/" replace={true} />} />
          </Routes>
        );
      } else {
        return (
          <Routes>
            <Route element={<NonLoginLayout />}>
              <Route path="/" element={<NonLoginChat />} />
            </Route>
            <Route path={Path.EULA} element={<EULA></EULA>} />
            <Route path={Path.Auth} element={<AuthPage />} />
            <Route path="*" element={<Navigate to="/" replace={true} />} />
          </Routes>
        );
      }
    }

    const RouteLayout = function () {
      let auth = <></>;
      if (!accessStore.isAuthorized()) {
        auth = <Route path={Path.Auth} element={<AuthPage />} />;
      }
      return (
        <Routes>
          <Route element={<RootLayout />}>
            {/*<Route path={Path.NewChat} element={<NewChat />} />*/}
            {/*<Route path={Path.Masks} element={<MaskPage />} />*/}
            {/*<Route path={Path.Settings} element={<Settings />} />*/}
            <Route
              path={Path.Chat + "/:conversation_id"}
              element={<ChatRoute />}
            ></Route>
            {layout.type === LayoutType.Layout_v2 && (
              <Route path={"/"} element={<ChatDefault />} />
            )}
            {layout.type === LayoutType.Layout_v2 && (
              <>
                <Route element={<NewsPage />}>
                  <Route
                    path={Path.News + NewsPath.Tech}
                    element={<NewsListBody type={NewsType.News} />}
                  />
                  <Route
                    path={Path.News + "/*"}
                    element={<Navigate to={Path.News + NewsPath.Tech} />}
                  />
                </Route>
                <Route
                  path={Path.News + NewsRoutes.Details + "/*"}
                  element={<NewsDetailPage />}
                />
              </>
            )}
            <Route path="*" element={<Chat />} />
          </Route>
          <Route path={Path.User} element={<UserPage />} />
          <Route path={Path.Pricing} element={<PricePageV2 />} />
          <Route path={Path.Pay} element={<PayPage />} />
          <Route path={Path.PayCode} element={<PayCodePage />} />
          <Route path={Path.EULA} element={<EULA />} />
          {auth}
        </Routes>
      );
    };

    return RouteLayout();
  }, [isMobileScreen, accessStore, site, layout.type]);

  return content;
}

export function Home() {
  useSwitchTheme();
  useEffect(() => {
    console.log("[Config] got config from build time", getClientConfig());
  }, []);
  const site = useSiteType();

  if (!useHasHydrated()) {
    return <Loading />;
  }

  let floatingBlock = <FloatingButtons />;
  if (site.siteType === SiteType.TRAIN || site.siteType === SiteType.EXAM) {
    floatingBlock = <></>;
  }

  return (
    <>
      <ErrorBoundary>
        <HistoryRouter history={history}>
          <Screen />
          {floatingBlock}
          <GlobalConfigRule />
        </HistoryRouter>
      </ErrorBoundary>
    </>
  );
}

const NonLoginLayout = function () {
  const tightBorder = useAppConfig(useShallow((state) => state.tightBorder));
  const isMobileScreen = useMobileScreen();
  const layout = useLayoutConfigStore();

  return (
    <>
      <div className={styles.page}>
        <div
          className={classNames(styles.container, {
            [styles["tight-container"]]: tightBorder && !isMobileScreen,
          })}
        >
          <SideBarItemList></SideBarItemList>
          <div className={styles["window-content"]} id={SlotID.AppBody}>
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </>
  );
};

const RootLayout = function () {
  const layout = useLayoutConfigStore();
  const isMobileScreen = useMobileScreen();
  const config = useAppConfig();
  const location = useLocation();
  const isHome = location.pathname === Path.Home;

  const showSideBar = useAppConfig(useShallow((state) => state.showChatList));

  return (
    <>
      <div
        className={styles.page}
        css={[
          layout.type === LayoutType.Layout_v2 &&
            css`
              flex-direction: column;
            `,
        ]}
      >
        {layout.type === LayoutType.Layout_v2 && <CAIEHeaderV2 />}
        <div
          className={classNames([
            {
              [styles.container]: layout.type === LayoutType.Layout_v1,
              [styles["tight-container"]]:
                layout.type === LayoutType.Layout_v1 &&
                config.tightBorder &&
                !isMobileScreen,
            },
          ])}
          css={[
            layout.type === LayoutType.Layout_v2 &&
              css`
                min-width: 100%;
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                flex-grow: 1;
                display: flex;
                overflow: hidden;
                color: var(--black);
                background-color: var(--main-surface-primary);
                position: relative;
              `,
          ]}
        >
          <div
            css={[
              layout.type === LayoutType.Layout_v1 &&
                css`
                  height: 100%;
                `,
              layout.type === LayoutType.Layout_v2 &&
                css`
                  flex-shrink: 0;
                `,
              layout.type === LayoutType.Layout_v2 &&
                isMobileScreen &&
                showSideBar &&
                css`
                  position: absolute;
                  left: 0;
                  z-index: 2;
                  height: 100%;
                  top: 0;
                  width: 100%;
                `,
              layout.type === LayoutType.Layout_v2 &&
                isMobileScreen &&
                !showSideBar &&
                css`
                  position: absolute;
                  left: -100%;
                  top: 0;
                  width: 100%;
                  height: 100%;
                `,
            ]}
          >
            <SideBar
              className={classNames({
                [styles["sidebar-show"]]:
                  layout.type === LayoutType.Layout_v1 && isHome,
              })}
            />
          </div>
          <div
            className={classNames({
              [styles["window-content"]]: layout.type === LayoutType.Layout_v1,
            })}
            css={[
              css`
                display: flex;
                flex-direction: column;
              `,
              layout.type === LayoutType.Layout_v2 &&
                css`
                  flex-grow: 1;
                  min-width: 0;
                `,
            ]}
            id={SlotID.AppBody}
          >
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </>
  );
};
