import { ChatOptions, getHeaders, LLMUsage } from "@/app/client/api";
import { ChatSession, useChatStore } from "@/app/store";
import { fetchTimeout } from "@/app/client/platforms/openai";
import { LLMApi } from "@/app/client/classes";

export class ChatGPTWithBingApi extends LLMApi {
  public constructor() {
    super();
  }
  async chat(options: ChatOptions) {
    const session = useChatStore
      .getState()
      .sessions.find((it) => it.conversationId === options.conversationId);
    if (!session) {
      options.onError?.(new Error("session not found"), { code: -1 });
      return;
    }

    await this.gpt3_5WithBing(options, session);
  }
  usage(): Promise<LLMUsage> {
    throw new Error("Method not implemented.");
  }
  async gpt3_5WithBing(options: ChatOptions, session: ChatSession) {
    const message = options.messages.at(-1)!.content;

    const requestPayload = {
      content: message,
      conversation_id: session.conversationId!,
      stream: true,
      lang: session.lang ?? "Simplified_Chinese",
      return_html_app_token: "1",
    };

    try {
      const resp = await fetchTimeout(
        fetch("/v1/asst/chat/with/search", {
          headers: getHeaders(),
          method: "POST",
          body: JSON.stringify(requestPayload),
        }),
        60_000,
      );
      void this.finish(resp, options);
    } catch (e) {
      console.log("request chat with search failed", e);
      options.onError?.(e as Error, { code: -1 });
    }
  }
}
