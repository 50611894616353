"use client";
import { css } from "@emotion/react";
import NextImage from "next/image";
import FileIconTransparent from "@/app/icons/chat/file-icon-transparent.png";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";
import FileIcon from "@/app/icons/chat/file-icon-small.png";
import DeleteIcon from "@/app/icons/chat/delete-icon.png";
import { ChatMessageFile, useAccessStore, useChatStore } from "@/app/store";
import { message } from "antd";
import { UploadFileType, useUploadFileStore } from "@/app/store/uploadFiles";
import CloseIcon from "@/app/icons/chat/close-icon.png";
import { useShallow } from "zustand/react/shallow";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import isEqual from "react-fast-compare";
import { eventBus } from "@/app/utils/eventBus";
import { EventBus } from "@/app/enums/eventBusName";
import { showToast } from "@/app/components/ui-lib";
import { history } from "@/app/utils/history";
import { Path } from "@/app/constant";
import { v4 } from "uuid";
import ChatFileIcon from "@/app/icons/layout_v2/chat/file.svg";
import ChatCloseIcon from "@/app/icons/layout_v2/chat/close.svg";
import {
  FileInputIconV1,
  SmallClickInput,
} from "@/app/components/chatComponents/v2/InputComponents";
import { useUpdatePendingFileByType } from "@/app/hooks/chatHooks";
import { LayoutType } from "@/app/enums/layoutType";
import { ConversationIdContext } from "@/app/contexts/chatIdContext";

export const FileItem = function (prop: {
  fileName: string;
  fileId?: number;
  fileStatus?: number;
}) {
  const layout = useLayoutConfigStore();

  return (
    <div
      css={[
        layout.type === LayoutType.Layout_v1 &&
          css`
            display: flex;
            border-color: #0c74ed;
            border-width: 1px;
            border-style: solid;
            border-radius: 4px;
            height: 32px;
            width: fit-content;
            max-width: 100%;
          `,
        layout.type === LayoutType.Layout_v2 &&
          css`
            display: flex;
            background-color: #fff5c2;
            border-radius: 4px;
            height: 36px;
            width: fit-content;
            max-width: 100%;
          `,
      ]}
    >
      <div
        css={[
          layout.type === LayoutType.Layout_v1 &&
            css`
              width: 32px;
              height: 32px;
              display: flex;
              flex-shrink: 0;
              background-color: #0c74ed;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              margin-left: 0;
              height: 100%;
              display: flex;
              flex-shrink: 0;
              width: 32px;
            `,
        ]}
      >
        {layout.type === LayoutType.Layout_v1 && (
          <NextImage
            src={FileIconTransparent}
            alt={""}
            width={14}
            height={14}
            css={[
              css`
                margin: auto;
              `,
            ]}
          ></NextImage>
        )}
        {layout.type === LayoutType.Layout_v2 && (
          <FontAwesomeIcon
            icon={faFileLines}
            style={{
              color: "#ff6e25",
              width: "18px",
              height: "18px",
              margin: "auto",
            }}
          />
        )}
      </div>
      <div
        css={[
          layout.type === LayoutType.Layout_v1 &&
            css`
              height: 32px;
              line-height: 32px;
              flex-grow: 1;
              flex-shrink: 1;
              min-width: 0;
              padding-left: 10px;
              padding-right: 8px;
              font-size: 14px;
              overflow-x: hidden;
              text-overflow: ellipsis;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              white-space: pre;

              @media (prefers-color-scheme: light) {
                background-color: white;
                color: #0c74ed;
              }
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              height: 36px;
              line-height: 36px;
              flex-grow: 1;
              flex-shrink: 1;
              min-width: 0;
              font-size: 14px;
              color: black;
              margin-right: 20px;
              overflow-x: hidden;
              text-overflow: ellipsis;
              white-space: pre;
            `,
        ]}
      >
        {prop.fileName}
      </div>
    </div>
  );
};
export const FileUploadItem = function (props: {
  uuid: string;
  uploadId?: number;
  fileName: string;
  file?: File;
  onDelete: (uuid: string, uploadId?: number) => void;
  length: number;
  onUpload: (
    uuid: string,
    fileName: string,
    uploadId: number,
    conversationId: string,
  ) => void;
}) {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)),
  );
  const [progress, setProgress] = useState("0%");
  const [showMessage, contextHolder] = message.useMessage();
  const [uploadId, setUploadId] = useState(props.uploadId);
  const [hasStartUpload, setHasStartUpload] = useState(false);
  const layout = useLayoutConfigStore();

  const onDeleteClicked = () => {
    props.onDelete(props.uuid, uploadId);
  };

  const uploadFile = useCallback(
    function () {
      console.log(props);
      if (
        (uploadId === undefined || uploadId === -1) &&
        props.file !== undefined
      ) {
        useUploadFileStore.getState().uploadFileWithProgress({
          uuid: props.uuid,
          conversationId: session!.conversationId!,
          onSuccess(fileName, fileId) {
            props.onUpload(
              props.uuid,
              fileName,
              fileId,
              session!.conversationId!,
            );
            setUploadId(fileId);
            setProgress("0%");
          },
          onError(_error) {
            setUploadId(-2);
            setProgress("0%");
          },
          onUploadProgress(progress) {
            setProgress(progress + "%");
          },
        });
      }
    },
    [props, session, uploadId],
  );
  useEffect(() => {
    if (!hasStartUpload) {
      setHasStartUpload(true);
      uploadFile();
    }
  }, [uploadFile, hasStartUpload]);

  return (
    <div
      css={[
        css`
          position: relative;
          display: flex;
          height: 32px;
          border-width: 1px;
          border-style: solid;
          border-color: #0c74ed;
          border-radius: 4px;
          width: fit-content;
          margin-top: 10px;
          margin-left: 28px;
          flex-basis: auto;
        `,
        layout.type === LayoutType.Layout_v2 &&
          css`
            height: 36px;
            background-color: #f1f5f7;
            border: initial;

            :hover circle {
              fill: #248eb3;
            }
          `,
      ]}
    >
      {contextHolder}
      <div
        css={[
          css`
            width: 32px;
            height: 32px;
            display: flex;
            background-color: ${uploadId !== undefined && uploadId >= 0
              ? "#0c74ed"
              : "#78afee"};
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              background-color: initial;
            `,
        ]}
      >
        {layout.type === LayoutType.Layout_v1 && (
          <NextImage
            src={FileIconTransparent}
            alt={""}
            width={22}
            height={22}
            css={css`
              margin: auto;
              display: block;
            `}
          ></NextImage>
        )}
        {layout.type === LayoutType.Layout_v2 && (
          <FontAwesomeIcon
            icon={faFileLines}
            style={{
              color: "#248eb3",
              width: "18px",
              height: "18px",
              margin: "auto",
              display: "block",
            }}
          />
        )}
      </div>
      {uploadId !== undefined && uploadId >= 0 ? (
        <div
          css={[
            css`
              color: #0c74ed;
              font-size: 14px;
              line-height: 32px;
              padding-left: 10px;
              padding-right: 10px;
            `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                color: #7a848a;
                line-height: 20px;
                margin-top: auto;
                margin-bottom: auto;
                padding-left: 0;
              `,
          ]}
        >
          {props.fileName}
        </div>
      ) : (
        <div
          css={css`
            padding-left: 10px;
            padding-right: 10px;
            position: relative;
            display: flex;
          `}
        >
          <div
            style={{ width: progress }}
            css={css`
              position: absolute;
              height: 100%;
              background-color: #e8f2fe;
              content: " ";
              top: 0;
              left: 0;
            `}
          ></div>
          <span
            css={css`
              line-height: 32px;
              color: #0c74ed;
              font-size: 14px;
              position: relative;
              display: block;
              width: auto;
              white-space: pre;
            `}
          >
            上传中……
          </span>
          <span
            css={css`
              color: #0c74ed;
              font-size: 14px;
              line-height: 32px;
              display: block;
              margin-left: 14px;
              position: relative;
            `}
          >
            {props.length}/10
          </span>
        </div>
      )}
      <div
        css={[
          layout.type === LayoutType.Layout_v1 &&
            css`
              position: absolute;
              width: 28px;
              height: 28px;
              top: -12px;
              right: -12px;
              display: ${uploadId !== undefined && uploadId >= 0
                ? "block"
                : "none"};
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              position: absolute;
              height: 18px;
              width: 18px;
              top: -9px;
              right: -9px;
              display: block;
            `,
        ]}
        onClick={onDeleteClicked}
      >
        {layout.type === LayoutType.Layout_v1 && (
          <NextImage
            src={CloseIcon}
            alt={""}
            width={28}
            height={28}
          ></NextImage>
        )}
        {layout.type === LayoutType.Layout_v2 && (
          <ChatCloseIcon
            css={css`
              width: 18px;
              height: 18px;
            `}
          />
        )}
      </div>
    </div>
  );
};
export const UploadHistoryListItem = function (props: {
  fileName: string;
  fileSize: string;
  fileDate: string;
  file_id: number;
  onDelete: (file_id: number) => void;
}) {
  const clickToDelete = function () {
    props.onDelete(props.file_id);
  };

  const deleteButtonStyle = css`
    display: none;
    height: 30px;
    width: 30px;
    flex-shrink: 0;
    margin: auto 20px auto -10px;
    cursor: pointer;
    label: delete_button;
  `;

  return (
    <div
      css={css`
        display: flex;
        height: 52px;

        &:hover .css-${deleteButtonStyle.name} {
          display: flex;
        }
      `}
    >
      <div
        css={css`
          width: 32px;
          height: 32px;
          border-radius: 4px;
          background-color: #e7f1fd;
          display: flex;
          margin-left: 22px;
          margin-top: auto;
          margin-bottom: auto;
          flex-shrink: 0;
        `}
      >
        <NextImage
          src={FileIcon}
          alt={""}
          width={14}
          height={14}
          css={css`
            margin: auto;
          `}
        />
      </div>

      <div
        css={css`
          flex-grow: 1;
          flex-shrink: 1;
          min-width: 0;
          height: 35px;
          margin: auto 10px;
        `}
      >
        <div
          css={css`
            color: var(--black);
            font-size: 14px;
            line-height: 20px;
            height: 20px;
            white-space: pre;
            overflow-x: hidden;
            text-overflow: ellipsis;
          `}
        >
          {props.fileName}
        </div>
        <div
          css={css`
            display: flex;
            height: 15px;
          `}
        >
          <div
            css={css`
              color: var(--border-in-light);
              font-size: 10px;
              line-height: 15px;
              margin-right: 15px;
            `}
          >
            {props.fileSize}
          </div>
          <div
            css={css`
              color: var(--border-in-light);
              font-size: 10px;
              line-height: 15px;
              height: 15px;
            `}
          >
            {props.fileDate}
          </div>
        </div>
      </div>

      <div css={deleteButtonStyle} onClick={clickToDelete}>
        <NextImage
          src={DeleteIcon}
          alt={""}
          width={16}
          height={16}
          css={css`
            margin: auto;

            @media (prefers-color-scheme: dark) {
              filter: invert(1);
            }
          `}
        />
      </div>
    </div>
  );
};
export const ChatFileListComponent = function (props: {
  fileList?: ChatMessageFile[];
}) {
  const fileListRef = useRef(props.fileList);
  if (!isEqual(fileListRef.current, props.fileList)) {
    fileListRef.current = props.fileList;
  }
  const layout = useLayoutConfigStore();

  const fileList = useMemo(() => {
    if (!fileListRef.current) {
      return [];
    }
    return fileListRef.current.filter(
      (it) =>
        it.fileType === UploadFileType.ASSISTANT || it.fileType === undefined,
    );
  }, [fileListRef.current]);

  const showFileList = fileList.length > 0;
  let fileListArea = <></>;

  const mapFunc = useCallback((item: ChatMessageFile) => {
    return <FileItem fileName={item.fileName} key={item.file_id} />;
  }, []);

  if (showFileList) {
    fileListArea = (
      <div
        css={[
          layout.type === LayoutType.Layout_v1 &&
            css`
              max-width: 357px;
              min-width: 120px;
              background-color: rgb(0 0 0 / 5%);
              border-radius: 10px;
              color: var(--color-fg-default);
              border: var(--border-in-light);
              box-sizing: border-box;
              padding: 18px 20px 16px;
              margin-top: 8px;
              display: flex;
              flex-direction: column;
              row-gap: 10px;
              margin-left: auto;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              max-width: 357px;
              min-width: 120px;
              margin-left: auto;
              display: flex;
              flex-direction: column;
              margin-bottom: 10px;
            `,
        ]}
      >
        {fileList.map(mapFunc)}
      </div>
    );
  }

  return fileListArea;
};

export const ChatFileUploadButton = function () {
  const conversationId = useContext(ConversationIdContext);
  const accessStore = useAccessStore();
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)!),
  );
  const [showMessage, messageContextHolder] = message.useMessage();
  const [, startTransition] = useTransition();
  const layout = useLayoutConfigStore();

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const enableInput = function () {
      if (fileInputRef.current) {
        fileInputRef.current.disabled = false;
      }
    };
    eventBus.on(EventBus.FileInput.Assistant.Action.STATUS_ENABLE, enableInput);

    return () => {
      eventBus.off(
        EventBus.FileInput.Assistant.Action.STATUS_ENABLE,
        enableInput,
      );
    };
  }, []);

  const beforeClickToUploadFile = useCallback(
    function () {
      if (!accessStore.isAuthorized()) {
        showToast("尚未登录", {
          text: "去登录",
          onClick() {
            history.replace(Path.Auth);
          },
        });
        return false;
      }
      if (session.fileCount! + session.pendingFileList!.length >= 10) {
        void showMessage.error(
          "单个对话最多上传10个文件，如需上传请打开新的对话框",
        );
        return false;
      }
      return true;
    },
    [showMessage, session, accessStore],
  );

  const clickToUploadFile = useCallback(
    function (fileList: File[]) {
      if (session.fileCount! + session.pendingFileList!.length >= 10) {
        void showMessage.error(
          "单个对话最多共上传10个文件，如需上传请重新打开新的对话框",
        );
        return;
      }
      void showMessage.open({
        type: "loading",
        content: "上传中",
      });
      startTransition(() => {
        const length = fileList!.length;
        const tmp = [];
        for (let i = 0; i < length; i++) {
          tmp.push({
            uuid: v4(),
            file: fileList[i],
            fileName: "",
            uploadId: -1,
            fileType: UploadFileType.ASSISTANT,
          });
        }
        useUploadFileStore
          .getState()
          .updatePendingFileList([...tmp, ...session.pendingFileList!]);
      });
    },
    [session, showMessage],
  );

  const iconStyle = css`
    width: 20px;
    height: 20px;

    path {
      fill: #4b5562;
    }
  `;
  return (
    <>
      {messageContextHolder}
      {layout.type === LayoutType.Layout_v1 && (
        <FileInputIconV1
          beforeUpload={beforeClickToUploadFile}
          onChange={clickToUploadFile}
          multiple={true}
        />
      )}
      {layout.type === LayoutType.Layout_v2 && (
        <SmallClickInput
          title="文件"
          onChange={clickToUploadFile}
          multiple={true}
        >
          <ChatFileIcon css={iconStyle} />
        </SmallClickInput>
      )}
    </>
  );
};

export const ChatFileUploadButtonV2 = function () {
  const uploadByType = useUpdatePendingFileByType();

  const upload = useCallback(
    (fileList: File[]) => {
      for (const file of fileList) {
        uploadByType(file, UploadFileType.ASSISTANT, false);
      }
    },
    [uploadByType],
  );

  const iconStyle = css`
    width: 20px;
    height: 20px;

    path {
      fill: #4b5562;
    }
  `;

  return (
    <SmallClickInput title={"文件"} multiple={true} onChange={upload}>
      <ChatFileIcon css={iconStyle} />
    </SmallClickInput>
  );
};
