"use client";
import styles from "./user.module.scss";

import { useNavigate, useSearchParams } from "react-router-dom";
import { Path } from "../constant";
import NextImage from "next/image";
import HeadLogoIcon from "@/app/icons/pricing/head-logo.png";
import HeadAvatarIcon from "@/app/icons/pricing/head-avatar.png";
import { useEffect, useMemo, useState } from "react";
import { useUserStore } from "@/app/store/user";
import { useAccessStore } from "@/app/store";
import CircleProgressBar from "@/app/components/progressbar";
import VipIcon from "@/app/icons/user/vip-icon.png";
import { useSiteType } from "@/app/store/siteType";
import { UserType } from "@/app/utils/userUsage";
import { getImageWithSiteType } from "@/app/utils";
import ExamHeadLogoIcon from "@/app/icons/exam/logo_full.png";
import ExamAvatar from "@/app/icons/exam/avatar.png";
import { SiteType } from "@/app/enums/siteType";

export function UserPage() {
  const navigate = useNavigate();
  const [alipay, setAlipay] = useState(true);
  const [searchParams] = useSearchParams();
  const userStore = useUserStore();
  const accessStore = useAccessStore();
  const type = searchParams.get("type") || "1";
  const site = useSiteType();

  const goPricing = () => {
    navigate(Path.Pricing);
  };

  const goHome = () => navigate(Path.Home);

  const exit = () => {
    accessStore.updateToken("");
    goHome();
  };
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!accessStore.isAuthorized()) {
      navigate(Path.Auth, { replace: true });
      return;
    }
    useUserStore.getState().getUserInfo();
  }, [accessStore, navigate]);

  const tokenNum = useMemo(() => {
    let token = userStore.token;
    if (isNaN(parseInt(String(token)))) {
      token = 0;
    }
    if (token < 10000) {
      return "" + token;
    }
    return "" + (Math.floor(token / 100) / 100).toFixed(2) + "万";
  }, [userStore.token]);

  let usageQuotaBlock = <></>;

  const chatGPT_3_5_quota = () => {
    return (
      <div className="flex flex-col w-[108px] flex-grow mx-auto mt-[20px] items-center">
        <div className="flex flex-col justify-center items-center gap-[5px] relative w-[108px] h-[108px]">
          <div className="absolute">
            <CircleProgressBar
              percent={Math.floor(
                userStore.vip_type == 0
                  ? (userStore.balance3_5 * 100) / 5
                  : (userStore.balance3_5 * 100) / 3000,
              )}
            />
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]">
            剩余
          </div>
          <div
            className={`${
              userStore.balance3_5 > 0 ? "text-[#252525]" : "text-[#aaaaaa]"
            } text-[24px] leading-[24px] font-bold`}
          >
            {userStore.balance3_5}
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]"></div>
        </div>
        <div className="text-[14px] text-[#aaaaaa] font-normal leading-[14px] mt-[16px]">
          CAIE 3.5
        </div>
      </div>
    );
  };

  const chatGPT_4_0_quota = () => {
    return (
      <div className="flex flex-col w-[108px] flex-grow mx-auto mt-[20px] items-center">
        <div className="flex flex-col justify-center items-center gap-[5px] relative w-[108px] h-[108px]">
          <div className="absolute">
            <CircleProgressBar
              percent={Math.floor(
                userStore.vip_type == 0
                  ? 0
                  : userStore.vip_type == 1
                    ? (userStore.balance4_0 * 100) / 40
                    : (userStore.balance4_0 * 100) / 20,
              )}
            />
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]">
            剩余
          </div>
          <div
            className={`${
              userStore.balance4_0 > 0 ? "text-[#252525]" : "text-[#aaaaaa]"
            } text-[24px] leading-[24px] font-bold`}
          >
            {userStore.balance4_0}
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]">
            {userStore.vip_type == 0
              ? "共 0 条"
              : userStore.vip_type == 1
                ? "共 40 条"
                : "共 20 条"}
          </div>
        </div>
        <div className="text-[14px] text-[#aaaaaa] font-normal leading-[14px] mt-[16px]">
          CAIE 4.0
        </div>
      </div>
    );
  };

  const token_quota = () => {
    return (
      <div className="flex flex-col w-[108px] flex-grow mx-auto mt-[20px] items-center">
        <div className="flex flex-col justify-center items-center gap-[5px] relative w-[108px] h-[108px]">
          <div className="absolute">
            <CircleProgressBar percent={100} />
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]">
            剩余
          </div>
          <div
            className={`${
              userStore.token > 0 ? "text-[#252525]" : "text-[#aaaaaa]"
            } text-[20px] leading-[24px] font-bold`}
          >
            {tokenNum}
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]"></div>
        </div>
        <div className="text-[14px] text-[#aaaaaa] font-normal leading-[14px] mt-[16px]">
          剩余 Token 数
        </div>
      </div>
    );
  };

  const dall_e_quota = () => {
    return (
      <div className="flex flex-col w-[108px] flex-grow mx-auto mt-[20px] items-center">
        <div className="flex flex-col justify-center items-center gap-[5px] relative w-[108px] h-[108px]">
          <div className="absolute">
            <CircleProgressBar percent={100} />
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]">
            剩余
          </div>
          <div
            className={`${
              userStore.balance_dall_e > 0 ? "text-[#252525]" : "text-[#aaaaaa]"
            } text-[20px] leading-[24px] font-bold`}
          >
            {userStore.balance_dall_e}
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]"></div>
        </div>
        <div className="text-[14px] text-[#aaaaaa] font-normal leading-[14px] mt-[16px]">
          文生图
        </div>
      </div>
    );
  };

  const text_generate_quota = () => {
    return (
      <div className="flex flex-col w-[108px] flex-grow mx-auto mt-[20px] items-center">
        <div className="flex flex-col justify-center items-center gap-[5px] relative w-[108px] h-[108px]">
          <div className="absolute">
            <CircleProgressBar
              percent={Math.floor(
                (userStore.balance_vip_3_text_generation * 100) / 1000,
              )}
            />
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]">
            剩余
          </div>
          <div
            className={`${
              userStore.balance_vip_3_text_generation > 0
                ? "text-[#252525]"
                : "text-[#aaaaaa]"
            } text-[20px] leading-[24px] font-bold`}
          >
            {userStore.balance_vip_3_text_generation}
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]"></div>
        </div>
        <div className="text-[14px] text-[#aaaaaa] font-normal leading-[14px] mt-[16px]">
          文生文
        </div>
      </div>
    );
  };

  const picture_generate_quota = () => {
    return (
      <div className="flex flex-col w-[108px] flex-grow mx-auto mt-[20px] items-center">
        <div className="flex flex-col justify-center items-center gap-[5px] relative w-[108px] h-[108px]">
          <div className="absolute">
            <CircleProgressBar
              percent={Math.floor(
                (userStore.balance_vip_3_text_generation * 100) / 80,
              )}
            />
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]">
            剩余
          </div>
          <div
            className={`${
              userStore.balance_vip_3_text_to_image > 0
                ? "text-[#252525]"
                : "text-[#aaaaaa]"
            } text-[20px] leading-[24px] font-bold`}
          >
            {userStore.balance_vip_3_text_to_image}
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]"></div>
        </div>
        <div className="text-[14px] text-[#aaaaaa] font-normal leading-[14px] mt-[16px]">
          图片生成
        </div>
      </div>
    );
  };

  const picture_edit_quota = () => {
    return (
      <div className="flex flex-col w-[108px] flex-grow mx-auto mt-[20px] items-center">
        <div className="flex flex-col justify-center items-center gap-[5px] relative w-[108px] h-[108px]">
          <div className="absolute">
            <CircleProgressBar
              percent={Math.floor(
                (userStore.balance_vip_3_image_update * 100) / 100,
              )}
            />
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]">
            剩余
          </div>
          <div
            className={`${
              userStore.balance_vip_3_image_update > 0
                ? "text-[#252525]"
                : "text-[#aaaaaa]"
            } text-[20px] leading-[24px] font-bold`}
          >
            {userStore.balance_vip_3_image_update}
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]"></div>
        </div>
        <div className="text-[14px] text-[#aaaaaa] font-normal leading-[14px] mt-[16px]">
          图片修改
        </div>
      </div>
    );
  };

  const ppt_quota = () => {
    return (
      <div className="flex flex-col w-[108px] flex-grow mx-auto mt-[20px] items-center">
        <div className="flex flex-col justify-center items-center gap-[5px] relative w-[108px] h-[108px]">
          <div className="absolute">
            <CircleProgressBar
              percent={Math.floor((userStore.balance_vip_3_ppt * 100) / 10)}
            />
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]">
            剩余
          </div>
          <div
            className={`${
              userStore.balance_vip_3_ppt > 0
                ? "text-[#252525]"
                : "text-[#aaaaaa]"
            } text-[20px] leading-[24px] font-bold`}
          >
            {userStore.balance_vip_3_ppt}
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]"></div>
        </div>
        <div className="text-[14px] text-[#aaaaaa] font-normal leading-[14px] mt-[16px]">
          PPT 生成
        </div>
      </div>
    );
  };

  const assistant_quota = function () {
    return (
      <div className="flex flex-col w-[108px] flex-grow mx-auto mt-[20px] items-center">
        <div className="flex flex-col justify-center items-center gap-[5px] relative w-[108px] h-[108px]">
          <div className="absolute">
            <CircleProgressBar percent={0} />
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]">
            剩余
          </div>
          <div
            className={`${userStore.balance_asst > 0 ? "text-[#252525]" : "text-[#aaaaaa]"} text-[20px] leading-[24px] font-bold`}
          >
            {userStore.balance_asst}
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]"></div>
        </div>
        <div className="text-[14px] text-[#aaaaaa] font-normal leading-[14px] mt-[16px]">
          CAIE 助手
        </div>
      </div>
    );
  };

  const virtual_coin_quota = function () {
    return (
      <div className="flex flex-col w-[108px] flex-grow mx-auto mt-[20px] items-center">
        <div className="flex flex-col justify-center items-center gap-[5px] relative w-[108px] h-[108px]">
          <div className="absolute">
            <CircleProgressBar percent={0} />
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]">
            剩余
          </div>
          <div
            className={`${userStore.paper_app_token > 0 ? "text-[#252525]" : "text-[#aaaaaa]"} text-[20px] leading-[24px] font-bold`}
          >
            {userStore.paper_app_token}
          </div>
          <div className="text-[10px] font-normal text-[#aaaaaa] leading-[10px]"></div>
        </div>
        <div className="text-[14px] text-[#aaaaaa] font-normal leading-[14px] mt-[16px]">
          算粒
        </div>
      </div>
    );
  };

  const usageQuota = () => {
    let quotaBlock = <></>;

    if (userStore.vip_type === UserType.EXAM_USER) {
      quotaBlock = (
        <>
          {text_generate_quota()}
          {picture_generate_quota()}
          {picture_edit_quota()}
          {ppt_quota()}
          {virtual_coin_quota()}
        </>
      );
    } else if (site.siteType !== SiteType.EXAM) {
      if (userStore.role === 2) {
        quotaBlock = (
          <>
            {chatGPT_3_5_quota()}
            {chatGPT_4_0_quota()}
            {dall_e_quota()}
            {token_quota()}
            {assistant_quota()}
            {virtual_coin_quota()}
          </>
        );
      } else {
        quotaBlock = (
          <>
            {chatGPT_3_5_quota()}
            {chatGPT_4_0_quota()}
            {dall_e_quota()}
            {token_quota()}
            {virtual_coin_quota()}
          </>
        );
      }
    }

    return (
      <>
        <div className="text-[24px] text-[#252525] font-medium mt-[35px] ml-[40px]">
          我的额度
        </div>
        <div className="flex ">
          <div className="max-w-[741px] flex flex-grow">
            <div className="max-w-[701px] mx-auto flex min-w-0 flex-grow flex-wrap">
              {quotaBlock}
            </div>
          </div>
        </div>
      </>
    );
  };

  if (site.siteType !== SiteType.EXAM) {
    usageQuotaBlock = usageQuota();
  }

  let vipBrandBlock = <></>;

  const vipBrand = (
    <div
      className={`${
        userStore.vip_type == 0
          ? styles["vip-type-free"]
          : styles["vip-type-vip"]
      } w-[60px] h-[27px] rounded-[14px] text-center leading-[27px] text-[18px] ml-[17px]`}
    >
      {userStore.vip_type == 0
        ? "FREE"
        : userStore.vip_type == 1
          ? "包年"
          : "包月"}
    </div>
  );

  if (site.siteType !== SiteType.EXAM) {
    vipBrandBlock = vipBrand;
  }

  let upgradeArea = <></>;

  const upgradeBlock = () => {
    return (
      <div
        className={
          "flex items-center w-full h-[110px] bg-gradient-to-t from-[#1fd6cc] to-[#0c74ed]  rounded-[8px] text-white " +
          styles["buy_vip_banner"]
        }
      >
        <div
          className={
            " flex flex-col flex-grow gap-[16px] ml-[98px] " +
            styles["banner_text_block"]
          }
        >
          <div
            className={
              "text-[20px] font-bold leading-[20px] " + styles["banner_text"]
            }
          >
            升级以获取更好聊天体验
          </div>
          <div className={"flex items-center " + styles["banner_items"]}>
            <div
              className={
                "flex max-w-[527px] ml-0 mr-auto " +
                styles["banner_items_wrapper"]
              }
            >
              <div className={"flex ml-0 mr-auto " + styles["banner_item"]}>
                <NextImage
                  src={VipIcon.src}
                  alt="VIP图标"
                  width={18}
                  height={18}
                ></NextImage>
                <div className="text-[14px] ml-[4px]">更加丰富的模版</div>
              </div>
              <div className={"flex mr-0 ml-auto " + styles["banner_item"]}>
                <NextImage
                  src={VipIcon.src}
                  alt="VIP图标"
                  width={18}
                  height={18}
                  className=""
                ></NextImage>
                <div className="text-[14px] ml-[4px]">更多的使用次数</div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            styles["subscribe-now"] +
            " " +
            styles["banner_button"] +
            " w-[150px] h-[46px] leading-[46px] mr-[53px] rounded-[23px] cursor-pointer hover:opacity-75 text-[16px] font-bold text-center text-[#1295e2]"
          }
          onClick={goPricing}
        >
          立即升级
        </div>
      </div>
    );
  };
  if (
    site.siteType === SiteType.UNDETERMINED ||
    site.siteType === SiteType.DEV ||
    site.siteType === SiteType.NORMAL ||
    site.siteType === SiteType.ISSUER
  ) {
    if (userStore.vip_type !== 1) {
      upgradeArea = upgradeBlock();
    }
  }

  let logoArea = () => {
    if (site.siteType === SiteType.EXAM) {
      return (
        <>
          <NextImage
            src={ExamHeadLogoIcon}
            alt={""}
            width={112}
            height={50}
            onClick={goHome}
          />
        </>
      );
    }

    return (
      <>
        <NextImage
          src={HeadLogoIcon}
          alt="头部logo"
          width={50}
          height={50}
          onClick={goHome}
        ></NextImage>
        <div className={styles["logo-title"]}>CAIE</div>
      </>
    );
  };

  return (
    <div className={styles["user-page"]}>
      {/*头部*/}
      <div className="flex bg-white h-[80px] justify-center items-center">
        <div className="flex items-center ml-[18.75%] hover:opacity-75 cursor-pointer">
          {logoArea()}
        </div>

        <div className="flex-grow"></div>
        {/*<NextImage*/}
        {/*  className="mr-[18.75%] hover:opacity-75 cursor-pointer"*/}
        {/*  src={HeadAvatarIcon}*/}
        {/*  alt="头像"*/}
        {/*  width={46}*/}
        {/*  height={46}*/}
        {/*></NextImage>*/}
      </div>
      {/*内容*/}
      <div className={styles["content-wrapper"]}>
        <div className={styles["sidebar"] + " hidden 2xl:block"}>
          <div className={styles["item"]}>我的信息</div>
        </div>
        <div className="flex flex-col w-full 2xl:w-[950px] gap-[30px]">
          <div className={styles["data-wrapper"]}>
            <div className="flex w-full mt-[48px] box-border px-[40px]">
              {/*用户头像*/}
              <NextImage
                src={getImageWithSiteType(
                  site.siteType,
                  SiteType.EXAM,
                  ExamAvatar,
                  HeadAvatarIcon,
                )}
                alt="头像"
                width={54}
                height={54}
              ></NextImage>
              {/*用户名 描述*/}
              <div className="flex flex-col ml-[10px] gap-[7px] text-[18px] leading-[18px] self-center">
                <div className="font-bold text-[#252525]">
                  {"ID_" + userStore.id}
                </div>
                <div className="font-normal text-[#aaaaaa]">
                  {"UID_" + userStore.pg_uid}
                </div>
              </div>
              {/*会员标识*/}
              {vipBrandBlock}
              <div className="flex-grow"></div>
              <div
                className="text-[18px] text-[#666666] font-normal cursor-pointer hover:opacity-75"
                onClick={() => exit()}
              >
                退出
              </div>
            </div>
            {usageQuotaBlock}
          </div>

          {/*vip权益*/}
          {upgradeArea}
        </div>
      </div>
    </div>
  );
}
