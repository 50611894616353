"use client";
import React, {
  KeyboardEvent,
  useCallback,
  useId,
  useMemo,
  useRef,
} from "react";
import styles from "@/app/components/home.module.scss";
import { Modal as AntModal } from "antd";
import { css } from "@emotion/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  ChatMessage,
  createMessage,
  DEFAULT_TOPIC,
  useAccessStore,
  useAppConfig,
  useChatStore,
} from "@/app/store";
import { useShallow } from "zustand/react/shallow";
import dynamic from "next/dynamic";
import { Loading } from "@/app/components/home";
import classNames from "classnames";
import { EnableSearchButton } from "@/app/components/chat";
import { useNavigate } from "react-router-dom";
import { Path } from "@/app/constant";
import NextImage from "next/image";
import HiImage from "@/app/icons/hi.png";
import NonLoginIcon1 from "@/app/icons/nonLogin/icon1.png";
import NonLoginIcon2 from "@/app/icons/nonLogin/icon2.png";
import NonLoginIcon3 from "@/app/icons/nonLogin/icon3.png";
import NonLoginIcon4 from "@/app/icons/nonLogin/icon4.png";
import FileIcon from "@/app/icons/nonLogin/file.png";
import PictureIcon from "@/app/icons/nonLogin/picture.png";
import SendIcon from "@/app/icons/nonLogin/send.png";
import TextareaAutosize from "react-textarea-autosize";
import { useSiteType } from "@/app/store/siteType";
import { requestLogin } from "@/app/utils/login";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import { CAIEHeaderV2 } from "@/app/components/chatComponents/v2/globalHeader";
import { SiteType } from "@/app/enums/siteType";
import { LayoutType } from "@/app/enums/layoutType";

const Markdown = dynamic(
  async () => {
    const it = await import("@/app/components/markdown");
    return it.Markdown;
  },
  {
    loading() {
      return <Loading noLogo></Loading>;
    },
  },
);

type RenderMessage = ChatMessage & { preview?: boolean };

const NonLoginHello = `欢迎来到 CAIE AI 对话功能，作为虚拟助手我可以完成许多任务，如：

  1. 回答各种问题，提供解释概念

  2. 提供关于旅行、购物、饮食、健康等方面的建议

  3. 编文章、邮件、推广等文本

  4. 提供一些创意、灵感、新想法

  5. 可以与我聊天、奖笑话，放松一下。
  `;

export const NonLoginChat = function () {
  const [modal, modalContextHolder] = AntModal.useModal();
  const session = useChatStore(useShallow((state) => state.currentSession()));
  const navigate = useNavigate();

  const virtualParentRef = useRef<HTMLDivElement>(null);

  const memoContext = useMemo(() => {
    const context = [...(session?.mask.context ?? [])];
    if (
      context.length === 0 &&
      session?.messages.at(0)?.content !== NonLoginHello
    ) {
      context.push(
        createMessage({
          role: "assistant",
          content: NonLoginHello,
        }),
      );
    }
    context.push(...(session?.messages ?? []));
    return context as RenderMessage[];
  }, [session]);

  const virtualizer = useVirtualizer({
    count: memoContext.length,
    getScrollElement() {
      return virtualParentRef.current;
    },
    estimateSize() {
      return 100;
    },
    enabled: true,
    scrollPaddingEnd: 50,
  });

  const items = virtualizer.getVirtualItems();

  const clickInput = () => {
    if (useSiteType.getState().siteType === SiteType.PAPER666) {
      if (typeof window !== "undefined" && window.__POWERED_BY_WUJIE__) {
        requestLogin(true);
        return;
      }
    }
    navigate(Path.Auth);
  };

  return (
    <>
      {modalContextHolder}
      <div className={styles["chat"]}>
        <div
          className={"window-header"}
          css={[
            css`
              padding: 14px 10px;
              margin-left: -20px;
              margin-right: -20px;
              justify-content: initial;
              border-bottom: var(--border-in-light);
            `,
          ]}
        >
          <div
            className={
              "window-header-title flex items-center gap-[10px] ml-[10px]"
            }
            css={css`
              width: fit-content;
            `}
          >
            <div
              className={classNames(
                "window-header-main-title",
                styles["chat-body-title"],
              )}
            >
              {(session?.topic.length ?? 0) > 0
                ? session!.topic
                : DEFAULT_TOPIC}
            </div>
          </div>
        </div>
        <div
          className={styles["chat-body"]}
          css={css`
            padding-top: 15px;
            margin-bottom: 34px;
          `}
          ref={virtualParentRef}
        >
          <div
            style={{ height: virtualizer.getTotalSize() }}
            css={css`
              position: absolute;
              width: 100%;
            `}
          >
            {items.map((it) => {
              return (
                <NonLoginMessage
                  message={memoContext.at(it.index)!}
                  key={it.key}
                />
              );
            })}
          </div>
        </div>
        <div className={styles["chat-input-panel"]}>
          <div
            css={css`
              position: relative;
              height: 0;
            `}
          >
            <div
              css={[
                css`
                  position: absolute;
                  top: -30px;
                  height: 30px;
                  display: flex;
                `,
              ]}
            >
              <EnableSearchButton hideLeftMargin={true} />
            </div>
          </div>
          <div className={styles["chat-input-panel"]}>
            <div
              css={[
                css`
                  background-color: var(--gray);
                  display: flow-root;
                  margin-left: -20px;
                  margin-right: -20px;
                  padding-left: 14px;
                  padding-right: 20px;
                  min-height: 58px;
                `,
              ]}
            >
              <div className={styles["chat-input-panel-inner"]}>
                <div className={styles["chat_input_textarea_wrapper"]}>
                  <div
                    className={classNames(
                      styles["chat_input_textarea"],
                      "bg-[#F0F1F5] border border-[#E5E5E5] text-[#333333] resize-none box-border py-[7px] px-[20px] text-[15px] font-normal focus:outline-none ",
                    )}
                    onClick={clickInput}
                  >
                    请输入发送消息
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NonLoginMessage = function (props: { message: RenderMessage }) {
  const key = useId();
  let result = <></>;

  if (props.message.role === "system" || props.message.role === "assistant") {
    result = (
      <NonLoginBotMessage
        message={props.message}
        key={key}
      ></NonLoginBotMessage>
    );
  } else if (props.message.role === "user") {
    result = (
      <NonLoginUserMessage
        message={props.message}
        key={key}
      ></NonLoginUserMessage>
    );
  }

  return result;
};

const NonLoginBotMessage = function (props: { message: RenderMessage }) {
  const fontSize = useAppConfig(useShallow((state) => state.fontSize));
  const conversationId = useChatStore(
    useShallow((state) => state.currentSession()?.conversationId),
  );
  const layout = useLayoutConfigStore();

  return (
    <div
      css={css`
        width: 100%;
        padding-bottom: 16px;
      `}
    >
      <div
        css={css`
          display: flex;
        `}
      >
        <div
          css={css`
            max-width: 20px;
            min-width: 0;
            flex-shrink: 1;
          `}
        ></div>
        <div
          css={css`
            flex-grow: 1;
            flex-shrink: 1;
            background-color: rgb(0 0 0 / 5%);
            color: var(--color-fg-default);
            border: var(--border-in-light);
            user-select: text;
            height: auto;
            border-radius: 0 8px 8px;
            padding: 15px;
            max-width: 80%;
          `}
        >
          <Markdown
            content={props.message.content}
            loading={props.message.content.length === 0}
            defaultShow={true}
            isUser={true}
            fontSize={fontSize}
            message={props.message}
            conversationId={conversationId}
          ></Markdown>
        </div>
        <div
          css={css`
            max-width: 20px;
            min-width: 0;
            flex-shrink: 1;
            flex-grow: 1;
          `}
        ></div>
      </div>
      <div
        css={css`
          display: flex;
          margin-top: 15px;
        `}
      >
        <div
          css={[
            css`
              color: var(--color-fg-default);
              font-size: 14px;
              line-height: 14px;
              height: 14px;
            `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                color: #838484;
              `,
          ]}
          suppressHydrationWarning
        >
          {props.message.date}
        </div>
      </div>
    </div>
  );
};

const NonLoginUserMessage = function (props: { message: RenderMessage }) {
  const fontSize = useAppConfig(useShallow((state) => state.fontSize));
  const conversationId = useChatStore(
    useShallow((state) => state.currentSession()?.conversationId),
  );
  const layout = useLayoutConfigStore();

  return (
    <div
      css={css`
        width: 100%;
        padding-bottom: 16px;
      `}
    >
      <div
        css={css`
          display: flex;
        `}
      >
        <div
          css={css`
            min-width: 0;
            flex-shrink: 1;
            flex-grow: 1;
          `}
        ></div>
        <div
          css={css`
            flex-grow: 1;
            flex-shrink: 1;
            background-color: rgb(0 0 0 / 5%);
            color: var(--color-fg-default);
            border: var(--border-in-light);
            user-select: text;
            height: auto;
            padding: 15px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            border-top-left-radius: 8px;
            max-width: 80%;
          `}
        >
          <Markdown
            content={props.message.content}
            loading={props.message.content.length === 0}
            defaultShow={true}
            isUser={true}
            fontSize={fontSize}
            message={props.message}
            conversationId={conversationId}
          ></Markdown>
        </div>
        <div
          css={css`
            min-width: 0;
            flex-shrink: 1;
            flex-grow: 0;
          `}
        ></div>
      </div>
      <div
        css={css`
          display: flex;
          margin-top: 15px;
        `}
      >
        <div
          css={[
            css`
              color: var(--color-fg-default);
              font-size: 14px;
              line-height: 14px;
              height: 14px;
              margin-left: auto;
            `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                color: #838484;
              `,
          ]}
          suppressHydrationWarning
        >
          {props.message.date}
        </div>
      </div>
    </div>
  );
};

export const NonLoginChatDesktop = function () {
  const layout = useLayoutConfigStore();

  const featureBlockItemStyle = css`
    background-color: var(--non-login-bg-light);
    width: 380px;
    border-radius: 8px;
    min-height: 120px;
    display: flow-root;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (width <= 400px) {
      width: 100%;
    }
  `;

  const featureTitleContainerStyle = css`
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
  `;

  const featureTitleTextStyle = css`
    font-size: 18px;
    line-height: 20px;
    color: var(--black);
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    margin-left: 4px;
  `;

  const featureContainerTextStyle = css`
    margin: 11px 20px 20px;
    color: var(--non-login-text-light);
    font-size: 14px;
    line-height: 24px;
  `;

  const selectContainerStyle = css`
    display: flex;
    margin-left: 12px;
  `;

  const selectContainerTextStyle = css`
    font-size: 14px;
    color: var(--black);
    line-height: 20px;
    height: 20px;
  `;

  const navigate = useNavigate();
  const accessStore = useAccessStore();

  const login = useCallback(() => {
    if (useSiteType.getState().siteType === SiteType.PAPER666) {
      if (typeof window !== "undefined" && window.__POWERED_BY_WUJIE__) {
        requestLogin(true);
        return;
      }
    }
    navigate(Path.Auth);
  }, [navigate]);

  const clickToLogin = useCallback(() => {
    if (!accessStore.isAuthorized()) {
      login();
    }
  }, [login, accessStore]);

  const submit = useCallback(async () => {
    if (!accessStore.isAuthorized()) {
      login();
      return;
    }
  }, [accessStore, login]);

  const tapEnterToSubmit = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        void submit();
      }
    },
    [submit],
  );

  return (
    <div
      css={[
        css`
          display: flex;
          flex-direction: column;
          background-color: var(--non-login-body-white);
          height: 100%;
          width: 100%;
          min-width: 100%;
        `,
      ]}
    >
      {layout.type === LayoutType.Layout_v2 && <CAIEHeaderV2 />}
      <div
        css={css`
          display: flex;
          margin-left: auto;
          margin-right: auto;
          width: fit-content;
          margin-top: 60px;
        `}
      >
        <NextImage
          src={HiImage}
          alt={""}
          width={35}
          height={35}
          css={css`
            margin-top: auto;
            margin-bottom: auto;
          `}
        ></NextImage>
        <p
          css={[
            css`
              color: var(--black);
              font-size: 34px;
              line-height: 48px;
              margin-top: 0;
              margin-bottom: 0;
              margin-left: 5px;
              font-weight: 600;
            `,
          ]}
        >
          你好，欢迎来到 CAIE Chat
        </p>
      </div>
      <p
        css={[
          css`
            text-align: center;
            color: var(--black);
            font-size: 14px;
            line-height: 20px;
            margin-top: 30px;
          `,
        ]}
      >
        多种服务模型可选，不论是文字还是图片，我都能处理！快来试试吧
      </p>
      <div
        css={css`
          margin-top: auto;
          margin-bottom: auto;
        `}
      >
        <p
          css={[
            css`
              color: var(--black);
              text-align: center;
              font-size: 22px;
              line-height: 30px;
              font-weight: 600;
            `,
          ]}
        >
          我可以为你做什么？
        </p>
        <div
          css={css`
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
          `}
        >
          <div css={[featureBlockItemStyle]}>
            <div css={featureTitleContainerStyle}>
              <NextImage src={NonLoginIcon1} alt={""} width={20} height={20} />
              <p css={[featureTitleTextStyle]}>自然语言处理</p>
            </div>
            <div css={[featureContainerTextStyle]}>
              比如：文本生成、语言翻译、生成摘要、情感分析等。
              <br />
              我可以创作新闻文章、编写小说。
            </div>
          </div>
          <div css={[featureBlockItemStyle]}>
            <div css={featureTitleContainerStyle}>
              <NextImage src={NonLoginIcon2} alt={""} width={20} height={20} />
              <p css={[featureTitleTextStyle]}>图像处理与计算机视觉</p>
            </div>
            <div css={[featureContainerTextStyle]}>
              比如：图像识别、图像生成、图像风格转换等。
              <br />
              我可以将上传的普通照片转换成名画风格。
            </div>
          </div>
          <div css={[featureBlockItemStyle]}>
            <div css={featureTitleContainerStyle}>
              <NextImage src={NonLoginIcon3} alt={""} width={20} height={20} />
              <p css={[featureTitleTextStyle]}>数据分析与预测</p>
            </div>
            <div css={[featureContainerTextStyle]}>
              比如：趋势预测、数据挖掘、客户行为分析等。
              <br />
              我可以预测股市的未来走势，帮助投资者做出决策。
            </div>
          </div>
          <div css={[featureBlockItemStyle]}>
            <div css={featureTitleContainerStyle}>
              <NextImage src={NonLoginIcon4} alt={""} width={20} height={20} />
              <p css={[featureTitleTextStyle]}>创意与设计</p>
            </div>
            <div css={[featureContainerTextStyle]}>
              比如：设计辅助等。
              <br />
              我可以生成设计草图帮助设计师快速迭代创意。
            </div>
          </div>
        </div>
      </div>
      <div
        css={css`
          margin: auto;
        `}
      >
        <div
          css={css`
            width: 200px;
            height: 44px;
            border-width: 1px;
            border-color: #ff6e25;
            border-style: solid;
            text-align: center;
            line-height: 44px;
            color: #ff6e25;
            font-size: 18px;
            border-radius: 8px;
            cursor: pointer;

            :hover {
              color: white;
              background-color: #ff6e25;
            }
          `}
          onClick={login}
        >
          立即登录
        </div>
      </div>
      <div>
        <div
          css={css`
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
          `}
        >
          <div
            css={css`
              display: flex;
              height: 20px;
              margin-left: -12px;
            `}
          >
            <div css={selectContainerStyle}>
              <NextImage src={FileIcon} alt={""} width={20} height={20} />
              <span css={[selectContainerTextStyle]}>文件</span>
            </div>
            <div css={selectContainerStyle}>
              <NextImage src={PictureIcon} alt={""} width={20} height={20} />
              <span css={[selectContainerTextStyle]}>图片</span>
            </div>
          </div>
          <div
            css={[
              css`
                width: 100%;
                max-height: 120px;
                border-width: 1px;
                border-color: var(--non-login-fg-border);
                border-style: solid;
                border-radius: 6px;
                height: 100%;
                margin-top: 10px;
                display: flow-root;
                background-color: var(--non-login-bg-white);
                position: relative;
              `,
            ]}
          >
            <TextareaAutosize
              placeholder="写下您的问题"
              maxRows={4}
              minRows={4}
              css={[
                css`
                  resize: none;
                  width: calc(100% - 40px);
                  border: none;
                  font-size: 14px;
                  line-height: 20px;
                  margin: 20px 20px 30px;
                  background-color: var(--non-login-bg-white);
                  outline: none;
                `,
              ]}
              onClick={clickToLogin}
              onKeyDown={tapEnterToSubmit}
            />
            <div
              css={css`
                position: absolute;
                right: 20px;
                bottom: 10px;
                height: 20px;
                display: flex;
              `}
            >
              <span
                css={css`
                  color: #dfdfdf;
                  font-size: 14px;
                  line-height: 20px;
                  height: 20px;
                  display: block;
                `}
              >
                按下Enter键发送
              </span>
              <NextImage
                src={SendIcon}
                alt={""}
                height={20}
                width={20}
                css={css`
                  display: block;
                  height: 20px;
                  width: 20px;
                `}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        css={css`
          margin-top: auto;
          content: " ";
        `}
      ></div>
    </div>
  );
};
