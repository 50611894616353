"use client";
import { Theme, useAccessStore, useAppConfig } from "@/app/store";
import { useSiteType } from "@/app/store/siteType";
import { useEffect } from "react";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import { requestLogin } from "@/app/utils/login";
import { SiteType } from "@/app/enums/siteType";
import { LayoutType } from "@/app/enums/layoutType";
import { useMatchNextChannel } from "@/app/hooks/routeHooks";

export const GlobalConfigRule = function () {
  const config = useAppConfig();
  const site = useSiteType();
  const layout = useLayoutConfigStore();
  const accessStore = useAccessStore();
  useMatchNextChannel();

  useEffect(() => {
    if (site.siteType === SiteType.EXAM) {
      if (layout.type === LayoutType.Layout_v1) {
        if (!config.tightBorder) {
          config.update((config) => (config.tightBorder = true));
        }
      }
    } else if (site.siteType === SiteType.PAPER666) {
      if (layout.type === LayoutType.Layout_v1) {
        layout.updateLayout(LayoutType.Layout_v2);
      }
      if (!config.tightBorder) {
        config.update((config) => (config.tightBorder = true));
      }
    }
  }, [site, config, layout]);

  useEffect(() => {
    if (site.siteType === SiteType.PAPER666) {
      if (config.theme !== Theme.Light) {
        config.update((conf) => {
          conf.theme = Theme.Light;
        });
      }
    }
  }, [site, config]);

  if (typeof window !== "undefined") {
    if (window.__POWERED_BY_WUJIE__) {
      if (site.siteType === SiteType.PAPER666 && !accessStore.isAuthorized()) {
        requestLogin();
      }
    }
  }

  return <></>;
};
