"use client";

import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { StoreKey } from "@/app/constant";
import { isInDev } from "@/app/utils";
import { getHeaders } from "@/app/client/api";
import { immer } from "zustand/middleware/immer";
import { NewsSource, NewsType } from "@/app/enums/newsTypes";

type NewsStore = {
  data: Map<string, NewsListItemData[]>;
  fetchNewsList: (
    type: NewsType,
    page: number,
  ) => Promise<Array<NewsListItemData>>;
  fetchNewsDetail: (props: {
    type: NewsType;
    source?: NewsSource;
    date?: string;
    category?: string;
    subCategory?: string;
    id: string;
  }) => Promise<{ title: string; content: string }>;
  updateData: (fn: (data: Map<string, any>) => void) => Promise<void>;
};

export const useNewsStore = create<NewsStore>()(
  devtools(
    immer((setState, getState, store) => {
      return {
        data: new Map(),
        async fetchNewsList(type, page) {
          let innerPage = page;
          if (innerPage < 1) {
            innerPage = 1;
          }
          try {
            const resp = await fetch(
              `/api/news?type=${type}&page=${innerPage}`,
              {
                method: "GET",
                headers: getHeaders(),
              },
            );
            const json = await resp.json();
            return json;
          } catch (e) {
            console.error(e);
            return [];
          }
        },
        async fetchNewsDetail(props) {
          const response = await fetch(
            `/api/news/detail?type=${encodeURIComponent(props.type ?? "")}&date=${encodeURIComponent(props.date ?? "")}&category=${encodeURIComponent(props.category ?? "")}&subCategory=${encodeURIComponent(props.subCategory ?? "")}&id=${encodeURIComponent(props.id)}`,
            {
              method: "GET",
            },
          );
          let json = await response.json();
          if (json.code !== 0) {
            console.warn("new content response", json);
            return {
              title: "",
              content: "",
            };
          }

          return {
            title: json.data.title,
            content: json.data.content,
          };
        },
        async updateData(fn) {
          setState((state) => {
            fn(state.data);
          });
        },
      };
    }),
    {
      name: StoreKey.News,
      enabled: isInDev(),
    },
  ),
);

export interface NewsListItemData {
  id: number;
  title: string;
  timestamp: string;
  date: string;
  category: string;
  subCategory: string;
  imageUrl: string[];
}
