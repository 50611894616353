import { LLMApi } from "@/app/client/classes";
import { ChatOptions, getHeaders, LLMUsage } from "../api";
import { ChatSession, useChatStore } from "@/app/store";
import { fetchTimeout } from "@/app/client/platforms/openai";
import { responseProcess } from "@/app/utils/responseProcess";

export class MindmapApi extends LLMApi {
  constructor() {
    super();
  }

  async chat(options: ChatOptions) {
    const session = useChatStore
      .getState()
      .currentSession(options.conversationId);
    if (!session) {
      options.onError?.(new Error("Session not found"), { code: -1 });
      return;
    }
    void this.send(options, session);
  }

  usage(): Promise<LLMUsage> {
    throw new Error("Method not implemented.");
  }

  async send(options: ChatOptions, session: ChatSession) {
    const message = options.messages.at(-1)!.content;

    const requestPayload = {
      conversation_id: session.conversationId!,
      prompt: message,
      return_html_app_token: "1",
    };

    try {
      const resp = await fetchTimeout(
        fetch("/v1/asst/mindmap", {
          headers: getHeaders(),
          method: "POST",
          body: JSON.stringify(requestPayload),
        }),
        60_000,
      );

      const responseText = await resp.clone().text();
      const respJson = JSON.parse(responseText);
      if (parseInt(respJson.code) !== 0) {
        console.log("request failed", respJson);
        options.onError?.(new Error(respJson.msg ?? "请求失败"), { code: -1 });
        await responseProcess(resp, false);
        return;
      }
      const url = respJson.data;
      if (!url || url.length <= 0) {
        console.log("content empty");
        options.onError?.(new Error(respJson.msg ?? "图片地址为空"), {
          code: -1,
        });
        return;
      }
      options.onFinish(`![](${url})`);
    } catch (e) {
      console.log("request failed with error", e);
      options.onError?.(e as Error, { code: -1 });
    }
  }
}
