"use client";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  type JSX,
} from "react";
import { actOnKeyDown, useMobileScreen } from "@/app/utils";
import { Input, message, Modal } from "antd";
import { css } from "@emotion/react";
import SettingsSVG from "@/app/icons/chat/setting.svg";
import SettingsIcon from "@/app/icons/settings.svg";
import SendIcon from "@/app/icons/chat/send.svg";
import TextareaAutosize from "react-textarea-autosize";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import { Link, useNavigate } from "react-router-dom";
import { Path } from "@/app/constant";
import LightningIcon from "@/app/icons/layout_v2/sidebar/lighting.svg";
import { useBalanceStr, useGetCurrentUsage } from "@/app/utils/userUsage";
import ChatPicIcon from "@/app/icons/layout_v2/chat/pic.svg";
import { ChatFileUploadButton } from "@/app/components/chatComponents/files";
import { UploadFileType } from "@/app/store/uploadFiles";
import { eventBus } from "@/app/utils/eventBus";
import { EventBus } from "@/app/enums/eventBusName";
import { useSiteType } from "@/app/store/siteType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { SiteType } from "@/app/enums/siteType";
import { LayoutType } from "@/app/enums/layoutType";
import { InputMenusWrapper } from "@/app/components/chatComponents/v2/InputMenus";

export const AdditionalSettingsPopUp = function (
  props: React.PropsWithChildren<{ containerRef?: HTMLDivElement }>,
) {
  const [isModalOpen, setModalOpen] = useState(false);

  const clickToOpenModal = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const clickToCloseModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <>
      <AdditionSettingButton
        onClick={clickToOpenModal}
        activate={isModalOpen}
      ></AdditionSettingButton>
      <Modal
        open={isModalOpen}
        onClose={clickToCloseModal}
        onCancel={clickToCloseModal}
        footer={null}
        centered={true}
      >
        <AdditionSettingModal onClose={clickToCloseModal}>
          {props.children}
        </AdditionSettingModal>
      </Modal>
    </>
  );
};

const AdditionSettingButton = function (props: {
  onClick?: () => void;
  activate?: boolean;
}) {
  const isMobile = useMobileScreen();
  const layout = useLayoutConfigStore();

  const click = useCallback(() => {
    if (props.onClick) {
      props.onClick();
    }
  }, [props]);

  let button = <></>;
  if (isMobile) {
    const containerStyleBase = css`
      width: 84px;
      height: 30px;
      display: flex;
      border-radius: 4px;
    `;

    const textStyleBase = css`
      font-size: 14px;
      height: 20px;
      line-height: 20px;
      color: var(--black);
      margin: auto auto auto 3px;
    `;

    const iconStyleBase = css`
      width: 20px;
      height: 20px;
      margin: auto 3px auto auto;
    `;

    const activateStyle = css`
      color: #0057cc;
    `;
    const containerActivateStyle = css`
      background-color: rgb(0 87 204 / 10%);
    `;

    const iconActivateStyle = css`
      & path {
        fill: #0057cc;
      }
    `;

    const containerStyle = [containerStyleBase];
    const iconStyle = [iconStyleBase];

    if (props.activate) {
      containerStyle.push(activateStyle, containerActivateStyle);
      iconStyle.push(iconActivateStyle);
    }

    button = (
      <div css={containerStyle} onClick={click}>
        <SettingsSVG css={iconStyle}></SettingsSVG>
        <div
          css={[
            textStyleBase,
            layout.type === LayoutType.Layout_v1 &&
              props.activate &&
              activateStyle,
            layout.type === LayoutType.Layout_v2 &&
              css`
                color: var(--text-secondary);
              `,
          ]}
        >
          生成设置
        </div>
      </div>
    );
  } else {
    const iconStyle = css`
      width: 16px;
      height: 16px;
      margin: auto 1px auto auto;
      label: icon;
    `;

    const iconStyleV2 = css`
      width: 20px;
      height: 20px;
      margin: auto 1px auto auto;
      label: icon;
    `;

    const textStyle = css`
      font-size: 14px;
      line-height: 14px;
      margin: auto auto auto 1px;
      color: var(--black);
      label: text;
    `;

    const containerStyle = css`
      display: flex;
      width: 104px;
      height: 28px;
      border-radius: 8px;
      border-width: 1px;
      border-color: #ddd;
      border-style: solid;

      :hover {
        border-color: #0057cc;
      }

      :hover .css-${iconStyle.name} path {
        stroke: #0075cc !important;
      }

      :hover .css-${textStyle.name} {
        color: #0075cc;
      }
    `;

    const textStyleV2 = css`
      font-size: 14px;
      color: var(--text-secondary);
      line-height: 20px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: auto;
      display: block;
    `;

    button = (
      <div
        css={[
          containerStyle,
          layout.type === LayoutType.Layout_v2 &&
            css`
              margin-top: auto;
              margin-bottom: auto;
              width: 60px;
              height: 30px;
              border: none;
              border-radius: 4px;

              :hover {
                background-color: #ecf5fa;
              }

              &:hover div {
                color: #248eb3;
              }

              &:hover path {
                stroke: #248eb3 !important;
              }
            `,
        ]}
        onClick={click}
      >
        <SettingsIcon
          css={[
            layout.type === LayoutType.Layout_v1 && iconStyle,
            layout.type === LayoutType.Layout_v2 && iconStyleV2,
          ]}
        ></SettingsIcon>
        <div
          css={[
            layout.type === LayoutType.Layout_v1 &&
              css`
                font-size: 14px;
                line-height: 14px;
                margin: auto auto auto 1px;
                color: var(--black);
                label: text;
              `,
            layout.type === LayoutType.Layout_v2 && textStyleV2,
          ]}
        >
          {layout.type === LayoutType.Layout_v1 && "更多设置"}
          {layout.type === LayoutType.Layout_v2 && "更多"}
        </div>
      </div>
    );
  }

  return button;
};

const AdditionSettingModal = function (
  props: React.PropsWithChildren<{ onClose?: () => void }>,
) {
  const isMobile = useMobileScreen();
  const layout = useLayoutConfigStore();
  const close = useCallback(() => {
    if (props.onClose) {
      props.onClose();
    }
  }, [props]);

  let modalBody: JSX.Element;

  if (isMobile) {
    modalBody = (
      <div>
        <div
          css={css`
            font-size: 16px;
            color: #3e4143;
            font-weight: bold;
            text-align: center;
          `}
        >
          生成设置
        </div>
        <div
          css={css`
            margin-top: 20px;
            display: flex;
            flex-direction: column;
          `}
        >
          {props.children}
        </div>
      </div>
    );
  } else {
    modalBody = (
      <div>
        <div
          css={css`
            color: #333;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
          `}
        >
          更多设置
        </div>
        <div
          css={css`
            margin-top: 36px;
            display: flex;
            flex-direction: column;
          `}
        >
          {props.children}
        </div>
        <div
          css={[
            css`
              color: white;
              border-radius: 8px;
              width: 180px;
              height: 46px;
              font-size: 18px;
              line-height: 46px;
              text-align: center;
              margin-left: auto;
              margin-right: auto;
              margin-top: 20px;
              cursor: pointer;
            `,
            layout.type === LayoutType.Layout_v1 &&
              css`
                background-image: linear-gradient(0deg, #1fd6cc, #0c74ed);
              `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                background-color: #0057cc;
              `,
          ]}
          onClick={close}
        >
          确定
        </div>
      </div>
    );
  }

  return modalBody;
};

export const AdditionSettingItem = function (
  props: React.PropsWithChildren<{ title?: string }>,
) {
  const isMobile = useMobileScreen();

  let result: JSX.Element;

  if (isMobile) {
    const titleStyle = css`
      color: #3e4143;
      font-size: 15px;
      font-weight: bold;
      line-height: 21px;
      height: 21px;
      position: relative;
      margin-left: 9px;

      ::before {
        position: absolute;
        content: " ";
        width: 4px;
        height: 15px;
        background-color: #0057cc;
        left: -9px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
      }
    `;

    const childrenStyle = css`
      width: 100%;
      margin-top: 3px;
    `;

    const containerStyle = css`
      display: flow-root;
      margin-top: 6px;
      margin-bottom: 6px;
    `;

    result = (
      <div css={containerStyle}>
        <div css={titleStyle}>{props.title}</div>
        <div css={childrenStyle}>{props.children}</div>
      </div>
    );
  } else {
    const containerStyle = css`
      display: flex;
      margin-top: 4px;
      margin-bottom: 4px;
    `;
    const textStyle = css`
      font-size: 16px;
      color: #333;
      line-height: 16px;
      height: 16px;
      flex: 5;
      text-align: right;
      margin-top: auto;
      margin-bottom: auto;
    `;
    const childrenStyle = css`
      flex: 9;
      margin-left: 10px;
    `;
    result = (
      <div css={containerStyle}>
        <div css={textStyle}>{props.title}</div>
        <div css={childrenStyle}>{props.children}</div>
      </div>
    );
  }

  return result;
};

export const CommonPromptInputBlock = function (
  props: React.PropsWithChildren<{
    placeHolder?: string;
    updatePrompts: (str: string) => void;
    submit: (ignoreEmptyInput: boolean) => void;
    shouldIgnoreEmptyInput?: boolean;
    inputAboveArea?: React.ReactNode;
  }>,
) {
  const [messages, messageContextHolder] = message.useMessage();
  const [prompt, updatePrompt] = useState("");
  const isMobileScreen = useMobileScreen();
  const layout = useLayoutConfigStore();
  const [outline, setOutline] = useState(false);
  const site = useSiteType();

  const submit = useCallback(() => {
    if ((!prompt || prompt === "") && !props.shouldIgnoreEmptyInput) {
      void messages.error("提示词不能为空");
      return;
    }
    props.submit(props.shouldIgnoreEmptyInput ?? false);
    updatePrompt("");
  }, [props, prompt, messages]);

  const changePrompt = useCallback(
    (value: string) => {
      updatePrompt(value);
      props.updatePrompts(value);
    },
    [props],
  );

  const showOutline = useCallback(() => {
    setOutline(true);
  }, []);

  const hideOutline = useCallback(() => {
    setOutline(false);
  }, []);

  let inputBlock = <></>;
  let placeHolder = props.placeHolder || "向“思宝”提问";

  if (layout.type === LayoutType.Layout_v2) {
    if (site.siteType === SiteType.TRAIN || site.siteType === SiteType.EXAM) {
      placeHolder = props.placeHolder || "请输入发送消息";
    }
  }

  if (layout.type === LayoutType.Layout_v1) {
    if (isMobileScreen) {
      inputBlock = (
        <>
          <div
            css={css`
              min-width: calc(100% + 6px);
              min-height: 40px;
              margin-bottom: 7px;
              border-radius: 4px;
            `}
          >
            <Input
              type={"textarea"}
              style={{ display: "flex", minHeight: "40px" }}
              styles={{
                input: {
                  flexGrow: 1,
                  display: "block",
                  textAlign: "initial",
                },
              }}
              suffix={
                <SendIcon
                  css={css`
                    height: 20px;
                    width: 20px;
                  `}
                  onClick={submit}
                />
              }
              onChange={(e) => changePrompt(e.target.value)}
              placeholder={placeHolder}
              onPressEnter={submit}
            />
          </div>
        </>
      );
    } else {
      inputBlock = (
        <>
          <div
            css={css`
              flex-grow: 1;
              flex-shrink: 1;
              min-width: 0;
            `}
          >
            <div>
              <TextareaAutosize
                value={prompt}
                placeholder={placeHolder}
                css={css`
                  resize: none;
                  background-color: #f0f1f5;
                  border-color: #e5e5e5;
                  color: #333;
                  box-sizing: border-box;
                  font-size: 15px;
                  padding: 7px 20px;
                  border-width: 1px;
                  border-radius: 4px;
                  width: 100%;
                `}
                onChange={(e) => void changePrompt(e.target.value)}
                onKeyDown={(e) => {
                  actOnKeyDown(e, { submit });
                }}
                enterKeyHint="send"
              ></TextareaAutosize>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex-shrink: 0;
              margin-left: 5px;
              margin-bottom: 4px;
            `}
          >
            <div
              css={css`
                color: white;
                width: 106px;
                height: 42px;
                border-radius: 8px;
                background-image: linear-gradient(to top, #1fd6cc, #0c74ed);
                text-align: center;
                line-height: 42px;
                margin-top: auto;
                margin-bottom: auto;
                cursor: pointer;
                user-select: none;

                :hover {
                  opacity: 0.75;
                }
              `}
              onClick={submit}
            >
              发送
            </div>
          </div>
        </>
      );
    }
  } else if (layout.type === LayoutType.Layout_v2) {
    inputBlock = (
      <>
        <div
          css={css`
            width: calc(100% - 36px);
            display: flex;
            flex-grow: 1;
            flex-shrink: 1;
            min-height: 0;
            margin: 12px 16px;
          `}
        >
          {props.children}
          <TextareaAutosize
            value={prompt}
            placeholder={placeHolder}
            onChange={(e) => void changePrompt(e.target.value)}
            onKeyDown={(e) => {
              actOnKeyDown(e, { submit });
            }}
            css={css`
              resize: none;
              border: none;
              flex-grow: 1;
              font-size: 16px;
              line-height: 1.75;
              color: var(--black);
              padding: 0;
              outline: none;
              min-height: 100%;
              max-height: 100%;
              background-color: var(--main-surface-secondary);
              margin-left: 13px;

              ::placeholder {
                color: var(--color-fg-muted);
                background-color: var(--main-surface-secondary);
                font-size: 16px;
              }
            `}
            maxRows={6}
            onFocus={showOutline}
            onBlur={hideOutline}
            enterKeyHint="send"
          />
          <InputMenusWrapper right>
            <ChatSubmitButtonV2 onSubmit={submit} />
          </InputMenusWrapper>
        </div>
      </>
    );
  }
  return (
    <>
      {messageContextHolder}
      <div
        css={[
          layout.type === LayoutType.Layout_v1 &&
            css`
              width: 100%;
              display: flex;
              padding-top: 10px;
              margin-right: -6px;
              padding-right: 6px;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              border-radius: 26px;
              width: 100%;
              background-color: var(--main-surface-secondary);
              display: flex;
              flex-direction: column;
              height: 100%;
              max-height: 180px;
            `,
          layout.type === LayoutType.Layout_v2 &&
            outline &&
            css`
              outline: 2px solid var(--hover-color);
            `,
        ]}
      >
        {layout.type === LayoutType.Layout_v2 && props.inputAboveArea}
        {inputBlock}
      </div>
    </>
  );
};

export const CommonPromptContainerBlock = function (
  props: React.PropsWithChildren<{
    usageStr?: string;
    hideTopPending?: boolean;
    hideUsageBlock?: boolean;
  }>,
) {
  const isMobileScreen = useMobileScreen();
  const layout = useLayoutConfigStore();

  let usageBlock = <></>;
  if (layout.type === LayoutType.Layout_v1 && !isMobileScreen) {
    usageBlock = (
      <div
        css={css`
          position: absolute;
          font-size: 14px;
          line-height: 14px;
          color: #91a2bf;
          unicode-bidi: isolate;
          text-align: right;
          height: 14px;
          top: -20px;
          right: 20px;
        `}
      >
        {props.usageStr}
      </div>
    );
  }

  return (
    <div
      css={[
        css`
          position: relative;
        `,
        layout.type === LayoutType.Layout_v1 &&
          css`
            margin-left: -20px;
            margin-right: -20px;
            background-color: var(--gray);
            min-height: 58px;
            padding-left: 14px;
            padding-right: 20px;
            display: flow-root;
          `,
        layout.type === LayoutType.Layout_v2 &&
          css`
            width: 100%;
            max-width: 824px;
            padding-left: 12px;
            padding-right: 12px;
            box-sizing: border-box;
            margin-left: auto;
            margin-right: auto;
            max-height: 260px;
            display: flex;
            flex-direction: column;
          `,
        layout.type === LayoutType.Layout_v2 &&
          !props.hideTopPending &&
          css`
            padding-top: 40px;
          `,
      ]}
    >
      {usageBlock}
      {props.children}
      {layout.type === LayoutType.Layout_v2 && !props.hideUsageBlock && (
        <CommonUsageNoticeBlockV2 />
      )}
    </div>
  );
};

export const CommonSelectBaseBlock = function (
  props: React.PropsWithChildren<{ usageStr?: string }>,
) {
  const isMobileScreen = useMobileScreen();
  const layout = useLayoutConfigStore();

  let usageBlock = <></>;
  let usagePlaceholder = <></>;

  if (isMobileScreen) {
    usageBlock = (
      <>
        <div
          css={css`
            color: #91a2bf;
            font-size: 14px;
            line-height: 30px;
            margin-left: auto;
            white-space: pre-line;
            flex-shrink: 0;
          `}
        >
          {props.usageStr}
        </div>
      </>
    );
  } else {
    usagePlaceholder = (
      <div
        css={css`
          flex-shrink: 0;
          flex-basis: auto;
          white-space: pre-line;
          font-size: 14px;
          height: 0;
          overflow: hidden;
          margin-left: 4px;
        `}
      >
        {props.usageStr}
      </div>
    );
  }

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <div
        css={[
          layout.type === LayoutType.Layout_v1 &&
            css`
              display: flex;
              width: 100%;
              height: 37px;
              scrollbar-width: none;

              ::-webkit-scrollbar {
                width: 0;
                height: 0;
              }
            `,
          layout.type === LayoutType.Layout_v1 &&
            isMobileScreen &&
            css`
              margin-top: 8px;
            `,
          layout.type === LayoutType.Layout_v1 &&
            !isMobileScreen &&
            css`
              position: absolute;
              top: -37px;
              left: 0;
            `,
        ]}
      >
        <div
          css={css`
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            width: 100%;
            height: 37px;
            scrollbar-width: none;

            ::-webkit-scrollbar {
              width: 0;
              height: 0;
            }
          `}
        >
          {props.children}
          {usageBlock}
        </div>
        {usagePlaceholder}
      </div>
    </div>
  );
};
export const ChatInputInnerBottomAreaV2 = function (
  props: React.PropsWithChildren<object>,
) {
  const navigate = useNavigate();

  const toPay = useCallback(() => {
    navigate(Path.Pricing);
  }, [navigate]);

  return (
    <div
      css={css`
        display: flex;
        padding-left: 14px;
        padding-right: 14px;
        box-sizing: border-box;
        width: 100%;
      `}
    >
      <ChatUsageStrComponentV2 />
      <>
        <div
          css={css`
            content: " ";
            height: 16px;
            width: 1px;
            background-color: #d8d8d8;
            margin: auto 4px auto 10px;
          `}
        ></div>
        <div
          css={css`
            display: flex;
            height: 32px;
            width: 60px;
            cursor: pointer;
            border-radius: 16px;

            :hover {
              background-color: #fff7f1;
            }
          `}
          onClick={toPay}
        >
          <LightningIcon
            css={css`
              width: 16px;
              height: 16px;
              margin-top: auto;
              margin-bottom: auto;
              margin-left: 6px;
              filter: initial !important;
            `}
          />
          <span
            css={css`
              color: #ff6e25;
              font-size: 14px;
              line-height: 32px;
              height: 32px;
              display: block;
            `}
          >
            升级
          </span>
        </div>
      </>
      <div
        css={css`
          display: flex;
          margin-top: auto;
          margin-left: auto;
          margin-bottom: auto;
        `}
      >
        <span
          css={css`
            color: #dfdfdf;
            font-size: 14px;
            line-height: 20px;
            height: 20px;
            display: block;
            margin-right: 2px;
          `}
        >
          按下Enter键发送
        </span>
        {props.children}
      </div>
    </div>
  );
};
const ChatUsageStrComponentV2 = function () {
  const balance = useGetCurrentUsage();
  const usageStr = useBalanceStr();

  return (
    <div
      css={[
        css`
          font-size: 14px;
          line-height: 20px;
          white-space: pre;
          height: 20px;
          color: #7a848a;
          margin-top: auto;
          margin-bottom: auto;
        `,
        (!balance ||
          (balance.oldUsageCount <= 0 && balance.virtualCoinCount <= 0)) &&
          css`
            color: #b24d19;
          `,
      ]}
    >
      {usageStr}
    </div>
  );
};
export const ChatInputAboveButtonsAreaV2 = function (
  props: React.PropsWithChildren<{
    showFile?: boolean;
    showImage?: boolean;
    imageType?: UploadFileType;
  }>,
) {
  const iconStyle = css`
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 6px;

    path {
      fill: #4b5562;
    }
  `;

  const textStyle = css`
    font-size: 14px;
    display: block;
    color: #4b5562;
    line-height: 30px;
    height: 30px;
    margin-right: 6px;
  `;

  const containerStyle = css`
    width: fit-content;
    height: 30px;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 4px;
    cursor: pointer;

    :hover {
      background-color: #ecf5fa;
    }

    :hover .css-${iconStyle.name} path {
      fill: #248eb3;
    }

    :hover .css-${textStyle.name} {
      color: #248eb3;
    }
  `;

  const [showSpecialImage, setShowSpecialImage] = useState(false);
  const [showMaskFile, setShowMaskFile] = useState(false);
  const [showTTFFile, setShowTTFFile] = useState(false);

  useEffect(() => {
    const inputImage = function (value: boolean) {
      if (value !== showSpecialImage) {
        setShowSpecialImage(value);
      }
    };

    const maskFile = function (value: boolean) {
      if (value !== showMaskFile) {
        setShowMaskFile(value);
      }
    };

    const ttfFile = function (value: boolean) {
      if (value != showTTFFile) {
        setShowTTFFile(value);
      }
    };

    eventBus.on(
      EventBus.FileInput.Entry.Action.ACTION_ORIGINAL_IMAGE,
      inputImage,
    );
    eventBus.on(EventBus.FileInput.Entry.Action.ACTION_MASK_IMAGE, maskFile);
    eventBus.on(EventBus.FileInput.Entry.Action.ACTION_TTF_FILE, ttfFile);

    return () => {
      eventBus.off(
        EventBus.FileInput.Entry.Action.ACTION_ORIGINAL_IMAGE,
        inputImage,
      );
      eventBus.off(EventBus.FileInput.Entry.Action.ACTION_MASK_IMAGE, maskFile);
      eventBus.off(EventBus.FileInput.Entry.Action.ACTION_TTF_FILE, ttfFile);
    };
  }, [showSpecialImage, showMaskFile, showTTFFile]);

  const onImageFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const file = target.files![0];
    eventBus.emit(EventBus.FileInput.ImageInput.Action.ACTION_INPUT, file);
    target.value = "";
  }, []);

  const onImageMaskChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const file = target.files![0];
    eventBus.emit(EventBus.FileInput.ImageInput.Action.ACTION_INPUT_MASK, file);
    target.value = "";
  }, []);

  const onTTFFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const file = target.files![0];
    eventBus.emit(EventBus.FileInput.ImageInput.Action.ACTION_INPUT_TTF, file);
    target.value = "";
  }, []);

  return (
    <ChatInputAboveButtonsContainer>
      {props.showFile && <ChatFileUploadButton />}
      {(props.showImage || showSpecialImage) && (
        <label css={containerStyle}>
          <ChatPicIcon css={iconStyle} />
          <span css={textStyle}>{showSpecialImage ? "上传原图" : "图片"}</span>
          <input
            type="file"
            accept="image/*"
            css={css`
              display: none;
            `}
            onInput={onImageFileChange}
          />
        </label>
      )}
      {showMaskFile && (
        <label css={containerStyle}>
          <ChatPicIcon css={iconStyle} />
          <span css={textStyle}>遮罩图</span>
          <input
            type="file"
            accept="image/*"
            css={css`
              display: none;
            `}
            onInput={onImageMaskChange}
          />
        </label>
      )}
      {showTTFFile && (
        <label css={containerStyle}>
          <ChatPicIcon css={iconStyle} />
          <span css={textStyle}>字体</span>
          <input
            type="file"
            accept="image/*"
            css={css`
              display: none;
            `}
            onInput={onTTFFileChange}
          />
        </label>
      )}
      <div>{props.children}</div>
    </ChatInputAboveButtonsContainer>
  );
};

export const ChatInputAboveButtonsContainer = function (
  props: React.PropsWithChildren<object>,
) {
  return (
    <div
      css={css`
        display: flex;
        height: 40px;
        width: 100%;
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 12px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 1;
        column-gap: 10px;
      `}
    >
      {props.children}
    </div>
  );
};

export const ChatSubmitButtonV2 = function (props: {
  onSubmit?: () => void | Promise<void>;
}) {
  const propSubmit = props.onSubmit;

  const submit = useCallback(() => {
    if (propSubmit) {
      propSubmit();
    }
  }, [propSubmit]);

  return (
    <div
      css={css`
        width: 36px;
        height: 36px;
        display: flex;
        border-radius: 50%;
        cursor: pointer;

        :hover {
          background-color: var(--hover-color);
        }
      `}
      onClick={submit}
    >
      <FontAwesomeIcon
        icon={faArrowUp}
        style={{
          width: "16px",
          height: "16px",
          display: "block",
          margin: "auto",
          color: "var(--black)",
        }}
      />
    </div>
  );
};

export const CommonUsageNoticeBlockV2 = function () {
  const usage = useGetCurrentUsage();

  console.log("usage", usage);

  let notice = <></>;

  if (usage.oldUsageCount <= 10 && usage.virtualCoinCount <= 100) {
    notice = (
      <Link
        to={Path.Pricing}
        css={css`
          text-decoration: none;
          text-decoration-color: var(--text-primary);
          color: var(--text-primary);
        `}
      >
        {usage.oldUsageCount >= 0
          ? "您当前剩余条数为" + usage.oldUsageCount + "，请前往充值"
          : "您当前剩余算粒为" + usage.virtualCoinCount + "，请前往充值"}
      </Link>
    );
  }

  return (
    <div
      css={css`
        width: 100%;
        text-align: center;
        height: 40px;
        min-height: 40px;
        display: flex;
      `}
    >
      <p
        css={css`
          display: block;
          margin: auto;
          color: var(--text-primary);
          font-size: 12px;
          text-align: center;
        `}
      >
        {notice}
      </p>
    </div>
  );
};
