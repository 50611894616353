"use client";
import React from "react";
import { motion, useSpring, useTransform } from "framer-motion";

// @ts-ignore
const CircleProgressBar = ({ percent }) => {
  const radius = 46;
  const strokeWidth = 8;
  const circumference = 2 * Math.PI * radius;
  const progress = useSpring(percent, {
    stiffness: 0.5,
    damping: 0.5,
  });
  const strokeDashoffset = useTransform(progress, [0, 100], [circumference, 0]);

  return (
    <svg width="100" height="100" viewBox="0 0 108 108">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" stopColor="rgba(31,214,204, 1)" />
          <stop offset="100%" stopColor="rgba(12, 116, 237, 1)" />
        </linearGradient>
      </defs>
      <circle
        stroke="#EAEAEA"
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        cx="54"
        cy="54"
      />
      <motion.circle
        stroke="url(#gradient)"
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        r={radius}
        cx="54"
        cy="54"
        style={{
          rotate: -90,
          strokeDashoffset,
        }}
      />
    </svg>
  );
};

export default CircleProgressBar;
