"use client";
import { ChatEnums } from "@/app/enums/chat";
import { css } from "@emotion/react";
import { message, Select } from "antd";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useImmer } from "use-immer";
import { useChatStore } from "@/app/store";
import { produce } from "immer";
import { useDebouncedCallback } from "use-debounce";
import { UploadFileType, useUploadFileStore } from "@/app/store/uploadFiles";
import { useShallow } from "zustand/react/shallow";
import {
  AdditionalSettingsPopUp,
  AdditionSettingItem,
  ChatInputAboveButtonsContainer,
  CommonPromptContainerBlock,
  CommonPromptInputBlock,
  CommonSelectBaseBlock,
} from "@/app/components/chatComponents/commonLib";
import { useSiteType } from "@/app/store/siteType";
import {
  useDeleteByType,
  useSubmit,
  useUpdatePendingFileByType,
} from "@/app/hooks/chatHooks";
import { useBalanceStr } from "@/app/utils/userUsage";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import { ImageInputBlock } from "@/app/components/chatComponents/wanx";
import { eventBus } from "@/app/utils/eventBus";
import { EventBus } from "@/app/enums/eventBusName";
import { SiteType } from "@/app/enums/siteType";
import { LayoutType } from "@/app/enums/layoutType";
import { SmallClickInput } from "@/app/components/chatComponents/v2/InputComponents";
import ChatPicIcon from "@/app/icons/layout_v2/chat/pic.svg";
import {
  InputMenus,
  InputMenusWrapper,
} from "@/app/components/chatComponents/v2/InputMenus";
import { MenuItem } from "@mui/joy";
import { ConversationIdContext } from "@/app/contexts/chatIdContext";

export const StableDiffusionSelectArea = function (props: {
  usageStr?: string;
}) {
  const chatStore = useChatStore();
  const session = useChatStore(useShallow((state) => state.currentSession()!));
  const layout = useLayoutConfigStore();

  const [stableDiffusionOptions, updateDiffusionOptions] = useImmer(
    session.stableDiffusionOptions,
  );
  useEffect(() => {
    const stableDiffusionOp = session.stableDiffusionOptions;
    if (!stableDiffusionOp) {
      const defaultStat = {
        operation: ChatEnums.StableDiffusion.Operation.GENERATE,
        size: ChatEnums.StableDiffusion.Size.SIZE_1024_1024,
        count: ChatEnums.StableDiffusion.Count.COUNT_1,
      };
      chatStore.updateCurrentSession((session1) => {
        session1.stableDiffusionOptions = defaultStat;
      });
      updateDiffusionOptions(defaultStat);
    }
  }, [chatStore, session.stableDiffusionOptions, updateDiffusionOptions]);

  const debouncedStableDiffusionOptionUpdate = useDebouncedCallback(
    (value: ChatEnums.StableDiffusion.Options) => {
      useChatStore.getState().updateCurrentSession((session1) => {
        session1.stableDiffusionOptions = value;
      });
    },
    100,
  );

  const updateFn = (values: ChatEnums.StableDiffusion.Options) => {
    updateDiffusionOptions(values);
    debouncedStableDiffusionOptionUpdate(values);
  };

  const optionsChange = {
    operation(value: ChatEnums.StableDiffusion.Operation) {
      updateFn(
        produce(stableDiffusionOptions!, (option) => {
          option.operation = value;
        }),
      );
    },
    count(value: ChatEnums.StableDiffusion.Count) {
      updateFn(
        produce(stableDiffusionOptions!, (option) => {
          option.count = value;
        }),
      );
    },
    size(value: ChatEnums.StableDiffusion.Size) {
      updateFn(
        produce(stableDiffusionOptions!, (option) => {
          option.size = value;
        }),
      );
    },
  };

  return (
    <CommonSelectBaseBlock usageStr={props.usageStr}>
      <div
        css={[
          css`
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            scrollbar-width: none;

            ::-webkit-scrollbar {
              width: 0;
              height: 0;
            }
          `,
          layout.type === LayoutType.Layout_v1 &&
            css`
              height: 34px;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              height: 100%;
            `,
        ]}
      >
        <Select
          value={stableDiffusionOptions?.operation}
          popupMatchSelectWidth={true}
          placement="topLeft"
          placeholder="操作类型"
          onChange={optionsChange.operation}
          style={{
            height: "28px",
            marginTop:
              layout.type === LayoutType.Layout_v2 ? "auto" : undefined,
            marginBottom:
              layout.type === LayoutType.Layout_v2 ? "auto" : undefined,
          }}
          options={[
            {
              value: ChatEnums.StableDiffusion.Operation.GENERATE,
              label: "生成",
            },
            {
              value: ChatEnums.StableDiffusion.Operation.EDIT,
              label: "编辑",
            },
            {
              value: ChatEnums.StableDiffusion.Operation.VARIATION,
              label: "变化",
            },
          ]}
        />
        <div
          css={[
            css`
              margin-left: 10px;
            `,
            layout.type === LayoutType.Layout_v1 &&
              css`
                height: 28px;
              `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                height: 100%;
                display: flex;
                align-items: center;
              `,
          ]}
        >
          <AdditionalSettingsPopUp>
            <AdditionSettingItem title={"生成图片数量"}>
              <Select
                value={stableDiffusionOptions?.count}
                popupMatchSelectWidth={true}
                placement="topLeft"
                placeholder="生成数量"
                onChange={optionsChange.count}
                style={{ height: "28px", width: "100%" }}
                options={[
                  {
                    value: ChatEnums.StableDiffusion.Count.COUNT_1,
                    label: "生成1张",
                  },
                  {
                    value: ChatEnums.StableDiffusion.Count.COUNT_2,
                    label: "生成2张",
                  },
                  {
                    value: ChatEnums.StableDiffusion.Count.COUNT_3,
                    label: "生成3张",
                  },
                  {
                    value: ChatEnums.StableDiffusion.Count.COUNT_4,
                    label: "生成4张",
                  },
                ]}
              />
            </AdditionSettingItem>
            <AdditionSettingItem title={"图片尺寸"}>
              <Select
                value={stableDiffusionOptions?.size}
                popupMatchSelectWidth={true}
                placement="topLeft"
                placeholder="图片尺寸"
                onChange={optionsChange.size}
                style={{
                  height: "28px",
                  width: "100%",
                  marginTop:
                    layout.type === LayoutType.Layout_v2 ? "auto" : undefined,
                  marginBottom:
                    layout.type === LayoutType.Layout_v2 ? "auto" : undefined,
                }}
                options={[
                  {
                    value: ChatEnums.StableDiffusion.Size.SIZE_512_512,
                    label: "512x512",
                  },
                  {
                    value: ChatEnums.StableDiffusion.Size.SIZE_1024_1024,
                    label: "1024x1024",
                  },
                  {
                    value: ChatEnums.StableDiffusion.Size.SIZE_1024_1792,
                    label: "1024x1792",
                  },
                  {
                    value: ChatEnums.StableDiffusion.Size.SIZE_1792_1024,
                    label: "1792x1024",
                  },
                ]}
              />
            </AdditionSettingItem>
          </AdditionalSettingsPopUp>
        </div>
      </div>
    </CommonSelectBaseBlock>
  );
};
export const StableDiffusionImageUploadArea = function (props: {
  uploadFn: (file: File) => void;
  deleteFn?: () => void;
}) {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)!),
  );
  const uploadStore = useUploadFileStore();
  const layout = useLayoutConfigStore();

  const image = useMemo(() => {
    return (
      uploadStore
        .getPendingFileList(session.id)
        .find((it) => it.fileType === UploadFileType.STABLE_DIFFUSION) ?? {
        uuid: "",
        fileName: "",
        uploadId: -1,
        imageUrl: "",
        fileType: UploadFileType.STABLE_DIFFUSION,
      }
    );
  }, [uploadStore, session.id]);
  const uploadFn = props.uploadFn;

  useEffect(() => {
    const baseImage = function (file: File) {
      uploadFn(file);
    };
    eventBus.on(EventBus.FileInput.ImageInput.Action.ACTION_INPUT, baseImage);

    return () => {
      eventBus.off(
        EventBus.FileInput.ImageInput.Action.ACTION_INPUT,
        baseImage,
      );
    };
  }, [uploadFn]);

  return (
    <>
      {(layout.type === LayoutType.Layout_v1 ||
        (layout.type === LayoutType.Layout_v2 && image.imageUrl)) && (
        <div
          css={css`
            margin-top: 10px;
            margin-bottom: 10px;
          `}
        >
          <ImageInputBlock
            imageUrl={image.imageUrl}
            onChange={props.uploadFn}
            onDelete={props.deleteFn}
            titleStr={"上传图片"}
          />
        </div>
      )}
    </>
  );
};

export const StableDiffusionInputArea = function () {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)),
  );
  const [messageApi, messageContextHolder] = message.useMessage();
  const uploadImage = useUpdatePendingFileByType({
    onError(e) {
      void messageApi.error(e.message);
    },
  });
  const site = useSiteType();
  const doSubmit = useSubmit();
  const [userInput, setUserInput] = useState("");
  const usageStr = useBalanceStr();
  const layout = useLayoutConfigStore();

  const upload = useCallback(
    (file: File) => {
      uploadImage(file, UploadFileType.STABLE_DIFFUSION);
    },
    [uploadImage],
  );
  const deleteByType = useDeleteByType();

  const deleteFile = useCallback(() => {
    deleteByType(UploadFileType.STABLE_DIFFUSION);
  }, [deleteByType]);

  const submit = useCallback(
    (ignoreEmptyInput: boolean) => {
      doSubmit(userInput, ignoreEmptyInput);
      setUserInput("");
    },
    [doSubmit, userInput],
  );

  const updateUserInput = useCallback((value: string) => {
    setUserInput(value);
  }, []);

  let imageSelectArea = <></>;
  if (
    session?.stableDiffusionOptions?.operation ===
      ChatEnums.StableDiffusion.Operation.EDIT ||
    session?.stableDiffusionOptions?.operation ===
      ChatEnums.StableDiffusion.Operation.VARIATION
  ) {
    imageSelectArea = (
      <>
        <StableDiffusionImageUploadArea
          uploadFn={upload}
          deleteFn={deleteFile}
        />
      </>
    );
  }

  let placeHolder = '向"思宝"提问';
  if (site.siteType === SiteType.TRAIN || site.siteType === SiteType.EXAM) {
    placeHolder = "请使用英文输入发送消息";
  }
  return (
    <>
      {messageContextHolder}
      <CommonPromptContainerBlock usageStr={usageStr}>
        {layout.type === LayoutType.Layout_v1 && (
          <StableDiffusionSelectArea usageStr={usageStr} />
        )}
        {layout.type === LayoutType.Layout_v2 && (
          <ChatInputAboveButtonsContainer>
            <StableDiffusionSelectArea />
          </ChatInputAboveButtonsContainer>
        )}
        {layout.type === LayoutType.Layout_v1 && imageSelectArea}
        <CommonPromptInputBlock
          updatePrompts={updateUserInput}
          submit={submit}
          placeHolder={placeHolder}
        >
          {/*{layout.type === LayoutType.Layout_v2 && imageSelectArea}*/}
          {layout.type === LayoutType.Layout_v2 && (
            <StableDiffusionInputMenuV2></StableDiffusionInputMenuV2>
          )}
        </CommonPromptInputBlock>
      </CommonPromptContainerBlock>
    </>
  );
};

const StableDiffusionInputMenuV2 = function () {
  const conversationId = useContext(ConversationIdContext);
  const stableDiffusionOperation = useChatStore(
    useShallow(
      (state) =>
        state.currentSession(conversationId)?.stableDiffusionOptions?.operation,
    ),
  );

  let displayArea = <></>;

  if (
    stableDiffusionOperation === ChatEnums.StableDiffusion.Operation.EDIT ||
    stableDiffusionOperation === ChatEnums.StableDiffusion.Operation.VARIATION
  ) {
    displayArea = (
      <>
        <InputMenusWrapper left>
          <InputMenus>
            <MenuItem>
              <StableDiffusionOriginalImageV2 />
            </MenuItem>
          </InputMenus>
        </InputMenusWrapper>
      </>
    );
  }

  return displayArea;
};

const StableDiffusionOriginalImageV2 = function () {
  const uploadByType = useUpdatePendingFileByType();

  const upload = useCallback(
    (fileList: File[]) => {
      const file = fileList[0];
      if (file) {
        uploadByType(file, UploadFileType.STABLE_DIFFUSION);
      }
    },
    [uploadByType],
  );

  return (
    <SmallClickInput title={"上传图片"} accept={"image/*"} onChange={upload}>
      <ChatPicIcon
        css={css`
          width: 20px;
          height: 20px;
          margin-top: auto;
          margin-bottom: auto;

          path {
            fill: #4b5562;
          }
        `}
      />
    </SmallClickInput>
  );
};
