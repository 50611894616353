"use client";
import { useSiteType } from "@/app/store/siteType";
import { useAccessStore } from "@/app/store";
import { useUserStore } from "@/app/store/user";
import { SiteType } from "@/app/enums/siteType";

export const requestLogin = function (showLoginPopup?: boolean) {
  if (useSiteType.getState().siteType === SiteType.PAPER666) {
    if (typeof window !== "undefined" && window.__POWERED_BY_WUJIE__) {
      import("wujie")
        .then((it) => it.bus)
        .then((bus) => {
          bus.$on("caie_chat:getAuthToken", (props: { token: string }) => {
            useAccessStore.setState({ token: props.token });
            useUserStore.getState().getUserInfo();
          });
          bus.$emit("paper666:requestLogin", {
            callback: "caie_chat:getAuthToken",
            showLoginPopup,
          });
        });
    }
  }
};
