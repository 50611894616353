"use client";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { FETCH_COMMIT_URL, StoreKey } from "../constant";
import { apiClientTypeByUsageType, ClientApi } from "../client/api";
import { getClientConfig } from "../config/client";
import { useUserStore } from "@/app/store/user";
import { createWithEqualityFn } from "zustand/traditional";
import isEqual from "react-fast-compare";

export interface UpdateStore {
  lastUpdate: number;
  remoteVersion: string;

  used?: number;
  subscription?: number;
  lastUpdateUsage: number;

  version: string;
  getLatestVersion: (force?: boolean) => Promise<void>;
  updateUsage: (force?: boolean) => Promise<void>;
}

const ONE_MINUTE = 60 * 1000;

export const useUpdateStore = createWithEqualityFn<UpdateStore>()(
  persist(
    (set, get) => ({
      lastUpdate: 0,
      remoteVersion: "",

      lastUpdateUsage: 0,

      version: "unknown",

      async getLatestVersion(force = false) {
        set(() => ({ version: getClientConfig()?.commitId ?? "unknown" }));

        const overTenMins = Date.now() - get().lastUpdate > 10 * ONE_MINUTE;
        if (!force && !overTenMins) return;

        set(() => ({
          lastUpdate: Date.now(),
        }));

        try {
          const data = await (await fetch(FETCH_COMMIT_URL)).json();
          const remoteCommitTime = data[0].commit.committer.date;
          const remoteId = new Date(remoteCommitTime).getTime().toString();
          set(() => ({
            remoteVersion: remoteId,
          }));
          console.log("[Got Upstream] ", remoteId);
        } catch (error) {
          console.error("[Fetch Upstream Commit Id]", error);
        }
      },

      async updateUsage(force = false) {
        const overOneMinute = Date.now() - get().lastUpdateUsage >= ONE_MINUTE;
        if (!overOneMinute && !force) return;

        set(() => ({
          lastUpdateUsage: Date.now(),
        }));

        try {
          let api = new ClientApi(
            apiClientTypeByUsageType(useUserStore.getState().usage_type),
          );
          const usage = await api.llm.usage();

          if (usage) {
            set(() => ({
              used: usage.used,
              subscription: usage.total,
            }));
          }
        } catch (e) {
          console.error((e as Error).message);
        }
      },
    }),
    {
      name: StoreKey.Update,
      version: 1,
    },
  ),
  isEqual,
);
