"use client";
import styles from "./floatingButtons.module.scss";
import classNames from "classnames";
import NextImage from "next/image";
import SupportIcon from "../icons/support.png";
import { Button, Modal } from "antd";
import KeFuIcon from "@/app/icons/chat/kefu_1.png";
import { useState } from "react";
import { Path } from "@/app/constant";
import { useLocation } from "react-router-dom";

export function FloatingButtons() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation();

  if (
    location.pathname.startsWith(Path.Chat) ||
    location.pathname === Path.Home
  ) {
    return <></>;
  }

  return (
    <>
      <div
        className={classNames(styles["float_root"])}
        onClick={() => setIsModalOpen(true)}
      >
        <NextImage
          src={SupportIcon}
          alt={""}
          width={30}
          height={30}
          className={classNames("mx-auto mt-[10px]", styles["icon"])}
        ></NextImage>
        <p className={classNames(styles["text"])}>联系客服</p>
      </div>
      <Modal
        title={<div style={{ textAlign: "center" }}>反馈问题和建议</div>}
        footer={null}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <NextImage
            src={KeFuIcon}
            alt="客服"
            width={100}
            height={100}
          ></NextImage>
          <Button
            type="primary"
            style={{ marginTop: "30px" }}
            onClick={() => setIsModalOpen(false)}
          >
            知道了
          </Button>
        </div>
      </Modal>
    </>
  );
}
