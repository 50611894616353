import { Path } from "@/app/constant";
import { match, P } from "ts-pattern";

export enum ErrorCodeRaw {
  CODE_401 = "未登录",
  CODE_402 = "登录过期",
  CODE_403 = "没有权限",
  CODE_422 = "无效参数",
  CODE_428 = "您的算粒已用完",
  CODE_429 = "您的次数已用完",
  CODE_430 = "免费次数已用完",
  CODE_431 = "内容包含违禁词，请重新编辑后再试",
  CODE_500 = "服务器内部错误",
  CODE_502 = "bad gateway",
  CODE_N1 = "client error",
  CODE_N2 = "unknown error",
}

export enum ErrorCodeDisplay {
  CODE_401 = `尚未登录，请先 [登录](caie-jump://${Path.Auth})`,
  CODE_402 = `登录已过期，请重新登录`,
  CODE_403 = `你没有访问权限`,
  CODE_428 = `您的算粒已用完，您可以 [购买算粒](caie-jump://${Path.Pricing})`,
  CODE_429 = `您的次数已用完，您可以 [升级套餐](caie-jump://${Path.Pricing})`,
  CODE_430 = `免费次数已用完，如果想继续使用，您可以 [购买套餐](caie-jump://${Path.Pricing})`,
  CODE_431 = `内容包含违禁词，请重新编辑后再重试`,
  CODE_500 = `服务器错误，请稍后再试`,
  CODE_502 = `服务器无响应，请稍后再试`,
  CODE_N1 = "客户端错误",
  CODE_N2 = "未知错误",
}

export const matchErrorCode = function (errorCode: number) {
  return match(errorCode)
    .with(401, () => {
      return {
        raw: ErrorCodeRaw.CODE_401,
        display: ErrorCodeDisplay.CODE_401,
        additionalContent: false,
        isError: true,
      };
    })
    .with(402, () => {
      return {
        raw: ErrorCodeRaw.CODE_402,
        display: ErrorCodeDisplay.CODE_402,
        additionalContent: false,
        isError: true,
      };
    })
    .with(403, () => {
      return {
        raw: ErrorCodeRaw.CODE_403,
        display: ErrorCodeDisplay.CODE_403,
        additionalContent: false,
        isError: true,
      };
    })
    .with(428, () => {
      return {
        raw: ErrorCodeRaw.CODE_428,
        display: ErrorCodeDisplay.CODE_428,
        additionalContent: false,
        isError: true,
      };
    })
    .with(429, () => {
      return {
        raw: ErrorCodeRaw.CODE_429,
        display: ErrorCodeDisplay.CODE_429,
        additionalContent: false,
        isError: true,
      };
    })
    .with(430, () => {
      return {
        raw: ErrorCodeRaw.CODE_430,
        display: ErrorCodeDisplay.CODE_430,
        additionalContent: false,
        isError: true,
      };
    })
    .with(431, () => {
      return {
        raw: ErrorCodeRaw.CODE_431,
        display: ErrorCodeDisplay.CODE_431,
        additionalContent: true,
        isError: true,
      };
    })
    .with(500, () => {
      return {
        raw: ErrorCodeRaw.CODE_500,
        display: ErrorCodeDisplay.CODE_500,
        additionalContent: false,
        isError: true,
      };
    })
    .with(502, () => {
      return {
        raw: ErrorCodeRaw.CODE_502,
        display: ErrorCodeDisplay.CODE_502,
        additionalContent: false,
        isError: true,
      };
    })
    .with(
      P.when((it) => it < 0),
      () => {
        return {
          raw: ErrorCodeRaw.CODE_N1,
          display: ErrorCodeDisplay.CODE_N1,
          additionalContent: true,
          isError: true,
        };
      },
    )
    .with(
      // 服务器自定义消息
      P.when((it) => it >= 490 && it < 500),
      () => {
        return {
          raw: "",
          display: "",
          additionalContent: true,
          isError: true,
        };
      },
    )
    .with(0, () => {
      return {
        raw: "",
        display: "",
        additionalContent: false,
        isError: false,
      };
    })
    .otherwise(() => {
      return {
        raw: ErrorCodeRaw.CODE_N2,
        display: ErrorCodeDisplay.CODE_N2,
        additionalContent: true,
        isError: true,
      };
    });
};
