import { useAccessStore } from "@/app/store";
import { showToast } from "@/app/components/ui-lib";

export const responseProcess = async (
  rep: Response,
  stream: boolean,
): Promise<boolean> => {
  if (stream) {
    const code = rep.headers.get("code")?.trim();
    console.log("response code", code);
    if (code === "402") {
      useAccessStore.getState().updateToken("");
      showToast("登录已过期，请重新登录");
      return false;
    }
    if (code === "401") {
      useAccessStore.getState().updateToken("");
      showToast("未登录");
      return false;
    }
  } else {
    const code = (await rep.clone().json()).code;
    console.log("response code", code);
    if (code == "402") {
      useAccessStore.getState().updateToken("");
      showToast("登录已过期，请重新登录");
      return false;
    }
    if (code === "401") {
      useAccessStore.getState().updateToken("");
      showToast("未登录");
      return false;
    }
  }
  return true;
};

export const jsonResponseProcess = async (json: any): Promise<boolean> => {
  if (json.code == "402") {
    useAccessStore.getState().updateToken("");
    showToast("登录已过期，请重新登录");
    return false;
  }
  if (json.code === "401") {
    useAccessStore.getState().updateToken("");
    showToast("未登录");
    return false;
  }
  return true;
};
