import styles from "./auth.module.scss";

import { useAccessStore } from "../store";
import NextImage from "next/image";
import AuthCoverIcon from "@/app/icons/auth/auth_cover.png";
import ExamAuthCoverIcon from "@/app/icons/exam/login.png";
import WechatIcon from "@/app/icons/auth/wechat.png";
import AgreeIcon from "@/app/icons/auth/agree_icon.png";
import PhoneLoginIcon from "@/app/icons/auth/phone_login.png";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { message } from "antd";
import { Path } from "@/app/constant";
import { useNavigate } from "react-router-dom";
import { getImageWithSiteType, useMobileScreen } from "@/app/utils";
import { useUserStore } from "@/app/store/user";
import { useSiteType } from "@/app/store/siteType";
import { css } from "@emotion/react";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import { SiteType } from "@/app/enums/siteType";
import { LayoutType } from "@/app/enums/layoutType";

export function AuthPage() {
  const [phoneValue, setPhoneValue] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [codeValue, setCodeValue] = useState("");
  const [codeButtonText, setCodeButtonText] = useState("获取验证码");
  const [codeDisable, setCodeDisable] = useState(true);
  const [codeLoading, setCodeLoading] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [phoneLogin, setPhoneLogin] = useState(true);
  const accessStore = useAccessStore();
  const navigate = useNavigate();
  const site = useSiteType();
  const [messageApi, messageContextHolder] = message.useMessage();
  const layout = useLayoutConfigStore();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const goChat = useCallback(() => navigate(Path.Chat), [navigate]);
  const passportQrLoginToLogin = useCallback(
    (data: any) => {
      console.log("扫码登录", data);
      void messageApi.success("扫码成功");
      useAccessStore.getState().wechatLogin(data.uid, data.time, data.hash);
    },
    [messageApi],
  );
  const passportQrLoginToReg = useCallback(
    (data: any) => {
      console.log("扫码注册", data);
      void messageApi.success("扫码成功");
      useAccessStore.getState().wechatReg(data.unionid, data.time, data.hash);
    },
    [messageApi],
  );
  const passportQrLoginError = useCallback(
    (data: any) => {
      console.log("扫码错误", data);
      void messageApi.warning(data || "扫码错误,请稍后再试");
    },
    [messageApi],
  );

  useEffect(() => {
    useAccessStore.getState().initState();
    useAccessStore.getState().refreshCaptchaUrl();
  }, []);

  useEffect(() => {
    // @ts-ignore
    window.passportQrLoginToLogin = passportQrLoginToLogin;
    // @ts-ignore
    window.passportQrLoginToReg = passportQrLoginToReg;
    // @ts-ignore
    window.passportQrLoginError = passportQrLoginError;

    return () => {
      // @ts-ignore
      delete window.passportQrLoginToLogin;
      // @ts-ignore
      delete window.passportQrLoginToReg;
      // @ts-ignore
      delete window.passportQrLoginError;
    };
  }, [passportQrLoginToLogin, passportQrLoginToReg, passportQrLoginError]);

  if (codeLoading !== accessStore.codeLoading) {
    setCodeLoading(accessStore.codeLoading);
    console.log("codeLoading", accessStore.codeLoading);
  }

  if (submitLoading !== accessStore.submitLoading) {
    setSubmitLoading(accessStore.submitLoading);
    console.log("submitLoading", accessStore.submitLoading);
  }

  useEffect(() => {
    if (accessStore.isAuthorized()) {
      useUserStore.getState().getUserInfo();
      goChat();
    }
  }, [accessStore, goChat]);

  if (codeButtonText !== accessStore.fetchMobileCodeButtonText) {
    setCodeButtonText(accessStore.fetchMobileCodeButtonText);
  }

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhoneValue(event.target.value);
    setSubmitDisable(
      event.target.value.length == 0 ||
        captchaValue.length == 0 ||
        codeValue.length == 0,
    );
  };

  const handleCaptchaChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCaptchaValue(event.target.value);
    setCodeDisable(event.target.value.length == 0);
    setSubmitDisable(
      phoneValue.length == 0 ||
        event.target.value.length == 0 ||
        codeValue.length == 0,
    );
  };

  const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCodeValue(event.target.value);
    setSubmitDisable(
      phoneValue.length == 0 ||
        captchaValue.length == 0 ||
        event.target.value.length == 0,
    );
  };

  const toggleLoginType = () => {
    setPhoneLogin(!phoneLogin);
  };

  const handleUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setSubmitDisable(event.target.value.length === 0 || password.length == 0);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setSubmitDisable(username.length === 0 || event.target.value.length === 0);
  };

  const isMobile = useMobileScreen();
  if (isMobile && !phoneLogin) {
    setPhoneLogin(true);
  }

  const eula = function () {
    navigate(Path.EULA);
  };

  const phoneLoginBlock = (
    <>
      <input
        type="text"
        className={styles["phone-input"]}
        value={phoneValue}
        onChange={handlePhoneChange}
        placeholder="手机号"
      />
      <div className={styles["code-wrapper"]}>
        <input
          type="text"
          className={styles["captcha-input"]}
          value={captchaValue}
          onChange={handleCaptchaChange}
          placeholder="图形验证码"
        />
        <img
          className={styles["captcha"]}
          src={accessStore.captcha_url}
          onClick={() => {
            accessStore.refreshCaptchaUrl();
          }}
          alt=""
        />
      </div>
      <div className={styles["verification-code-wrapper"]}>
        <input
          type="text"
          className={styles["verification-code-input"]}
          value={codeValue}
          onChange={handleCodeChange}
          placeholder="手机验证码"
        />
        <button
          className={styles["get-verification-code"]}
          disabled={codeDisable || codeLoading}
          onClick={() => accessStore.fetchMobileCode(phoneValue, captchaValue)}
        >
          {codeButtonText}
        </button>
      </div>
      <button
        className={
          styles["login-button"] +
          " w-full h-[46px] min-h-[46px] border-none mt-[10px] text-[18px] text-white rounded-[8px]"
        }
        disabled={submitDisable || submitLoading}
        onClick={() => accessStore.submitMobileLogin(phoneValue, codeValue)}
      >
        立即登录
      </button>
    </>
  );

  const wechatLoginBlock = (
    <>
      <div className="w-full flex items-center justify-center mt-[40px]">
        <div className="w-[240px] h-[240px] border-solid border-[1px] border-[#e5e5e5] flex items-center justify-center">
          <iframe
            src="/login/index?appId=1&onlyQr=1&redirect=https%3A%2F%2Fedu.cda.cn%2F%3Futm_source%3Dbbs_pinggu_org"
            width="240"
            height="240"
            className="rounded-[8px] bg-white border-none border-0"
          ></iframe>
        </div>
      </div>
    </>
  );

  const simpleLoginBlock = () => {
    const verifyPassword = function () {
      if (site.siteType === SiteType.EXAM) {
        if (username && username.length !== 18) {
          void message.error("用户名必须为18位");
          return;
        }
      }
      accessStore.passwordLogin(username, password);
    };

    let usernamePlaceHolder = "用户名";
    let passwordPlaceholder = "密码";

    if (site.siteType === SiteType.EXAM) {
      usernamePlaceHolder = "报名ID";
      passwordPlaceholder = "报名ID后六位";
    }

    return (
      <div
        css={css`
          margin-top: 30px;
          display: flow-root;
        `}
      >
        <input
          type="text"
          value={username}
          placeholder={usernamePlaceHolder}
          css={css`
            margin-top: 40px;
            min-width: 100%;
            max-width: 100%;
            min-height: 46px;
            border-radius: 8px;
            border: 1px solid #c6c6c6;
            text-align: left;
            font-size: 18px;
          `}
          onChange={handleUserNameChange}
        />
        <input
          type="password"
          value={password}
          placeholder={passwordPlaceholder}
          css={css`
            margin-top: 16px;
            min-width: 100%;
            max-width: 100%;
            min-height: 46px;
            border-radius: 8px;
            border: 1px solid #c6c6c6;
            text-align: left;
            font-size: 18px;
          `}
          onChange={handlePasswordChange}
        />
        <button
          className={
            styles["login-button"] +
            " w-full h-[46px] min-h-[46px] border-none mt-[10px] text-[18px] text-white rounded-[8px]"
          }
          disabled={submitDisable || submitLoading}
          onClick={verifyPassword}
        >
          立即登录
        </button>
      </div>
    );
  };

  let loginBlock = <></>;

  if (
    site.siteType === SiteType.DEV ||
    site.siteType === SiteType.NORMAL ||
    site.siteType === SiteType.UNDETERMINED ||
    site.siteType === SiteType.PAPER666
  ) {
    if (phoneLogin) {
      loginBlock = phoneLoginBlock;
    } else {
      loginBlock = wechatLoginBlock;
    }
  }

  if (site.siteType === SiteType.ISSUER) {
    loginBlock = phoneLoginBlock;
  }

  if (site.siteType === SiteType.TRAIN || site.siteType === SiteType.EXAM) {
    loginBlock = simpleLoginBlock();
  }

  let switchLoginBlock = <></>;

  const switchToWechatBlock = (
    <>
      <div className="text-[18px] text-[#252525] mt-[50px] w-full text-center">
        其他登录方式
      </div>

      <div
        className="w-full flex justify-center mt-[20px] cursor-pointer hover:opacity-75"
        onClick={toggleLoginType}
      >
        <NextImage
          src={phoneLogin ? WechatIcon.src : PhoneLoginIcon.src}
          alt="wechat"
          width={60}
          height={60}
        />
      </div>
    </>
  );

  const isMobileScreen = useMobileScreen();

  if (site.siteType === SiteType.DEV || site.siteType === SiteType.NORMAL) {
    if (!isMobileScreen) {
      switchLoginBlock = switchToWechatBlock;
    }
  }

  return (
    <>
      {messageContextHolder}
      <div className={styles["auth-page"]}>
        <div
          className={styles["center"]}
          css={[
            layout.type === LayoutType.Layout_v2 &&
              css`
                max-width: 500px !important;
              `,
          ]}
        >
          {layout.type === LayoutType.Layout_v1 && (
            <div className={styles["cover-wrapper"]}>
              <NextImage
                src={getImageWithSiteType(
                  site.siteType,
                  SiteType.EXAM,
                  ExamAuthCoverIcon,
                  AuthCoverIcon,
                )}
                alt="cover"
                width={500}
                height={600}
              />
            </div>
          )}
          <div
            className={styles["form-wrapper"]}
            css={
              layout.type === LayoutType.Layout_v2 &&
              css`
                border-radius: 20px;
              `
            }
          >
            <div className={styles["form_inner_wrapper"]}>
              <div className={styles["title"]}>登录</div>
              {loginBlock}
              {switchLoginBlock}
            </div>
            <div className="inline-block absolute bottom-[21px] left-[26px] text-[14px] text-[#999999] font-normal items-center">
              <NextImage
                className="mr-[5px]"
                src={AgreeIcon.src}
                alt="同意协议"
                width={14}
                height={14}
              />
              我已阅读并同意
              <div
                className="text-[#3873C2] cursor-pointer hover:opacity-80 inline-block"
                onClick={eula}
              >
                《服务协议》
              </div>
              和
              <div
                className="text-[#3873C2] cursor-pointer hover:opacity-80 inline-block"
                onClick={eula}
              >
                《隐私政策》
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
