"use client";

import { create } from "zustand";
import { Channel } from "@/app/enums/channel";
import { getChannel } from "@/app/utils/getChannel";
import { persist } from "zustand/middleware";

export type ChannelStore = {
  channel: Channel;
  updateChannel: (channel: string) => void;
};

export const useChannelStore = create<ChannelStore>()(
  persist(
    (setState) => {
      return {
        channel: getChannel(),
        updateChannel(channel: string) {
          if (channel.toLowerCase() === "lenovo") {
            setState({ channel: Channel.LENOVO });
          } else {
            console.log(
              "unknown channel " + channel + ", resetting to normal channel",
            );
            setState({ channel: Channel.NORMAL });
          }
        },
      };
    },
    { name: "caie_channel_store" },
  ),
);

if (typeof window !== "undefined") {
  globalThis.overrideChannel = (str: string) => {
    useChannelStore.getState().updateChannel(str);
  };
}
