"use client";
import styles from "./pricing.module.scss";

import { useNavigate } from "react-router-dom";
import { Path } from "../constant";
import NextImage from "next/image";
import HeadLogoIcon from "@/app/icons/pricing/head-logo.png";
import React, { useCallback, useRef, useState } from "react";
import { useAccessStore } from "@/app/store";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { LayoutType } from "@/app/enums/layoutType";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import type { Swiper as SwiperClass } from "swiper/types";

// register();

const NavigateBack = function (props: { backUrl?: string }) {
  const navigate = useNavigate();

  const url = props.backUrl;
  const back = useCallback(() => {
    if (url) {
      navigate(url);
    }
  }, [url, navigate]);

  return (
    <div
      css={css`
        display: flex;
        cursor: pointer;
        margin-top: auto;
        margin-bottom: auto;

        :hover path {
          color: #10618a;
        }

        :hover span {
          color: #10618a;
        }
      `}
      onClick={back}
    >
      <div
        css={css`
          width: 20px;
          height: 20px;
          margin-top: auto;
          margin-bottom: auto;
          color: #248eb3;
        `}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          style={{
            width: "100%",
            height: "100%",
            color: "#248eb3",
            filter: "initial",
          }}
        />
      </div>
      <span
        css={css`
          font-size: 18px;
          display: flex;
          color: #248eb3;
        `}
      >
        返回
      </span>
    </div>
  );
};

export const PricePageV2 = function () {
  return (
    <>
      <div className={styles["pricing-page"]}>
        <PricePageHeader />
        <div className={styles["content-wrapper"]}>
          <div className={"flex mx-auto w-full overflow-x-hidden"}>
            <PriceSwiperV2 />
          </div>
        </div>
      </div>
    </>
  );
};

const PricePageHeader = function () {
  const navigate = useNavigate();
  const layout = useLayoutConfigStore();

  const goHome = useCallback(() => navigate(Path.Home), [navigate]);

  return (
    <>
      {layout.type === LayoutType.Layout_v1 && (
        <div className="flex bg-white h-[80px] justify-center items-center">
          <div className="flex items-center ml-[18.75%] hover:opacity-75 cursor-pointer">
            <NextImage
              src={HeadLogoIcon}
              alt="头部logo"
              width={50}
              height={50}
              onClick={goHome}
            ></NextImage>
            <div className={styles["logo-title"]}>CAIE</div>
          </div>

          <div className="flex-grow"></div>
          {/*<NextImage*/}
          {/*  className="mr-[18.75%] hover:opacity-75 cursor-pointer"*/}
          {/*  src={HeadAvatarIcon}*/}
          {/*  alt="头像"*/}
          {/*  width={46}*/}
          {/*  height={46}*/}
          {/*></NextImage>*/}
        </div>
      )}
      {layout.type === LayoutType.Layout_v2 && (
        <div
          css={css`
            width: 100%;
            box-sizing: border-box;
            padding-left: 20px;
            max-width: 1110px;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1;
            max-height: 200px;
            height: calc((100% - 500px) / 2);
            display: flex;
          `}
        >
          <NavigateBack backUrl={Path.Chat}></NavigateBack>{" "}
        </div>
      )}
    </>
  );
};

const PriceSwiperV2 = function () {
  const [currentIndex, setCurrentIndex] = useState(2);
  const swiperContainer = useRef<SwiperRef>(null);

  const swiperIndexChange = useCallback(
    (swiper: SwiperClass) => {
      const index = swiper.realIndex;
      if (index !== currentIndex) {
        setCurrentIndex(index);
      }
    },
    [currentIndex],
  );

  // useWindowSizeWithCallback([
  //   (width) => {
  //     if (width >= 1600) {
  //       if (slidesPerView !== "auto") {
  //         setSlidesPerView("auto");
  //       }
  //     } else if (width >= 1150) {
  //       if (slidesPerView !== 5) {
  //         setSlidesPerView(5);
  //       }
  //     } else {
  //       if (slidesPerView !== "auto") {
  //         setSlidesPerView("auto");
  //       }
  //     }
  //   },
  // ]);

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        overflow-x: hidden;
      `}
    >
      <Swiper
        ref={swiperContainer}
        modules={[]}
        slideToClickedSlide={true}
        centeredSlides={true}
        initialSlide={2}
        mousewheel={true}
        className={
          "flex flex-grow min-w-0 flex-shrink mx-auto justify-center max-w-[1600px] w-full h-[600px] z-10"
        }
        slidesPerView={"auto"}
        breakpointsBase={"container"}
        simulateTouch={true}
        onSlideChange={swiperIndexChange}
      >
        <SwiperSlide
          data-index="0"
          className="my-auto min-w-[230px] max-w-[320px] h-[400px] flex flex-grow !flex-shrink"
        >
          <PriceItemV2
            title={"会员充值10元"}
            description={"获得1000算粒"}
            additionalDescription={"赠送50算粒"}
            price={"10"}
            isActivate={currentIndex === 0}
            navigationUrl={`${Path.Pay}?amount=10`}
          ></PriceItemV2>
        </SwiperSlide>
        <SwiperSlide
          data-index="1"
          className="my-auto min-w-[230px] max-w-[320px] h-[400px] flex flex-grow !flex-shrink"
        >
          <PriceItemV2
            title={"会员充值20元"}
            description={"获得2000算粒"}
            additionalDescription={"赠送300算粒"}
            price={"20"}
            isActivate={currentIndex === 1}
            navigationUrl={`${Path.Pay}?amount=20`}
          ></PriceItemV2>
        </SwiperSlide>
        <SwiperSlide
          data-index="2"
          className="my-auto min-w-[230px] max-w-[320px] h-[400px] flex flex-grow !flex-shrink"
        >
          <PriceItemV2
            title={"会员充值50元"}
            description={"获得5000算粒"}
            additionalDescription={"赠送700算粒"}
            price={"50"}
            isActivate={currentIndex === 2}
            navigationUrl={`${Path.Pay}?amount=50`}
          ></PriceItemV2>
        </SwiperSlide>
        <SwiperSlide
          data-index="3"
          className="my-auto min-w-[230px] max-w-[320px] h-[400px] flex flex-grow !flex-shrink"
        >
          <PriceItemV2
            title={"会员充值100元"}
            description={"获得10000算粒"}
            additionalDescription={"赠送2000算粒"}
            price={"100"}
            isActivate={currentIndex === 3}
            navigationUrl={`${Path.Pay}?amount=100`}
          ></PriceItemV2>
        </SwiperSlide>
        <SwiperSlide
          data-index="4"
          className="my-auto min-w-[230px] max-w-[320px] h-[400px] flex flex-grow !flex-shrink"
        >
          <PriceItemV2
            title={"会员充值200元"}
            description={"获得20000算粒"}
            additionalDescription={"赠送5000算粒"}
            price={"200"}
            isActivate={currentIndex === 4}
            navigationUrl={`${Path.Pay}?amount=200`}
          ></PriceItemV2>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

const PriceItemV2 = function (
  props: React.PropsWithChildren<{
    title?: string;
    price?: string;
    description?: string;
    additionalDescription?: string;
    navigationUrl?: string;
    isActivate?: boolean;
  }>,
) {
  const navigate = useNavigate();
  const navigationUrl = props.navigationUrl;

  const clickToNavigate = useCallback(() => {
    if (navigationUrl) {
      navigate(navigationUrl);
    }
  }, [navigate, navigationUrl]);

  return (
    <>
      <div
        css={css`
          display: flex;
          position: relative;
          margin: auto;
          width: 100%;
          height: 100%;
        `}
      >
        <div
          css={[
            css`
              width: 220px;
              height: 280px;
              background-color: white;
              border-radius: 12px;
              color: var(--gray-950);
              margin: auto;
              display: flex;
              flex-direction: column;
            `,
            !!props.isActivate &&
              css`
                border-color: #0c74ed;
                border-width: 1px;
                border-style: solid;
              `,
          ]}
        >
          <div
            css={css`
              width: 100%;
              text-align: center;
              font-size: 20px;
              font-weight: 800;
              margin-top: 40px;
            `}
          >
            {props.title}
          </div>
          <div
            css={css`
              margin-left: 20px;
              margin-right: 20px;
              margin-top: 30px;
              text-align: center;
              font-size: 18px;
              line-height: 1;
            `}
          >
            {props.description}
          </div>
          <div
            css={css`
              margin-left: auto;
              margin-right: auto;
              margin-top: 8px;
              font-size: 18px;
              line-height: 1;
            `}
          >
            {props.additionalDescription}
          </div>
          <div
            css={css`
              font-size: 30px;
              width: fit-content;
              margin-left: auto;
              margin-right: auto;
              display: flex;
              margin-top: auto;
            `}
          >
            <span
              css={css`
                font-size: 16px;
                display: block;
                margin-top: auto;
              `}
            >
              ¥
            </span>
            <span
              css={css`
                display: block;
                line-height: 1;
              `}
            >
              {props.price}
            </span>
          </div>
          <div
            css={css`
              height: 70px;
              flex-shrink: 0;
              display: flex;
              width: 100%;
              margin-top: 20px;
            `}
          >
            <div
              css={css`
                margin: 0 auto auto;
                width: 120px;
                height: 50px;
                text-align: center;
                font-size: 20px;
                line-height: 50px;
                background-image: linear-gradient(#1fd6cc, #0c74ed);
                border-radius: 8px;
                color: white;
                font-weight: bold;
                font-family: "Noto Sans SC Variable", serif;
                cursor: pointer;
              `}
              onClick={clickToNavigate}
            >
              立即充值
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
