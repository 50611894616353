export const getBuildConfig = () => {
  let COMMIT_ID: string = process.env.NEXT_PUBLIC_COMMIT_ID || "unknown";

  return {
    commitId: COMMIT_ID,
    buildMode: process.env.BUILD_MODE ?? "standalone",
    isApp: !!process.env.BUILD_APP,
  };
};

export type BuildConfig = ReturnType<typeof getBuildConfig>;
