import { ChatOptions, getHeaders, LLMUsage } from "@/app/client/api";
import { ChatSession, useChatStore } from "@/app/store";
import { UploadFileType } from "@/app/store/uploadFiles";
import { fetchTimeout } from "@/app/client/platforms/openai";
import { match } from "ts-pattern";
import { ChatEnums } from "@/app/enums/chat";
import { LLMApi } from "@/app/client/classes";

export class PicWishChatApi extends LLMApi {
  public constructor() {
    super();
  }
  async chat(options: ChatOptions): Promise<void> {
    const session = useChatStore
      .getState()
      .sessions.find((it) => it.conversationId === options.conversationId);
    if (!session) {
      options.onError?.(new Error("session not found"), { code: -1 });
      return;
    }
    const picWishOptions = session.picWishOptions!;

    match(picWishOptions.operation)
      .with(ChatEnums.PicWish.Operation.SEGMENTATION, () => {
        this.segmentation(options, session);
      })
      .with(ChatEnums.PicWish.Operation.SCALE, () => {
        this.scale(options, session);
      })
      .otherwise(() => {
        throw new Error("Unsupported operation");
      });
  }
  usage(): Promise<LLMUsage> {
    throw new Error("Method not implemented.");
  }

  async scale(options: ChatOptions, session: ChatSession) {
    const picWishOptions = session.picWishOptions!;
    let imageFile = session.pendingFileList
      ?.find((it) => it.fileType === UploadFileType.PICWISH_IMAGE_FILE)
      ?.filePath?.trim();
    let imageUrl = picWishOptions.image_url?.trim();
    if (imageUrl === "") {
      imageUrl = undefined;
    }
    if (imageFile === "") {
      imageFile = undefined;
    }

    const requestPayload = {
      conversation_id: session.conversationId!,
      image_path: imageFile,
      image_url: imageUrl,
      type: picWishOptions.type,
      scale_factor:
        picWishOptions.scale_factor ===
        ChatEnums.PicWish.Scale.ScaleFactor.SCALE_AUTO
          ? undefined
          : picWishOptions.scale_factor,
      fix_face_only: picWishOptions.fix_face_only,
      return_html_app_token: "1",
    };

    try {
      const resp = await fetchTimeout(
        fetch("/v1/asst/picwish/visual/scale", {
          method: "POST",
          headers: getHeaders(),
          body: JSON.stringify(requestPayload),
        }),
        60_000,
      );

      void this.finish(resp, options);
    } catch (e) {
      console.log("request failed with error", e);
      options.onError?.(e as Error, { code: -1 });
    }
  }

  async segmentation(options: ChatOptions, session: ChatSession) {
    const picWishOptions = session.picWishOptions!;
    let imageFile = session.pendingFileList
      ?.find((it) => it.fileType === UploadFileType.PICWISH_IMAGE_FILE)
      ?.filePath?.trim();
    let imageUrl = picWishOptions.image_url?.trim();
    if (imageUrl === "") {
      imageUrl = undefined;
    }
    if (imageFile === "") {
      imageFile = undefined;
    }

    const requestPayload = {
      conversation_id: session.conversationId!,
      image_path: imageFile,
      image_url: imageUrl,
      type:
        picWishOptions.type === ChatEnums.PicWish.Segmentation.Type.DEFAULT
          ? undefined
          : picWishOptions.type,
      output_type: picWishOptions.output_type,
      crop: picWishOptions.crop,
      format: picWishOptions.format,
      bg_color: picWishOptions.bg_color,
      return_html_app_token: "1",
    };

    try {
      const resp = await fetchTimeout(
        fetch("/v1/asst/picwish/visual/segmentation", {
          method: "POST",
          headers: getHeaders(),
          body: JSON.stringify(requestPayload),
        }),
        60_000,
      );

      void this.finish(resp, options);
    } catch (e) {
      console.log("request segmentation error, ", e);
      options.onError?.(e as Error, { code: -1 });
    }
  }
}
