import { ImageLoaderPropsWithConfig } from "next/dist/shared/lib/image-config";

function loader({ src, width, quality, config }: ImageLoaderPropsWithConfig) {
  let baseUrl = `${config.path}?url=${encodeURIComponent(src)}&w=${width}&q=${quality ?? 75}&optimize=1`;

  if (typeof window !== "undefined") {
    if (window.__POWERED_BY_WUJIE__) {
      if (window.__WUJIE_PUBLIC_PATH__.endsWith("/")) {
        let path = window.__WUJIE_PUBLIC_PATH__.slice(0, -1);
        return path + baseUrl;
      }
      return window.__WUJIE_PUBLIC_PATH__ + baseUrl;
    }
  }
  return baseUrl;
}

// internal hack
loader.__next_img_default = true;

export default loader;
