"use client";
import * as React from "react";

import styles from "./mask-new.module.scss";
import { Button, Tooltip } from "antd";
import { Path } from "@/app/constant";
import { useChatStore } from "@/app/store";
import { useNavigate } from "react-router-dom";
import { BUILTIN_MASK_STORE } from "../masks";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";

const masks = [
  {
    name: "办公效率",
    children: [
      {
        name: "#帮我写日报",
        id: 20000,
      },
      {
        name: "#帮我写周报",
        id: 20001,
      },
      {
        name: "#帮我写邮件",
        id: 20002,
      },
      {
        name: "#高影响力销售脚本",
        id: 20003,
      },
      {
        name: "#Excel表格制作",
        id: 20004,
      },
      {
        name: "#内容提炼总结",
        id: 20005,
      },
    ],
  },
  {
    name: "营销创意",
    children: [
      {
        name: "#小红书种草文案",
        id: 20006,
      },
      {
        name: "#短视频脚本策划",
        id: 20007,
      },
      {
        name: "#小红书写手",
        id: 20033,
      },
    ],
  },
  {
    name: "写作辅助",
    children: [
      {
        name: "#文章修改润色",
        id: 20008,
      },
      {
        name: "#论文写作助手",
        id: 20009,
      },
      {
        name: "#标题生成器",
        id: 20010,
      },
      {
        name: "#诗词创作",
        id: 20011,
      },
      {
        name: "#读书总结",
        id: 20012,
      },
    ],
  },
  {
    name: "数据分析",
    children: [
      {
        name: "#数据分析师",
        id: 20013,
      },
      {
        name: "#SQL取数",
        id: 20014,
      },
      {
        name: "#机器学习",
        id: 20028,
      },
    ],
  },
  {
    name: "职业专家",
    children: [
      {
        name: "#睡眠咨询师",
        id: 20015,
      },
      {
        name: "#心理健康顾问",
        id: 20016,
      },
      {
        name: "#健康营养咨询师",
        id: 20017,
      },
      {
        name: "#健身教练",
        id: 20018,
      },
      {
        name: "#后勤工作",
        id: 20029,
      },
      {
        name: "#职业顾问",
        id: 20030,
      },
      {
        name: "#英专写手",
        id: 20031,
      },
      {
        name: "#文案专员",
        id: 20027,
      },
      {
        name: "#心理医生",
        id: 20035,
      },
      {
        name: "#互联网写手",
        id: 20037,
      },
      {
        name: "#心灵导师",
        id: 20038,
      },
    ],
  },
  {
    name: "英语学习",
    children: [
      {
        name: "#句子润色",
        id: 20019,
      },
      {
        name: "#翻译批改",
        id: 20020,
      },
      {
        name: "#词组学习",
        id: 20021,
      },
      {
        name: "#同义词辨析",
        id: 20022,
      },
      {
        name: "#句子分析",
        id: 20023,
      },
      {
        name: "#兴趣阅读",
        id: 20024,
      },
      {
        name: "#编故事记单词",
        id: 20025,
      },
    ],
  },
  {
    name: "实用工具",
    children: [
      {
        name: "#以文搜图",
        id: 20026,
      },
      {
        name: "#语言检测器",
        id: 20032,
      },
      {
        name: "#简历写手",
        id: 20034,
      },
      {
        name: "#创业点子王",
        id: 20036,
      },
      {
        name: "#越狱模式",
        id: 20039,
      },
    ],
  },
];

export function MaskNew() {
  const chatStore = useChatStore();
  const navigate = useNavigate();
  const layout = useLayoutConfigStore();
  const startChat = (id: number | undefined) => {
    if (!id) {
      return;
    }
    const mask = BUILTIN_MASK_STORE.get(id);
    console.log("点击的mask", mask);
    if (!mask) {
      return;
    }
    chatStore.newSession(mask);
    setTimeout(() => {
      navigate(
        Path.Chat + "/" + (useChatStore.getState().currentConversationId ?? ""),
      );
    }, 1);
  };
  return (
    <div className={styles["mask-new-container"]}>
      {masks.map((v) => (
        <Tooltip
          overlayInnerStyle={{ padding: 0 }}
          title={
            <div className={styles["mask-new-container"]}>
              {v.children.map((child) => (
                <Button
                  type="text"
                  className={styles["button"]}
                  key={child.name}
                  onClick={() => startChat(child.id)}
                >
                  {child.name}
                </Button>
              ))}
            </div>
          }
          arrow={false}
          color={"#ffffff"}
          placement={"right"}
          key={v.name}
        >
          <Button type="text" className={styles["button"]}>
            {v.name}
          </Button>
        </Tooltip>
      ))}
    </div>
  );
}
