"use client";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  Canvas,
  FabricImage,
  FabricObject,
  PencilBrush,
  Point,
  TPointerEventInfo,
} from "fabric";
import { EraserBrush, ErasingEvent } from "@erase2d/fabric";
import { InputNumber } from "antd";
import { css } from "@emotion/react";
import { EventTypes, FullGestureState, Gesture } from "@use-gesture/vanilla";
import DrawSVG from "@/app/icons/draw/draw.svg";
import MoveSVG from "@/app/icons/draw/move.svg";
import { showToast } from "@/app/components/ui-lib";
import EraseSVG from "@/app/icons/draw/eraser.svg";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { getWidthByWindow } from "@/app/utils";
import * as fabric from "fabric";
import { FabricCustomGroup } from "@/app/utils/FabricCustom";

export const EraseImageCanvas = function (
  props: React.PropsWithChildren<{
    onEditComplete?: (file: File) => void | Promise<void>;
    onEditCancel?: () => void | Promise<void>;
    url?: string;
    size?: { w: number; h: number };
    type: "erase" | "draw" | "mark";
  }>,
) {
  const fabricCanvasRef = useRef<Canvas | null>(null);
  const [key, resetUpdater] = useReducer((v) => v + 1, 0);

  const onLoad = useMemo(() => {
    return (canvas: Canvas) => {
      console.log("onload called");
      fabricCanvasRef.current = canvas;
      resetUpdater();
    };
  }, []);

  const canvasUpdater = useCallback(function () {
    if (fabricCanvasRef.current) {
      void fabricCanvasRef.current.dispose();
    }
    fabricCanvasRef.current = null;
    resetUpdater();
  }, []);

  return (
    <div
      css={css`
        width: 100%;
        display: flow-root;
      `}
    >
      <FCCanvas onLoad={onLoad} size={props.size} ref={fabricCanvasRef} />
      <CanvasOptionsArea
        canvas={fabricCanvasRef.current}
        {...props}
        canvasForceUpdate={canvasUpdater}
        key={key}
      />
    </div>
  );
};

const FCCanvas = forwardRef<
  Canvas,
  { onLoad?: (canvas: Canvas) => void; size?: { w: number; h: number } }
>(function FCCanvas(props, ref) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  let size = { w: getWidthByWindow(500, 64), h: getWidthByWindow(500, 64) };
  if (props.size) {
    size = props.size;
  }

  const onLoad = props.onLoad;

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const canvas = new Canvas(canvasRef.current, {
      width: size.w,
      height: size.h,
    });
    canvas.enableRetinaScaling = true;

    if (typeof ref === "function") {
      ref(canvas);
    } else if (typeof ref === "object" && ref) {
      ref.current = canvas;
    }

    onLoad?.(canvas);

    return () => {
      if (typeof ref === "function") {
        ref(null);
      } else if (typeof ref === "object" && ref) {
        ref.current = null;
      }

      void canvas.dispose();
    };
  }, [onLoad, ref, size.h, size.w]);

  return (
    <div
      css={css`
        width: fit-content;
        height: fit-content;
        background-repeat: repeat;
      `}
      style={{
        backgroundImage:
          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAASFBMVEUAAAD////h4eH+/v79/f37+/v6+vr5+fnm5ubk5OTg4ODf39/39/fp6eno6Ojn5+fl5eXj4+Pi4uLe3t7d3d3c3Nzb29v///9R1MU4AAAAGHRSTlP//////////////////////////////wDNEy7qAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAaElEQVQYlZ3OQQrCMBAF0DdptAGhev8zulBcaBQbF2kV3AjOYuA/PsxEAZFrC7SUB7C7PICUr6C0nkXyNb8hL1dKXSE6rFmMYB6HusV9E7E0p9SQjm94lhtK++OPD0Rfucsch9Me5+kFl+UUWcTFFFsAAAAASUVORK5CYII=")',
      }}
    >
      <canvas height={size.h} width={size.w} ref={canvasRef} />
    </div>
  );
});

const CanvasOptionsArea = function (
  props: React.PropsWithChildren<{
    canvas: Canvas | null;
    type: "erase" | "draw" | "mark";
    onEditComplete?: (file: File) => void;
    onEditCancel?: () => void;
    url?: string;
    size?: { w: number; h: number };
    canvasForceUpdate?: () => void;
    _update?: number;
  }>,
) {
  const eraseBrushRef = useRef<EraserBrush | null>(null);
  const drawBrushRef = useRef<PencilBrush | null>(null);
  const [brushWidth, setBrushWidth] = useState(20);

  const canvasImageRef = useRef<FabricImage | null>(null);

  const gestureRef = useRef<Gesture | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isDrawing, setIsDrawing] = useState(true);
  const [altKeyDown, setAltKeyDown] = useState(false);
  const [isDrawingInternal, setIsDrawingInternal] = useState(true);
  const [isDraggingInternal, setIsDraggingInternal] = useState(false);
  const isMouseDown = useRef(false);

  const [invert, setInvert] = useState(false);

  const isDrawingDefer = isDrawing && isDrawingInternal;
  const isDraggingDefer = isDragging || isDraggingInternal;

  const fileInput = useCallback(async () => {
    if (!props.url) {
      return;
    }

    if (props.type === "mark") {
      return;
    }

    if (canvasImageRef.current !== null) {
      return;
    }

    const resp = await fetch(props.url).then((resp) => resp.clone());
    const url = URL.createObjectURL(await resp.blob());

    const image = new Image();
    image.src = url;
    image.onload = () => {
      console.log(image.naturalWidth, image.naturalHeight);
      const canvasImage = new FabricImage(image, {
        erasable: props.type === "erase",
        width: image.naturalWidth,
        height: image.naturalHeight,
        imageSmoothing: true,
        centeredScaling: true,
        hasControls: false,
      });
      canvasImage.setControlsVisibility({
        tl: false,
        bl: false,
        tr: false,
        br: false,
        ml: false,
        mb: false,
        mr: false,
        mt: false,
      });
      canvasImageRef.current = canvasImage;
      props.canvas!.add(canvasImage);
      if (image.naturalWidth > image.naturalHeight) {
        props.canvas!.setZoom(
          props.canvas!.getZoom() *
            (getWidthByWindow(500, 64) / image.naturalWidth),
        );
      } else {
        props.canvas!.setZoom(
          props.canvas!.getZoom() *
            (getWidthByWindow(500, 64) / image.naturalHeight),
        );
      }
    };
  }, [props]);

  const undoErasing = function () {
    if (props.type === "erase") {
      if (eraseBrushRef.current) {
        const i = !invert;
        eraseBrushRef.current.inverted = i;
        setInvert(i);
      }
    } else if (props.type === "draw" || props.type === "mark") {
      if (!eraseBrushRef.current) {
        const brush = new EraserBrush(props.canvas!);
        brush.width = brushWidth;
        brush.on("end", eraserEnd);
        eraseBrushRef.current = brush;
      }
      const i = !invert;
      if (i) {
        props.canvas!.freeDrawingBrush = eraseBrushRef.current;
        FabricCustomGroup.caieType = "erase";
      } else {
        props.canvas!.freeDrawingBrush = drawBrushRef.current!;
        FabricCustomGroup.caieType = "draw";
      }
      setInvert(i);
    }
  };

  const saveImage = async function () {
    if (props.canvas === null) {
      return;
    }

    if (props.type === "erase" || props.type === "draw") {
      if (canvasImageRef.current === null) {
        return;
      }
      const data = canvasImageRef.current.toDataURL();
      const resp = await fetch(data).then((resp) => resp.clone());
      const file = new File([await resp.blob()], "mask.png", {
        type: resp.headers.get("content-type")!,
      });
      if (props.onEditComplete) {
        props.onEditComplete(file);
      }
      return;
    }
    if (props.type === "mark") {
      const data = props.canvas.toDataURL();
      const resp = await fetch(data).then((resp) => resp.clone());
      const file = new File([await resp.blob()], "mask.png", {
        type: resp.headers.get("content-type")!,
      });
      if (props.onEditComplete) {
        props.onEditComplete(file);
      }
    }
  };

  const enableEditing = function () {
    if (altKeyDown) {
      showToast("请放开Alt键后切换");
      return;
    }
    if (isMouseDown.current) {
      showToast("请暂停修改图片后切换");
      return;
    }
    const drawMode = props.canvas!.isDrawingMode;
    props.canvas!.isDrawingMode = !drawMode;
    if (props.canvas!.isDrawingMode) {
      setIsDrawing(true);
      setIsDrawingInternal(true);
      setIsDragging(false);
      setIsDraggingInternal(false);
    } else {
      setIsDrawing(false);
      setIsDrawingInternal(false);
      setIsDragging(true);
      setIsDraggingInternal(true);
    }
  };

  const zooming = useCallback(
    function (e: TPointerEventInfo<WheelEvent>) {
      const delta = e.e.deltaY;
      let zoom = props.canvas!.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) {
        zoom = 20;
      }
      if (zoom < 0.01) {
        zoom = 0.01;
      }
      props.canvas!.zoomToPoint(new Point(e.e.offsetX, e.e.offsetY), zoom);
      e.e.preventDefault();
      e.e.stopPropagation();
    },
    [props.canvas],
  );

  const startDragging = useCallback(
    function (e: TPointerEventInfo<PointerEvent>) {
      if (e.e.type === "touchstart") {
        return;
      }
      isMouseDown.current = true;
      if (altKeyDown) {
        setIsDraggingInternal(true);
        if (isDrawing) {
          props.canvas!.isDrawingMode = false;
          setIsDrawingInternal(false);
        }
      }
    },
    [props.canvas, altKeyDown, isDrawing],
  );

  const endDragging = useCallback(
    function (
      e: TPointerEventInfo<PointerEvent> & {
        isClick: boolean;
        currentTarget?: FabricObject;
        currentSubTargets: FabricObject[];
      },
    ) {
      if (e.e.type === "touchend") {
        return;
      }
      isMouseDown.current = false;
      if (isDraggingInternal) {
        // fabricCanvasRef.current!.setViewportTransform(fabricCanvasRef.current!.viewportTransform);

        if (isDrawing) {
          if (!altKeyDown) {
            props.canvas!.isDrawingMode = true;
            setIsDrawingInternal(true);
            setIsDraggingInternal(false);
          }
        }
      }
    },
    [props.canvas, isDrawing, altKeyDown, isDraggingInternal],
  );

  const onAltKeyDown = useCallback(
    function (e: KeyboardEvent) {
      if (e.altKey) {
        if (props.canvas) {
          if (isDrawing && !isMouseDown.current) {
            props.canvas.isDrawingMode = false;
            setIsDrawingInternal(false);
            setIsDraggingInternal(true);
          }
        }
        setAltKeyDown(true);
      }
    },
    [props.canvas, isDrawing],
  );

  const onAltKeyUp = useCallback(
    function (e: KeyboardEvent) {
      if (e.code === "AltLeft" || e.code === "AltRight") {
        if (props.canvas) {
          if (isDrawing && !isMouseDown.current) {
            props.canvas.isDrawingMode = true;
            setIsDrawingInternal(true);
            setIsDraggingInternal(false);
          }
        }
        setAltKeyDown(false);
      }
    },
    [props.canvas, isDrawing],
  );

  const eraserEnd = useCallback(
    function (e: ErasingEvent<"end">) {
      e.preventDefault();

      eraseBrushRef.current!.commit(e.detail).then(() => {
        props.canvas!.clearContext(props.canvas!.getTopContext());
        props.canvas!.requestRenderAll();
      });
    },
    [props.canvas],
  );

  const pinchGestureHandler = useCallback(
    function (
      state: Omit<FullGestureState<"pinch">, "event"> & {
        event: EventTypes["pinch"];
      },
    ) {
      if (isDrawingDefer) {
        return;
      }
      const [offsetX, offsetY] = state.offset;
      props.canvas!.zoomToPoint(new Point(offsetX, offsetY), state.offset[0]);
      state.event.preventDefault();
      state.event.stopPropagation();
    },
    [props.canvas, isDrawingDefer],
  );

  const onLoad = useCallback(
    (canvas: Canvas) => {
      const eraseBrush = new EraserBrush(canvas);
      eraseBrush.width = brushWidth;
      eraseBrush.on("end", eraserEnd);
      eraseBrushRef.current = eraseBrush;

      const pencilBrush = new PencilBrush(canvas);
      pencilBrush.width = brushWidth;
      drawBrushRef.current = pencilBrush;

      if (props.type === "erase") {
        eraseBrush.inverted = invert;
        canvas.freeDrawingBrush = eraseBrush;
      } else if (props.type === "draw" || props.type === "mark") {
        if (!invert) {
          canvas.freeDrawingBrush = drawBrushRef.current;
        } else {
          canvas.freeDrawingBrush = eraseBrushRef.current;
        }
      }
      canvas.isDrawingMode = true;
      canvas.on("before:path:created", (path) => {
        if (props.type === "draw" || props.type === "mark") {
          path.path.erasable = true;
          path.path.selectable = false;
          path.path.hasControls = false;
          path.path.on("selected", (e) => {
            e.e?.preventDefault();
            e.e?.stopPropagation();
          });
          if (props.type === "draw") {
            if (canvasImageRef.current) {
              if (!canvasImageRef.current.clipPath) {
                canvasImageRef.current.clipPath = new FabricCustomGroup([], {
                  erasable: "deep",
                });
                FabricCustomGroup.caieType = "draw";
              }
              fabric.util.sendObjectToPlane(
                path.path,
                undefined,
                canvasImageRef.current.calcTransformMatrix(),
              );
              (canvasImageRef.current.clipPath as FabricCustomGroup).add(
                path.path,
              );
              canvasImageRef.current.clipPath.set("dirty", true);
              canvasImageRef.current.set("dirty", true);
              props.canvas?.requestRenderAll();
            }
          }
        }
      });

      canvas.on("path:created", (path) => {
        if (props.type === "draw") {
          if (
            canvas.freeDrawingBrush &&
            !(canvas.freeDrawingBrush instanceof EraserBrush)
          ) {
            canvas.remove(path.path);
          }
        }
      });

      void fileInput();
    },
    [props.canvas, props.type, brushWidth, fileInput, eraserEnd, invert],
  );

  useEffect(() => {
    console.log("init");
    if (props.canvas) {
      console.log("run init");
      onLoad(props.canvas);
    }
    return () => {
      eraseBrushRef.current?.dispose();
      eraseBrushRef.current = null;
      drawBrushRef.current = null;
    };
  }, [props.canvas, onLoad]);

  const buttonsStyle = css`
    width: 32px;
    height: 32px;
    color: #999;
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;

    & svg {
      fill: #999;
      stroke: #999;
      filter: initial !important;
    }
  `;

  const selectedButtonsStyle = css`
    background-color: var(--border-in-light);

    & svg {
      fill: var(--primary);
      stroke: var(--primary);
    }
  `;

  const editButton = () => {
    const style = [buttonsStyle];
    if (isDrawingDefer) {
      style.push(selectedButtonsStyle);
    }

    return (
      <div
        css={style}
        onClick={() => {
          if (isDragging) {
            enableEditing();
          }
        }}
      >
        <DrawSVG width={32} height={32} />
      </div>
    );
  };

  const moveButton = () => {
    if (props.type === "mark") {
      return <></>;
    }
    const style = [buttonsStyle];
    if (isDraggingDefer) {
      style.push(selectedButtonsStyle);
    }

    return (
      <div
        css={style}
        onClick={() => {
          if (isDrawing) {
            enableEditing();
          }
        }}
      >
        <MoveSVG width={32} height={32} />
      </div>
    );
  };

  const eraseButton = () => {
    if (!isDrawing) {
      return <></>;
    }

    const style = [buttonsStyle];
    if (invert) {
      style.push(selectedButtonsStyle);
    }

    return (
      <div css={style} onClick={undoErasing} title={"橡皮擦"}>
        <EraseSVG width={32} height={32} />
      </div>
    );
  };

  let bottomBlock = <></>;
  if (isDrawing) {
    bottomBlock = (
      <div
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        <span
          css={css`
            margin-right: 8px;
            height: 30px;
            line-height: 30px;
          `}
        >
          画笔粗细
        </span>
        <InputNumber
          value={brushWidth}
          onChange={(e) => {
            if (eraseBrushRef.current) {
              eraseBrushRef.current.width = e || 0;
            }
            if (drawBrushRef.current) {
              drawBrushRef.current.width = e || 0;
            }
            setBrushWidth(e || 0);
          }}
          min={0}
          max={60}
          step={1}
        />
      </div>
    );
  }

  useEffect(() => {
    document.addEventListener("keydown", onAltKeyDown);
    document.addEventListener("keyup", onAltKeyUp);

    return () => {
      document.removeEventListener("keydown", onAltKeyDown);
      document.removeEventListener("keyup", onAltKeyUp);
    };
  }, [onAltKeyUp, onAltKeyDown]);

  useEffect(() => {
    if (!props.canvas) {
      return;
    }
    props.canvas.on("mouse:wheel", zooming);
    props.canvas.on("mouse:down", startDragging);
    props.canvas.on("mouse:up", endDragging);
    return () => {
      if (!props.canvas) {
        return;
      }
      props.canvas.off("mouse:wheel", zooming);
      props.canvas.off("mouse:down", startDragging);
      props.canvas.off("mouse:up", endDragging);
    };
  }, [props.canvas, zooming, startDragging, endDragging]);

  useEffect(() => {
    if (!props.canvas) {
      return;
    }
    gestureRef.current = new Gesture(
      props.canvas.upperCanvasEl,
      { onPinch: pinchGestureHandler },
      { pinch: { preventDefault: false } },
    );

    return () => {
      gestureRef.current?.destroy();
    };
  }, [props.canvas, pinchGestureHandler]);

  return (
    <>
      <div
        css={css`
          display: flex;
          margin-top: 8px;
          justify-content: center;
        `}
      >
        {editButton()}
        {moveButton()}
        {eraseButton()}
        <div
          css={css`
            content: " ";
            width: 1px;
            height: 16px;
            margin-top: auto;
            margin-bottom: auto;
            background-color: #ccc;
          `}
        />
        <div
          css={buttonsStyle}
          onClick={() => {
            if (props.onEditCancel) {
              props.onEditCancel();
            }
          }}
        >
          <CloseOutlined style={{ fontSize: "32px" }} />
        </div>
        <div
          css={buttonsStyle}
          onClick={() => {
            void saveImage();
          }}
        >
          <CheckOutlined style={{ fontSize: "32px" }} />
        </div>
      </div>
      <div
        css={css`
          width: 100%;
          margin-top: 8px;
        `}
      >
        {bottomBlock}
      </div>
      <div
        css={css`
          width: 100%;
          display: flow-root;
        `}
      >
        {props.children}
      </div>
    </>
  );
};
