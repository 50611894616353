"use client";

import { match, P } from "ts-pattern";

// 内部 usage_type 转为 /v1/asst/chat/completions 接口 usage_type
export const usageTypeTransform = function (
  internalUsageType: number | string,
): number {
  let usageType = internalUsageType;
  if (typeof usageType === "string") {
    usageType = parseInt(usageType);
  }
  console.log("usageType", usageType);
  return match(usageType)
    .with(
      P.when((it) => {
        // chatGPT 3.5, chatGPT 4.0, DALL-E, 助手, 文心一言，通义千问，stable diffusion, ppt, 通义万相, 佐糖, 在线搜索（编号废弃）, 思维导图
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].includes(it);
      }),
      () => {
        return usageType;
      },
    )
    .with(12, () => {
      // gemini
      return 8;
    })
    .with(13, () => {
      // claude
      return 9;
    })
    .with(14, () => {
      // o1-preview
      return 10;
    })
    .with(15, () => {
      // o1-mini
      return 11;
    })
    .otherwise((it) => {
      return it;
    });
};
