"use client";

import { QRCodeSVG } from "qrcode.react";
import { css } from "@emotion/react";
import PhoneIcon from "@/app/icons/phone.svg";
import { Tooltip } from "@mui/material";
import { useCallback } from "react";
import { openLinkInNewTab } from "@/app/utils/openLinkInNewTab";

export const AppQrcode = function () {
  return (
    <div
      css={css`
        border-radius: 8px;
        padding: 6px;
        display: flex;
        flex-direction: column;
        background-color: var(--main-surface-primary);
        box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.06);
      `}
      className={"no-dark"}
    >
      <QRCodeSVG
        value={"https://a.app.qq.com/o/simple.jsp?pkgname=com.paper666.uniapp"}
        size={88}
      ></QRCodeSVG>
      <span
        css={css`
          text-align: center;
          color: var(--text-primary);
          font-size: 12px;
        `}
      >
        扫码下载APP
      </span>
    </div>
  );
};

export const AppDownloadButton = function () {
  const goToAppPage = useCallback(() => {
    openLinkInNewTab(
      "https://a.app.qq.com/o/simple.jsp?pkgname=com.paper666.uniapp",
    );
  }, []);

  return (
    <Tooltip
      title={<AppQrcode />}
      slots={{
        tooltip: "div",
      }}
    >
      <div
        css={css`
          display: flex;
          margin-top: auto;
          margin-bottom: auto;
          cursor: pointer;
        `}
        onClick={goToAppPage}
      >
        <PhoneIcon
          css={css`
            width: 20px;
            height: 20px;
            display: block;
            margin-top: auto;
            margin-bottom: auto;
          `}
          width={20}
          height={20}
        ></PhoneIcon>
        <span
          css={css`
            font-size: 14px;
            color: var(--text-primary);
            line-height: 14px;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 10px;
          `}
        >
          APP下载
        </span>
      </div>
    </Tooltip>
  );
};
