"use client";
import { create } from "zustand";
import { useChatStore } from "@/app/store/chat";
import structuredClone from "@ungap/structured-clone";
import axios from "axios";
import { useAccessStore } from "@/app/store/access";
import { createWithEqualityFn } from "zustand/traditional";
import isEqual from "react-fast-compare";
import { produce } from "immer";
import { jsonResponseProcess } from "@/app/utils/responseProcess";

enum UploadStatus {
  NOT_UPLOAD,
  UPLOADING,
  UPLOAD_FINISHED,
  UPLOAD_ERROR,
}

export enum UploadFileType {
  ASSISTANT = 0,
  DALL_E_ORIGINAL = 1,
  DALL_E_MASK = 2,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  STABLE_DIFFUSION = 1,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  WANX_TEXT_BASE = 1,
  WANX_TEXT_MASK = 1.1,
  WANX_TTF_FILE = 3,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PICWISH_IMAGE_FILE = 1,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MINDMAP_TTF_TILE = 3,
}

export type UploadFilesStore = {
  fileMap: Map<
    string,
    {
      file?: File;
      uploadStatus: UploadStatus;
      uploadProgress?: number;
      uploadFileType: UploadFileType;
    }
  >;
  getPendingFileList: (id?: number) => {
    uploadId?: number;
    uuid: string;
    fileName: string;
    file?: File;
    fileType?: UploadFileType;
    imageUrl?: string;
    filePath?: string;
  }[];
  updatePendingFileList: (
    list: {
      uploadId?: number;
      uuid: string;
      fileName: string;
      file?: File;
      fileType?: UploadFileType;
      imageUrl?: string;
      filePath?: string;
    }[],
    id?: number,
  ) => void;
  uploadFileWithProgress: (props: {
    uuid: string;
    conversationId: string;
    fileType?: UploadFileType;
    onUploadProgress?: (process: number) => void;
    onError?: (error: Error) => void;
    onSuccess?: (
      fileName: string,
      fileId: number,
      imageUrl?: string,
      filePath?: string,
    ) => void;
  }) => void;
  deleteChatFileWithUUIDs: (list: string[]) => void;
};

export const useUploadFileStore = createWithEqualityFn<UploadFilesStore>()(
  (set, get) => ({
    fileMap: new Map(),
    getPendingFileList(id) {
      let currentSessionId = id;
      if (currentSessionId === undefined) {
        currentSessionId = useChatStore.getState().currentSession()?.id;
      }
      const session = useChatStore
        .getState()
        .sessions.find((it) => it.id === currentSessionId);
      if (!session) {
        return [];
      }

      const pendingList = session.pendingFileList;
      if (!pendingList) {
        return [];
      }

      const map = get().fileMap;

      const result = pendingList.map((it) => {
        return {
          ...it,
          file: map.get(it.uuid)?.file,
        };
      });
      // console.log("get result", result);
      return result;
    },
    updatePendingFileList(list, id) {
      let currentSessionIndex = id;
      if (currentSessionIndex === undefined) {
        currentSessionIndex = useChatStore.getState().currentSession()?.id;
      }
      if (!list) {
        return;
      }
      console.log("currentSessionIndex", currentSessionIndex);

      const session = useChatStore
        .getState()
        .sessions.find((it) => it.id === currentSessionIndex);
      if (!session) {
        console.log("cannot find session");
        return;
      }

      const storedList = session.pendingFileList ?? [];
      let removedUUIDs = storedList.map((it) => it.uuid);
      const fullStoredUUIDs = structuredClone(removedUUIDs);
      const addedFileMap: Map<
        string,
        { file: File; fileType: UploadFileType }
      > = new Map();

      const result = list.map((it) => {
        removedUUIDs = removedUUIDs.filter((item) => item !== it.uuid);
        if (!fullStoredUUIDs.includes(it.uuid) && it.file !== undefined) {
          addedFileMap.set(it.uuid, {
            file: it.file,
            fileType: it.fileType ?? UploadFileType.ASSISTANT,
          });
        }
        return {
          uploadId: it.uploadId,
          uuid: it.uuid,
          fileName: it.fileName,
          fileType: it.fileType ?? UploadFileType.ASSISTANT,
          imageUrl: it.imageUrl,
          filePath: it.filePath,
        };
      });

      const tmp = get().fileMap;
      const neoTmp = produce(tmp, (tmp) => {
        console.log("removedUUIDs", removedUUIDs);
        removedUUIDs.forEach((it) => {
          tmp.delete(it);
        });

        const keys = addedFileMap.keys();
        console.log("addedFileMap", addedFileMap);
        [...keys].forEach((it) => {
          const itm = addedFileMap.get(it)!;
          tmp.set(it, {
            file: itm.file,
            uploadStatus: UploadStatus.NOT_UPLOAD,
            uploadProgress: 0,
            uploadFileType: itm.fileType,
          });
        });
      });

      addedFileMap.clear();

      console.log("set result", result);

      useChatStore.setState((state) => {
        const sessions = state.sessions;
        const neoSessions = produce(sessions, (sessions) => {
          sessions.map((it) => {
            if (it.id === currentSessionIndex) {
              it.pendingFileList = result;
            }
            return it;
          });
        });
        return { sessions: neoSessions };
      });

      console.log("set map", neoTmp);

      set({ fileMap: neoTmp });
    },
    uploadFileWithProgress(props) {
      const fileMap = get().fileMap;
      const file = fileMap.get(props.uuid);
      let fileType = props.fileType;
      if (!file || !file.file) {
        console.log("upload file empty, uuid: " + props.uuid);
        return;
      }
      if (!fileType) {
        if (file.uploadFileType) {
          fileType = file.uploadFileType;
        } else {
          fileType = UploadFileType.ASSISTANT;
        }
      }

      if (file.uploadStatus === UploadStatus.UPLOADING) {
        if (props.onUploadProgress) {
          props.onUploadProgress(file.uploadProgress!);
        }
        return;
      }

      if (file.uploadStatus === UploadStatus.UPLOAD_FINISHED) {
        if (props.onSuccess) {
          const session = useChatStore
            .getState()
            .sessions.find((it) => it.conversationId === props.conversationId);
          if (!session) {
            console.log(
              "wrong conversation id found, id: " + props.conversationId,
            );
            return;
          }
          const fileInfo = session.pendingFileList!.find(
            (it) => it.uuid === props.uuid,
          );
          if (!fileInfo) {
            console.log(
              "wrong file id found, maybe pendingFileList has cleared. file uuid: " +
                props.uuid,
            );
            return;
          }
          props.onSuccess(fileInfo.fileName, fileInfo.uploadId!);
        }

        return;
      }

      const form = new FormData();
      form.set("file", file.file);
      form.set("conversation_id", props.conversationId);
      form.set("source_type", Math.floor(fileType).toString());
      const neoFile = produce(file, (file) => {
        file.uploadStatus = UploadStatus.UPLOADING;
      });
      const neoFileMap = produce(fileMap, (fmp) => {
        fmp.set(props.uuid, neoFile);
      });

      set({
        fileMap: neoFileMap,
      });

      axios
        .post("/v1/asst/conversation/file/upload", form, {
          headers: {
            Authorization: `Bearer ${useAccessStore.getState().token}`,
          },
          onUploadProgress(progress) {
            if (props.onUploadProgress) {
              props.onUploadProgress(progress.progress!);
              const neoFile = produce(file!, (file) => {
                file.uploadProgress = progress.progress!;
              });
              const neoFileMap = produce(fileMap, (fmp) => {
                fmp.set(props.uuid, neoFile);
              });
              set({ fileMap: neoFileMap });
            }
          },
        })
        .then((data) => {
          console.log(data);
          if (data.data && data.data.code === 0) {
            const neoFile = produce(file!, (file) => {
              file.uploadStatus = UploadStatus.UPLOAD_FINISHED;
              file.uploadProgress = 0;

              delete file.file;
            });
            const neoFileMap = produce(fileMap, (fmp) => {
              fmp.set(props.uuid, neoFile);
            });
            set({ fileMap: neoFileMap });

            if (props.onSuccess) {
              props.onSuccess(
                data.data.data.file_name,
                data.data.data.id as number,
                data.data.data.url,
                data.data.data.file_path,
              );
            }
            return;
          }
          const neoFile = produce(file!, (file) => {
            file.uploadStatus = UploadStatus.UPLOAD_ERROR;
          });
          const neoFileMap = produce(fileMap, (fmp) => {
            fmp.set(props.uuid, neoFile);
          });
          set({ fileMap: neoFileMap });

          void jsonResponseProcess(data);

          if (props.onError) {
            if (!data.data) {
              props.onError(
                new Error("response body empty, status code " + data.status),
              );
              return;
            }
            if (data.data.code !== 0) {
              props.onError(new Error(data.data.msg));
            }
          }
        })
        .catch((err) => {
          console.log(err);
          const neoFile = produce(file!, (file) => {
            file.uploadStatus = UploadStatus.UPLOAD_ERROR;
          });
          const neoFileMap = produce(fileMap, (fmp) => {
            fmp.set(props.uuid, neoFile);
          });
          set({ fileMap: neoFileMap });

          if (props.onError) {
            props.onError(err);
          }
        });
    },
    deleteChatFileWithUUIDs(list) {
      if (!list || list.length <= 0) {
        return;
      }
      console.log("delete ids:", list);
      const fileMap = get().fileMap;

      const neoFileMap = produce(fileMap, (fmp) => {
        list.forEach((it) => {
          fmp.delete(it);
        });
      });
      console.log("filemap size: ", neoFileMap.size);
      set({ fileMap: neoFileMap });
    },
  }),
  isEqual,
);
