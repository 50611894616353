import React, {
  ChangeEvent,
  DragEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  type JSX,
} from "react";

import styles from "./home.module.scss";

import {
  Button,
  ConfigProvider,
  message,
  Modal,
  Popconfirm,
  Tooltip,
} from "antd";

import UserAvatarIcon from "../icons/chat/user-avatar.png";
import MenuChatIcon from "../icons/chat/menu-chat.png";
import MenuTemplateChatIcon from "../icons/chat/menu-template.png";
import MenuFeedbackChatIcon from "../icons/chat/menu-feedback.png";
import PromptCommunityIcon from "../icons/chat/prompt-community.png";
import MenuPhoneIcon from "../icons/chat/menu-phone.png";
import MenuSettingIcon from "../icons/chat/menu-setting.png";
import NewIcon from "../icons/chat/new-icon.png";
import RocketIcon from "../icons/chat/rocket.png";
import KeFuIcon from "../icons/chat/kefu_1.png";
import AiToolsIcon from "../icons/chat/tools.png";
import PaperIcon from "../icons/paper_icon.png";
import FileIcon from "../icons/chat/file-icon.png";
import ExamRoundLogo from "@/app/icons/exam/logo.png";
import CloseIcon from "@/app/icons/chat/close-icon.png";
import LogoIcon from "@/app/icons/layout_v2/chat/headicon.svg";

import { useAccessStore, useAppConfig, useChatStore } from "../store";

import { MAX_SIDEBAR_WIDTH, NARROW_SIDEBAR_WIDTH, Path } from "../constant";

import { useNavigate } from "react-router-dom";
import {
  downloadFileFromUrl,
  getImageWithSiteType,
  useMobileScreen,
} from "../utils";
import dynamic from "next/dynamic";
import { MaskNew } from "@/app/components/mask-new";
import NextImage from "next/image";
import { Mask } from "@/app/store/mask";
import { QRCodeSVG } from "qrcode.react";
import { useSiteType } from "@/app/store/siteType";
import { css } from "@emotion/react";
import {
  CloudUploadOutlined,
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  ExamUploadFile,
  useExamUploadFilesStore,
} from "@/app/store/examUploadFiles";
import { showToast } from "@/app/components/ui-lib";
import { history } from "@/app/utils/history";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import classNames from "classnames";
import LightningIcon from "@/app/icons/layout_v2/sidebar/lighting.svg";
import { useShallow } from "zustand/react/shallow";
import { LoginComponentV2 } from "@/app/components/chatComponents/v2/loginComponent";
import MenuChatIconV2 from "@/app/icons/layout_v2/sidebar/chat.svg";
import MenuTemplateChatIconV2 from "@/app/icons/layout_v2/sidebar/menu_template.svg";
import MenuPaperIconV2 from "@/app/icons/layout_v2/sidebar/paper.svg";
import MenuToolsIconV2 from "@/app/icons/layout_v2/sidebar/tools.svg";
import MenuPromptCommunityIconV2 from "@/app/icons/layout_v2/sidebar/prompt_community.svg";
import MenuFeedbackIconV2 from "@/app/icons/layout_v2/sidebar/feedback.svg";
import MenuMobileIconV2 from "@/app/icons/layout_v2/sidebar/mobile.svg";
import MenuSettingIconV2 from "@/app/icons/layout_v2/sidebar/settings.svg";
import { SiteType } from "@/app/enums/siteType";
import { LayoutType } from "@/app/enums/layoutType";
import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import WriteIcon from "@/app/icons/layout_v2/sidebar/write.svg";
import { NewsPageEntry } from "@/app/components/chatComponents/v2/news/newsComponent";
import { Tooltip as MuiToolTip } from "@mui/material";
import { AppQrcode } from "@/app/components/chatComponents/app";

const ChatList = dynamic(
  () =>
    import("./chat-list").then((it) => ({
      default: it.ChatList,
    })),
  {
    loading: () => null,
  },
);

function useHotKey() {
  const chatStore = useChatStore();

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey || e.altKey || e.ctrlKey) {
        const n = chatStore.sessions.length;
        const limit = (x: number) => (x + n) % n;
        const i = chatStore.currentSessionIndex;
        if (e.key === "ArrowUp") {
          chatStore.selectSession(limit(i - 1));
        } else if (e.key === "ArrowDown") {
          chatStore.selectSession(limit(i + 1));
        }
      }
    };

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  });
}

function useDragSideBar() {
  const limit = (x: number) => Math.min(MAX_SIDEBAR_WIDTH, x);

  const config = useAppConfig();
  const startX = useRef(0);
  const startDragWidth = useRef(config.sidebarWidth ?? 300);
  const lastUpdateTime = useRef(Date.now());
  const layout = useLayoutConfigStore();

  const handleMouseMove = useRef((e: MouseEvent) => {
    if (Date.now() < lastUpdateTime.current + 50) {
      return;
    }
    lastUpdateTime.current = Date.now();
    const d = e.clientX - startX.current;
    const nextWidth = limit(startDragWidth.current + d);
    config.update((config) => (config.sidebarWidth = nextWidth));
  });

  const handleMouseUp = useRef(() => {
    startDragWidth.current = config.sidebarWidth ?? 300;
    window.removeEventListener("mousemove", handleMouseMove.current);
    window.removeEventListener("mouseup", handleMouseUp.current);
  });

  const onDragMouseDown = (e: MouseEvent) => {
    startX.current = e.clientX;

    window.addEventListener("mousemove", handleMouseMove.current);
    window.addEventListener("mouseup", handleMouseUp.current);
  };
  const isMobileScreen = useMobileScreen();
  const shouldNarrow =
    !isMobileScreen &&
    // config.sidebarWidth < MIN_SIDEBAR_WIDTH
    false;
  useEffect(() => {
    const barWidth = shouldNarrow
      ? NARROW_SIDEBAR_WIDTH
      : // limit(config.sidebarWidth ?? 300)
        layout.type === LayoutType.Layout_v1
        ? 300
        : isMobileScreen
          ? 284
          : 200;
    const sideBarWidth = isMobileScreen ? "100%" : `${barWidth}px`;
    document.documentElement.style.setProperty("--sidebar-width", sideBarWidth);
  }, [config.sidebarWidth, isMobileScreen, shouldNarrow, layout.type]);

  return {
    onDragMouseDown,
    shouldNarrow,
  };
}

export function SideBar(props: { className?: string }) {
  const chatStore = useChatStore();
  const navigate = useNavigate();
  const config = useAppConfig();
  const [showChatList, setShowChatList] = useState(config.showChatList ?? true);
  const isMobileScreen = useMobileScreen();
  // drag side bar
  const { onDragMouseDown, shouldNarrow } = useDragSideBar();
  const isLogin = useAccessStore((state) => state.isAuthorized());

  const startChat = (mask?: Mask) => {
    chatStore.newSession(mask);
    setTimeout(() => navigate(Path.Chat), 1);
  };
  useHotKey();
  const site = useSiteType();
  const layout = useLayoutConfigStore();

  const goPricing = useCallback(() => navigate(Path.Pricing), [navigate]);

  let upgradeServiceBlock = <></>;
  const upgradeService = () => {
    let serviceText = "升级服务";
    const upgradeIcon = () => {
      if (layout.type === LayoutType.Layout_v2) {
        return (
          <LightningIcon
            width={16}
            height={16}
            css={css`
              margin-top: auto;
              margin-bottom: auto;
              height: 16px;
              width: 16px;
              filter: initial !important;
            `}
          />
        );
      }
      return (
        <NextImage
          src={RocketIcon}
          alt="火箭"
          width={14}
          height={14}
        ></NextImage>
      );
    };
    if (layout.type === LayoutType.Layout_v2) {
      serviceText = "升级";
    }
    return (
      <div
        className={classNames(
          "flex flex-shrink-0 items-center justify-center rounded-[8px] cursor-pointer ",
          {
            "h-[42px] mx-[26px] mb-[22px] gap-[5px] bg-gradient-to-r to-[#0c74ed] from-[#1fd6cc] hover:opacity-75":
              layout.type === LayoutType.Layout_v1,
          },
        )}
        css={[
          layout.type === LayoutType.Layout_v2 &&
            css`
              margin-left: 10px;
              margin-right: 10px;
              border-color: #ff6e25;
              border-style: solid;
              border-width: 1px;
              color: #ff6e25;
              margin-bottom: 30px;
              height: 44px;
              gap: 5px;

              :hover {
                background-color: #ff6e25;
              }

              :hover path {
                fill: white;
              }

              :hover > div {
                color: white;
              }
            `,
        ]}
        onClick={goPricing}
      >
        {upgradeIcon()}
        <div
          className={classNames("", {
            "text-sm text-white font-normal":
              layout.type === LayoutType.Layout_v1,
          })}
          css={[
            layout.type === LayoutType.Layout_v2 &&
              css`
                font-size: 16px;
                font-weight: 600;
                height: 44px;
                line-height: 44px;
              `,
          ]}
        >
          {serviceText}
        </div>
      </div>
    );
  };

  const navigateToHome = useCallback(() => {
    navigate(Path.Home);
    if (isMobileScreen) {
      useAppConfig.setState({ showChatList: false });
    }
  }, [navigate, isMobileScreen]);

  if (
    [
      SiteType.DEV,
      SiteType.NORMAL,
      SiteType.ISSUER,
      SiteType.UNDETERMINED,
      SiteType.PAPER666,
    ].includes(site.siteType)
  ) {
    upgradeServiceBlock = upgradeService();
  }

  let colorPrimary = "#ff4029";
  let listTitle = "聊天";
  if (layout.type === LayoutType.Layout_v2) {
    colorPrimary = "#ff6e25";
    listTitle = "历史对话";
  }

  if (isMobileScreen && !showChatList) {
    setShowChatList(true);
  }
  if (
    !isMobileScreen &&
    config.showChatList !== showChatList &&
    config.showChatList !== undefined
  ) {
    setShowChatList(config.showChatList);
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colorPrimary,
        },
      }}
    >
      <div
        className={classNames({
          [`${styles.sidebar} ${props.className} ${shouldNarrow && styles["narrow-sidebar"]}`]:
            layout.type === LayoutType.Layout_v1,
        })}
        css={[
          css`
            top: 0;
            box-sizing: border-box;
            display: flex;
            transition: width ease 0.05s;
            background-color: white;
            position: relative;
            height: 100%;
          `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              height: 100%;
              width: 100%;
              background-color: var(--main-surface-secondary);
            `,
          layout.type === LayoutType.Layout_v2 &&
            site.siteType !== SiteType.PAPER666 &&
            showChatList &&
            css`
              width: var(--sidebar-width);
            `,
          layout.type === LayoutType.Layout_v2 &&
            site.siteType === SiteType.PAPER666 &&
            showChatList &&
            !isMobileScreen &&
            css`
              width: calc(var(--sidebar-width));
            `,
          layout.type === LayoutType.Layout_v2 &&
            site.siteType === SiteType.PAPER666 &&
            !showChatList &&
            css`
              width: 0;
            `,
          layout.type === LayoutType.Layout_v2 &&
            site.siteType !== SiteType.PAPER666 &&
            !showChatList &&
            isMobileScreen &&
            css`
              width: 64px;
            `,
          layout.type === LayoutType.Layout_v2 &&
            site.siteType !== SiteType.PAPER666 &&
            !showChatList &&
            !isMobileScreen &&
            css`
              width: 0;
            `,
        ]}
      >
        {layout.type === LayoutType.Layout_v1 && (
          <SideBarItemList></SideBarItemList>
        )}
        {(layout.type === LayoutType.Layout_v1 ||
          (layout.type === LayoutType.Layout_v2 && showChatList)) && (
          <div
            className={classNames(
              "flex flex-col flex-grow  border-0 border-r-[1px] border-solid ",
              {
                "bg-[#F0F1F5] border-[#e7e7e7] ":
                  layout.type === LayoutType.Layout_v1,
              },
            )}
            css={[
              layout.type === LayoutType.Layout_v2 &&
                css`
                  box-shadow:
                    0 20px 15px 0 rgb(0 0 0 / 4%),
                    0 13px 14px 0 rgb(0 0 0 / 8%),
                    0 7px 12px 0 rgb(0 0 0 / 6%);
                  z-index: 1;
                  background-color: var(--main-surface-primary);
                  border-color: var(--border-sharp);
                `,
              layout.type === LayoutType.Layout_v2 &&
                site.siteType !== SiteType.PAPER666 &&
                isMobileScreen &&
                css`
                  max-width: calc(var(--sidebar-width));
                `,
              layout.type === LayoutType.Layout_v2 &&
                !isMobileScreen &&
                css`
                  max-width: calc(var(--sidebar-width));
                `,
              layout.type === LayoutType.Layout_v2 &&
                site.siteType === SiteType.PAPER666 &&
                isMobileScreen &&
                css`
                  max-width: var(--sidebar-width);
                `,
            ]}
          >
            <>
              {/*聊天页面*/}
              <div
                className={classNames("flex box-border w-full items-end ", {
                  "mt-[50px] px-[20px]": layout.type === LayoutType.Layout_v1,
                })}
                css={[
                  layout.type === LayoutType.Layout_v2 &&
                    css`
                      padding-left: 10px;
                      padding-right: 10px;
                      margin-top: 10px;
                    `,
                ]}
              >
                <div
                  className={classNames("flex-grow ", {
                    "text-2xl text-gray-700 font-bold":
                      layout.type === LayoutType.Layout_v1,
                  })}
                  css={[
                    layout.type === LayoutType.Layout_v2 &&
                      css`
                        font-size: 16px;
                        line-height: 22px;
                        height: 22px;
                        font-weight: 600;
                        margin-bottom: auto;
                        margin-top: auto;
                        color: var(--text-primary);
                      `,
                  ]}
                >
                  {listTitle}
                </div>
                {layout.type === LayoutType.Layout_v1 && (
                  <NextImage
                    className="cursor-pointer hover:opacity-75"
                    src={NewIcon}
                    alt="+号"
                    width={28}
                    height={28}
                    onClick={() => {
                      startChat();
                    }}
                  />
                )}
                {layout.type === LayoutType.Layout_v2 && isLogin && (
                  <SideBarItemList></SideBarItemList>
                )}
                {layout.type === LayoutType.Layout_v2 && (
                  <AddConversationButton />
                )}
              </div>
              <div
                className={classNames("flex-grow overflow-y-scroll", {
                  "mt-5 px-2.5 mb-2": layout.type === LayoutType.Layout_v1,
                })}
              >
                {layout.type === LayoutType.Layout_v2 && (
                  <div
                    css={css`
                      margin-left: 10px;
                      margin-right: 10px;
                    `}
                  >
                    <div
                      css={css`
                        font-size: 14px;
                        display: flex;
                        height: 40px;
                        width: 100%;
                        padding-left: 12px;
                        padding-right: 12px;
                        box-sizing: border-box;
                        cursor: pointer;
                      `}
                      onClick={navigateToHome}
                    >
                      <div
                        css={css`
                          width: 20px;
                          height: 20px;
                          border-radius: 50%;
                          border-color: var(--border-sharp);
                          border-style: solid;
                          border-width: 1px;
                          display: flex;
                          margin-top: auto;
                          margin-bottom: auto;
                        `}
                      >
                        <LogoIcon
                          width={16}
                          height={16}
                          css={css`
                            margin: auto;
                            display: block;
                          `}
                        ></LogoIcon>
                      </div>
                      <span
                        css={css`
                          margin-top: auto;
                          margin-bottom: auto;
                          margin-left: 8px;
                        `}
                      >
                        CAIE Chat
                      </span>
                    </div>
                  </div>
                )}
                <div
                  css={[
                    layout.type === LayoutType.Layout_v1 &&
                      css`
                        width: 100%;
                      `,
                    layout.type === LayoutType.Layout_v2 &&
                      css`
                        margin-left: 10px;
                        margin-right: 10px;
                        display: flow-root;
                        margin-top: -10px;
                      `,
                  ]}
                >
                  <ChatList narrow={shouldNarrow} />
                </div>
              </div>
              {layout.type === LayoutType.Layout_v2 && <NewsPageEntry />}
              {upgradeServiceBlock}
            </>
          </div>
        )}
        {layout.type === LayoutType.Layout_v2 && <ChatListCollapse />}
      </div>
    </ConfigProvider>
  );
}

const AttachmentUploadBlock = function () {
  const [messageApi, messageContextHolder] = message.useMessage();

  const examFileStore = useExamUploadFilesStore();

  const onDrop = function (e: DragEvent<HTMLLabelElement>) {
    e.preventDefault();
    if (e.dataTransfer.items) {
      console.log(e.dataTransfer.types);
      const file = e.dataTransfer.items[0];
      if (file && file.kind === "file") {
        const fileContent = file.getAsFile();
        if (fileContent) {
          uploadFile(fileContent);
        }
      }
    }
  };

  const onDragOver = function (e: DragEvent<HTMLLabelElement>) {
    e.preventDefault();
  };

  const onFileChange = function (e: ChangeEvent<HTMLInputElement>) {
    const target = e.target!;
    const file = e.target.files?.[0];
    if (file) {
      uploadFile(file);
    }
    target.value = "";
  };

  const uploadFile = function (file: File) {
    void Promise.allSettled([
      messageApi.loading("正在上传……"),
      examFileStore.uploadFile(file, {
        onSuccess() {
          void Promise.allSettled([
            messageApi.success("上传完成"),
            examFileStore.getFileList(),
          ]);
        },
        onError(e) {
          console.error(e);
          void messageApi.error(e.message || "上传失败");
        },
      }),
    ]);
  };

  return (
    <>
      {messageContextHolder}
      <label
        css={css`
          display: block;
          width: 100%;
          aspect-ratio: 1;
          max-width: 500px;
        `}
        onDropCapture={onDrop}
        onDragOverCapture={onDragOver}
      >
        <div
          css={css`
            width: 100%;
            max-width: 500px;
            aspect-ratio: 1;
            display: flex;
            border-style: dashed;
            border-width: 1px;
            border-color: #999;
            flex-direction: column;
            cursor: pointer;
          `}
        >
          <CloudUploadOutlined
            css={css`
              font-size: 40px;
              margin: auto auto 0;
              border-radius: 8px;
            `}
          />
          <p
            css={css`
              text-align: center;
              margin: 15px auto auto;
              font-size: 14px;
            `}
          >
            点击或拖拽文件至此上传
          </p>
        </div>
        <input
          type="file"
          css={css`
            display: none;
          `}
          onChange={onFileChange}
        />
      </label>
      <div
        css={css`
          display: flex;
          flex-wrap: nowrap;
          overflow-x: scroll;
          margin-left: -12px;
        `}
      >
        {examFileStore.uploadFileList.map((item) => (
          <AttachmentFileItem key={item.id} item={item} />
        ))}
      </div>
    </>
  );
};

const AttachmentFileItem = function (props: { item: ExamUploadFile }) {
  const examFileStore = useExamUploadFilesStore();
  const [messageApi, messageContextHolder] = message.useMessage();

  const deleteFile = useCallback(
    function () {
      void examFileStore.deleteFile(props.item.id, {
        async onSuccess() {
          messageApi.success("删除成功");
        },
        async onError(e) {
          messageApi.error(e.message);
        },
      });
    },
    [examFileStore, messageApi, props.item.id],
  );

  return (
    <>
      {messageContextHolder}
      <div
        css={css`
          display: flex;
          height: 60px;
          width: 200px;
          align-items: center;
          border-style: solid;
          border-width: 1px;
          border-color: #ccc;
          border-radius: 8px;
          position: relative;
          margin-top: 20px;
          margin-left: 12px;
        `}
      >
        <NextImage
          src={FileIcon}
          alt={""}
          width={24}
          height={24}
          css={css`
            margin-left: 12px;
            margin-right: 12px;
          `}
        />
        <span
          css={css`
            flex-shrink: 1;
            flex-grow: 1;
            min-width: 0;
            overflow: hidden;
            margin-right: 8px;
            text-overflow: ellipsis;
            font-size: 14px;
            color: #252525;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          `}
        >
          {props.item.file_name}
        </span>
        <Popconfirm
          title={"是否要删除文件"}
          cancelText={"取消"}
          okText={"确定"}
          onConfirm={deleteFile}
        >
          <div
            css={css`
              position: absolute;
              height: 28px;
              width: 28px;
              top: -14px;
              right: -14px;
            `}
          >
            <NextImage src={CloseIcon} alt={""} width={28} height={28} />
          </div>
        </Popconfirm>
      </div>
    </>
  );
};

export const SideBarItemList = function () {
  const site = useSiteType();
  const isMobile = useMobileScreen();
  const [isModalCourseOpen, setIsModalCourseOpen] = useState(false);
  const [isModalPhoneOpen, setIsModalPhoneOpen] = useState(false);
  const [isModalFeedbackOpen, setIsModalFeedbackOpen] = useState(false);
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const navigate = useNavigate();
  const accessStore = useAccessStore();
  const layout = useLayoutConfigStore();
  const [menuOpen, setMenuOpen] = useState(false);
  const [promptToolTipOpen, setPromptToolTipOpen] = useState(false);

  const goSettings = useCallback(() => {
    if (!accessStore.isAuthorized()) {
      showToast("尚未登录", {
        text: "去登录",
        onClick() {
          history.replace(Path.Auth);
        },
      });
      return;
    }
    navigate(Path.User);
  }, [navigate, accessStore]);

  let sidebarBlocks = () => {
    return (
      <div
        css={css`
          flex-grow: 1;
          content: " ";
        `}
      ></div>
    );
  };

  const goPaper = useCallback(() => {
    window.location.href = "https://paper.pinggu.org/articleGenerate";
  }, []);

  const goTools = useCallback(() => {
    window.open("https://www.cda.cn/aitools.html", "_blank");
  }, []);

  const goPromptCommunity = useCallback(() => {
    window.open("https://prompt.caie.ai/", "_blank");
  }, []);

  const feedback = useCallback(() => {
    setIsModalFeedbackOpen(true);
  }, []);

  const phoneModalOpen = useCallback(() => {
    setIsModalPhoneOpen(true);
  }, []);

  const materialDown = useCallback(() => {
    void downloadFileFromUrl(
      "/p/exam/question?token=" + useAccessStore.getState().token,
    );
  }, []);

  const submit = useCallback(() => {
    void setUploadModalOpen(true);
    void useExamUploadFilesStore.getState().getFileList();
  }, []);

  const wrapUpComponents = function (item: React.ReactNode) {
    if (!item) {
      return item;
    }
    if (
      layout.type === LayoutType.Layout_v2 &&
      site.siteType !== SiteType.PAPER666 &&
      accessStore.isAuthorized()
    ) {
      return <MenuItem>{item}</MenuItem>;
    }
    return item;
  };

  const menuStatusChange = useCallback(
    (e: React.SyntheticEvent | null, value: boolean) => {
      setMenuOpen(value);
    },
    [],
  );

  const disableMenuClickToClose = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  const clickToChangePromptToolTipState = useCallback(
    (e: React.SyntheticEvent) => {
      setPromptToolTipOpen(!promptToolTipOpen);
      e.stopPropagation();
    },
    [promptToolTipOpen],
  );

  const showPromptToolTip = useCallback(() => {
    setPromptToolTipOpen(true);
  }, []);

  const hidePromptToolTip = useCallback(() => {
    setPromptToolTipOpen(false);
  }, []);

  const buttons = {
    prompt: () => {
      return (
        <Tooltip
          title={<MaskNew />}
          arrow={false}
          color={"#ffffff"}
          overlayInnerStyle={{ padding: 0 }}
          placement={"right"}
          key="prompt"
        >
          <div>
            <SideBarItems title={"智能专项"}>
              {layout.type === LayoutType.Layout_v1 && (
                <NextImage
                  src={MenuTemplateChatIcon}
                  alt="智能专项"
                  css={[
                    css`
                      width: 25px;
                      height: 25px;
                    `,
                  ]}
                ></NextImage>
              )}
              {layout.type === LayoutType.Layout_v2 && (
                <MenuTemplateChatIconV2
                  style={{ width: "20px", height: "20px", filter: "initial" }}
                />
              )}
            </SideBarItems>
          </div>
        </Tooltip>
      );
    },
    paper: () => {
      return (
        <SideBarItems title={"范文生成"} onClick={goPaper}>
          {layout.type === LayoutType.Layout_v1 && (
            <NextImage
              src={PaperIcon}
              alt={"文房思宝"}
              css={[
                css`
                  width: 25px;
                  height: 25px;
                `,
              ]}
            ></NextImage>
          )}
          {layout.type === LayoutType.Layout_v2 && (
            <MenuPaperIconV2
              style={{ width: "20px", height: "20px", filter: "initial" }}
            />
          )}
        </SideBarItems>
      );
    },
    tools: () => {
      return (
        <SideBarItems title={"AI工具集"} onClick={goTools}>
          {layout.type === LayoutType.Layout_v1 && (
            <NextImage
              src={AiToolsIcon}
              alt="Ai工具集"
              css={[
                css`
                  width: 25px;
                  height: 25px;
                `,
              ]}
            ></NextImage>
          )}
          {layout.type === LayoutType.Layout_v2 && (
            <MenuToolsIconV2
              style={{ width: "20px", height: "20px", filter: "initial" }}
            />
          )}
        </SideBarItems>
      );
    },
    promptCommunity: () => {
      return (
        <SideBarItems
          title={
            <>
              {"提示语"}
              <br
                css={[
                  (layout.type === LayoutType.Layout_v1 ||
                    (layout.type === LayoutType.Layout_v2 && !isMobile)) &&
                    css`
                      display: none;
                    `,
                ]}
              />
              {"社区"}
            </>
          }
          onClick={goPromptCommunity}
        >
          {layout.type === LayoutType.Layout_v1 && (
            <NextImage
              src={PromptCommunityIcon}
              alt="提示语社区"
              css={[
                css`
                  width: 25px;
                  height: 25px;
                `,
              ]}
            ></NextImage>
          )}
          {layout.type === LayoutType.Layout_v2 && (
            <MenuPromptCommunityIconV2
              style={{ width: "20px", height: "20px", filter: "initial" }}
            />
          )}
        </SideBarItems>
      );
    },
    feedback: () => {
      return (
        <SideBarItems title={"意见反馈"} onClick={feedback}>
          {layout.type === LayoutType.Layout_v1 && (
            <NextImage
              src={MenuFeedbackChatIcon}
              alt="意见反馈"
              css={[
                css`
                  width: 25px;
                  height: 25px;
                `,
              ]}
            ></NextImage>
          )}
          {layout.type === LayoutType.Layout_v2 && (
            <MenuFeedbackIconV2
              style={{ width: "20px", height: "20px", filter: "initial" }}
            />
          )}
        </SideBarItems>
      );
    },
    mobile: () => {
      return (
        <>
          <MuiToolTip title={<AppQrcode />} slots={{ tooltip: "div" }}>
            <div>
              <SideBarItems title={"APP"} onClick={phoneModalOpen}>
                {layout.type === LayoutType.Layout_v1 && (
                  <NextImage
                    src={MenuPhoneIcon.src}
                    alt="手机模式"
                    width={36}
                    height={36}
                    css={[
                      css`
                        width: 36px;
                        height: 36px;
                      `,
                    ]}
                  ></NextImage>
                )}
                {layout.type === LayoutType.Layout_v2 && (
                  <MenuMobileIconV2
                    style={{ width: "20px", height: "20px", filter: "initial" }}
                  />
                )}
              </SideBarItems>
            </div>
          </MuiToolTip>
        </>
      );
    },
    materialDownload: () => {
      return (
        <SideBarItems title={"题目下载"} onClick={materialDown}>
          <DownloadOutlined
            css={[
              layout.type === LayoutType.Layout_v1 &&
                css`
                  font-size: 36px;
                  color: white;
                `,
              layout.type === LayoutType.Layout_v2 &&
                css`
                  font-size: 20px;
                `,
            ]}
          />
        </SideBarItems>
      );
    },
    submit: () => {
      return (
        <SideBarItems title={"提交作品"} onClick={submit}>
          <UploadOutlined
            css={[
              layout.type === LayoutType.Layout_v1 &&
                css`
                  font-size: 36px;
                  color: white;
                `,
              layout.type === LayoutType.Layout_v2 &&
                css`
                  font-size: 20px;
                `,
            ]}
          />
        </SideBarItems>
      );
    },
    placeholder: () => {
      if (layout.type === LayoutType.Layout_v2 && accessStore.isAuthorized()) {
        return null;
      }
      return (
        <div
          css={[
            css`
              content: " ";
              flex-grow: 1;
            `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                border-bottom: 1px solid var(--border-sharp);
              `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                margin-left: 12px;
                margin-right: 12px;
              `,
          ]}
        ></div>
      );
    },
    chat: () => {
      return (
        <SideBarItems title={"AI对话"} selected>
          {layout.type === LayoutType.Layout_v1 && (
            <NextImage
              src={MenuChatIcon}
              alt="聊天"
              css={[
                css`
                  width: 25px;
                  height: 25px;
                `,
              ]}
            ></NextImage>
          )}
          {layout.type === LayoutType.Layout_v2 && (
            <MenuChatIconV2
              style={{ width: "20px", height: "20px", filter: "initial" }}
            />
          )}
        </SideBarItems>
      );
    },
    settings: () => {
      return (
        <SideBarItems title={"设置"} onClick={goSettings}>
          {layout.type === LayoutType.Layout_v1 && (
            <NextImage
              src={MenuSettingIcon.src}
              alt="设置"
              width={25}
              height={25}
            ></NextImage>
          )}
          {layout.type === LayoutType.Layout_v2 && (
            <MenuSettingIconV2
              style={{ width: "20px", height: "20px", filter: "initial" }}
            />
          )}
        </SideBarItems>
      );
    },
  };

  if (
    site.siteType === SiteType.DEV ||
    site.siteType === SiteType.NORMAL ||
    site.siteType === SiteType.UNDETERMINED ||
    site.siteType === SiteType.PAPER666
  ) {
    sidebarBlocks = () => {
      return (
        <>
          {!isMobile ? wrapUpComponents(buttons.prompt()) : <></>}
          {wrapUpComponents(buttons.paper())}
          {wrapUpComponents(buttons.tools())}
          {wrapUpComponents(buttons.promptCommunity())}
          {wrapUpComponents(buttons.placeholder())}
          {wrapUpComponents(buttons.feedback())}
          {isMobile ? <></> : wrapUpComponents(buttons.mobile())}
        </>
      );
    };
  } else if (site.siteType === SiteType.ISSUER) {
    sidebarBlocks = () => {
      return (
        <>
          {wrapUpComponents(buttons.prompt())}
          {wrapUpComponents(buttons.paper())}
          {wrapUpComponents(buttons.promptCommunity())}
          {wrapUpComponents(buttons.feedback())}
          {isMobile ? <></> : wrapUpComponents(buttons.mobile())}
        </>
      );
    };
  } else if (site.siteType === SiteType.EXAM) {
    sidebarBlocks = () => {
      return (
        <>
          {wrapUpComponents(buttons.materialDownload())}
          {wrapUpComponents(buttons.submit())}
          {wrapUpComponents(buttons.placeholder())}
        </>
      );
    };
  }

  if (
    layout.type === LayoutType.Layout_v2 &&
    site.siteType === SiteType.PAPER666
  ) {
    return <></>;
  }

  return (
    <>
      {(layout.type === LayoutType.Layout_v1 ||
        (layout.type === LayoutType.Layout_v2 &&
          !accessStore.isAuthorized())) && (
        <div
          css={[
            css`
              display: flex;
              flex-direction: column;
              box-sizing: border-box;
              height: 100%;
              justify-content: center;
              align-items: center;
            `,
            layout.type === LayoutType.Layout_v1 &&
              css`
                width: 80px;
                padding-top: 49px;
                padding-bottom: 48px;
                background: linear-gradient(
                  357deg,
                  #1fd6cc 0%,
                  #0c74ed 51%,
                  #000c75 100%
                );
              `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                background-color: var(--main-surface-primary);
                z-index: 2;
                border-right: 1px solid var(--border-sharp);
              `,
            layout.type === LayoutType.Layout_v2 &&
              !isMobile &&
              css`
                width: 164px;
                padding-top: 20px;
                padding-bottom: 30px;
              `,
            layout.type === LayoutType.Layout_v2 &&
              isMobile &&
              css`
                width: 64px;
                padding-top: 10px;
                padding-bottom: 20px;
              `,
          ]}
        >
          <div
            css={[
              layout.type === LayoutType.Layout_v1 &&
                css`
                  max-height: 780px;
                  display: flex;
                  flex-direction: column;
                  box-sizing: border-box;
                  height: 100%;
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                `,
              layout.type === LayoutType.Layout_v2 &&
                css`
                  flex-grow: 1;
                  flex-shrink: 1;
                  display: flex;
                  flex-flow: column nowrap;
                  overflow-y: scroll;
                  column-gap: 2px;
                `,
            ]}
          >
            {layout.type === LayoutType.Layout_v1 && (
              <div className="w-[50px] h-[50px] rounded-[50px] bg-white flex justify-center items-center mb-[19px]">
                <NextImage
                  src={getImageWithSiteType(
                    site.siteType,
                    SiteType.EXAM,
                    ExamRoundLogo,
                    UserAvatarIcon,
                  )}
                  alt="用户头像"
                  width={46}
                  height={46}
                ></NextImage>
              </div>
            )}
            {buttons.chat()}
            {sidebarBlocks()}
            {buttons.settings()}
          </div>
          {layout.type === LayoutType.Layout_v2 && isMobile && (
            <div
              css={css`
                width: 40px;
                border-top: 1px solid #dfdfdf;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                padding-top: 10px;
                flex-shrink: 0;
              `}
            >
              <LoginComponentV2 />
            </div>
          )}
        </div>
      )}
      {layout.type === LayoutType.Layout_v2 &&
        site.siteType !== SiteType.PAPER666 &&
        accessStore.isAuthorized() && (
          <div>
            <Dropdown open={menuOpen} onOpenChange={menuStatusChange}>
              <MenuButton
                variant={"plain"}
                sx={{
                  width: "24px",
                  height: "24px",
                  minHeight: "24px",
                  minWidth: "24px",
                  padding: 0,
                }}
              >
                <FontAwesomeIcon
                  icon={faBars}
                  width={24}
                  height={24}
                ></FontAwesomeIcon>
              </MenuButton>
              <Menu keepMounted={true}>
                {sidebarBlocks()}
                {wrapUpComponents(buttons.settings())}
              </Menu>
            </Dropdown>
          </div>
        )}
      <Modal
        title={<div style={{ textAlign: "center" }}>反馈问题和建议</div>}
        footer={null}
        open={isModalFeedbackOpen}
        onCancel={() => {
          setIsModalFeedbackOpen(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <NextImage
            src={KeFuIcon}
            alt="客服"
            width={100}
            height={100}
          ></NextImage>
          <Button
            type="primary"
            style={{ marginTop: "30px" }}
            onClick={() => setIsModalFeedbackOpen(false)}
          >
            知道了
          </Button>
        </div>
      </Modal>

      <Modal
        title={<div style={{ textAlign: "center" }}>扫码使用手机</div>}
        footer={null}
        open={isModalPhoneOpen}
        onCancel={() => {
          setIsModalPhoneOpen(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <QRCodeSVG
            value="https://a.app.qq.com/o/simple.jsp?pkgname=com.paper666.uniapp" //value参数为字符串类型
            size={150} //二维码的宽高尺寸
            fgColor="#000000" //二维码的颜色
            style={{
              filter: "initial",
            }}
          />
          <Button
            type="primary"
            style={{ marginTop: "30px" }}
            onClick={() => setIsModalPhoneOpen(false)}
          >
            知道了
          </Button>
        </div>
      </Modal>
      <Modal open={isDownloadModalOpen}></Modal>
      <Modal
        open={isUploadModalOpen}
        onCancel={() => void setUploadModalOpen(false)}
        footer={null}
        closable={false}
      >
        <AttachmentUploadBlock></AttachmentUploadBlock>
      </Modal>
    </>
  );
};

const AddConversationButton = function () {
  const navigate = useNavigate();

  const createNewChat = useCallback(() => {
    const session = useChatStore.getState().newSession();
    navigate(Path.Chat + "/" + session.conversationId!);
  }, [navigate]);

  return (
    <div
      css={css`
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: 12px;
      `}
      onClick={createNewChat}
    >
      <WriteIcon></WriteIcon>
    </div>
  );
};

const ChatListCollapse = function () {
  const isCollapse = useAppConfig(useShallow((state) => !state.showChatList));
  const isMobileScreen = useMobileScreen();
  const navigate = useNavigate();

  const arrowStyle = css`
    font-size: 10px;
    height: 10px;
    width: 10px;
    margin: auto;
    color: var(--black);
  `;

  const clickToChangeCollapseState = useCallback(() => {
    useAppConfig.getState().update((config) => {
      config.showChatList = !config.showChatList;
    });
  }, []);

  return (
    <div
      css={[
        css`
          width: 20px;
          height: 32px;
          position: absolute;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
          right: -26px;
          border-radius: 10px;
          background-color: var(--main-surface-secondary);
          display: flex;
          z-index: 100;

          :hover {
            background-color: var(--hover-color);
          }

          :hover .css-${arrowStyle.name} {
            color: var(--neo-primary);
          }
        `,
      ]}
      onClick={clickToChangeCollapseState}
    >
      {isCollapse || isMobileScreen ? (
        <RightOutlined css={arrowStyle} />
      ) : (
        <LeftOutlined css={arrowStyle} />
      )}
    </div>
  );
};

const SideBarItems = function (
  props: React.PropsWithChildren<{
    title?: string | JSX.Element;
    selected?: boolean;
    onClick?: () => void;
  }>,
) {
  const isMobileScreen = useMobileScreen();
  const layout = useLayoutConfigStore();

  return (
    <div
      css={[
        layout.type === LayoutType.Layout_v1 &&
          css`
            width: 80px;
            height: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 11px;
            cursor: pointer;

            &:hover {
              background: rgb(0 0 0 / 18%);
            }
          `,
        layout.type === LayoutType.Layout_v2 &&
          css`
            border-radius: 6px;
            cursor: pointer;

            :hover {
              background-color: var(--hover-color);
            }
          `,
        layout.type === LayoutType.Layout_v2 &&
          !isMobileScreen &&
          css`
            margin-left: 12px;
            margin-right: 12px;
            width: 140px;
            height: 40px;
            display: flex;
          `,
        layout.type === LayoutType.Layout_v2 &&
          props.selected &&
          css`
            background-color: var(--selected-conversation-background);
          `,
        layout.type === LayoutType.Layout_v2 &&
          isMobileScreen &&
          css`
            margin-left: 4px;
            margin-right: 4px;
            height: 56px;
            min-height: 56px;
            width: 56px;
            display: flex;
            flex-direction: column;
          `,
      ]}
      onClick={props.onClick}
    >
      <div
        css={[
          layout.type === LayoutType.Layout_v2 &&
            css`
              width: 20px;
              height: 20px;
            `,
          layout.type === LayoutType.Layout_v2 &&
            !isMobileScreen &&
            css`
              margin-top: auto;
              margin-bottom: auto;
              margin-left: 13px;
            `,
          // layout.type === LayoutType.Layout_v2 &&
          //   !isMobileScreen &&
          //   props.selected &&
          //   css`
          //     path {
          //       fill: #248eb3;
          //     }
          //   `,
          layout.type === LayoutType.Layout_v2 &&
            isMobileScreen &&
            css`
              margin-left: auto;
              margin-right: auto;
              margin-top: 7px;
            `,
        ]}
      >
        {props.children}
      </div>
      <span
        css={[
          layout.type === LayoutType.Layout_v1 &&
            css`
              color: white;
              font-size: 11px;
              margin-top: 2px;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              font-size: 14px;
              font-weight: 500;
              color: var(--text-primary);
            `,
          layout.type === LayoutType.Layout_v2 &&
            !isMobileScreen &&
            css`
              margin-top: auto;
              margin-bottom: auto;
              margin-left: 10px;
            `,
          // layout.type === LayoutType.Layout_v2 &&
          //   !isMobileScreen &&
          //   props.selected &&
          //   css`
          //     color: #248eb3;
          //   `,
          layout.type === LayoutType.Layout_v2 &&
            isMobileScreen &&
            css`
              margin-left: auto;
              margin-right: auto;
              text-align: center;
              font-size: 12px;
              line-height: 16px;
              height: 16px;
              display: block;
            `,
        ]}
      >
        {props.title}
      </span>
    </div>
  );
};
