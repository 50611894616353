"use client";

import { Channel } from "@/app/enums/channel";

export const getChannel = function () {
  if (typeof window === "undefined") {
    return Channel.NORMAL;
  }
  if (typeof jsbridge !== "undefined") {
    return Channel.LENOVO;
  }
  return Channel.NORMAL;
};
