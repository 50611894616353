"use client";
import { ChatEnums } from "@/app/enums/chat";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useTransition,
} from "react";
import { css } from "@emotion/react";
import { Input, InputRef, Select } from "antd";
import { useImmer } from "use-immer";
import { useChatStore } from "@/app/store";
import { useDebouncedCallback } from "use-debounce";
import { produce } from "immer";
import { useShallow } from "zustand/react/shallow";
import {
  AdditionalSettingsPopUp,
  AdditionSettingItem,
  ChatInputAboveButtonsContainer,
  CommonPromptContainerBlock,
  CommonPromptInputBlock,
  CommonSelectBaseBlock,
} from "@/app/components/chatComponents/commonLib";
import { useBalanceStr } from "@/app/utils/userUsage";
import { useSubmit } from "@/app/hooks/chatHooks";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import { LayoutType } from "@/app/enums/layoutType";
import { ConversationIdContext } from "@/app/contexts/chatIdContext";

export const PPTSelectArea = function (props: { usageStr?: string }) {
  const [pptOptions, updatePPTOptions] = useImmer({
    operation: ChatEnums.PPT.Operation.AUTO,
    theme: ChatEnums.PPT.Theme.AUTO,
    generateNotes: ChatEnums.PPT.Notes.N,
    author: "",
  } as ChatEnums.PPT.Options);

  const conversationId = useContext(ConversationIdContext);

  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)!),
  );
  const [, startTransition] = useTransition();
  const inputRef = useRef<InputRef>(null);
  const layout = useLayoutConfigStore();

  const debouncedPPTOptionsUpdate = useDebouncedCallback(
    (value: ChatEnums.PPT.Options) => {
      useChatStore.getState().updateCurrentSession((session1) => {
        session1.pptOptions = value;
      }, conversationId);
    },
    0,
  );

  const updateFn = (value: ChatEnums.PPT.Options) => {
    updatePPTOptions(value);
    startTransition(() => {
      debouncedPPTOptionsUpdate(value);
    });
  };
  const [selectOpen, setSelectOpen] = useState(false);

  const pptOptionCallback = useCallback(() => {
    const options = session.pptOptions;
    if (options) {
      startTransition(() => {
        updatePPTOptions(options);
      });
    } else {
      startTransition(() => {
        useChatStore.getState().updateCurrentSession((session1) => {
          session1.pptOptions = pptOptions;
        }, conversationId);
      });
    }
  }, [pptOptions, updatePPTOptions, session, conversationId]);

  useEffect(() => {
    pptOptionCallback();
  }, [session.conversationId, pptOptionCallback]);

  const optionChange = {
    operation(value: ChatEnums.PPT.Operation) {
      updateFn(
        produce(pptOptions, (option) => {
          option.operation = value;
        }),
      );
    },
    theme(value: ChatEnums.PPT.Theme) {
      updateFn(
        produce(pptOptions, (option) => {
          option.theme = value;
        }),
      );
    },
    generateNotes(value: ChatEnums.PPT.Notes) {
      updateFn(
        produce(pptOptions, (option) => {
          option.generateNotes = value;
        }),
      );
    },
    author(value: string) {
      updateFn(
        produce(pptOptions, (option) => {
          option.author = value;
        }),
      );
    },
  };

  return (
    <CommonSelectBaseBlock usageStr={props.usageStr}>
      <div
        css={[
          css`
            display: flex;
            flex-grow: 1;
            flex-shrink: 1;
            min-width: 0;
            overflow: scroll visible;
            flex-wrap: nowrap;
            box-sizing: content-box;
            padding: 0;
            border: initial;
            scrollbar-width: none;

            ::-webkit-scrollbar {
              width: 0;
              height: 0;
            }
          `,
          layout.type === LayoutType.Layout_v1 &&
            css`
              height: 32px;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              height: 100%;
            `,
        ]}
      >
        <Select
          style={{
            width: "108px",
            height: "28px",
            marginLeft:
              layout.type === LayoutType.Layout_v1 ? "10px" : undefined,
            marginTop:
              layout.type === LayoutType.Layout_v2 ? "auto" : undefined,
            marginBottom:
              layout.type === LayoutType.Layout_v2 ? "auto" : undefined,
          }}
          value={pptOptions.operation}
          onChange={optionChange.operation}
          popupMatchSelectWidth={true}
          placement={"topLeft"}
          placeholder={"生成类型"}
          options={[
            { value: ChatEnums.PPT.Operation.AUTO, label: "程序判断" },
            {
              value: ChatEnums.PPT.Operation.TEXT_GENERATION,
              label: "文本生成",
            },
            {
              value: ChatEnums.PPT.Operation.TOPIC_GENERATION,
              label: "话题生成",
            },
          ]}
          css={[
            layout.type === LayoutType.Layout_v1 &&
              css`
                margin-left: 10px;
              `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                margin-top: auto;
                margin-bottom: auto;
              `,
          ]}
        />

        <div
          css={[
            css`
              margin-left: 10px;
            `,
            layout.type === LayoutType.Layout_v1 &&
              css`
                height: 28px;
              `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                height: 100%;
                display: flex;
                align-items: center;
              `,
          ]}
        >
          <AdditionalSettingsPopUp>
            <AdditionSettingItem title={"生成主题"}>
              <Select
                style={{ width: "100%", height: "28px", marginLeft: "10px" }}
                value={pptOptions.theme}
                onChange={optionChange.theme}
                popupMatchSelectWidth={true}
                placement={"topLeft"}
                placeholder={"生成主题"}
                options={[
                  { value: ChatEnums.PPT.Theme.AUTO, label: "随机主题" },
                  { value: ChatEnums.PPT.Theme.PURPLE, label: "紫色主题" },
                  { value: ChatEnums.PPT.Theme.GREEN, label: "绿色主题" },
                  {
                    value: ChatEnums.PPT.Theme.LIGHT_BLUE,
                    label: "清逸天蓝",
                  },
                  { value: ChatEnums.PPT.Theme.TAUPE, label: "星光夜影" },
                  {
                    value: ChatEnums.PPT.Theme.TELECOM_RED,
                    label: "炽热暖阳",
                  },
                  {
                    value: ChatEnums.PPT.Theme.TELECOM_GREEN,
                    label: "幻翠奇旅",
                  },
                ]}
              />
            </AdditionSettingItem>
            <AdditionSettingItem title={"演讲者备注"}>
              <Select
                style={{ width: "100%", height: "28px", marginLeft: "10px" }}
                value={pptOptions.generateNotes}
                onChange={optionChange.generateNotes}
                popupMatchSelectWidth={true}
                placement={"topLeft"}
                placeholder={"是否生成演讲者备注"}
                options={[
                  { value: ChatEnums.PPT.Notes.N, label: "不生成演讲备注" },
                  { value: ChatEnums.PPT.Notes.Y, label: "生成演讲备注" },
                ]}
              />
            </AdditionSettingItem>
            <AdditionSettingItem title={"作者名"}>
              <Select
                style={{ width: "100%", height: "28px", marginLeft: "10px" }}
                value={pptOptions.author}
                onChange={optionChange.author}
                popupMatchSelectWidth={true}
                placement={"topLeft"}
                placeholder={"作者名"}
                options={[{ value: "", label: "不显示作者名" }]}
                open={selectOpen}
                onDropdownVisibleChange={(open) => void setSelectOpen(open)}
                onInputKeyDown={(e) => {
                  e.preventDefault();
                }}
                dropdownRender={(menu) => {
                  return (
                    <>
                      {menu}
                      <Input
                        ref={inputRef}
                        value={pptOptions.author}
                        placeholder={"输入作者名"}
                        style={{ width: "151px", maxWidth: "100%" }}
                        onChange={(e) => {
                          optionChange.author(e.currentTarget.value);
                          e.stopPropagation();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setSelectOpen(false);
                          }
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                        }}
                      ></Input>
                    </>
                  );
                }}
              />
            </AdditionSettingItem>
          </AdditionalSettingsPopUp>
        </div>
      </div>
    </CommonSelectBaseBlock>
  );
};

export const PPTInputBlock = function () {
  const usageStr = useBalanceStr();
  const doSubmit = useSubmit();
  const [userInput, setUserInput] = useState("");
  const layout = useLayoutConfigStore();

  const submit = useCallback(
    (ignoreEmptyInput: boolean) => {
      doSubmit(userInput, ignoreEmptyInput);
    },
    [doSubmit, userInput],
  );

  const updateUserInput = useCallback((value: string) => {
    setUserInput(value);
  }, []);

  return (
    <CommonPromptContainerBlock usageStr={usageStr}>
      {layout.type === LayoutType.Layout_v1 && (
        <PPTSelectArea usageStr={usageStr} />
      )}
      {layout.type === LayoutType.Layout_v2 && (
        <ChatInputAboveButtonsContainer>
          <PPTSelectArea />
        </ChatInputAboveButtonsContainer>
      )}
      <CommonPromptInputBlock
        submit={submit}
        updatePrompts={updateUserInput}
      ></CommonPromptInputBlock>
    </CommonPromptContainerBlock>
  );
};
