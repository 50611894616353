"use client";

import { useEffect } from "react";
import { match } from "ts-pattern";
import { Channel } from "@/app/enums/channel";
import { useChannelStore } from "@/app/store/channel";
import { useSearchParams as useNextSearchParams } from "next/navigation";
import { useSearchParams as useRRSearchParams } from "react-router-dom";

export const useMatchNextChannel = function () {
  const params = useNextSearchParams();
  useEffect(() => {
    const channelStr = params.get("channel");
    matchChannel(channelStr);
  });
};

export const useMatchRRChannel = function () {
  const [params] = useRRSearchParams();

  useEffect(() => {
    const channelStr = params.get("channel");
    matchChannel(channelStr);
  });
};

const matchChannel = function (channel: string | undefined | null) {
  if (channel) {
    match(channel.toLowerCase())
      .with(Channel.LENOVO, () => {
        setChannel(Channel.LENOVO);
      })
      .with("null", () => {
        setChannel(Channel.NORMAL, true);
      })
      .otherwise(() => {
        // do nothing
      });
  }
};

export const setChannel = function (channel: Channel, forceNormal?: boolean) {
  const currentChannel = useChannelStore.getState().channel;
  if (
    (channel !== Channel.NORMAL || forceNormal) &&
    currentChannel !== channel
  ) {
    useChannelStore.setState({ channel });
  }
};
