"use client";
import { match } from "ts-pattern";
import { Scalar } from "yaml";

export enum ChatImageOperation {
  CREATE,
  EDITING,
  VARIATION,
}

export enum ChatImageModelType {
  CAIE_E_2,
  CAIE_E_3,
}

export enum ChatImageSize {
  SIZE_256_256,
  SIZE_512_512,
  SIZE_1024_1024,
  SIZE_1792_1024,
  SIZE_1024_1792,
}

export enum ChatImageQuality {
  STANDARD,
  HD,
}

export enum ChatImageVariant {
  DEFAULT,
  VIVID,
  NATURAL,
}

export type ImageUploadOptions = {
  operation: ChatImageOperation;
  model: ChatImageModelType;
  imageSize: ChatImageSize;
  quality: ChatImageQuality;
  variant: ChatImageVariant;
};

export const getSizeStringByEnum = function (size: ChatImageSize) {
  return match(size)
    .with(ChatImageSize.SIZE_256_256, () => "256x256")
    .with(ChatImageSize.SIZE_512_512, () => "512x512")
    .with(ChatImageSize.SIZE_1024_1024, () => "1024x1024")
    .with(ChatImageSize.SIZE_1792_1024, () => "1792x1024")
    .with(ChatImageSize.SIZE_1024_1792, () => "1024x1792")
    .otherwise(() => "0x0");
};

export const getQualityStringByEnum = function (quality: ChatImageQuality) {
  return match(quality)
    .with(ChatImageQuality.HD, () => "hd")
    .with(ChatImageQuality.STANDARD, () => "standard")
    .otherwise(() => "");
};

export const getStyleStringByEnum = function (style: ChatImageVariant) {
  return match(style)
    .with(ChatImageVariant.NATURAL, () => "natural")
    .with(ChatImageVariant.VIVID, () => "vivid")
    .otherwise(() => "vivid");
};

export const getModelStringByEnum = function (model: ChatImageModelType) {
  return match(model)
    .with(ChatImageModelType.CAIE_E_3, () => "DALL-E-3")
    .with(ChatImageModelType.CAIE_E_2, () => "DALL-E-2")
    .otherwise(() => "");
};

export namespace ChatEnums {
  export namespace PPT {
    export type Options = {
      operation: Operation;
      theme: Theme;
      generateNotes: Notes;
      author: string;
    };

    export enum Operation {
      TEXT_GENERATION = "text",
      TOPIC_GENERATION = "topic",
      AUTO = "auto",
    }

    export enum Theme {
      AUTO = "auto",
      PURPLE = "purple",
      GREEN = "green",
      LIGHT_BLUE = "lightblue",
      TAUPE = "taupe",
      BLUE = "blue",
      TELECOM_RED = "telecomRed",
      TELECOM_GREEN = "telecomGreen",
    }

    export enum Notes {
      Y = 1,
      N = 0,
    }
  }

  export namespace StableDiffusion {
    export type Options = {
      operation: Operation;
      count: Count;
      size: Size;
    };

    export enum Operation {
      GENERATE,
      EDIT,
      VARIATION,
    }

    export enum Count {
      COUNT_1 = 1,
      COUNT_2 = 2,
      COUNT_3 = 3,
      COUNT_4 = 4,
    }

    export enum Size {
      SIZE_512_512 = "512x512",
      SIZE_1024_1024 = "1024x1024",
      SIZE_1792_1024 = "1792x1024",
      SIZE_1024_1792 = "1024x1792",
    }
  }

  export namespace Wanx {
    export namespace Generate {
      export enum Style {
        AUTO = "<auto>",
        THREE_D_CARTOON = "<3d cartoon>",
        ANIME = "<anime>",
        OIL_PAINTING = "<oil painting>",
        WATER_COLOR = "<watercolor>",
        SKETCH = "<sketch>",
        CHINESE_PAINTING = "<chinese painting>",
        FLAT_ILLUSTRATION = "<flat illustration>",
      }

      export enum Size {
        SIZE_1024_1024 = "1024*1024",
        SIZE_720_1280 = "720*1280",
        SIZE_1280_720 = "1280*720",
      }

      export enum Mode {
        REPAINT = "repaint",
        REFONLY = "refonly",
      }
    }

    export namespace Mixture {
      export enum Layout {
        VERTICAL = "vertical",
        HORIZONTAL = "horizontal",
      }

      export enum Width {
        WIDTH_256 = 256,
        WIDTH_320 = 320,
        WIDTH_384 = 384,
        WIDTH_448 = 448,
        WIDTH_512 = 512,
        WIDTH_576 = 576,
        WIDTH_640 = 640,
        WIDTH_704 = 704,
        WIDTH_768 = 768,
      }

      export enum Height {
        HEIGHT_256 = 256,
        HEIGHT_320 = 320,
        HEIGHT_384 = 384,
        HEIGHT_448 = 448,
        HEIGHT_512 = 512,
        HEIGHT_576 = 576,
        HEIGHT_640 = 640,
        HEIGHT_704 = 704,
        HEIGHT_768 = 768,
      }
    }

    export enum Count {
      COUNT_1 = 1,
      COUNT_2 = 2,
      COUNT_3 = 3,
      COUNT_4 = 4,
    }

    export enum Operation {
      GENERATE = 0,
      IMAGE_GENERATE = 1,
      IMAGE_EDIT = 2,
      TEXTURE = 3,
      SEMANTIC = 4,
    }

    export type Options = {
      operation: Operation;
      n: Count;
      style: Generate.Style;
      size: Generate.Size;
      // generate: (0 , 4294967290), mixture [-1, 99999999]
      seed?: number;
      ref_mode: Generate.Mode;
      ref_strength?: number;
      layout_priority: Mixture.Layout;
      text_position_revise?: boolean;
      image_width: Mixture.Width | undefined;
      image_height: Mixture.Height | undefined;
      // [0.0, 2.0]
      strength?: number;
      // [0.1, 30.0]
      cfg_scale?: number;
      // [0, 1.0]
      eta?: number;
      // [1 , 100 ]
      steps?: number;
      additionalPrompt: string;
      negativePrompt: string;
    };
  }

  export namespace WordArt {
    export namespace Texture {
      export enum TextType {
        UPLOAD_IMG = 0,
        TYPING_TEXT = 1,
      }

      export namespace Style {
        export enum Custom {
          MATERIAL = "material",
          SCENE = "scene",
          LIGHTING = "lighting",
        }

        export enum Preset {
          WATERFALL = "waterfall",
          SNOW_PLATEAU = "snow_plateau",
          FOREST = "forest",
          SKY = "sky",
          CHINESE_BUILDING = "chinese_building",
          CARTOON = "cartoon",
          LEGO = "lego",
          FLOWER = "flower",
          ACRYLIC = "acrylic",
          MARBLE = "marble",
          FELT = "felt",
          OIL_PAINTING = "oil_painting",
          WATERCOLOR_PAINTING = "watercolor_painting",
          CHINESE_PAINTING = "chinese_painting",
          CLABORATE_STYLE_PAINTING = "claborate_style_painting",
          CITY_NIGHT = "city_night",
          MOUNTAIN_LAKE = "mountain_lake",
          AUTUMN_LEAVES = "autumn_leaves",
        }
      }

      export enum ShortSize {
        SIZE_512 = 512,
        SIZE_576 = 576,
        SIZE_640 = 640,
        SIZE_704 = 704,
        SIZE_768 = 768,
        SIZE_832 = 832,
        SIZE_896 = 896,
        SIZE_960 = 960,
        SIZE_1024 = 1024,
      }

      export enum Ratio {
        RATIO_1_1 = "1:1",
        RATIO_16_9 = "16:9",
        RATIO_9_16 = "9:16",
      }
    }

    export namespace Semantic {
      export enum Ratio {
        SIZE_1280_720 = "1280x720",
        SIZE_720_1280 = "720x1280",
        SIZE_1024_1024 = "1024x1024",
      }
    }

    export enum Font {
      DONG_FANG_DA_KAI = "dongfangdakai",
      PUHUITI_M = "puhuiti_m",
      SHUHEITI = "shuheiti",
      JINBUTI = "jinbuti",
      KUHEITI = "kuheiti",
      KUAILETI = "kuaileti",
      WENYITI = "wenyiti",
      LOGOTI = "logoti",
      CANGERYUYANGTI_M = "cangeryuyangti_m",
      SIYUANSONGTI_b = "siyuansongti_b",
      SIYUANHEITI_M = "siyuanheiti_m",
      FANGZHENGKAITI = "fangzhengkaiti",
      _UPLOAD_FONT = "_upload_font",
    }

    export enum Count {
      COUNT_1 = 1,
      COUNT_2 = 2,
      COUNT_3 = 3,
      COUNT_4 = 4,
    }

    export type Options = {
      text_type: Texture.TextType;
      font_name: Font | undefined;
      output_image_ratio: Texture.Ratio | Semantic.Ratio | undefined;
      text_content: string;
      texture_style: Texture.Style.Custom | Texture.Style.Preset | undefined;
      image_short_size: Texture.ShortSize;
      n: Count;
      alpha_channel: boolean;
      // [10, 100]
      steps: number | undefined;
    };
  }

  export namespace PicWish {
    export namespace Segmentation {
      export enum Type {
        PERSON = "person",
        OBJECT = "object",
        STAMP = "stamp",
        DEFAULT = "_default",
      }

      export enum OutputType {
        PICTURE = 2,
        MASK = 3,
        BOTH = 1,
      }

      export enum Crop {
        DEFAULT = 0,
        EDGE = 1,
      }

      export enum Format {
        JPG = "jpg",
        PNG = "png",
      }
    }

    export namespace Scale {
      export enum Type {
        CLEAN = "clean",
        FACE = "face",
      }

      export enum ScaleFactor {
        SCALE_1 = 1,
        SCALE_2 = 2,
        SCALE_4 = 4,
        SCALE_AUTO = "_auto",
      }

      export enum FixType {
        ALL = 0,
        FACE = 1,
      }
    }

    export enum Operation {
      SCALE = 0,
      SEGMENTATION = 1,
    }

    export type Option = {
      operation: Operation;
      image_url?: string;
      type?: Segmentation.Type | Scale.Type | undefined;
      scale_factor?: Scale.ScaleFactor;
      fix_face_only?: Scale.FixType;
      output_type?: Segmentation.OutputType;
      crop?: Segmentation.Crop;
      format?: Segmentation.Format;
      bg_color?: string;
    };
  }

  export namespace MindMap {
    export enum FontType {
      DONGFANGDAKAI = "dongfangdakai",
      PUHUITI_M = "puhuiti_m",
      SHUHEITI = "shuheiti",
      JINBUTI = "jinbuti",
      KUHEITI = "kuheiti",
      KUAILETI = "kuaileti",
      WENYITI = "wentiyi",
      LOGOTI = "logoti",
      CANGERYUYANGTI_M = "cangeryuyangti_m",
      SIYUANSONGTI_B = "siyuansongti_b",
      SIYUANHEITI_M = "siyuanheiti_m",
      FANGZHENGKAITI = "fangzhengkaiti",
    }

    export enum Count {
      COUNT_1 = 1,
      COUNT_2 = 2,
      COUNT_3 = 3,
      COUNT_4 = 4,
    }

    export enum Ratio {
      SIZE_1280_720 = "1280x720",
      SIZE_720_1280 = "720x1280",
      SIZE_1024_1024 = "1024x1024",
    }

    export type Options = {
      steps?: number;
      text?: string;
      n?: Count;
      font_name?: FontType;
      output_image_ratio?: Ratio;
    };
  }
}
