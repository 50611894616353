import ipRegex from "ip-regex";

import { SiteType } from "@/app/enums/siteType";

export const getSiteTypeByHost = function (host: string) {
  let neoHost = host.toLowerCase().split(":")[0];
  if (
    neoHost === "localhost" ||
    ipRegex({ exact: true, includeBoundaries: true }).test(neoHost)
  ) {
    return SiteType.DEV;
  }
  if (neoHost === "i.caie.ai") {
    return SiteType.NORMAL;
  }
  if (neoHost === "i.haoxueai.cn") {
    return SiteType.ISSUER;
  }
  if (neoHost === "exam.haoxueai.cn" || neoHost === "exam.caieglobal.com") {
    return SiteType.EXAM;
  }
  if (neoHost === "lab.caieglobal.com" || neoHost === "lab.haoxueai.cn") {
    return SiteType.TRAIN;
  }
  if (
    neoHost === "caie.paper666.com" ||
    neoHost === "dev-caie.paper666.com" ||
    neoHost === "dev-local.paper666.com"
  ) {
    return SiteType.PAPER666;
  }
  return SiteType.UNDETERMINED;
};
