"use client";
import { useMobileScreen } from "@/app/utils";
import { useSiteType } from "@/app/store/siteType";
import { css } from "@emotion/react";
import CaieLogoIcon from "@/app/icons/layout_v2/logo.svg";
import { LoginComponentV2 } from "@/app/components/chatComponents/v2/loginComponent";
import { SiteType } from "@/app/enums/siteType";
import { useCurrentTheme } from "@/app/hooks/chatHooks";
import { Theme, useAppConfig } from "@/app/store";
import { useShallow } from "zustand/react/shallow";
import { Option, Select } from "@mui/joy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faMoon as faMoonSolid,
  faSun as faSunSolid,
} from "@fortawesome/free-solid-svg-icons";
import {
  faMoon as faMoonRegular,
  faSun as faSunRegular,
} from "@fortawesome/free-regular-svg-icons";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Path } from "@/app/constant";
import PhoneIcon from "@/app/icons/phone.svg";
import { AppDownloadButton } from "@/app/components/chatComponents/app";

export const CAIEHeaderV2 = function () {
  const isMobileScreen = useMobileScreen();
  const site = useSiteType();
  const navigate = useNavigate();

  const toHome = useCallback(() => {
    navigate(Path.Home);
  }, [navigate]);

  if (isMobileScreen || site.siteType === SiteType.PAPER666) {
    return <></>;
  }
  return (
    <div
      css={css`
        width: 100%;
        background-color: var(--main-surface-secondary);
        height: 60px;
        min-height: 60px;
        display: flex;
        flex-shrink: 0;
        border-bottom: 1px solid var(--border-sharp);
      `}
      className={"no-dark"}
    >
      <CaieLogoIcon
        css={css`
          width: 168px;
          height: 70px;
          margin-top: auto;
          margin-bottom: auto;
          margin-left: 20px;
          cursor: pointer;
        `}
        onClick={toHome}
      />
      <div
        css={css`
          margin: auto 10px auto auto;
        `}
      >
        <AppDownloadButton />
      </div>
      <div
        className={"no-dark"}
        css={css`
          margin-top: auto;
          margin-bottom: auto;
        `}
      >
        <DarkModeSwitcher />
      </div>
      <div
        css={css`
          display: flex;
          margin-right: 20px;
        `}
      >
        <LoginComponentV2 />
      </div>
    </div>
  );
};

const DarkModeSwitcher = function () {
  const currentTheme = useCurrentTheme();
  const storedTheme = useAppConfig(useShallow((state) => state.theme));
  const [theme, setTheme] = useState(storedTheme ?? Theme.Light);

  const onThemeChange = useCallback(
    (_: React.SyntheticEvent | null, value: Theme | null) => {
      setTheme(value ?? Theme.Light);
      useAppConfig.setState({ theme: value ?? Theme.Light });
    },
    [],
  );

  return (
    <Select
      value={theme}
      onChange={onThemeChange}
      sx={{
        borderColor: "transparent",
        boxShadow: "initial",
        backgroundColor: "initial",
        "--joy-palette-neutral-outlinedColor": "var(--text-primary)",
        "--joy-palette-neutral-outlinedHoverBg": "var(--hover-color)",
      }}
      indicator={<FontAwesomeIcon icon={faCaretDown} />}
    >
      <Option
        value={Theme.Dark}
        label={
          <div className={"no-dark"}>
            <FontAwesomeIcon
              icon={faMoonSolid}
              style={{ color: "var(--neo-primary)" }}
            />
          </div>
        }
      >
        <div
          css={css`
            color: var(--text-secondary);
          `}
        >
          深色模式
        </div>
      </Option>
      <Option
        value={Theme.Light}
        label={
          <div className={"no-dark"}>
            <FontAwesomeIcon
              icon={faSunSolid}
              style={{ color: "var(--neo-primary)" }}
            />
          </div>
        }
      >
        <div
          css={css`
            color: var(--text-secondary);
          `}
        >
          浅色模式
        </div>
      </Option>
      <Option
        value={Theme.Auto}
        label={
          <div className={"no-dark"}>
            <FontAwesomeIcon
              icon={currentTheme === Theme.Dark ? faMoonRegular : faSunRegular}
            />
          </div>
        }
      >
        <div
          css={css`
            color: var(--text-secondary);
          `}
        >
          自动
        </div>
      </Option>
    </Select>
  );
};
