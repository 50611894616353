"use client";
import styles from "./pay.module.scss";

import { useNavigate, useSearchParams } from "react-router-dom";
import { Path } from "../constant";
import NextImage from "next/image";
import HeadLogoIcon from "@/app/icons/pricing/head-logo.png";
import AlipayIcon from "@/app/icons/pay/alipay-icon.png";
import WechatIcon from "@/app/icons/pay/wechat-icon.png";
import BackIcon from "@/app/icons/pay/back-icon.png";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAccessStore } from "@/app/store";
import { css } from "@emotion/react";
import { virtualCoinAmountTransform } from "@/app/utils/price";
import { showToast } from "@/app/components/ui-lib";
import { PaymentPlatform, usePaymentPlatforms } from "@/app/enums/platform";
import classNames from "classnames";

export function PayPage() {
  const navigate = useNavigate();
  const [paymentPlatform, setPaymentPlatform] = useState(
    PaymentPlatform.UNKNOWN,
  );
  const [searchParams] = useSearchParams();
  const amount = searchParams.get("amount") || "10";
  const accessStore = useAccessStore();
  const payPlatformList = usePaymentPlatforms();

  useEffect(() => {
    if (!accessStore.isAuthorized()) {
      navigate(Path.Auth, { replace: true });
    }
  }, [accessStore, navigate]);

  const priceInfo = useMemo(() => {
    return virtualCoinAmountTransform(amount);
  }, [amount]);

  const goPayCode = useCallback(() => {
    let localPlatform = paymentPlatform;
    if (priceInfo.status === "error") {
      showToast("未知档位");
      return;
    }
    if (localPlatform === PaymentPlatform.UNKNOWN) {
      if (payPlatformList.length <= 0) {
        showToast("没有有效的支付渠道，请联系客服");
        return;
      }
      if (payPlatformList.length === 1) {
        if (!payPlatformList.includes(PaymentPlatform.UNKNOWN)) {
          localPlatform = payPlatformList.at(0)!;
        } else {
          showToast("没有有效的支付方式，请联系客服");
          return;
        }
      }
      if (payPlatformList.length >= 2) {
        showToast("请选择支付方式");
        return;
      }
    }

    navigate(`${Path.PayCode}?amount=${amount}&platform=${localPlatform}`);
  }, [amount, navigate, priceInfo, paymentPlatform, payPlatformList]);

  const goHome = useCallback(() => navigate(Path.Home), [navigate]);
  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const eula = useCallback(
    function () {
      navigate(Path.EULA);
    },
    [navigate],
  );

  const clickToUseAlipay = useCallback(() => {
    setPaymentPlatform(PaymentPlatform.ALIPAY);
  }, []);

  const clickToUseWeChat = useCallback(() => {
    setPaymentPlatform(PaymentPlatform.WECHAT);
  }, []);

  const clickToUseLenovo = useCallback(() => {
    setPaymentPlatform(PaymentPlatform.LENOVO);
  }, []);

  return (
    <div className={styles["pay-page"]}>
      {/*头部*/}
      <div className="flex bg-white h-[80px] justify-center items-center">
        <div className="flex items-center ml-[18.75%] hover:opacity-75 cursor-pointer">
          <NextImage
            src={HeadLogoIcon}
            alt="头部logo"
            width={50}
            height={50}
            onClick={goHome}
          ></NextImage>
          <div className={styles["logo-title"]}>CAIE</div>
        </div>

        <div className="flex-grow"></div>
        {/*<NextImage*/}
        {/*  className="mr-[18.75%] hover:opacity-75 cursor-pointer"*/}
        {/*  src={HeadAvatarIcon}*/}
        {/*  alt="头像"*/}
        {/*  width={46}*/}
        {/*  height={46}*/}
        {/*></NextImage>*/}
      </div>
      {/*内容*/}
      <div className={styles["content-wrapper"]}>
        <div className={styles["pay_content_wrapper"]}>
          <div className={styles["pay-wrapper"] + " text-[#555555] text-base"}>
            <NextImage
              className="hover:opacity-75 cursor-pointer"
              src={BackIcon.src}
              alt="返回"
              width={20}
              height={20}
              onClick={handleBack}
            ></NextImage>
            <div className="text-[#555555] font-normal mt-4">充值算粒</div>
            <div className="flex items-end">
              <div className="font-bold">¥</div>
              <div className="text-[30px] leading-[30px]  font-bold ml-[7px] mt-[6px] mr-[5px]">
                {priceInfo.status === "error" ? "?" : amount}
              </div>
              <div
                css={css`
                  margin-left: 20px;
                  font-size: 20px;
                `}
              >
                {priceInfo.coin}算粒（{priceInfo.description}）
              </div>
            </div>
            <div className="mt-[62px] font-normal">支付信息</div>
            <div className="flex gap-[20px] h-[66px]">
              {payPlatformList.includes(PaymentPlatform.ALIPAY) && (
                <div
                  className={classNames({
                    [styles["pay-item-active"]]:
                      paymentPlatform === PaymentPlatform.ALIPAY,
                    [styles["pay-item"]]:
                      paymentPlatform !== PaymentPlatform.ALIPAY,
                  })}
                  onClick={clickToUseAlipay}
                >
                  <NextImage
                    src={AlipayIcon.src}
                    alt="支付宝"
                    width={27}
                    height={27}
                  ></NextImage>
                  <div className="text-[#555555] font-normal text-sm">
                    支付宝
                  </div>
                </div>
              )}
              {payPlatformList.includes(PaymentPlatform.WECHAT) && (
                <div
                  className={classNames({
                    [styles["pay-item-active"]]:
                      paymentPlatform === PaymentPlatform.WECHAT,
                    [styles["pay-item"]]:
                      paymentPlatform !== PaymentPlatform.WECHAT,
                  })}
                  onClick={clickToUseWeChat}
                >
                  <NextImage
                    src={WechatIcon.src}
                    alt="微信"
                    width={27}
                    height={27}
                  ></NextImage>
                  <div className="text-[#555555] font-normal text-sm">微信</div>
                </div>
              )}
              {payPlatformList.includes(PaymentPlatform.LENOVO) && (
                <div
                  className={classNames({
                    [styles["pay-item-active"]]:
                      paymentPlatform === PaymentPlatform.LENOVO,
                    [styles["pay-item"]]:
                      paymentPlatform !== PaymentPlatform.LENOVO,
                  })}
                  onClick={clickToUseLenovo}
                >
                  <div
                    css={css`
                      width: 27px;
                      height: 27px;
                      content: " ";
                    `}
                  ></div>
                  <div className="text-[#555555] font-normal text-sm">联想</div>
                </div>
              )}
            </div>
            <div
              className={styles["subscribe-action"]}
              onClick={goPayCode}
              css={
                priceInfo.status === "error" &&
                css`
                  background: var(--gray-500) !important;
                `
              }
            >
              立即订阅
            </div>
            <div className="flex gap-[30px] text-[#999999] text-14px mt-[30px] w-full justify-center">
              <div className="cursor-pointer" onClick={eula}>
                条款
              </div>
              <div className="cursor-pointer" onClick={eula}>
                隐私
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
