"use client";
import {
  ChatImageModelType,
  ChatImageOperation,
  ChatImageQuality,
  ChatImageSize,
  ChatImageVariant,
  ImageUploadOptions,
} from "@/app/enums/chat";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { match } from "ts-pattern";
import { UploadFileType, useUploadFileStore } from "@/app/store/uploadFiles";
import { css } from "@emotion/react";
import { message, Modal, Select } from "antd";
import {
  ImageInputBlock,
  MaskImageDisplayBlock,
} from "@/app/components/chatComponents/wanx";
import { useChatStore } from "@/app/store";
import { EraseImageCanvas } from "@/app/components/chatComponents/draw";
import { getWidthByWindow, releaseObjectUrl } from "@/app/utils";
import { useImmer } from "use-immer";
import { useDebouncedCallback } from "use-debounce";
import { produce } from "immer";
import { useShallow } from "zustand/react/shallow";
import {
  AdditionalSettingsPopUp,
  AdditionSettingItem,
  ChatInputAboveButtonsContainer,
  CommonPromptContainerBlock,
  CommonPromptInputBlock,
} from "@/app/components/chatComponents/commonLib";
import { useSubmit, useUpdatePendingFileByType } from "@/app/hooks/chatHooks";
import { useBalanceStr } from "@/app/utils/userUsage";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import { eventBus } from "@/app/utils/eventBus";
import { EventBus } from "@/app/enums/eventBusName";
import { LayoutType } from "@/app/enums/layoutType";
import {
  InputMenus,
  InputMenusWrapper,
} from "@/app/components/chatComponents/v2/InputMenus";
import { SmallClickInput } from "@/app/components/chatComponents/v2/InputComponents";
import ChatPicIcon from "@/app/icons/layout_v2/chat/pic.svg";
import { MenuItem } from "@mui/joy";
import { ConversationIdContext } from "@/app/contexts/chatIdContext";

export const ImageAdditionalArea = function (props: { usageStr?: string }) {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)!),
  );
  const [imageOption, updateImageOption] = useImmer(session.imageOptions);
  const layout = useLayoutConfigStore();
  const debouncedImageOptionUpdate = useDebouncedCallback(
    (option: ImageUploadOptions) => {
      useChatStore.getState().updateCurrentSession((session1) => {
        session1.imageOptions = option;
      }, conversationId);
    },
    100,
  );

  useEffect(() => {
    if (!session.imageOptions) {
      const defaultOptions = {
        operation: ChatImageOperation.CREATE,
        model: ChatImageModelType.CAIE_E_2,
        imageSize: ChatImageSize.SIZE_256_256,
        quality: ChatImageQuality.STANDARD,
        variant: ChatImageVariant.VIVID,
      };
      useChatStore.getState().updateCurrentSession((session1) => {
        session1.imageOptions = defaultOptions;
      }, conversationId);
      updateImageOption(defaultOptions);
    }
  }, [session.imageOptions, updateImageOption, conversationId]);

  const updateFn = (option: ImageUploadOptions) => {
    updateImageOption(option);
    debouncedImageOptionUpdate(option);
  };

  const changeImageOptions = {
    operation(value: ChatImageOperation) {
      updateFn(
        produce(imageOption!, (op) => {
          op.operation = value;
          if (
            !modelOptions({ operations: value }).find(
              (it) => it.value === imageOption!.model,
            )
          ) {
            op.model = ChatImageModelType.CAIE_E_2;
            if (
              !qualityOptions({ model: ChatImageModelType.CAIE_E_2 }).find(
                (it) => it.value === op.quality,
              )
            ) {
              op.quality = ChatImageQuality.STANDARD;
            }
            if (
              !variantOptions({ model: ChatImageModelType.CAIE_E_2 }).find(
                (it) => it.value === op.variant,
              )
            ) {
              op.variant = ChatImageVariant.VIVID;
            }
            if (
              !imageSizeOptions({ model: ChatImageModelType.CAIE_E_2 }).find(
                (it) => it.value === op.imageSize,
              )
            ) {
              op.imageSize = ChatImageSize.SIZE_256_256;
            }
          }
        }),
      );
    },
    model(value: ChatImageModelType) {
      match(value)
        .with(ChatImageModelType.CAIE_E_2, () => {
          updateFn(
            produce(imageOption!, (op) => {
              op.model = value;
              if (
                !qualityOptions({ model: value }).find(
                  (it) => it.value === op.quality,
                )
              ) {
                op.quality = ChatImageQuality.STANDARD;
              }
              if (
                !variantOptions({ model: value }).find(
                  (it) => it.value === op.variant,
                )
              ) {
                op.variant = ChatImageVariant.VIVID;
              }
              if (
                !imageSizeOptions({ model: value }).find(
                  (it) => it.value === op.imageSize,
                )
              ) {
                op.imageSize = ChatImageSize.SIZE_256_256;
              }
            }),
          );
        })
        .with(ChatImageModelType.CAIE_E_3, () => {
          updateFn(
            produce(imageOption!, (op) => {
              op.model = value;
              if (
                !imageSizeOptions({ model: value }).find(
                  (it) => it.value === op.imageSize,
                )
              ) {
                op.imageSize = ChatImageSize.SIZE_1024_1024;
              }
            }),
          );
        })
        .otherwise(() => {});
    },
    imageSize(value: ChatImageSize) {
      updateFn(
        produce(imageOption!, (op) => {
          op.imageSize = value;
        }),
      );
    },
    quality(value: ChatImageQuality) {
      updateFn(
        produce(imageOption!, (op) => {
          op.quality = value;
        }),
      );
    },
    variant(value: ChatImageVariant) {
      updateFn(
        produce(imageOption!, (op) => {
          op.variant = value;
        }),
      );
    },
  };

  const modelOptions = useCallback(
    (props: { operations?: ChatImageOperation }) => {
      return match(props.operations)
        .with(ChatImageOperation.CREATE, () => [
          { value: ChatImageModelType.CAIE_E_2, label: "CAIE-E-2" },
          { value: ChatImageModelType.CAIE_E_3, label: "CAIE-E-3" },
        ])
        .with(ChatImageOperation.EDITING, () => [
          { value: ChatImageModelType.CAIE_E_2, label: "CAIE-E-2" },
        ])
        .with(ChatImageOperation.VARIATION, () => [
          { value: ChatImageModelType.CAIE_E_2, label: "CAIE-E-2" },
        ])
        .otherwise(() => [
          // empty list
        ]);
    },
    [],
  );

  const imageSizeOptions = useCallback(
    (props: { model?: ChatImageModelType }) => {
      return match(props.model)
        .with(ChatImageModelType.CAIE_E_2, () => [
          { value: ChatImageSize.SIZE_256_256, label: "256×256" },
          { value: ChatImageSize.SIZE_512_512, label: "512×512" },
          { value: ChatImageSize.SIZE_1024_1024, label: "1024×1024" },
        ])
        .with(ChatImageModelType.CAIE_E_3, () => [
          { value: ChatImageSize.SIZE_1024_1024, label: "1024×1024" },
          { value: ChatImageSize.SIZE_1792_1024, label: "1792×1024" },
          { value: ChatImageSize.SIZE_1024_1792, label: "1024×1792" },
        ])
        .otherwise(() => [
          // empty list
        ]);
    },
    [],
  );

  const qualityOptions = useCallback(
    (props: { model?: ChatImageModelType }) => {
      return match(props.model)
        .with(ChatImageModelType.CAIE_E_2, () => [
          { value: ChatImageQuality.STANDARD, label: "标准" },
        ])
        .with(ChatImageModelType.CAIE_E_3, () => [
          { value: ChatImageQuality.STANDARD, label: "标准" },
          { value: ChatImageQuality.HD, label: "高清" },
        ])
        .otherwise(() => [
          // empty list
        ]);
    },
    [],
  );

  const variantOptions = useCallback(
    (props: { model?: ChatImageModelType }) => {
      return match(props.model)
        .with(ChatImageModelType.CAIE_E_2, () => [])
        .with(ChatImageModelType.CAIE_E_3, () => [
          { value: ChatImageVariant.VIVID, label: "生动" },
          { value: ChatImageVariant.NATURAL, label: "自然" },
        ])
        .otherwise(() => [
          // empty list
        ]);
    },
    [],
  );

  const selectOptions = {
    imageSize: () => {
      return (
        <Select
          value={imageOption?.imageSize}
          key={"image_size"}
          style={{ width: "100%", height: 28 }}
          popupMatchSelectWidth={true}
          placement={"topLeft"}
          placeholder={"图片尺寸"}
          onChange={changeImageOptions.imageSize}
          options={imageSizeOptions({ model: imageOption?.model })}
        />
      );
    },
    quality: () => {
      return (
        <Select
          value={imageOption?.quality}
          key={"quality"}
          style={{ width: "100%", height: 28 }}
          popupMatchSelectWidth={true}
          placement={"topLeft"}
          placeholder={"清晰度"}
          onChange={changeImageOptions.quality}
          options={qualityOptions({ model: imageOption?.model })}
        />
      );
    },
    variant: () => {
      return (
        <Select
          value={imageOption?.variant}
          key={"variant"}
          style={{ width: "100%", height: 28 }}
          popupMatchSelectWidth={true}
          placement={"topLeft"}
          placeholder={"风格"}
          onChange={changeImageOptions.variant}
          options={variantOptions({ model: imageOption?.model })}
        />
      );
    },
    model: () => {
      return (
        <Select
          value={imageOption?.model}
          key={"model"}
          style={{ width: "100%", height: 28 }}
          popupMatchSelectWidth={true}
          placement={"topLeft"}
          placeholder={"模型"}
          onChange={changeImageOptions.model}
          options={modelOptions({ operations: imageOption?.operation })}
        />
      );
    },
  };

  const showSelect = match(imageOption?.model)
    .with(ChatImageModelType.CAIE_E_2, () => {
      return (
        <div
          css={[
            css`
              margin-left: 10px;
            `,
            layout.type === LayoutType.Layout_v1 &&
              css`
                height: 28px;
              `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                height: 100%;
                display: flex;
                align-items: center;
              `,
          ]}
        >
          <AdditionalSettingsPopUp>
            <AdditionSettingItem title={"模型"}>
              {selectOptions.model()}
            </AdditionSettingItem>
            <AdditionSettingItem title={"图片尺寸"}>
              {selectOptions.imageSize()}
            </AdditionSettingItem>
          </AdditionalSettingsPopUp>
        </div>
      );
    })
    .with(ChatImageModelType.CAIE_E_3, () => {
      return (
        <div
          css={[
            css`
              margin-left: 10px;
            `,
            layout.type === LayoutType.Layout_v1 &&
              css`
                height: 28px;
              `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                height: 100%;
                display: flex;
                align-items: center;
              `,
          ]}
        >
          <AdditionalSettingsPopUp>
            <AdditionSettingItem title={"模型"}>
              {selectOptions.model()}
            </AdditionSettingItem>
            <AdditionSettingItem title={"图片尺寸"}>
              {selectOptions.imageSize()}
            </AdditionSettingItem>
            <AdditionSettingItem title={"清晰度"}>
              {selectOptions.quality()}
            </AdditionSettingItem>
            <AdditionSettingItem title={"风格"}>
              {selectOptions.variant()}
            </AdditionSettingItem>
          </AdditionalSettingsPopUp>
        </div>
      );
    })
    .otherwise(() => <></>);

  return (
    <div
      css={[
        css`
          max-height: 90px;
          position: relative;
        `,
        layout.type === LayoutType.Layout_v2 &&
          css`
            height: 100%;
          `,
      ]}
    >
      <div
        css={[
          css`
            display: flex;
          `,
          layout.type === LayoutType.Layout_v1 &&
            css`
              position: absolute;
              top: -37px;
              left: 0;
              width: 100%;
              height: 37px;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              height: 100%;
            `,
        ]}
      >
        <div
          css={css`
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            min-width: 0;
            flex-shrink: 1;
            flex-grow: 1;

            &::-webkit-scrollbar {
              width: 0;
              height: 0;
            }
          `}
        >
          <Select
            value={imageOption?.operation}
            key={"operation"}
            style={{
              width: 78,
              height: 28,
              marginTop:
                layout.type === LayoutType.Layout_v2 ? "auto" : undefined,
              marginBottom:
                layout.type === LayoutType.Layout_v2 ? "auto" : undefined,
            }}
            popupMatchSelectWidth={true}
            placement={"topLeft"}
            onChange={changeImageOptions.operation}
            options={[
              { value: ChatImageOperation.CREATE, label: "生成" },
              { value: ChatImageOperation.EDITING, label: "编辑" },
              { value: ChatImageOperation.VARIATION, label: "变体" },
            ]}
            css={
              layout.type === LayoutType.Layout_v2 &&
              css`
                margin-top: auto;
                margin-bottom: auto;
              `
            }
          />
          {showSelect}
        </div>
        {layout.type === LayoutType.Layout_v1 && (
          <div
            css={css`
              height: 0;
              overflow-y: hidden;
              flex-shrink: 0;
              pointer-events: none;
            `}
          >
            {props.usageStr}
          </div>
        )}
      </div>
    </div>
  );
};
export const ImageEditUploadArea = memo(function ImageEditUploadArea() {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)!),
  );
  const uploadFilesStore = useUploadFileStore();
  const [showImageEditor, setShowImageEditor] = useState(false);
  const originalImage = useRef("");
  const layout = useLayoutConfigStore();

  const baseFile = (function () {
    return (
      uploadFilesStore
        .getPendingFileList(session!.id)
        .find((it) => it.fileType === UploadFileType.DALL_E_ORIGINAL) ?? {
        uploadId: -1,
        uuid: "",
        fileName: "",
        fileType: UploadFileType.DALL_E_ORIGINAL,
      }
    );
  })();

  const maskFile = (function () {
    return (
      uploadFilesStore
        .getPendingFileList(session!.id)
        .find((it) => it.fileType === UploadFileType.DALL_E_MASK) ?? {
        uploadId: -1,
        uuid: "",
        fileName: "",
        fileType: UploadFileType.DALL_E_MASK,
      }
    );
  })();

  const [messages, messageContextHolder] = message.useMessage();

  const updatePendingFileByType = useUpdatePendingFileByType({
    onError(e) {
      void messages.error(e.message);
    },
  });

  const deleteImageByType = function (type: UploadFileType | UploadFileType[]) {
    const pendingList = uploadFilesStore.getPendingFileList(session!.id);
    const items = produce(pendingList, (pendingList) => {
      return pendingList.filter((it) => {
        if (type instanceof Array) {
          if (it.fileType === undefined) {
            return false;
          }
          return type.includes(it.fileType);
        }
        return type === it.fileType;
      });
    });
    if (!items || items.length <= 0) {
      return;
    }
    const neoPendingList = produce(pendingList, (pendingList) => {
      return pendingList.filter((it) => {
        return items.find((i) => it.uuid === i.uuid) === undefined;
      });
    });
    uploadFilesStore.updatePendingFileList(neoPendingList, session.id);
    items.forEach((it) => {
      if (it.uploadId !== undefined && it.uploadId > 0) {
        useChatStore.getState().deleteChatFile(it.uploadId);
      }
    });
  };

  const uploadOriginalImageFunc = useCallback(
    (file: File) => {
      updatePendingFileByType(file, UploadFileType.DALL_E_ORIGINAL);
      if (session.imageOptions?.operation === ChatImageOperation.EDITING) {
        originalImage.current = URL.createObjectURL(file);
        setShowImageEditor(true);
      }
    },
    [session, updatePendingFileByType],
  );

  useEffect(() => {
    eventBus.on(
      EventBus.FileInput.ImageInput.Action.ACTION_INPUT,
      uploadOriginalImageFunc,
    );
    return () => {
      eventBus.off(
        EventBus.FileInput.ImageInput.Action.ACTION_INPUT,
        uploadOriginalImageFunc,
      );
    };
  }, [uploadOriginalImageFunc]);

  return (
    <>
      {messageContextHolder}
      {(layout.type === LayoutType.Layout_v1 ||
        (layout.type === LayoutType.Layout_v2 &&
          (baseFile.imageUrl || maskFile.imageUrl))) && (
        <div
          css={[
            css`
              display: flex;
              padding-top: 12px;
              padding-bottom: 12px;
            `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                margin-left: 12px;
              `,
          ]}
        >
          <div
            css={[
              layout.type === LayoutType.Layout_v1 &&
                css`
                  width: 80px;
                  height: 80px;
                `,
              layout.type === LayoutType.Layout_v2 &&
                css`
                  width: 40px;
                  height: 40px;
                `,
            ]}
          >
            <ImageInputBlock
              imageUrl={baseFile.imageUrl}
              titleStr={"上传图片"}
              onChange={uploadOriginalImageFunc}
              onDelete={() => {
                if (
                  session.imageOptions?.operation === ChatImageOperation.EDITING
                ) {
                  deleteImageByType([
                    UploadFileType.DALL_E_MASK,
                    UploadFileType.DALL_E_ORIGINAL,
                  ]);
                } else {
                  deleteImageByType(UploadFileType.DALL_E_ORIGINAL);
                }
              }}
            />
          </div>
          {session.imageOptions?.operation === ChatImageOperation.EDITING && (
            <>
              {layout.type === LayoutType.Layout_v2 && (
                <div
                  css={css`
                    content: " ";
                    width: 1px;
                    height: 40px;
                    background-color: #ecf5fa;
                    margin: auto 16px;
                  `}
                ></div>
              )}
              {(layout.type === LayoutType.Layout_v1 ||
                (layout.type === LayoutType.Layout_v2 &&
                  maskFile.imageUrl &&
                  maskFile.imageUrl !== "")) && (
                <div
                  css={[
                    layout.type === LayoutType.Layout_v1 &&
                      css`
                        width: 80px;
                        height: 80px;
                        margin-left: 8px;
                      `,
                    layout.type === LayoutType.Layout_v2 &&
                      css`
                        width: 40px;
                        height: 40px;
                      `,
                  ]}
                >
                  <MaskImageDisplayBlock
                    imageUrl={maskFile.imageUrl}
                    titleStr={"遮罩图"}
                    onEdit={() => {
                      if (!baseFile.imageUrl) {
                        return;
                      }
                      originalImage.current = baseFile.imageUrl;
                      setShowImageEditor(true);
                    }}
                    onDelete={() => {
                      deleteImageByType(UploadFileType.DALL_E_MASK);
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
      <Modal
        open={showImageEditor}
        width={getWidthByWindow(548, -80)}
        footer={null}
        closable={false}
        destroyOnClose={true}
      >
        <EraseImageCanvas
          type={"erase"}
          url={originalImage.current}
          onEditComplete={(file) => {
            setShowImageEditor(false);
            updatePendingFileByType(file, UploadFileType.DALL_E_MASK);
            releaseObjectUrl(originalImage.current);
            originalImage.current = "";
          }}
          onEditCancel={() => {
            setShowImageEditor(false);
            releaseObjectUrl(originalImage.current);
            originalImage.current = "";
          }}
        />
      </Modal>
    </>
  );
});

export const ImageInputArea = function () {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)!),
  );
  const uploadFileStore = useUploadFileStore();
  const [showMessage, messageContextHolder] = message.useMessage();
  const [userInput, setUserInput] = useState("");
  const doSubmit = useSubmit();
  const usageStr = useBalanceStr();
  const layout = useLayoutConfigStore();

  let imageSelectBlock = <></>;
  let showImage = false;
  if (
    session.imageOptions?.operation === ChatImageOperation.EDITING ||
    session.imageOptions?.operation === ChatImageOperation.VARIATION
  ) {
    imageSelectBlock = <ImageEditUploadArea></ImageEditUploadArea>;
    showImage = true;
  }

  const submit = useCallback(
    (ignoreEmptyInput: boolean) => {
      const list = uploadFileStore.getPendingFileList(session.id);
      if (session.imageOptions!.operation === ChatImageOperation.EDITING) {
        const originalImage = list.find(
          (it) => it.fileType === UploadFileType.DALL_E_ORIGINAL,
        );
        const maskImage = list.find(
          (it) => it.fileType === UploadFileType.DALL_E_MASK,
        );
        if (!originalImage || !maskImage) {
          void showMessage.error("请上传原图与遮罩图");
          return;
        }
      } else if (
        session.imageOptions!.operation === ChatImageOperation.VARIATION
      ) {
        const originalImage = list.find(
          (it) => it.fileType === UploadFileType.DALL_E_ORIGINAL,
        );
        if (!originalImage) {
          void showMessage.error("请上传图片");
          return;
        }
      }
      doSubmit(userInput, ignoreEmptyInput);
      setUserInput("");
    },
    [session, showMessage, uploadFileStore, userInput, doSubmit],
  );

  const updateUserInput = useCallback((value: string) => {
    setUserInput(value);
  }, []);

  return (
    <>
      {messageContextHolder}
      <CommonPromptContainerBlock usageStr={usageStr}>
        {layout.type === LayoutType.Layout_v1 && (
          <ImageAdditionalArea usageStr={usageStr} />
        )}
        {layout.type === LayoutType.Layout_v2 && (
          <ChatInputAboveButtonsContainer>
            <ImageAdditionalArea />
          </ChatInputAboveButtonsContainer>
        )}
        {layout.type === LayoutType.Layout_v1 && imageSelectBlock}
        <CommonPromptInputBlock
          updatePrompts={updateUserInput}
          submit={submit}
          inputAboveArea={imageSelectBlock}
        >
          {layout.type === LayoutType.Layout_v2 && showImage && (
            <InputMenusWrapper left>
              <InputMenus>
                <MenuItem>
                  <ImageUploadButtonV2 />
                </MenuItem>
              </InputMenus>
            </InputMenusWrapper>
          )}
        </CommonPromptInputBlock>
      </CommonPromptContainerBlock>
    </>
  );
};

const ImageUploadButtonV2 = function () {
  const upload = useCallback((fileList: File[]) => {
    const file = fileList[0];
    if (file) {
      eventBus.emit(EventBus.FileInput.ImageInput.Action.ACTION_INPUT, file);
    }
  }, []);

  return (
    <SmallClickInput title={"上传图片"} accept={"image/*"} onChange={upload}>
      <ChatPicIcon
        css={css`
          width: 20px;
          height: 20px;
          margin-top: auto;
          margin-bottom: auto;

          path {
            fill: #4b5562;
          }
        `}
      />
    </SmallClickInput>
  );
};
