"use client";
import { useSiteType } from "@/app/store/siteType";
import { getSiteTypeByHost } from "@/app/utils/getSiteType";
import { SiteType } from "@/app/enums/siteType";
import { LayoutType } from "@/app/enums/layoutType";

export const getLayoutConfig = function () {
  let siteType = useSiteType.getState().siteType;
  if (typeof window !== "undefined") {
    siteType = getSiteTypeByHost(window.location.hostname);
  }
  if (siteType === SiteType.EXAM || siteType === SiteType.TRAIN) {
    return LayoutType.Layout_v1;
  }
  if (siteType === SiteType.PAPER666) {
    return LayoutType.Layout_v2;
  }
  if (siteType === SiteType.DEV) {
    return LayoutType.Layout_v2;
  }
  return LayoutType.Layout_v2;
};
