'use client'


import classNames from "classnames";

export const EULA = function () {


    return (
        <div className={classNames('max-w-[1200px] overflow-y-scroll max-h-[100%] mt-[100px]')}>
            <div className={classNames('text-[30px] font-bold')}>
                服务协议和隐私政策
            </div>
            <p>更新时间：2024年01月18日</p>
            <p></p>
            <p>开发者：广西数新网络科技有限公司</p>
            <p></p>
            <p>CAIE-GPT的软件和/或服务是由广西数新网络科技有限公司（以下简称“本
                平台”或“我们”），基于本平台所提供的网络服务的重要性，用户（以下简称“您”）
                为获得网络服务，应同意接受《服务协议和隐私政策》（以下称“本协议”）的全
                部条款，并按照页面上的操作规则完成全部的注册程序。</p>
            <p className={classNames('font-bold')}>
                1、服务使用说明
            </p>
            <p>（1）用户须对其在本平台的注册信息的真实性、合法性、有效性承担责任。用
                户不得以任何方式(包括但不限于冒用他人姓名/其他组织机构名称、头像、昵称
                等）冒充他人或其他组织机构；不得利用他人、其他组织机构的名义发布任何信
                息；不得恶意使用注册账户导致其他用户将其误认为他人或其他组织机构。如用
                户违反前述约定，本平台有权随时终止对该用户提供服务、收回其账号，并由该
                用户独立承担由此而产生的一切法律责任。</p>
            <p>（2）用户一旦注册成功，成为本平台的合法用户，将得到一个用户帐号和相应
                密码。用户将对用户帐号和密码安全负全部责任。另外，每个用户都要对以其用
                户名进行的所有活动和事件负全部法律责任。用户可随时根据指示改变您的密码。
                用户若发现任何非法使用用户帐号或存在安全漏洞的情况，请立即通知本平台。</p>
            <p>（3）本平台不对用户所发布信息的删除或储存失败负责。本平台有判定用户的
                行为是否符合本平台服务条款的要求和精神的权利，如果用户违背了服务条款的
                规定，本平台有中断对其提供网络服务的权利。</p>
            <p>（4）所有发给用户的通知（包括但不限于服务条款的修改、服务内容的变更以
                及其它重要事件）都可通过重要页面的公告或电子邮件或常规的信件的形式传送。</p>
            <p>（6）本平台提供账号删除服务，如果您要删除账号，请通过平台上的联系我们
                板块扫码添加微信进行沟通，我们将在15个工作日内处理。</p>
            <p className={classNames('font-bold')}>
                2、服务条款的修改和服务的中断与终止
            </p>
            <p>（1）本平台有权在必要时修改服务条款，本平台服务条款一旦发生变动，将会
                在重要页面上提示修改内容。如果不同意所改动的内容，用户有权停止使用网络
                服务。如果用户继续享用网络服务，则视为接受服务条款的变动。本平台保留随
                时修改服务或中断服务而无需通知用户的权利。本平台行使修改或中断服务的权
                利，无需对用户或第三方负任何责任。</p>
            <p>（2）本平台可随时根据实际情况中断或终止一项或多项网络服务而无需对任何
                用户或第三方承担任何责任，如用户对一项或多项网络服务的中断或终止有异议
                或不满，可以行使如下权利：</p>
            <div className={'ml-[20px]'}>
                <p>1)停止使用本平台的网络服务；</p>
                <p>2)通知本平台停止对该用户的服务。结束用户服务后，用户使用网络服务的权
                    利立即终止，从终止时起，用户没有权利，本平台也没有义务传送任何未处理的
                    信息或未完成的服务给用户或任何第三方。</p>
            </div>
            <p>（3）如果用户提供的资料不真实或违反本协议中的使用规则，本平台有权随时
                中断或终止为用户提供的网络服务而无需通知用户、无需对用户或任何第三方承
                担任何责任。</p>
            <p className={classNames('font-bold')}>
                3、用户的责任与义务
            </p>
            <p>（1）用户在使用本平台服务的过程中，负有以下责任与义务：</p>
            <div className={'ml-[20px]'}>
                <p>A.使用网络应遵守中国相关法律法规的规定；</p>
                <p>B.不得以任何非法目的使用网络服务；</p>
                <p>C.不干扰或混乱网络服务；</p>
                <p>D.遵守所有使用网络服务的网络协议、规定、程序和惯例；</p>
                <p>E.用户须承诺不得传输任何非法的、骚扰性的、中伤他人的、辱骂性的、恐吓
                    性的、伤害性的、庸俗的，淫秽的信息资料；</p>
                <p>F.用户不得传输任何教唆他人犯罪的信息和资料；</p>
                <p>G.不得传输助长国内不利条件和涉及国家安全的资料；</p>
                <p>H.不得未经许可而非法进入其它电脑系统；</p>
                <p>I.不得传播反动的、色情的信息和资料。</p>
            </div>
            <p>（2）若用户的行为违反上述责任与义务，本平台有权作出独立判断并立即取消
                用户的服务帐号，用户必须对自己在网上的行为承担一切法律责任，而且本平台
                的系统记录有可能作为用户违反法律的证据。</p>
            <p>（3）用户同意保障和维护本平台全体成员及其他用户的利益，如因违反本协议
                或违反有关的法律法规而给本平台或任何第三人造成损失，用户应承担因此产生
                的一切赔偿责任。</p>
            <p className={classNames('font-bold')}>
                4、网络服务内容的所有权
            </p>
            <p>（1）本平台定义的网络服务内容包括：文字、软件、声音、图片、录象、图表
                等。所有这些内容受著作权法、商标法、专利法及其他相关法律的保护。</p>
            <p>（2）用户只能在本平台或相关权利人授权下才能使用这些内容，而不能擅自复
                制、再造这些内容、或创造与内容有关的派生产品。</p>
            <p className={classNames('font-bold')}>5、免责条款</p>
            <p>（1）用户同意承担使用本平台服务所存在的一切风险以及因使用网络服务而产
                生的一切后果，本平台对用户不承担任何责任。</p>
            <p>（2）本平台不担保服务一定能满足用户的要求，也不担保服务不会受中断，对
                服务的及时性，安全性，及发生的错误都不作担保。本平台对在本平台上得到的
                任何商品购物服务或交易进程，不作任何担保。</p>
            <p>（3）本平台对任何直接、间接、偶然、特殊及继起的损害不负责任。这些损害
                可能来自：不正当使用网络服务，在网上购买商品或进行同类型服务，在网上进
                行交易，非法使用网络服务或用户传送的信息有所变动等。</p>
            <p>（4）用户在他们发表的信息中加入宣传资料或参与广告策划，在本平台的免费
                服务上展示他们的产品，任何这类促销方法，包括运输货物、付款、服务、商业
                条件、担保及与广告有关的描述都只是在相应的用户和广告销售商之间发生，本
                平台不对此类行为承担任何责任。</p>
            <p className={classNames('font-bold')}>6、法律的适用和管辖</p>
            <p>本协议的订立、履行解释以及因此而发生的一切争议，均适用中华人民共和国的
                法律。就本协议所发生的一切争议，双方应通过友好协商解决，协商不成，任何
                一方均可向本平台所在地人民法院提起诉讼。</p>
            <p className={classNames('font-bold')}>7、如何联系我们</p>
            <p>如果您对本服务协议有任何疑问、意见或建议，通过以下方式与我们联系：
                CAIEGPT平台——联系我们——扫码添加企微
                一般情况下，我们将在15个工作日内回复。</p>
        </div>
    )
}
