"use client";
import { useDebouncedCallback } from "use-debounce";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
  type JSX,
} from "react";
import BrainIcon from "../icons/brain.svg";
import AiAvatarIcon from "../icons/chat/ai-avatar.png";
import UserAvatarIcon from "../icons/chat/user-avatar.png";
import CopyIcon from "../icons/copy.svg";
import LoadingIcon from "../icons/three-dots.svg";
import PromptIcon from "../icons/prompt.svg";
import MaskIcon from "../icons/mask.svg";
import MaxIcon from "../icons/chat/max-icon.png";
import MinIcon from "../icons/chat/min-icon.png";
import ResetIcon from "../icons/reload.svg";
import BreakIcon from "../icons/break.svg";
import SettingsIcon from "../icons/chat-settings.svg";
import LightIcon from "../icons/light.svg";
import DarkIcon from "../icons/dark.svg";
import AutoIcon from "../icons/auto.svg";
import BottomIcon from "../icons/bottom.svg";
import StopIcon from "../icons/pause.svg";
import BackIcon from "../icons/chat/back.png";
import FolderIcon from "../icons/chat/folder-icon.png";
import ExamAvatarIcon from "@/app/icons/exam/avatar.png";
import ExamUserAvatarIcon from "@/app/icons/exam/user_avatar.png";
import EarthIcon from "@/app/icons/layout_v2/chat/earth.svg";
import LangIcon from "@/app/icons/layout_v2/chat/i18n.svg";
import GPTIcon from "@/app/icons/layout_v2/chat/headicon.svg";
import { MenuItem, Option as JoyOption, Select as JoySelect } from "@mui/joy";
import NextImage from "next/image";
import {
  BOT_HELLO,
  ChatMessage,
  createMessage,
  DEFAULT_TOPIC,
  DEFAULT_TOPIC_V2,
  Theme,
  useAppConfig,
  useChatStore,
} from "../store";

import {
  copyToClipboard,
  formatDateTimeFunc,
  getImageWithSiteType,
  useMobileScreen,
} from "../utils";

import dynamic from "next/dynamic";

import { ChatControllerPool } from "../client/controller";
import { Prompt, usePromptStore } from "../store/prompt";
import Locale from "../locales";
import { message, Modal as AntModal, Select, Switch, Tooltip } from "antd";

import { IconButton } from "./button";
import styles from "./home.module.scss";
import chatStyle from "./chat.module.scss";

import { ListItem, Modal } from "./ui-lib";
import { useLocation, useNavigate } from "react-router-dom";
import { LAST_INPUT_KEY, Path } from "../constant";
import { MaskConfig } from "./mask";
import { useMaskStore } from "../store/mask";
import { getClientConfig } from "../config/client";
// import "tailwindcss/tailwind.css";
import { useUserStore } from "@/app/store/user";
import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import { css } from "@emotion/react";
import CloseSvgIcon from "../icons/close.svg";
import { UploadFileType, useUploadFileStore } from "@/app/store/uploadFiles";
import { useSiteType } from "@/app/store/siteType";
import { StableDiffusionInputArea } from "@/app/components/chatComponents/stableDiffusion";
import { PPTInputBlock } from "@/app/components/chatComponents/ppt";
import { ImageInputArea } from "@/app/components/chatComponents/image";
import {
  ChatFileListComponent,
  ChatFileUploadButton,
  ChatFileUploadButtonV2,
  FileUploadItem,
  UploadHistoryListItem,
} from "@/app/components/chatComponents/files";
import { WanxImageGenerateInputBlock } from "@/app/components/chatComponents/wanx";
import { PicWishImageGenerateInputBlock } from "@/app/components/chatComponents/picwish";
import { useBalanceStr } from "@/app/utils/userUsage";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useShallow } from "zustand/react/shallow";
import { MindMapInputArea } from "@/app/components/chatComponents/mindmap";
import { useVirtualizer } from "@tanstack/react-virtual";
import { create } from "zustand";
import { useImmer } from "use-immer";
import { eventBus } from "@/app/utils/eventBus";
import { EventBus } from "@/app/enums/eventBusName";
import {
  useCurrentTheme,
  useHandleUsageTypeChange,
  useModelSelectOptions,
  useSearchWiderLayout,
  useSubmit,
} from "@/app/hooks/chatHooks";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import isEqual from "react-fast-compare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookBookmark,
  faCaretDown,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import MessageDeleteIcon from "@/app/icons/layout_v2/chat/message/delete.svg";
import MessageRetryIcon from "@/app/icons/layout_v2/chat/message/retry.svg";
import MessageCopyIcon from "@/app/icons/layout_v2/chat/message/copy.svg";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { tooltipFix } from "@/app/utils/tooltipFix";
import {
  InputMenus,
  InputMenusWrapper,
} from "@/app/components/chatComponents/v2/InputMenus";
import { SiteType } from "@/app/enums/siteType";
import { LayoutType } from "@/app/enums/layoutType";
import {
  ChatSubmitButtonV2,
  CommonUsageNoticeBlockV2,
} from "@/app/components/chatComponents/commonLib";
import { ConversationIdContext } from "@/app/contexts/chatIdContext";

const Markdown = dynamic(
  () =>
    import("./markdown").then((md) => ({
      default: md.Markdown,
    })),
  {
    loading: () => <LoadingIcon />,
  },
);

const BOT_HELLO_V2 =
  "**你好，我是CAIE Chat，作为智能AI助手，有什么可以帮你的吗？**";

export function SessionConfigModel(props: { onClose: () => void }) {
  const conversationId = useContext(ConversationIdContext);
  const chatStore = useChatStore();
  const session = chatStore.currentSession(conversationId);
  const maskStore = useMaskStore();
  const navigate = useNavigate();

  return (
    <div className="modal-mask">
      <Modal
        title={Locale.Context.Edit}
        onClose={() => props.onClose()}
        actions={[
          <IconButton
            key="reset"
            icon={<ResetIcon />}
            bordered
            text={Locale.Chat.Config.Reset}
            onClick={() => {
              if (confirm(Locale.Memory.ResetConfirm)) {
                chatStore.updateCurrentSession(
                  (session) => (session.memoryPrompt = ""),
                  conversationId,
                );
              }
            }}
          />,
          <IconButton
            key="copy"
            icon={<CopyIcon />}
            bordered
            text={Locale.Chat.Config.SaveAs}
            onClick={() => {
              navigate(Path.Masks);
              if (session) {
                setTimeout(() => {
                  maskStore.create(session.mask);
                }, 500);
              }
            }}
          />,
        ]}
      >
        <>
          {session && (
            <MaskConfig
              mask={session.mask}
              updateMask={(updater) => {
                const mask = { ...session.mask };
                updater(mask);
                chatStore.updateCurrentSession(
                  (session) => (session.mask = mask),
                  conversationId,
                );
              }}
              shouldSyncFromGlobal
              extraListItems={
                session.mask.modelConfig.sendMemory ? (
                  <ListItem
                    title={`${Locale.Memory.Title} (${session.lastSummarizeIndex} of ${session.messages.length})`}
                    subTitle={
                      session.memoryPrompt || Locale.Memory.EmptyContent
                    }
                  ></ListItem>
                ) : (
                  <></>
                )
              }
            ></MaskConfig>
          )}
          {!session && <p>当前对话不存在</p>}
        </>
      </Modal>
    </div>
  );
}

function PromptToast(props: {
  showToast?: boolean;
  showModal?: boolean;
  setShowModal: (_: boolean) => void;
}) {
  const conversationId = useContext(ConversationIdContext);
  const chatStore = useChatStore();
  const session = chatStore.currentSession(conversationId);
  const context = session?.mask.context;

  return (
    <div className={chatStyle["prompt-toast"]} key="prompt-toast">
      {props.showToast && (
        <div
          className={chatStyle["prompt-toast-inner"] + " clickable"}
          role="button"
          onClick={() => props.setShowModal(true)}
        >
          <BrainIcon />
          <span className={chatStyle["prompt-toast-content"]}>
            {Locale.Context.Toast(context?.length ?? 0)}
          </span>
        </div>
      )}
      {props.showModal && (
        <SessionConfigModel onClose={() => props.setShowModal(false)} />
      )}
    </div>
  );
}

function useSubmitHandler() {
  const config = useAppConfig();
  const submitKey = config.submitKey;

  const shouldSubmit = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key !== "Enter") return false;
    if (e.key === "Enter" && e.nativeEvent.isComposing) return false;
    // return (
    //   (config.submitKey === SubmitKey.AltEnter && e.altKey) ||
    //   (config.submitKey === SubmitKey.CtrlEnter && e.ctrlKey) ||
    //   (config.submitKey === SubmitKey.ShiftEnter && e.shiftKey) ||
    //   (config.submitKey === SubmitKey.MetaEnter && e.metaKey) ||
    //   (config.submitKey === SubmitKey.Enter &&
    //     !e.altKey &&
    //     !e.ctrlKey &&
    //     !e.shiftKey &&
    //     !e.metaKey)
    // );
    return !e.shiftKey;

    // return true;
  };

  return {
    submitKey,
    shouldSubmit,
  };
}

export function PromptHints(props: {
  prompts: Prompt[];
  onPromptSelect: (prompt: Prompt) => void;
}) {
  const noPrompts = props.prompts.length === 0;
  const [selectIndex, setSelectIndex] = useState(0);
  const selectedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectIndex(0);
  }, [props.prompts.length]);

  const promptsRef = useRef(props.prompts);
  if (!isEqual(props.prompts, promptsRef.current)) {
    promptsRef.current = props.prompts;
  }
  const promptSelect = props.onPromptSelect;

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (noPrompts) return;
      if (e.metaKey || e.altKey || e.ctrlKey) {
        return;
      }
      // arrow up / down to select prompt
      const changeIndex = (delta: number) => {
        e.stopPropagation();
        e.preventDefault();
        const nextIndex = Math.max(
          0,
          Math.min(promptsRef.current.length - 1, selectIndex + delta),
        );
        setSelectIndex(nextIndex);
        selectedRef.current?.scrollIntoView({
          block: "center",
        });
      };

      if (e.key === "ArrowUp") {
        changeIndex(1);
      } else if (e.key === "ArrowDown") {
        changeIndex(-1);
      } else if (e.key === "Enter") {
        const selectedPrompt = promptsRef.current.at(selectIndex);
        if (selectedPrompt) {
          promptSelect(selectedPrompt);
        }
      }
    };

    window.addEventListener("keydown", onKeyDown);

    return () => window.removeEventListener("keydown", onKeyDown);
  }, [selectIndex, noPrompts, promptSelect]);

  if (noPrompts) return null;
  return (
    <div className={styles["prompt-hints"]}>
      {props.prompts.map((prompt, i) => (
        <div
          ref={i === selectIndex ? selectedRef : null}
          className={
            styles["prompt-hint"] +
            ` ${i === selectIndex ? styles["prompt-hint-selected"] : ""}`
          }
          key={prompt.title + i.toString()}
          onClick={() => props.onPromptSelect(prompt)}
          onMouseEnter={() => setSelectIndex(i)}
        >
          <div className={styles["hint-title"]}>{prompt.title}</div>
          <div className={styles["hint-content"]}>{prompt.content}</div>
        </div>
      ))}
    </div>
  );
}

function ClearContextDivider() {
  const conversationId = useContext(ConversationIdContext);
  const chatStore = useChatStore();

  return (
    <div
      className={chatStyle["clear-context"]}
      onClick={() =>
        chatStore.updateCurrentSession(
          (session) => (session.clearContextIndex = undefined),
          conversationId,
        )
      }
    >
      <div className={chatStyle["clear-context-tips"]}>
        {Locale.Context.Clear}
      </div>
      <div className={chatStyle["clear-context-revert-btn"]}>
        {Locale.Context.Revert}
      </div>
    </div>
  );
}

function ChatAction(props: {
  text: string;
  icon: JSX.Element;
  onClick: () => void;
}) {
  const iconRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState({
    full: 20,
    icon: 20,
  });

  function updateWidth() {
    if (!iconRef.current || !textRef.current) return;
    const getWidth = (dom: HTMLDivElement) => dom.getBoundingClientRect().width;
    const textWidth = getWidth(textRef.current);
    const iconWidth = getWidth(iconRef.current);
    setWidth({
      full: textWidth + iconWidth,
      icon: iconWidth,
    });
  }

  useEffect(() => {
    updateWidth();
  }, []);

  return (
    <div
      className={`${chatStyle["chat-input-action"]} clickable`}
      onClick={() => {
        props.onClick();
        setTimeout(updateWidth, 1);
      }}
      style={
        {
          "--icon-width": `${width.icon}px`,
          "--full-width": `${width.full}px`,
        } as React.CSSProperties
      }
    >
      <div ref={iconRef} className={chatStyle["icon"]}>
        {props.icon}
      </div>
      <div className={chatStyle["text"]} ref={textRef}>
        {props.text}
      </div>
    </div>
  );
}
export function ChatActions(props: {
  showPromptModal: () => void;
  scrollToBottom: () => void;
  showPromptHints: () => void;
  hitBottom: boolean;
}) {
  const conversationId = useContext(ConversationIdContext);
  const config = useAppConfig();
  const navigate = useNavigate();
  const chatStore = useChatStore();

  // switch themes
  const theme = config.theme;

  function nextTheme() {
    const themes = [Theme.Auto, Theme.Light, Theme.Dark];
    const themeIndex = themes.indexOf(theme);
    const nextIndex = (themeIndex + 1) % themes.length;
    const nextTheme = themes[nextIndex];
    config.update((config) => (config.theme = nextTheme));
  }

  // stop all responses
  const couldStop = ChatControllerPool.hasPending();
  const stopAll = () => ChatControllerPool.stopAll();

  return (
    <div className={chatStyle["chat-input-actions"]}>
      {couldStop && (
        <ChatAction
          onClick={stopAll}
          text={Locale.Chat.InputActions.Stop}
          icon={<StopIcon />}
        />
      )}
      {!props.hitBottom && (
        <ChatAction
          onClick={props.scrollToBottom}
          text={Locale.Chat.InputActions.ToBottom}
          icon={<BottomIcon />}
        />
      )}
      {props.hitBottom && (
        <ChatAction
          onClick={props.showPromptModal}
          text={Locale.Chat.InputActions.Settings}
          icon={<SettingsIcon />}
        />
      )}

      <ChatAction
        onClick={nextTheme}
        text={Locale.Chat.InputActions.Theme[theme]}
        icon={
          <>
            {theme === Theme.Auto ? (
              <AutoIcon />
            ) : theme === Theme.Light ? (
              <LightIcon />
            ) : theme === Theme.Dark ? (
              <DarkIcon />
            ) : null}
          </>
        }
      />

      <ChatAction
        onClick={props.showPromptHints}
        text={Locale.Chat.InputActions.Prompt}
        icon={<PromptIcon />}
      />

      <ChatAction
        onClick={() => {
          navigate(Path.Masks);
        }}
        text={Locale.Chat.InputActions.Masks}
        icon={<MaskIcon />}
      />

      <ChatAction
        text={Locale.Chat.InputActions.Clear}
        icon={<BreakIcon />}
        onClick={() => {
          chatStore.updateCurrentSession((session) => {
            if (session.clearContextIndex === session.messages.length) {
              session.clearContextIndex = undefined;
            } else {
              session.clearContextIndex = session.messages.length;
              session.memoryPrompt = ""; // will clear memory
            }
          }, conversationId);
        }}
      />
    </div>
  );
}

type RenderMessage = ChatMessage & { preview?: boolean };

export function Chat() {
  const conversationId = useContext(ConversationIdContext);
  const usageTypeValue = useChatStore(
    useShallow(
      (state) =>
        state.currentSession(conversationId)?.currentModel?.toString() ?? "0",
    ),
  );
  const navigate = useNavigate();

  const layout = useLayoutConfigStore();

  useEffect(() => {
    const storedConversationId = useChatStore
      .getState()
      .currentSession(conversationId)?.conversationId;
    if (
      !conversationId &&
      storedConversationId &&
      layout.type === LayoutType.Layout_v2
    ) {
      navigate(Path.Chat + "/" + storedConversationId);
    } else if (conversationId && layout.type === LayoutType.Layout_v1) {
      navigate(Path.Chat);
    }
  }, [conversationId, navigate, layout]);

  let inputAreaBlock: JSX.Element;

  if (usageTypeValue === "2") {
    // 文生图
    inputAreaBlock = <ImageInputArea />;
  } else if (usageTypeValue === "6") {
    // stable diffusion
    inputAreaBlock = <StableDiffusionInputArea />;
  } else if (usageTypeValue === "7") {
    // ppt
    inputAreaBlock = <PPTInputBlock />;
  } else if (usageTypeValue === "8") {
    // wanx
    inputAreaBlock = <WanxImageGenerateInputBlock />;
  } else if (usageTypeValue === "9") {
    // picwish
    inputAreaBlock = <PicWishImageGenerateInputBlock />;
  } else if (usageTypeValue === "11") {
    // 思维导图
    inputAreaBlock = <MindMapInputArea />;
  } else {
    inputAreaBlock = <ChatBottomInputArea />;
  }

  return (
    <div
      className={classNames({
        [styles.chat]: layout.type === LayoutType.Layout_v1,
      })}
      css={[
        layout.type === LayoutType.Layout_v2 &&
          css`
            display: flex;
            flex-direction: column;
            position: relative;
            height: 100%;
            box-sizing: border-box;
          `,
      ]}
    >
      <ChatHeader />
      <ChatBody />
      {inputAreaBlock}
    </div>
  );
}

const ChatHeader = function () {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)),
  );

  const showSearchButton = useSearchWiderLayout();
  const layout = useLayoutConfigStore();

  let titleAdditionalArea = <></>;
  if (session?.currentModel === 0 || session?.currentModel === 1) {
    if (showSearchButton) {
      titleAdditionalArea = (
        <div
          css={css`
            margin-left: 20px;
            display: flex;
            flex-shrink: 0;
          `}
        >
          <EnableSearchButton />
        </div>
      );
    }
  }

  return (
    <>
      <div
        className={classNames({
          "window-header": layout.type === LayoutType.Layout_v1,
        })}
        data-tauri-drag-region
        css={[
          layout.type === LayoutType.Layout_v1 &&
            css`
              padding: 14px 4px;
              justify-content: initial;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              height: 70px;
              padding-left: 20px;
              padding-right: 20px;
              box-sizing: border-box;
              width: 100%;
              display: flex;
              border-bottom: 1px solid var(--border-sharp);
              align-content: center;
              background-color: var(--main-surface-secondary);
            `,
        ]}
      >
        <div className="window-header-title flex items-center gap-[10px] ml-0">
          <ChatBackIcon />
          <ChatSessionRename />
          <ChatModelSelect />
        </div>
        {titleAdditionalArea}
        <div className="window-actions ml-auto">
          {/*<div className={"window-action-button" + " " + styles.mobile}>*/}
          {/*  <IconButton*/}
          {/*    icon={<ReturnIcon />}*/}
          {/*    bordered*/}
          {/*    title={Locale.Chat.Actions.ChatList}*/}
          {/*    onClick={() => navigate(Path.Home)}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className="window-action-button flex justify-center items-center">*/}
          {/*  <NextImage*/}
          {/*    src={RenameIcon.src}*/}
          {/*    width={28}*/}
          {/*    height={28}*/}
          {/*    alt="修改名称"*/}
          {/*    className="cursor-pointer hover:opacity-75"*/}
          {/*    onClick={() => navigate(Path.Home)}*/}
          {/*    title="修改名称"*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className="window-action-button flex justify-center items-center">*/}
          {/*  <NextImage*/}
          {/*    src={ExportIcon.src}*/}
          {/*    width={28}*/}
          {/*    height={28}*/}
          {/*    alt="导出分享"*/}
          {/*    className="cursor-pointer hover:opacity-75"*/}
          {/*    onClick={() => {*/}
          {/*      setShowExport(true);*/}
          {/*    }}*/}
          {/*    title="导出分享"*/}
          {/*  />*/}
          {/*</div>*/}
          <ChatHistoryButton />
          <ChatLanguage />
          <ChatMaxIcon />
        </div>

        {/*<PromptToast*/}
        {/*  showToast={!hitBottom}*/}
        {/*  showModal={showPromptModal}*/}
        {/*  setShowModal={setShowPromptModal}*/}
        {/*/>*/}
      </div>
      <ChatHistoryList />
    </>
  );
};

const ChatBody = function () {
  const conversationId = useContext(ConversationIdContext);
  const storedMessages = useChatStore(
    useShallow((state) => state.currentSession(conversationId)?.messages ?? []),
  );
  const mask = useChatStore(
    useShallow((state) => state.currentSession(conversationId)?.mask),
  );
  const layout = useLayoutConfigStore();
  const [isLoading, setIsLoading] = useState(false);
  const firstMessageContent = storedMessages.at(0)?.content;
  const context: RenderMessage[] = useMemo(() => {
    const rawContext: RenderMessage[] = mask?.hideContext
      ? []
      : (mask?.context.slice() ?? []);
    let content = BOT_HELLO.content;
    if (layout.type === LayoutType.Layout_v2) {
      content = BOT_HELLO_V2;
    }
    if (rawContext.length <= 0 && firstMessageContent !== content) {
      rawContext.push(createMessage({ ...BOT_HELLO, content }));
    }
    return rawContext;
  }, [firstMessageContent, layout, mask]);

  const loadingMessage = useMemo(() => {
    return {
      ...createMessage({
        role: "assistant",
        content: "……",
      }),
      preview: true,
    };
  }, []);

  const messages = useMemo(() => {
    return context
      .concat(storedMessages as RenderMessage[])
      .concat(isLoading ? [loadingMessage] : []);
  }, [context, isLoading, storedMessages, loadingMessage]);

  const messageSelect = useMemo(() => {
    return (i: number) => {
      return messages.at(i)!;
    };
  }, [messages]);

  const scrollParentRef = useRef<HTMLDivElement>(null);
  const messageLength = messages.length;

  const rowVirtualizer = useVirtualizer({
    count: messageLength,
    estimateSize: () => 40,
    getScrollElement: () => scrollParentRef.current,
    scrollPaddingEnd: 40,
    paddingStart: 12,
  });

  const items = rowVirtualizer.getVirtualItems();

  useEffect(() => {
    const loadingStatus = function (value: boolean) {
      setIsLoading(value);
    };
    eventBus.on(EventBus.Global.Status.LOADING, loadingStatus);
    return () => {
      eventBus.off(EventBus.Global.Status.LOADING, loadingStatus);
    };
  }, []);

  useEffect(() => {
    const scrollToBottom = function () {
      rowVirtualizer.scrollToIndex(messageLength - 1, {
        align: "end",
      });
    };
    eventBus.on(EventBus.Global.ChatBody.Scroll.SCROLL_BOTTOM, scrollToBottom);
    return () => {
      eventBus.off(
        EventBus.Global.ChatBody.Scroll.SCROLL_BOTTOM,
        scrollToBottom,
      );
    };
  }, [rowVirtualizer, messageLength]);

  return (
    <div
      className={styles["chat-body"]}
      ref={scrollParentRef}
      css={
        layout.type === LayoutType.Layout_v2 &&
        css`
          padding-left: 12px;
          padding-right: 12px;
        `
      }
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          position: "relative",
          width: "100%",
        }}
        css={[
          layout.type === LayoutType.Layout_v2 &&
            css`
              max-width: 800px;
              margin-left: auto;
              margin-right: auto;
              width: calc(100% - 24px);
              left: 0;
              right: 0;
            `,
        ]}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${items[0]?.start ?? 0}px)`,
          }}
        >
          {items.map((it) => {
            return (
              <div
                key={it.key}
                ref={rowVirtualizer.measureElement}
                data-index={it.index}
              >
                <ChatMessageComponent
                  i={it.index}
                  message={messageSelect(it.index)}
                  key={it.key}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const ChatMessageComponent = function (props: {
  message: RenderMessage;
  i: number;
}) {
  const message = useRef(props.message);
  if (!isEqual(message.current, props.message)) {
    message.current = props.message;
  }

  const setLoading = useCallback((value: boolean) => {
    eventBus.emit(EventBus.Global.Status.LOADING, value);
  }, []);

  const updateUserInput = useCallback((value: string) => {
    eventBus.emit(EventBus.PromptInput.Action.SET_INPUT, value);
    console.log("input", value);
  }, []);

  if (props.message?.role === "user") {
    return (
      <ChatMessageUser
        message={message.current}
        i={props.i}
        updateUserInput={updateUserInput}
        setLoading={setLoading}
        key={props.i}
      />
    );
  } else {
    return (
      <ChatMessageBot
        message={message.current}
        i={props.i}
        updateUserInput={updateUserInput}
        setLoading={setLoading}
        key={props.i}
      />
    );
  }
};

const ChatMessageUser = function (props: {
  message: RenderMessage;
  i: number;
  updateUserInput?: (value: string) => void | Promise<void>;
  setLoading?: (value: boolean) => void;
}) {
  const conversationIdFromUrl = useContext(ConversationIdContext);
  const site = useSiteType();
  const config = useAppConfig();
  const fontSize = config.fontSize;
  const isMobileScreen = useMobileScreen();
  const conversationId = useChatStore(
    useShallow(
      (state) => state.currentSession(conversationIdFromUrl)!.conversationId!,
    ),
  );
  const layout = useLayoutConfigStore();
  const theme = useCurrentTheme();

  const messageRef = useRef(props.message);
  if (!isEqual(messageRef.current, props.message)) {
    messageRef.current = props.message;
  }

  const updateUserInput = props.updateUserInput;
  const content = props.message.content;
  const markdownDoubleClickCapture = useCallback(() => {
    if (!isMobileScreen) return;
    if (updateUserInput) {
      updateUserInput(content);
    }
  }, [isMobileScreen, updateUserInput, content]);

  const showTyping = props.message.preview || props.message.streaming;

  return (
    <div className={"flex"}>
      <div className={styles["chat-message-user"]}>
        <div
          className={classNames(
            styles["chat-message-container"],
            "flex-shrink-0",
          )}
        >
          {layout.type === LayoutType.Layout_v1 && (
            <div
              className={classNames(
                styles["chat-message-avatar"],
                styles["avatar_user"],
              )}
            >
              <NextImage
                src={getImageWithSiteType(
                  site.siteType,
                  SiteType.EXAM,
                  ExamUserAvatarIcon,
                  UserAvatarIcon,
                )}
                width={33}
                height={33}
                alt="AI头像"
              />
            </div>
          )}
          {showTyping && (
            <div
              className={classNames(
                styles["chat-message-status"],
                "flex-shrink-0",
              )}
            >
              {Locale.Chat.Typing}
            </div>
          )}
          <div
            className={styles["chat_content_wrapper"]}
            css={[
              layout.type === LayoutType.Layout_v1 &&
                showTyping &&
                css`
                  max-width: calc(100% - 113px);
                `,
              layout.type === LayoutType.Layout_v1 &&
                !showTyping &&
                css`
                  max-width: calc(100% - 53px);
                `,
            ]}
          >
            {layout.type === LayoutType.Layout_v2 && (
              <ChatFileListComponent fileList={props.message.file_list} />
            )}

            <div
              className={"flex max-w-full flex-shrink-0"}
              css={[
                layout.type === LayoutType.Layout_v2 &&
                  css`
                    background-color: var(--main-surface-secondary);
                    border-radius: 27px;
                    padding: 13px 20px;
                    user-select: text;
                    position: relative;
                    flex-direction: column;
                  `,
              ]}
            >
              {layout.type === LayoutType.Layout_v1 && (
                <div
                  className={classNames(
                    styles["chat-message-placeholder-left"],
                    "flex-grow",
                  )}
                ></div>
              )}
              <div
                className={classNames({
                  [styles["chat-message-item"]]:
                    layout.type === LayoutType.Layout_v1,
                  "prose dark:prose-invert":
                    layout.type === LayoutType.Layout_v2,
                  light: theme === "light",
                  dark: theme === "dark",
                })}
              >
                <Markdown
                  content={props.message.content}
                  loading={false}
                  isUser={props.message.preview}
                  // onContextMenu={(e) => onRightClick(e, message)}
                  onDoubleClickCapture={markdownDoubleClickCapture}
                  fontSize={fontSize}
                  // parentRef={scrollRef}
                  defaultShow={true}
                  message={messageRef.current}
                  conversationId={conversationId}
                />
                {/*{layout.type === LayoutType.Layout_v1 && props.message.content}*/}
                {/*{layout.type === LayoutType.Layout_v2 && (*/}
                {/*  <div*/}
                {/*    css={css`*/}
                {/*      margin-left: auto;*/}
                {/*      width: fit-content;*/}
                {/*    `}*/}
                {/*  >*/}
                {/*    {props.message.content}*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
              {layout.type === LayoutType.Layout_v1 && (
                <div
                  className={classNames(
                    styles["chat-message-placeholder-right"],
                    "flex-grow",
                  )}
                ></div>
              )}
              {/*{layout.type === LayoutType.Layout_v2 &&*/}
              {/*  !props.message.preview && (*/}
              {/*    <div*/}
              {/*      css={css`*/}
              {/*        font-size: 12px;*/}
              {/*        color: #838484;*/}
              {/*        line-height: 14px;*/}
              {/*        height: 14px;*/}
              {/*        margin-top: 4px;*/}
              {/*        margin-left: auto;*/}
              {/*      `}*/}
              {/*    >*/}
              {/*      {formatDateTimeFunc(props.message.date)}*/}
              {/*    </div>*/}
              {/*  )}*/}
            </div>
            {layout.type === LayoutType.Layout_v1 && (
              <div className={styles["chat-message-actions"]}>
                {!props.message.preview && (
                  <div className={styles["chat-message-action-date"]}>
                    {formatDateTimeFunc(props.message.date)}
                  </div>
                )}
              </div>
            )}
            {layout.type === LayoutType.Layout_v1 && (
              <ChatFileListComponent fileList={props.message.file_list} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ChatMessageBot = function (props: {
  message: RenderMessage;
  i: number;
  updateUserInput?: (value: string) => void | Promise<void>;
  setLoading?: (value: boolean) => void;
}) {
  const conversationIdFromUrl = useContext(ConversationIdContext);
  const site = useSiteType();
  const isMobileScreen = useMobileScreen();
  const conversationId = useChatStore(
    useShallow(
      (state) => state.currentSession(conversationIdFromUrl)?.conversationId,
    ),
  );
  const sessionIndex = useChatStore(
    useShallow((state) => state.currentSessionIndex),
  );
  const layout = useLayoutConfigStore();
  const session = useChatStore(useShallow((state) => state.currentSession()!));
  const messageRef = useRef(props.message);
  if (!isEqual(messageRef.current, props.message)) {
    messageRef.current = props.message;
  }

  const fontSize = 16;

  const updateUserInput = props.updateUserInput;
  const content = props.message.content;
  const markdownDoubleClickCapture = useCallback(() => {
    if (!isMobileScreen) return;
    if (updateUserInput) {
      updateUserInput(content);
    }
  }, [isMobileScreen, updateUserInput, content]);

  const showTyping = props.message?.preview || props.message?.streaming;
  const showActions =
    props.i > 0 &&
    !(props.message.preview || props.message.content.length === 0);

  const findLastUserIndex = useCallback(
    (messageId: number) => {
      // find last user input message and resend
      let lastUserMessageIndex: number | null = null;
      for (let i = 0; i < session.messages.length; i += 1) {
        const message = session.messages[i];
        if (message.id === messageId) {
          break;
        }
        if (message.role === "user") {
          lastUserMessageIndex = i;
        }
      }

      return lastUserMessageIndex;
    },
    [session],
  );

  const onUserStop = useCallback(
    (messageId: number) => {
      ChatControllerPool.stop(sessionIndex, messageId);
    },
    [sessionIndex],
  );

  const deleteMessage = useCallback(
    (userIndex: number) => {
      useChatStore
        .getState()
        .updateCurrentSession(
          (session) => session.messages.splice(userIndex, 2),
          conversationId,
        );
    },
    [conversationId],
  );

  const onDelete = useCallback(
    (botMessageId: number) => {
      const userIndex = findLastUserIndex(botMessageId);
      if (userIndex === null) return;
      deleteMessage(userIndex);
    },
    [deleteMessage, findLastUserIndex],
  );

  const setLoading = props.setLoading;

  const onResend = useCallback(
    (botMessageId: number) => {
      // find last user input message and resend
      const userIndex = findLastUserIndex(botMessageId);
      if (userIndex === null) return;

      if (setLoading) {
        setLoading(true);
      }
      const content = session.messages.at(userIndex)!.content;
      deleteMessage(userIndex);
      useChatStore
        .getState()
        .onUserInput(content)
        .then(() => {
          if (setLoading) {
            setLoading(false);
          }
        });
      eventBus.emit(EventBus.PromptInput.Action.FOCUS);
    },
    [deleteMessage, findLastUserIndex, setLoading, session.messages],
  );

  return (
    <div className={"flex"}>
      <div
        className={classNames({
          [styles["chat-message"]]: layout.type === LayoutType.Layout_v1,
        })}
        css={[
          layout.type === LayoutType.Layout_v2 &&
            css`
              display: flex;
              flex-direction: column;
              max-width: 100%;
            `,
        ]}
      >
        <div
          className={classNames(
            styles["chat-message-container"],
            "flex-shrink-0",
          )}
        >
          <div
            className={classNames(styles["chat-message-avatar"])}
            css={
              layout.type === LayoutType.Layout_v2 &&
              css`
                width: 32px;
                height: 32px;
                border-color: var(--main-surface-tertiary);
                border-radius: 50%;
                border-style: solid;
                border-width: 1px;
                display: flex;
                margin-right: 24px;
              `
            }
          >
            {layout.type === LayoutType.Layout_v1 && (
              <NextImage
                src={getImageWithSiteType(
                  site.siteType,
                  SiteType.EXAM,
                  ExamAvatarIcon,
                  AiAvatarIcon,
                )}
                width={33}
                height={33}
                alt="AI头像"
              />
            )}
            {layout.type === LayoutType.Layout_v2 && (
              <GPTIcon
                width={18}
                height={18}
                css={css`
                  width: 18px;
                  height: 18px;
                  color: var(--black);
                  display: block;
                  margin: auto;
                `}
              />
            )}
          </div>
          <div
            className={styles["chat_content_wrapper"]}
            css={[
              layout.type === LayoutType.Layout_v1 &&
                css`
                  max-width: calc(100% - 53px);
                `,
              layout.type === LayoutType.Layout_v2 &&
                css`
                  max-width: calc(100% - 60px);
                  padding-top: 20px;
                `,
            ]}
          >
            {showTyping && (
              <div
                className={classNames(
                  styles["chat-message-status"],
                  "flex-shrink-0",
                )}
                css={
                  layout.type === LayoutType.Layout_v2 &&
                  css`
                    height: 24px;
                    margin-bottom: 6px;
                  `
                }
              >
                {Locale.Chat.Typing}
              </div>
            )}
            {layout.type === LayoutType.Layout_v2 &&
              !showTyping &&
              props.message.role === "assistant" && (
                <div
                  css={css`
                    display: flex;
                    height: 24px;
                    font-size: 14px;
                    color: var(--text-tertiary);
                    margin-bottom: 6px;
                    cursor: pointer;
                  `}
                >
                  <div className={"no-dark"}>
                    <FontAwesomeIcon
                      icon={faBookBookmark}
                      color={"var(--text-tertiary)"}
                    />
                  </div>
                  <span
                    css={css`
                      display: block;
                      margin-left: 6px;
                    `}
                  >
                    记忆已更新
                  </span>
                </div>
              )}
            <div
              className={"flex max-w-full flex-shrink-0"}
              css={
                layout.type === LayoutType.Layout_v2 &&
                css`
                  position: relative;
                `
              }
            >
              {layout.type === LayoutType.Layout_v1 && (
                <div
                  className={classNames(
                    styles["chat-message-placeholder-left"],
                    "flex-grow",
                  )}
                ></div>
              )}
              <div
                className={classNames({
                  [styles["chat-message-item"]]:
                    layout.type === LayoutType.Layout_v1,
                })}
                css={[
                  layout.type === LayoutType.Layout_v2 &&
                    css`
                      box-sizing: border-box;
                      max-width: 100%;
                      border-radius: 4px;
                      padding: 0 0 14px;
                      user-select: text;
                    `,
                  layout.type === LayoutType.Layout_v2 &&
                    props.message.date === "" &&
                    css`
                      padding-bottom: 16px;
                    `,
                ]}
              >
                <Markdown
                  content={props.message.content}
                  loading={
                    props.message.preview || props.message.content.length === 0
                  }
                  isUser={true}
                  // onContextMenu={(e) => onRightClick(e, message)}
                  onDoubleClickCapture={markdownDoubleClickCapture}
                  fontSize={fontSize}
                  // parentRef={scrollRef}
                  defaultShow={true}
                  message={messageRef.current}
                  conversationId={conversationId}
                />
              </div>
              <div
                className={classNames(
                  styles["chat-message-placeholder-right"],
                  "flex-grow",
                )}
              ></div>
            </div>
            <div
              className={styles["chat-message-actions"]}
              css={
                layout.type === LayoutType.Layout_v2 &&
                css`
                  padding-top: 0;
                `
              }
            >
              {layout.type === LayoutType.Layout_v1 &&
                !props.message.preview &&
                props.message.role !== "system" && (
                  <div className={styles["chat-message-action-date"]}>
                    {formatDateTimeFunc(props.message.date)}
                  </div>
                )}
              {showActions ? (
                <div
                  className={classNames(
                    "flex gap-[10px]",
                    styles["message_actions"],
                  )}
                  css={
                    layout.type === LayoutType.Layout_v2 &&
                    css`
                      width: 100%;
                      margin-bottom: 8px;
                    `
                  }
                >
                  {props.message.streaming ? (
                    <>
                      {/*<div*/}
                      {/*  className={styles["chat-message-top-action"]}*/}
                      {/*  onClick={() => onUserStop(props.message.id ?? props.i)}*/}
                      {/*>*/}
                      {/*  {Locale.Chat.Actions.Stop}*/}
                      {/*</div>*/}
                    </>
                  ) : (
                    <>
                      <div
                        className={styles["chat-message-top-action"]}
                        css={
                          layout.type === LayoutType.Layout_v2 &&
                          css`
                            display: flex;
                          `
                        }
                        onClick={() => onDelete(props.message.id ?? props.i)}
                      >
                        {layout.type === LayoutType.Layout_v2 && (
                          <MessageDeleteIcon
                            css={css`
                              width: 16px;
                              height: 16px;
                              margin-right: 2px;
                            `}
                          />
                        )}
                        {Locale.Chat.Actions.Delete}
                      </div>
                      <div
                        className={styles["chat-message-top-action"]}
                        css={
                          layout.type === LayoutType.Layout_v2 &&
                          css`
                            display: flex;
                          `
                        }
                        onClick={() => onResend(props.message.id ?? props.i)}
                      >
                        {layout.type === LayoutType.Layout_v2 && (
                          <MessageRetryIcon
                            css={css`
                              width: 16px;
                              height: 16px;
                              margin-right: 2px;
                            `}
                          />
                        )}
                        {Locale.Chat.Actions.Retry}
                      </div>
                    </>
                  )}

                  <div
                    className={styles["chat-message-top-action"]}
                    css={
                      layout.type === LayoutType.Layout_v2 &&
                      css`
                        display: flex;
                      `
                    }
                    onClick={() => copyToClipboard(props.message.content)}
                  >
                    {layout.type === LayoutType.Layout_v2 && (
                      <MessageCopyIcon
                        css={css`
                          width: 16px;
                          height: 16px;
                          margin-right: 2px;
                        `}
                      />
                    )}
                    {Locale.Chat.Actions.Copy}
                  </div>
                  {/*{layout.type === LayoutType.Layout_v2 &&*/}
                  {/*  !props.message.preview &&*/}
                  {/*  props.message.role !== "system" && (*/}
                  {/*    <div*/}
                  {/*      css={css`*/}
                  {/*        font-size: 12px;*/}
                  {/*        color: #838384;*/}
                  {/*        height: 12px;*/}
                  {/*        line-height: 12px;*/}
                  {/*        margin-top: auto;*/}
                  {/*        margin-bottom: auto;*/}
                  {/*        margin-left: auto;*/}
                  {/*        white-space: pre;*/}
                  {/*      `}*/}
                  {/*    >*/}
                  {/*      {formatDateTimeFunc(props.message.date)}*/}
                  {/*    </div>*/}
                  {/*  )}*/}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const EnableSearchButton = (props: { hideLeftMargin?: boolean }) => {
  const layout = useLayoutConfigStore();
  if (layout.type === LayoutType.Layout_v1) {
    return <EnableSearchButtonV1 hideLeftMargin={props.hideLeftMargin} />;
  } else if (layout.type === LayoutType.Layout_v2) {
    return <EnableSearchButtonV2 hideLeftMargin={props.hideLeftMargin} />;
  }
  return <></>;
};

const EnableSearchButtonV1 = function (_props: { hideLeftMargin?: boolean }) {
  const conversationId = useContext(ConversationIdContext);
  const chatStore = useChatStore();
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)),
  );
  const [onlineSearch, setOnLineSearch] = useState(
    session?.shouldUseSearch ?? false,
  );

  const baseTitleStyle = css`
    font-size: 16px;
    line-height: 22px;
    height: 22px;
    display: block;
    margin-left: 4px;
  `;

  const disabledStyle = css`
    color: #999;
  `;

  const enabledStyle = css`
    color: #0c74ed;
  `;

  const titleStyle = [baseTitleStyle];
  const helpStyle = [
    css`
      font-size: 16px;
      display: block;
      width: 16px;
      height: 16px;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 4px;
    `,
  ];
  const currentSearchStatus =
    chatStore.currentSession(conversationId)?.shouldUseSearch ?? false;
  if (
    onlineSearch !== currentSearchStatus &&
    currentSearchStatus !== undefined
  ) {
    setOnLineSearch(currentSearchStatus);
  }

  if (onlineSearch) {
    titleStyle.push(enabledStyle);
    helpStyle.push(enabledStyle);
  } else {
    titleStyle.push(disabledStyle);
    helpStyle.push(disabledStyle);
  }

  const onChange = function (value: boolean) {
    setOnLineSearch(value);
    chatStore.updateCurrentSession((session1) => {
      session1.shouldUseSearch = value;
    }, conversationId);
  };

  return (
    <div
      css={css`
        display: flex;
        height: 22px;
        margin-top: auto;
        margin-bottom: auto;
      `}
    >
      <Switch
        checkedChildren={"开"}
        unCheckedChildren={"关"}
        value={onlineSearch}
        onChange={onChange}
        style={{
          display: "block",
        }}
      />
      <span css={titleStyle}>加强搜索</span>
      <Tooltip title="加强搜索不断更新文献，回答更全面；响应会稍有延迟">
        <QuestionCircleOutlined css={helpStyle} />
      </Tooltip>
    </div>
  );
};

const EnableSearchButtonV2 = function (props: { hideLeftMargin?: boolean }) {
  const conversationId = useContext(ConversationIdContext);
  const searchEnable = useChatStore(
    useShallow(
      (state) => state.currentSession(conversationId)?.shouldUseSearch ?? false,
    ),
  );

  const [onLineSearch, setOnLineSearch] = useState(searchEnable);
  if (onLineSearch !== searchEnable) {
    setOnLineSearch(searchEnable);
  }

  const changeSearch = useCallback(() => {
    setOnLineSearch((state) => !state);
    useChatStore.getState().updateCurrentSession((session) => {
      session.shouldUseSearch = !session.shouldUseSearch;
    }, conversationId);
  }, [conversationId]);

  return (
    <Tooltip
      title="加强搜索不断更新文献，回答更全面；响应会稍有延迟"
      getPopupContainer={tooltipFix}
    >
      <div
        css={[
          css`
            display: flex;
            height: 38px;
            margin-top: auto;
            margin-bottom: auto;
            border-radius: 4px;
            cursor: pointer;
            flex-shrink: 0;

            :hover {
              background-color: var(--hover-color);
            }
          `,
          !props.hideLeftMargin &&
            css`
              margin-left: 30px;
            `,
        ]}
        onClick={changeSearch}
      >
        <EarthIcon
          css={css`
            width: 20px;
            height: 20px;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 12px;
            filter: initial !important;
          `}
        />
        <div
          css={[
            css`
              font-size: 14px;
              line-height: 20px;
              height: 20px;
              margin-top: auto;
              margin-bottom: auto;
              margin-left: 2px;
            `,
            onLineSearch &&
              css`
                color: var(--black);
              `,
            !onLineSearch &&
              css`
                color: var(--color-fg-muted);
              `,
          ]}
        >
          加强检索
        </div>
        <Switch
          value={onLineSearch}
          size={"small"}
          css={css`
            margin: auto 10px auto 2px !important;

            &.ant-switch.ant-switch-checked,
            &.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
              background-color: #ff6e25;
            }
          `}
        />
      </div>
    </Tooltip>
  );
};

const useShowFileHistory = create(() => ({ state: false }));

const ChatHistoryButton = function () {
  const userStore = useUserStore();
  const layout = useLayoutConfigStore();

  const clickToShowList = useCallback(() => {
    useShowFileHistory.setState({ state: true });
  }, []);

  let result = <></>;

  if (layout.type === LayoutType.Layout_v1 && userStore.role === 2) {
    result = (
      <div
        css={css`
          margin-right: 16px;
          height: 100%;
          width: 28px;
          display: flex;
          cursor: pointer;
          user-select: none;

          @media (width <= 770px) {
            display: none;
          }
        `}
        onClick={clickToShowList}
      >
        <NextImage
          src={FolderIcon}
          alt={""}
          width={28}
          height={28}
          css={css`
            margin: auto;

            @media (prefers-color-scheme: dark) {
              filter: invert(1);
            }
          `}
        />
      </div>
    );
  }

  return result;
};

const ChatHistoryList = function () {
  const showFileHistory = useShowFileHistory();
  const [sessionFileList, updateSessionFileList] = useImmer(
    [] as { file_id: number; fileName: string }[],
  );
  const [modalApi, modalContextHolder] = AntModal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const conversationId = useContext(ConversationIdContext)!;

  const clickToHideFileList = useCallback(() => {
    useShowFileHistory.setState({ state: false });
  }, []);

  const deleteUploadFileFromExistingList = useCallback(
    function (file_id: number) {
      console.log(file_id);
      const modelInstance = modalApi.confirm({
        title: "确认删除文件？",
        cancelText: "取消",
        okText: "确定",
        onOk() {
          useChatStore.getState().deleteChatFile(
            file_id,
            () => {
              void messageApi.success("删除成功");
              updateSessionFileList(
                sessionFileList.filter((it) => it.file_id !== file_id),
              );
            },
            (error) => {
              void messageApi.error(error.message);
            },
          );
        },
        onCancel() {
          modelInstance.destroy();
        },
      });
    },
    [sessionFileList, messageApi, modalApi, updateSessionFileList],
  );

  useEffect(() => {
    if (!showFileHistory.state) {
      return;
    }

    const timeout = setTimeout(() => {
      void messageApi.loading("加载中……");
      useChatStore.getState().listChatFile(
        conversationId,
        (fileList) => {
          updateSessionFileList(fileList);
          useChatStore.getState().updateSessionFileList({ fileList });
        },
        (error) => {
          void messageApi.error(error.message);
        },
      );
    }, 0.5);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    showFileHistory.state,
    conversationId,
    messageApi,
    updateSessionFileList,
  ]);

  let result = <></>;

  if (showFileHistory.state) {
    result = (
      <>
        {modalContextHolder}
        {messageContextHolder}
        <div
          css={css`
            position: absolute;
            width: 300px;
            background-color: var(--gray);
            height: 100%;
            display: flex;
            flex-direction: column;
            top: 0;
            right: 0;
            z-index: 1;
          `}
        >
          <div
            css={css`
              height: 26px;
              margin-top: 20px;
              margin-left: 20px;
              display: flex;
            `}
          >
            <div
              css={css`
                color: var(--black);
                font-size: 18px;
                line-height: 26px;
              `}
            >
              历史文件
            </div>

            <div
              css={css`
                width: 24px;
                height: 24px;
                margin-right: 20px;
                margin-left: auto;
              `}
              onClick={clickToHideFileList}
            >
              <CloseSvgIcon />
            </div>
          </div>

          <div
            css={css`
              margin-top: 16px;
              overflow-y: scroll;
              min-height: 0;
              flex-grow: 1;
              flex-shrink: 1;
            `}
          >
            {sessionFileList.map((it) => {
              return (
                <UploadHistoryListItem
                  key={it.file_id}
                  fileName={it.fileName}
                  fileSize={""}
                  fileDate={""}
                  file_id={it.file_id}
                  onDelete={deleteUploadFileFromExistingList}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }

  return result;
};

const ChatBackIcon = function () {
  const navigate = useNavigate();
  const layout = useLayoutConfigStore();
  if (layout.type === LayoutType.Layout_v2) {
    return <></>;
  }
  return (
    <div
      css={css`
        margin-left: -14px;
      `}
    >
      <IconButton
        icon={<NextImage src={BackIcon} alt={""} width={22} height={22} />}
        title={Locale.Chat.Actions.ChatList}
        onClick={() => navigate(Path.Home)}
      ></IconButton>
    </div>
  );
};

const ChatMaxIcon = function () {
  const tightBorder = useAppConfig(useShallow((state) => state.tightBorder));
  const isMobileScreen = useMobileScreen();
  const site = useSiteType();
  const clientConfig = useMemo(() => getClientConfig(), []);
  const layout = useLayoutConfigStore();

  const showMaxIcon =
    layout.type === LayoutType.Layout_v1 &&
    !isMobileScreen &&
    !clientConfig?.isApp &&
    site.siteType !== SiteType.EXAM &&
    site.siteType !== SiteType.PAPER666;

  let result = <></>;
  if (showMaxIcon) {
    result = (
      <div className="window-action-button flex justify-center items-center">
        <NextImage
          src={tightBorder ? MaxIcon.src : MinIcon.src}
          width={28}
          height={28}
          alt={tightBorder ? "最大" : "最小"}
          className="cursor-pointer hover:opacity-75"
          onClick={() => {
            useAppConfig
              .getState()
              .update((config) => (config.tightBorder = !config.tightBorder));
          }}
          title={tightBorder ? "最大" : "最小"}
        />
      </div>
    );
  }

  return result;
};

const ChatLanguage = function () {
  const layout = useLayoutConfigStore();

  if (layout.type === LayoutType.Layout_v1) {
    return <ChatLanguageV1 />;
  } else if (layout.type === LayoutType.Layout_v2) {
    return <ChatLanguageV2 />;
  }

  return <></>;
};

const ChatLanguageV1 = function () {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)),
  );
  const [language, setLanguage] = useState(
    session?.lang ?? "Simplified_Chinese",
  );

  const handleLanguageTypeChange = useCallback(
    (e: string) => {
      setLanguage(e);
      useChatStore.getState().updateCurrentSession((session1) => {
        session1.lang = e;
      }, conversationId);
    },
    [conversationId],
  );

  return (
    <>
      <Select
        value={language}
        style={{ width: 80, marginTop: "auto", marginBottom: "auto" }}
        onSelect={handleLanguageTypeChange}
        options={[
          { value: "Simplified_Chinese", label: "中-简" },
          // {value: 'Traditional_Chinese', label: '中-繁'},
          { value: "English", label: "英" },
        ]}
      ></Select>
    </>
  );
};

const ChatLanguageV2 = function () {
  const conversationId = useContext(ConversationIdContext);
  const language = useChatStore(
    useShallow(
      (state) =>
        state.currentSession(conversationId)?.lang ?? "Simplified_Chinese",
    ),
  );
  const [lang, setLang] = useState(language);

  const handleLangChange = useCallback(
    (value: string) => {
      setLang(value);
      useChatStore.getState().updateCurrentSession((session) => {
        session.lang = value;
      }, conversationId);
    },
    [conversationId],
  );

  return (
    <>
      <JoySelect
        value={lang}
        onChange={(_e, value) => handleLangChange(value!)}
        css={css`
          height: 38px;
          width: 138px;
          font-size: 14px;
          margin-top: auto;
          margin-bottom: auto;
        `}
        sx={{
          borderColor: "transparent",
          boxShadow: "initial",
          backgroundColor: "initial",
          "--joy-palette-neutral-outlinedColor": "var(--text-primary)",
          "--joy-palette-neutral-outlinedHoverBg": "var(--hover-color)",
        }}
        startDecorator={
          <LangIcon
            css={css`
              width: 20px;
              height: 20px;
            `}
          />
        }
        indicator={<FontAwesomeIcon icon={faCaretDown} />}
      >
        <JoyOption
          value={"Simplified_Chinese"}
          sx={{ color: "var(--text-primary)" }}
        >
          简体中文
        </JoyOption>
        <JoyOption value={"English"} sx={{ color: "var(--text-primary)" }}>
          English
        </JoyOption>
      </JoySelect>
    </>
  );
};

const ChatModelSelect = function () {
  const conversationId = useContext(ConversationIdContext);
  const chatStore = useChatStore();
  const session = chatStore.currentSession(conversationId);
  const [usageTypeValue, handleUsageTypeChange] = useHandleUsageTypeChange();
  const [, startTransition] = useTransition();
  const layout = useLayoutConfigStore();

  const modelOptions = useModelSelectOptions();

  useEffect(() => {
    startTransition(() => {
      const option = modelOptions.find(
        (it) => it.value === session?.currentModel?.toString(),
      );
      if (option === undefined && session?.currentModel !== undefined) {
        chatStore.updateCurrentSession((session1) => {
          session1.currentModel = Number.parseInt(modelOptions[0].value);
        }, conversationId);
        return;
      }
    });
  }, [session?.currentModel, modelOptions, chatStore, conversationId]);

  const sessionConversationIdRef = useRef<string | null>(null);

  useEffect(() => {
    startTransition(() => {
      if (!session) {
        return;
      }
      if (
        sessionConversationIdRef.current !== null &&
        sessionConversationIdRef.current === session.conversationId
      ) {
        return;
      }
      sessionConversationIdRef.current = session.conversationId!;
      let hasAttachment = false;
      if (
        session.pendingFileList &&
        session.pendingFileList.length > 0 &&
        session.pendingFileList.filter(
          (it) => it.fileType === UploadFileType.ASSISTANT,
        ).length > 0
      ) {
        hasAttachment = true;
      } else {
        for (const message1 of session.messages) {
          if (
            message1.file_list &&
            message1.file_list.length > 0 &&
            message1.file_list.filter(
              (it) => it.fileType === UploadFileType.ASSISTANT,
            ).length > 0
          ) {
            hasAttachment = true;
            break;
          }
        }
      }
      if (hasAttachment) {
        handleUsageTypeChange("3");
      }
    });
  }, [session, handleUsageTypeChange]);

  return (
    <>
      {layout.type === LayoutType.Layout_v1 && (
        <Select
          value={usageTypeValue}
          style={{ width: 120 }}
          onChange={handleUsageTypeChange}
          options={modelOptions}
        />
      )}
      {layout.type === LayoutType.Layout_v2 && (
        <JoySelect
          value={usageTypeValue}
          onChange={(e, value) => handleUsageTypeChange(value!)}
          css={css`
            font-size: 14px;
            height: 38px;
          `}
          indicator={<FontAwesomeIcon icon={faCaretDown} />}
          slotProps={{
            listbox: {
              placement: "bottom-end",
            },
          }}
          sx={{
            borderColor: "transparent",
            boxShadow: "initial",
            backgroundColor: "initial",
            "--joy-palette-neutral-outlinedColor": "var(--text-primary)",
            "--joy-palette-neutral-outlinedHoverBg": "var(--hover-color)",
          }}
        >
          {modelOptions.map((it) => {
            return (
              <JoyOption
                value={it.value}
                key={it.value}
                sx={{
                  color: "#7a848a",
                  '&:not(.Mui-selected, [aria-selected="true"]):hover': {
                    color: "#106a8a",
                    backgroundColor: "#ecf3f7",
                  },
                  "&.Mui-selected": {
                    color: "#248eb3",
                    backgroundColor: "initial",
                  },
                  '&:not(.Mui-selected, [aria-selected="true"])': {
                    color: "#7a848a",
                  },
                  fontSize: "14px",
                }}
              >
                {it.label}
                {it.value === usageTypeValue && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    css={css`
                      margin-left: 20px;
                      height: 16px;
                      width: 16px;
                    `}
                  />
                )}
              </JoyOption>
            );
          })}
        </JoySelect>
      )}
    </>
  );
};

const ChatSessionRename = function () {
  const conversationId = useContext(ConversationIdContext);
  const chatStore = useChatStore();
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)),
  );
  const layout = useLayoutConfigStore();

  const renameSession = useCallback(() => {
    const newTopic = prompt(Locale.Chat.Rename, session?.topic);
    if (newTopic && newTopic !== session?.topic) {
      chatStore.updateCurrentSession(
        (session) => (session.topic = newTopic!),
        conversationId,
      );
    }
  }, [chatStore, session?.topic, conversationId]);

  const defaultTopic =
    layout.type === LayoutType.Layout_v2 ? DEFAULT_TOPIC_V2 : DEFAULT_TOPIC;

  if (layout.type === LayoutType.Layout_v2) {
    return <></>;
  }
  return (
    <div
      className={classNames({
        [styles["chat-body-title"]]: layout.type === LayoutType.Layout_v1,
      })}
      css={[
        css`
          font-size: 20px;
          font-weight: bolder;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          max-width: 50vw;
          cursor: pointer;
        `,
      ]}
      onClickCapture={renameSession}
    >
      {!session?.topic ? defaultTopic : session?.topic}
    </div>
  );
};

const ChatBottomInputArea = function () {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)),
  );
  const currentModelValue = session?.currentModel ?? 0;
  const isMobileScreen = useMobileScreen();
  const [userInput, setUserInput] = useState("");
  const promptStore = usePromptStore();
  const [promptHints, setPromptHints] = useState<Prompt[]>([]);
  const layout = useLayoutConfigStore();
  const userStore = useUserStore();
  const site = useSiteType();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { shouldSubmit } = useSubmitHandler();
  const [showOutline, setShowOutline] = useState(false);

  const doSubmit = useSubmit();

  const location = useLocation();
  const isChat = location.pathname === Path.Chat;
  let inputPlaceHolder = "请输入发送消息";
  if (layout.type === LayoutType.Layout_v2) {
    if (site.siteType !== SiteType.EXAM && site.siteType !== SiteType.TRAIN) {
      inputPlaceHolder = "向“思宝”提问";
    }
  }
  const autoFocus = !isMobileScreen || isChat; // only focus in chat page

  const SEARCH_TEXT_LIMIT = 30;
  const onInput = (text: string) => {
    setUserInput(text);
    const n = text.trim().length;

    // clear search results
    if (n === 0) {
      setPromptHints([]);
    } else if (n < SEARCH_TEXT_LIMIT) {
      // check if need to trigger auto completion
      if (text.startsWith("/")) {
        const searchText = text.slice(1);
        onSearch(searchText);
      }
    }
  };

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // if ArrowUp and no userInput, fill with last input
    if (
      e.key === "ArrowUp" &&
      userInput.length <= 0 &&
      !(e.metaKey || e.altKey || e.ctrlKey)
    ) {
      setUserInput(localStorage.getItem(LAST_INPUT_KEY) ?? "");
      e.preventDefault();
      return;
    }
    console.log("should submit", shouldSubmit(e));
    if (
      shouldSubmit(e)
      // && promptHints.length === 0
    ) {
      doSubmit(userInput);
      e.preventDefault();
      setUserInput("");
    }
  };

  const submit = useCallback(
    (ignoreEmptyInput?: boolean) => {
      if (currentModelValue === 6) {
        // stable diffusion
        doSubmit(userInput, true);
      } else {
        doSubmit(userInput, ignoreEmptyInput);
      }
      setUserInput("");
    },
    [doSubmit, userInput, currentModelValue],
  );

  const onPromptSelect = useCallback((prompt: Prompt) => {
    setPromptHints([]);
    inputRef.current?.focus();
    setTimeout(() => setUserInput(prompt.content), 60);
  }, []);

  const onSearch = useDebouncedCallback(
    (text: string) => {
      setPromptHints(promptStore.search(text));
    },
    100,
    { leading: true, trailing: true },
  );

  const showOutlineCall = useCallback(() => {
    if (layout.type === LayoutType.Layout_v2) {
      setShowOutline(true);
    }
  }, [layout]);

  const hideOutlineCall = useCallback(() => {
    if (layout.type === LayoutType.Layout_v2) {
      setShowOutline(false);
    }
  }, [layout]);

  return (
    <div
      className={classNames({
        [styles["chat-input-panel"]]: layout.type === LayoutType.Layout_v1,
      })}
      css={[
        layout.type === LayoutType.Layout_v2 &&
          css`
            max-width: 824px;
            width: 100%;
            padding-left: 12px;
            padding-right: 12px;
            box-sizing: border-box;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            display: flow-root;
          `,
      ]}
    >
      <PromptHints prompts={promptHints} onPromptSelect={onPromptSelect} />
      {/*输入对话框上面的面板*/}
      {/*<ChatActions*/}
      {/*    showPromptModal={() => setShowPromptModal(true)}*/}
      {/*    scrollToBottom={scrollToBottom}*/}
      {/*    hitBottom={hitBottom}*/}
      {/*    showPromptHints={() => {*/}
      {/*        // Click again to close*/}
      {/*        if (promptHints.length > 0) {*/}
      {/*            setPromptHints([]);*/}
      {/*            return;*/}
      {/*        }*/}
      {/*        inputRef.current?.focus();*/}
      {/*        setUserInput("/");*/}
      {/*        onSearch("");*/}
      {/*    }}*/}
      {/*/>*/}
      {layout.type === LayoutType.Layout_v1 && <ChatUsageStrComponent />}
      <div
        css={[
          layout.type === LayoutType.Layout_v1 &&
            css`
              background-color: var(--gray);
              margin-left: -20px;
              margin-right: -20px;
              padding-left: 14px;
              padding-right: 20px;
              min-height: 58px;
              display: flow-root;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              max-height: 180px;
              width: 100%;
              border-radius: 26px;
              background-color: var(--main-surface-secondary);
              display: flex;
              flex-direction: column;
            `,
          layout.type === LayoutType.Layout_v2 &&
            showOutline &&
            css`
              outline: solid 2px var(--hover-color);
            `,
        ]}
      >
        {layout.type === LayoutType.Layout_v1 && <ChatInputAboveComponent />}
        {layout.type === LayoutType.Layout_v1 && <ChatUploadPendingList />}
        <div
          className={classNames({
            [styles["chat-input-panel-inner"]]:
              layout.type === LayoutType.Layout_v1,
          })}
          css={
            layout.type === LayoutType.Layout_v2 &&
            css`
              display: flex;
              flex-direction: column;
            `
          }
        >
          {layout.type === LayoutType.Layout_v1 && (
            <ChatFileUploadButtonWithCondition />
          )}
          {layout.type === LayoutType.Layout_v2 && <ChatUploadPendingList />}
          <div
            className={classNames({
              [styles["chat_input_textarea_wrapper"]]:
                layout.type === LayoutType.Layout_v1,
            })}
            css={
              layout.type === LayoutType.Layout_v2 &&
              css`
                flex-grow: 1;
                flex-shrink: 1;
                display: flex;
                margin: 12px 16px;
              `
            }
          >
            {layout.type === LayoutType.Layout_v2 && userStore.role === 2 && (
              <InputMenusWrapper left>
                <InputMenus>
                  <MenuItem>
                    <ChatFileUploadButtonV2 />
                  </MenuItem>
                </InputMenus>
              </InputMenusWrapper>
            )}
            <TextareaAutosize
              ref={inputRef}
              className={classNames({
                ["bg-[#F0F1F5] border border-[#E5E5E5] text-[#333333] resize-none box-border py-[7px] px-[20px] text-[15px] font-normal focus:outline-none " +
                styles["chat_input_textarea"]]:
                  layout.type === LayoutType.Layout_v1,
              })}
              css={
                layout.type === LayoutType.Layout_v2 &&
                css`
                  resize: none;
                  border: none;
                  flex-grow: 1;
                  display: block;
                  font-size: 16px;
                  line-height: 1.75;
                  color: var(--black);
                  padding: 0;
                  outline: none;
                  min-height: 100%;
                  max-height: 100%;
                  background-color: var(--main-surface-secondary);
                  margin-left: 13px;

                  ::placeholder {
                    background-color: var(--main-surface-secondary);
                    color: var(--color-fg-muted);
                    font-size: 16px;
                  }
                `
              }
              placeholder={inputPlaceHolder}
              onInput={(e) => onInput(e.currentTarget.value)}
              value={userInput}
              onKeyDown={onInputKeyDown}
              // onFocus={() => setAutoScroll(true)}
              // onBlur={() => setAutoScroll(false)}
              onFocus={showOutlineCall}
              onBlur={hideOutlineCall}
              autoFocus={autoFocus}
              maxRows={layout.type === LayoutType.Layout_v1 ? 4 : 6}
              enterKeyHint={"send"}
            />
            {layout.type === LayoutType.Layout_v2 && (
              <InputMenusWrapper right>
                <ChatSubmitButtonV2 onSubmit={submit} />
              </InputMenusWrapper>
            )}
          </div>
          {layout.type === LayoutType.Layout_v1 && (
            <ChatSubmitButton submit={submit} />
          )}
        </div>
      </div>
      {layout.type === LayoutType.Layout_v2 && <CommonUsageNoticeBlockV2 />}
    </div>
  );
};

const ChatUploadPendingList = function () {
  const conversationId = useContext(ConversationIdContext);
  const uploadPendingList = useStoreWithEqualityFn(
    useUploadFileStore,
    (state) => state.getPendingFileList(),
    isEqual,
  ).filter((it) => it.fileType === UploadFileType.ASSISTANT);
  const uploadFileStore = useUploadFileStore();
  const sessionFileCount = useChatStore(
    useShallow((state) => state.currentSession(conversationId)?.fileCount ?? 0),
  );

  const [modal, modalContextHolder] = AntModal.useModal();
  const [showMessage, messageContextHolder] = message.useMessage();
  const [, startTransition] = useTransition();
  const [, handleUsageTypeChange] = useHandleUsageTypeChange();
  const layout = useLayoutConfigStore();

  const deleteFileFromUploadList = useCallback(
    function (uuid: string, uploadId = 0) {
      const modalInstance = modal.confirm({
        title: "确认删除文件？",
        cancelText: "取消",
        okText: "确定",
        onCancel() {
          modalInstance.destroy();
        },
        onOk() {
          const tmp = uploadPendingList.filter((item) => item.uuid !== uuid);
          if (uploadId > 0) {
            useChatStore.getState().deleteChatFile(
              uploadId,
              () => {
                void showMessage.success("删除成功");
                useUploadFileStore.getState().updatePendingFileList(tmp);
                eventBus.emit(
                  EventBus.FileInput.Assistant.Action.STATUS_ENABLE,
                );
              },
              (e) => {
                void showMessage.error(e.message);
              },
            );
          } else {
            useUploadFileStore.getState().updatePendingFileList(tmp);
          }
        },
      });
    },
    [modal, uploadPendingList, showMessage],
  );

  const updateUploadIdInUploadList = useCallback(
    function (
      uuid: string,
      fileName: string,
      uploadId: number,
      conversationId: string,
    ) {
      startTransition(() => {
        const session1 = useChatStore
          .getState()
          .sessions.find((it) => it.conversationId === conversationId)!;

        const tmp = uploadFileStore
          .getPendingFileList(session1.id)
          .map((item) => {
            if (item.uuid !== uuid) {
              return item;
            }
            item.uploadId = uploadId;
            item.fileName = fileName;
            return item;
          });
        uploadFileStore.updatePendingFileList(tmp, session1.id);
        handleUsageTypeChange("3");
      });
    },
    [handleUsageTypeChange, uploadFileStore],
  );

  return (
    <>
      {modalContextHolder}
      {messageContextHolder}
      {uploadPendingList.length > 0 && (
        <div
          css={[
            css`
              display: flex;
              flex-wrap: nowrap;
              overflow-x: scroll;
              max-height: 52px;
              box-sizing: border-box;

              div:first-of-type {
                margin-left: 0;
              }
            `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                border-bottom: 1px solid #ecf5fa;
                width: calc(100% - 28px);
                margin-left: 14px;
                margin-right: 14px;
                max-height: 100px;
              `,
          ]}
        >
          {uploadPendingList.map((fileItem, index) => {
            return (
              <FileUploadItem
                key={fileItem.uuid}
                uuid={fileItem.uuid}
                fileName={fileItem.fileName}
                file={fileItem.file}
                onDelete={deleteFileFromUploadList}
                length={sessionFileCount + (uploadPendingList.length - index)}
                onUpload={(uuid, fileName, uploadId, conversationId) => {
                  updateUploadIdInUploadList(
                    uuid,
                    fileName,
                    uploadId,
                    conversationId,
                  );
                }}
                uploadId={fileItem.uploadId}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

const ChatFileUploadButtonWithCondition = function () {
  const conversationId = useContext(ConversationIdContext);
  const role = useUserStore(useShallow((state) => state.role));
  const currentModel = useChatStore(
    useShallow(
      (state) => state.currentSession(conversationId)?.currentModel ?? 0,
    ),
  );
  let result = <></>;
  if (role === 2) {
    if ([0, 1, 3].includes(currentModel)) {
      result = <ChatFileUploadButton />;
    }
  }

  return result;
};

const ChatUsageStrComponent = function () {
  const conversationId = useContext(ConversationIdContext);
  const usageStr = useBalanceStr();
  const currentModel = useChatStore(
    useShallow(
      (state) => state.currentSession(conversationId)?.currentModel ?? 0,
    ),
  );

  const isSearchWiderLayout = useSearchWiderLayout();

  let result = (
    <div className="w-full text-right text-[#91A2BF] text-sm flex-shrink-0 whitespace-pre-line">
      {usageStr}
    </div>
  );

  if (currentModel === 0 || currentModel === 1) {
    if (!isSearchWiderLayout) {
      result = <></>;
    }
  }

  return result;
};

const ChatInputAboveComponent = function () {
  const conversationId = useContext(ConversationIdContext);
  const currentModelValue = useChatStore(
    useShallow(
      (state) => state.currentSession(conversationId)?.currentModel ?? 0,
    ),
  );
  const isSearchWiderLayout = useSearchWiderLayout();
  const usageStr = useBalanceStr();

  let result = <></>;

  if (currentModelValue === 0 || currentModelValue === 1) {
    if (!isSearchWiderLayout) {
      result = (
        <div
          css={css`
            margin-bottom: 5px;
            margin-top: 20px;
            display: flex;
          `}
        >
          <EnableSearchButton />
          <span
            css={css`
              color: #90a0bf;
              font-size: 14px;
              margin-left: auto;
            `}
          >
            {usageStr}
          </span>
        </div>
      );
    }
  }

  return result;
};

const ChatSubmitButton = function (props: {
  submit?: (ignoreEmpty?: boolean) => void | Promise<void>;
}) {
  const conversationId = useContext(ConversationIdContext);
  const currentModelValue = useChatStore(
    useShallow(
      (state) => state.currentSession(conversationId)?.currentModel ?? 0,
    ),
  );
  const propSubmit = props.submit;

  const submit = useCallback(() => {
    if (propSubmit) {
      if (currentModelValue === 6) {
        // stable diffusion
        propSubmit(true);
        return;
      }
      propSubmit();
    }
  }, [currentModelValue, propSubmit]);

  return (
    <div
      className={
        styles["chat-input-send"] +
        " flex items-center justify-center w-[106px] bg-gradient-to-t to-[#0c74ed] from-[#1fd6cc] cursor-pointer hover:opacity-75 my-auto flex-shrink-0"
      }
      onClick={submit}
    >
      <div className="text-white text-sm font-normal">{Locale.Chat.Send}</div>
    </div>
  );
};
