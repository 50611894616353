"use client";
import React, { useCallback, useRef } from "react";
import { css } from "@emotion/react";
import NextImage from "next/image";
import AddIcon from "@/app/icons/chat/add-icon.png";

export const SmallClickInput = function (
  props: React.PropsWithChildren<{
    title?: string;
    accept?: string;
    onChange?: (file: File[]) => void;
    beforeUpload?: () => boolean;
    multiple?: boolean;
  }>,
) {
  const propChange = props.onChange;
  const propBeforeUpload = props.beforeUpload;

  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      const files = [...(target.files ?? [])];
      if (propChange) {
        propChange(files);
      }
      target.value = "";
    },
    [propChange],
  );

  const beforeUpload = useCallback(() => {
    if (propBeforeUpload) {
      inputRef.current!.disabled = false;
      const result = propBeforeUpload();
      inputRef.current!.disabled = !result;
    }
  }, [propBeforeUpload]);

  return (
    <label
      css={css`
        display: flex;
        height: 30px;
        margin-top: auto;
        margin-bottom: auto;

        :hover {
          background-color: var(--main-surface-tertiary);
        }

        :hover div {
          color: var(--text-primary);
        }

        :hover path {
          fill: #248eb3;
        }

        :hover span {
          color: #248eb3;
        }
      `}
      onClick={beforeUpload}
    >
      <div
        css={css`
          width: 20px;
          height: 20px;
          margin-top: auto;
          margin-bottom: auto;
          margin-left: 6px;
        `}
      >
        {props.children}
      </div>
      <span
        css={css`
          font-size: 14px;
          display: block;
          color: var(--text-primary);
          line-height: 30px;
          height: 30px;
          margin-right: 6px;
        `}
      >
        {props.title}
      </span>
      <input
        type="file"
        css={css`
          display: none;
        `}
        ref={inputRef}
        multiple={props.multiple}
        accept={props.accept}
        onChange={onChange}
      />
    </label>
  );
};

export const FileInputIconV1 = function (
  props: React.PropsWithChildren<{
    title?: string;
    accept?: string;
    onChange?: (file: File[]) => void;
    beforeUpload?: () => boolean;
    multiple?: boolean;
  }>,
) {
  const propBeforeUpload = props.beforeUpload;
  const propChange = props.onChange;
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      const files = [...(target.files ?? [])];
      if (propChange) {
        propChange(files);
      }
      target.value = "";
    },
    [propChange],
  );

  const beforeUpload = useCallback(() => {
    if (propBeforeUpload) {
      inputRef.current!.disabled = false;
      const result = propBeforeUpload();
      inputRef.current!.disabled = !result;
    }
  }, [propBeforeUpload]);

  return (
    <label
      css={css`
        width: 24px;
        height: 24px;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 10px;
      `}
      onClick={beforeUpload}
    >
      <NextImage src={AddIcon} alt={""} width={24} height={24}></NextImage>
      <input
        type="file"
        ref={inputRef}
        onChange={onChange}
        multiple={props.multiple}
        css={css`
          display: none;
        `}
      />
    </label>
  );
};
