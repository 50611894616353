"use client";
import { createWithEqualityFn } from "zustand/traditional";
import isEqual from "react-fast-compare";
import { getSiteTypeByHost } from "@/app/utils/getSiteType";
import { SiteType } from "@/app/enums/siteType";

export interface SiteTypeStore {
  siteType: SiteType;
  siteHost: string;
}

interface SiteTypeAction {
  updateSiteType: (str: string) => SiteType;
}

export const useSiteType = createWithEqualityFn<
  SiteTypeStore & SiteTypeAction
>()((setState) => {
  let host = "";
  const result = {
    siteType: SiteType.UNDETERMINED,
    siteHost: "",
    updateSiteType(str: string) {
      const site = getSiteTypeByHost(str);
      setState({ siteType: site });
      return site;
    },
  };
  if (typeof window !== "undefined") {
    host = window.location.hostname;
    result.siteHost = window.location.origin;
  }

  result.siteType = getSiteTypeByHost(host);

  return result;
}, isEqual);
if (typeof window !== "undefined") {
  globalThis.overrideSiteType = (str: string) => {
    const site = useSiteType.getState().updateSiteType(str);
    console.log("SiteType." + SiteType[site]);
  };
}
