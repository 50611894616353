"use client";
import { getClientConfig } from "../config/client";
import { ChatMessage, ModelType, useAccessStore, useChatStore } from "../store";
import { ChatGPTApi } from "./platforms/openai";
import { match, P } from "ts-pattern";
import { BaiduErnieAPI } from "@/app/client/platforms/baidu";
import { WanxChatApi } from "@/app/client/platforms/wanx";
import { PicWishChatApi } from "@/app/client/platforms/picwish";
import { ChatGPTWithBingApi } from "@/app/client/platforms/openai_bing";
import { LLMApi } from "@/app/client/classes";
import { MindmapApi } from "@/app/client/platforms/mindmap";

export const ROLES = ["system", "user", "assistant"] as const;
export type MessageRole = (typeof ROLES)[number];

export const Models = ["gpt-3.5-turbo", "gpt-4"] as const;
export type ChatModel = ModelType;

export interface RequestMessage {
  role: MessageRole;
  content: string;
}

export interface LLMConfig {
  model: string;
  temperature?: number;
  top_p?: number;
  stream?: boolean;
  presence_penalty?: number;
  frequency_penalty?: number;
  lang?: string;
}

export interface ChatOptions {
  messages: RequestMessage[];
  config: LLMConfig;
  conversationId?: string;
  onUpdate?: (message: string, chunk: string) => void;
  onFinish: (
    message: string,
    additionalInfo?: Pick<ChatMessage, "messageUUID">,
  ) => void;
  onError?: (err: Error, additionalInfo?: { code?: number }) => void;
  onController?: (controller: AbortController) => void;
}

export interface LLMUsage {
  used: number;
  total: number;
}

export interface ChatSingleOptions {
  conversationId: string;
  messageString: string;
  additionalMessage?: string;
  onUpdate?: (message: string, chunk: string) => void;
  onFinish: (
    message: string,
    additionalInfo?: Pick<ChatMessage, "messageUUID">,
  ) => void;
  onError?: (err: Error) => void;
  onController?: (controller: AbortController) => void;
}

type ProviderName = "openai" | "azure" | "claude" | "palm";

interface Model {
  name: string;
  provider: ProviderName;
  ctxlen: number;
}

interface ChatProvider {
  name: ProviderName;
  apiConfig: {
    baseUrl: string;
    apiKey: string;
    summaryModel: Model;
  };
  models: Model[];

  chat: () => void;
  usage: () => void;
}

export enum APIProviderType {
  OPENAI = 0,
  BAIDU = 1,
  WANX = 2,
  PICWISH = 3,
  OPENAI_BING = 4,
  MIND_MAP = 5,
}

export class ClientApi {
  public llm!: LLMApi;

  constructor(provider: APIProviderType = APIProviderType.OPENAI) {
    match(provider)
      .with(APIProviderType.OPENAI, () => {
        this.llm = new ChatGPTApi();
      })
      .with(APIProviderType.BAIDU, () => {
        this.llm = new BaiduErnieAPI();
      })
      .with(APIProviderType.WANX, () => {
        this.llm = new WanxChatApi();
      })
      .with(APIProviderType.PICWISH, () => {
        this.llm = new PicWishChatApi();
      })
      .with(APIProviderType.OPENAI_BING, () => {
        this.llm = new ChatGPTWithBingApi();
      })
      .with(APIProviderType.MIND_MAP, () => {
        this.llm = new MindmapApi();
      })
      .otherwise(() => {
        throw new Error("Api provider type has no impl. provider: " + provider);
      });
  }

  config() {}

  prompts() {}

  masks() {}

  async share(messages: ChatMessage[], avatarUrl: string | null = null) {
    const msgs = messages
      .map((m) => ({
        from: m.role === "user" ? "human" : "gpt",
        value: m.content,
      }))
      .concat([
        {
          from: "human",
          value:
            "Share from [ChatGPT Next Web]: https://github.com/Yidadaa/ChatGPT-Next-Web",
        },
      ]);
    // 敬告二开开发者们，为了开源大模型的发展，请不要修改上述消息，此消息用于后续数据清洗使用
    // Please do not modify this message

    console.log("[Share]", msgs);
    const clientConfig = getClientConfig();
    const proxyUrl = "/sharegpt";
    const rawUrl = "https://sharegpt.com/api/conversations";
    const shareUrl = clientConfig?.isApp ? rawUrl : proxyUrl;
    const res = await fetch(shareUrl, {
      body: JSON.stringify({
        avatarUrl,
        items: msgs,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    const resJson = await res.json();
    console.log("[Share]", resJson);
    if (resJson.id) {
      return `https://shareg.pt/${resJson.id}`;
    }
  }
}

export const api = new ClientApi();

export function getHeaders() {
  const accessStore = useAccessStore.getState();
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    "x-requested-with": "XMLHttpRequest",
  };

  const makeBearer = (token: string) => `Bearer ${token.trim()}`;
  const validString = (x: string) => x && x.length > 0;

  if (validString(accessStore.token)) {
    headers.Authorization = makeBearer(accessStore.token);
  }

  return headers;
}

export function apiClientTypeByUsageType(
  usage: number,
  conversationId?: string,
): APIProviderType {
  const session = useChatStore.getState().currentSession(conversationId);
  return match(usage)
    .with(
      P.when((it) => {
        if ([0, 1].includes(it)) {
          return !(session?.shouldUseSearch ?? false);
        }
        return [2, 3, 6, 7].includes(it);
      }),
      () => {
        return APIProviderType.OPENAI;
      },
    )
    .with(
      P.when((it) => [4, 5].includes(it)),
      () => {
        return APIProviderType.BAIDU;
      },
    )
    .with(
      P.when((it) => [8].includes(it)),
      () => {
        return APIProviderType.WANX;
      },
    )
    .with(
      P.when((it) => [9].includes(it)),
      () => {
        return APIProviderType.PICWISH;
      },
    )
    .with(
      P.when((it) => {
        if ([0, 1].includes(it)) {
          return session?.shouldUseSearch ?? false;
        }
        return [10].includes(it);
      }),
      () => {
        return APIProviderType.OPENAI_BING;
      },
    )
    .with(
      P.when((it) => {
        // 思维导图
        return [11].includes(it);
      }),
      () => {
        return APIProviderType.MIND_MAP;
      },
    )
    .with(
      P.when((it) => {
        // gemini, claude, o1-preview, o1-mini
        // 共用接口
        return [12, 13, 14, 15].includes(it);
      }),
      () => {
        return APIProviderType.OPENAI;
      },
    )
    .otherwise(() => {
      return APIProviderType.OPENAI;
    });
}
