"use client";
import React, { useCallback, useState } from "react";
import { useBalanceStr } from "@/app/utils/userUsage";
import { useSubmit } from "@/app/hooks/chatHooks";
import {
  CommonPromptContainerBlock,
  CommonPromptInputBlock,
} from "@/app/components/chatComponents/commonLib";
import { useSiteType } from "@/app/store/siteType";
import { SiteType } from "@/app/enums/siteType";

export const MindMapInputArea = function (
  props: React.PropsWithChildren<{
    usageStr?: string;
    updatePromptsInput?: (str: string) => void;
    submit?: (ignoreEmpty: boolean) => void;
  }>,
) {
  const usageStr = useBalanceStr();
  const doSubmit = useSubmit();
  const [userInput, setUserInput] = useState("");
  const site = useSiteType();

  const submit = useCallback(
    (ignoreEmptyInput: boolean) => {
      doSubmit(userInput, ignoreEmptyInput);
      setUserInput("");
    },
    [doSubmit, userInput],
  );

  const updateUserInput = useCallback((value: string) => {
    setUserInput(value);
  }, []);

  let placeHolder = '向"思宝"提问，不超过200字';
  if (site.siteType === SiteType.TRAIN || site.siteType === SiteType.EXAM) {
    placeHolder = "请输入提示词，不超过200字";
  }

  return (
    <CommonPromptContainerBlock usageStr={usageStr} hideTopPending={true}>
      <CommonPromptInputBlock
        updatePrompts={updateUserInput}
        submit={submit}
        placeHolder={placeHolder}
      />
    </CommonPromptContainerBlock>
  );
};
