"use client";

export const openLinkInNewTab = function (str: string) {
  const anchor = document.createElement("a");
  anchor.href = str;
  anchor.target = "_blank";
  document.body.append(anchor);
  anchor.click();
  anchor.remove();
};
