"use client";
import React, { useCallback, useState } from "react";
import { Dropdown, Menu, MenuButton } from "@mui/joy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { css } from "@emotion/react";

export const InputMenus = function (props: React.PropsWithChildren<object>) {
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = useCallback(
    (_: React.SyntheticEvent | null, value: boolean) => {
      setMenuOpen(value);
    },
    [],
  );

  return (
    <Dropdown open={menuOpen} onOpenChange={closeMenu}>
      <MenuButton
        variant={"plain"}
        sx={{
          width: "36px",
          height: "36px",
          minHeight: "36px",
          minWidth: "36px",
          paddingInlineStart: "0",
          paddingInlineEnd: "0",
          paddingBlockStart: "0",
          paddingBlockEnd: "0",
          borderRadius: "50%",
          margin: "auto",
          ":hover": {
            backgroundColor: "var(--hover-color)",
          },
        }}
      >
        <FontAwesomeIcon
          icon={faPaperclip}
          style={{
            color: "var(--black)",
            width: "16px",
            height: "16px",
            rotate: "-45deg",
          }}
        />
      </MenuButton>
      <Menu
        variant={"plain"}
        sx={{
          border: "1px solid var(--border-light)",
          backgroundColor: "var(--main-surface-secondary)",
        }}
        keepMounted={true}
      >
        {props.children}
      </Menu>
    </Dropdown>
  );
};

export const InputMenusWrapper = function (
  props: React.PropsWithChildren<{ left?: boolean; right?: boolean }>,
) {
  return (
    <div
      css={[
        css`
          margin-top: auto;
          margin-bottom: 0;
          overflow: visible;
          display: flow-root;
          width: 36px;
          height: 28px;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
        `,
        props.left &&
          css`
            margin-left: -2px;
          `,
        props.right &&
          css`
            margin-right: -2px;
          `,
      ]}
    >
      <div
        css={css`
          height: 36px;
          width: 36px;
          margin-top: -4px;
          margin-bottom: 0;
          display: flex;
        `}
      >
        {props.children}
      </div>
    </div>
  );
};
