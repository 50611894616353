"use client";
import styles from "./wechat-pay.module.scss";

import { useNavigate, useSearchParams } from "react-router-dom";
import { Path } from "../constant";
import NextImage from "next/image";
import HeadLogoIcon from "@/app/icons/pricing/head-logo.png";
import BackIcon from "@/app/icons/pay/back-icon.png";
import { useAccessStore } from "@/app/store";
import { usePayStore } from "@/app/store/pay";
import { useCallback, useEffect, useMemo } from "react";
import { virtualCoinAmountTransform } from "@/app/utils/price";
import { message } from "antd";
import {
  matchPaymentPlatform,
  PaymentPlatform,
  scanCodeTips,
} from "@/app/enums/platform";
import * as url from "node:url";

export function PayCodePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const platform = searchParams.get("platform") || "alipay";
  const payPlatform = matchPaymentPlatform(platform);
  console.log("payPlatform", payPlatform);
  const amount = searchParams.get("amount") || "10";
  const payStore = usePayStore();
  const accessStore = useAccessStore();
  const [messageApi, messageContextHolder] = message.useMessage();

  const priceInfo = useMemo(() => {
    return virtualCoinAmountTransform(amount);
  }, [amount]);

  const goHome = useCallback(() => navigate(Path.Home), [navigate]);
  const showErrorMessage = useCallback(
    (msg: string) => {
      void messageApi.error(msg);
    },
    [messageApi],
  );

  useEffect(() => {
    if (!accessStore.isAuthorized()) {
      navigate(Path.Auth, { replace: true });
      return;
    }
    usePayStore.getState().fetchPayCode(amount, platform, showErrorMessage);

    let intervalId = undefined;
    intervalId = setInterval(() => {
      usePayStore.getState().fetchPayStatus();
    }, 3000);

    // 当组件卸载时，取消计时器
    return () => {
      clearInterval(intervalId);
    };
  }, [amount, platform, accessStore, navigate, showErrorMessage, payPlatform]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className={styles["wechat-pay-page"]}>
      {messageContextHolder}
      {/*头部*/}
      <div className="flex bg-white h-[80px] justify-center items-center">
        <div className="flex items-center ml-[18.75%] hover:opacity-75 cursor-pointer">
          <NextImage
            src={HeadLogoIcon}
            alt="头部logo"
            width={50}
            height={50}
            onClick={goHome}
          ></NextImage>
          <div className={styles["logo-title"]}>CAIE</div>
        </div>

        <div className="flex-grow"></div>
        {/*<NextImage*/}
        {/*  className="mr-[18.75%] hover:opacity-75 cursor-pointer"*/}
        {/*  src={HeadAvatarIcon}*/}
        {/*  alt="头像"*/}
        {/*  width={46}*/}
        {/*  height={46}*/}
        {/*></NextImage>*/}
      </div>
      {/*内容*/}
      <div className={styles["content-wrapper"]}>
        <div className={styles["pay-wrapper"] + " text-[#555555] text-base"}>
          <NextImage
            className="hover:opacity-75 cursor-pointer"
            src={BackIcon.src}
            alt="返回"
            width={20}
            height={20}
            onClick={handleBack}
          ></NextImage>
          <div className="flex w-full justify-center gap-[7px] text-[#555555] mt-[27px]">
            <div className="text-base font-bold">¥</div>
            <div className="text-[30px] font-bold">
              {priceInfo.status === "error" ? "?" : amount}
            </div>
          </div>
          <div className="w-[124px] h-[134px] rounded-[8px] bg-[#d8d8d8] mt-[20px] self-center">
            {}
            <img
              className="w-[124px] h-[134px] rounded-[8px] self-center"
              src={payStore.payCode || undefined}
              alt=""
            ></img>
          </div>
          <div className="text-[#555555] text-base font-normal mt-[20px] self-center">
            {scanCodeTips(payPlatform)}
          </div>
        </div>
      </div>
    </div>
  );
}
