import { OpenaiPath, REQUEST_TIMEOUT_MS } from "@/app/constant";
import { useAccessStore, useAppConfig, useChatStore } from "@/app/store";
import { throttle } from "throttle-debounce";

import { ChatOptions, getHeaders, LLMUsage } from "../api";
import Locale from "../../locales";
import {
  ChatEnums,
  getModelStringByEnum,
  getQualityStringByEnum,
  getSizeStringByEnum,
  getStyleStringByEnum,
} from "@/app/enums/chat";
import { UploadFileType } from "@/app/store/uploadFiles";
import { LLMApi } from "@/app/client/classes";
import { responseProcess } from "@/app/utils/responseProcess";
import { usageTypeTransform } from "@/app/client/usageTypeTransform";
import { parse } from "date-fns/parse";
import { matchErrorCode } from "@/app/client/errorCode";

export const fetchTimeout = async (
  _fetch: Promise<Response>,
  timeout: number,
) => {
  return Promise.race<Response>([
    _fetch,
    new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error("请求超时")), timeout);
    }),
  ]);
};

export class ChatGPTApi extends LLMApi {
  public constructor() {
    super();
  }

  path(path: string): string {
    let openaiUrl = useAccessStore.getState().openaiUrl;
    if (openaiUrl.endsWith("/")) {
      openaiUrl = openaiUrl.slice(0, openaiUrl.length - 1);
    }
    return [openaiUrl, path].join("/");
  }

  extractMessage(res: any) {
    return res.choices?.at(0)?.message?.content ?? "";
  }

  async chat(options: ChatOptions) {
    console.log("options", options);
    const messages = options.messages.map((v) => ({
      role: v.role,
      content: v.content,
    }));

    const hasFileAttachment = function () {
      for (const message of options.messages) {
        // @ts-ignore
        if (message.file_ids && message.file_ids.length > 0) {
          return true;
        }
      }
      return false;
    };

    console.log("has file attachment? ", hasFileAttachment());

    const currentSession =
      useChatStore
        .getState()
        .sessions.find((it) => it.conversationId === options.conversationId) ??
      null;
    if (!currentSession) {
      options.onError?.(new Error("No session found."), { code: -1 });
      return;
    }

    const modelConfig = {
      ...useAppConfig.getState().modelConfig,
      ...currentSession.mask.modelConfig,
      name: currentSession.mask.name,
      ...{
        model: options.config.model,
      },
    };

    console.log("modelConfig", modelConfig);

    // let conversationId = cache.get(CONVERSATION_STORAGE_KEY);
    // if (!conversationId) {
    //   conversationId = v4();
    //   cache.set(CONVERSATION_STORAGE_KEY, conversationId);
    // }

    const conversationId = currentSession.conversationId;
    const requestPayload = {
      messages,
      conversation_id: conversationId,
      stream: options.config.stream,
      model: modelConfig.model,
      usage_type: usageTypeTransform(currentSession.currentModel!),
      temperature: modelConfig.temperature,
      presence_penalty: modelConfig.presence_penalty,
      template_name: modelConfig.name,
      lang: options.config.lang,
      operation_mode: undefined as number | undefined,
      size: undefined as string | undefined,
      quality: undefined as string | undefined,
      style: undefined as string | undefined,
      image_path: undefined as string | undefined,
      mask_image_path: undefined as string | undefined,
      ppt_author: undefined as string | undefined,
      ppt_create_model: undefined as string | undefined,
      ppt_theme: undefined as string | undefined,
      ppt_is_card_note: undefined as boolean | undefined,
      n: undefined as number | undefined,
      return_html_app_token: "1",
    };

    if (requestPayload.usage_type === 2) {
      const imageOptions = currentSession.imageOptions!;
      requestPayload.operation_mode = imageOptions.operation;
      requestPayload.size = getSizeStringByEnum(imageOptions.imageSize);
      requestPayload.quality = getQualityStringByEnum(imageOptions.quality);
      requestPayload.style = getStyleStringByEnum(imageOptions.variant);
      requestPayload.model = getModelStringByEnum(imageOptions.model);
      const originalImageObj = currentSession.pendingFileList!.find(
        (it) => it.fileType === UploadFileType.DALL_E_ORIGINAL,
      );
      const maskImageObj = currentSession.pendingFileList!.find(
        (it) => it.fileType === UploadFileType.DALL_E_MASK,
      );
      requestPayload.image_path = originalImageObj?.filePath;
      requestPayload.mask_image_path = maskImageObj?.filePath;
    }

    // PPT 相关
    if (requestPayload.usage_type === 7) {
      requestPayload.ppt_author = currentSession.pptOptions!.author ?? "";
      requestPayload.ppt_create_model =
        currentSession.pptOptions!.operation ?? ChatEnums.PPT.Operation.AUTO;
      requestPayload.ppt_theme =
        currentSession.pptOptions!.theme ?? ChatEnums.PPT.Theme.AUTO;
      requestPayload.ppt_is_card_note =
        currentSession.pptOptions!.generateNotes === ChatEnums.PPT.Notes.Y;
    }
    // stable diffusion
    if (requestPayload.usage_type === 6) {
      requestPayload.operation_mode =
        currentSession.stableDiffusionOptions?.operation;
      requestPayload.n = currentSession.stableDiffusionOptions?.count;
      requestPayload.size = currentSession.stableDiffusionOptions?.size;
      const imageObj = currentSession.pendingFileList!.find(
        (it) => it.fileType === UploadFileType.STABLE_DIFFUSION,
      );
      requestPayload.image_path = imageObj?.filePath;
    }

    console.log("[Request] openai payload: ", requestPayload);

    const shouldStream = !!options.config.stream;
    const controller = new AbortController();
    options.onController?.(controller);

    try {
      let chatPath = this.path(OpenaiPath.ChatPath);
      const chatPayload = {
        method: "POST",
        body: JSON.stringify(requestPayload),
        // signal: controller.signal,
        headers: getHeaders(),
      };

      // make a fetch request
      const requestTimeoutId = setTimeout(
        () => controller.abort(),
        hasFileAttachment() ? 18e4 : REQUEST_TIMEOUT_MS,
      );

      chatPath = "/v1/asst/chat/completions";
      // chatPath = "http://103.53.211.33:38102/assistants/v1/chat/completions"
      // chatPath = "https://i.caie.ai/assistants/v1/chat/completions"
      // chatPath = "http://127.0.0.1:5000/v1/chat/completions"
      console.log("请求地址是:", chatPath);

      if (shouldStream) {
        let responseText = "";
        let finished = false;

        const finish = () => {
          if (!finished) {
            options.onFinish(responseText, { messageUUID: messageUuid });
            finished = true;
          }
        };
        controller.signal.onabort = finish;

        const res = await fetchTimeout(
          fetch(chatPath, {
            ...chatPayload,
          }),
          hasFileAttachment() ? 18e4 : REQUEST_TIMEOUT_MS,
        );
        const balance_3_5 = res.headers.get("balance_3_5");
        const balance_4_0 = res.headers.get("balance_4_0");
        const balance_dall_e = res.headers.get("balance_dall_e");
        // const conversationId = res.headers.get("Conversation_id") ?? "";
        const messageUuid = res.headers.get("Message_uuid") ?? "";
        const token = res.headers.get("token");
        const code = res.headers.get("code");
        // useUserStore
        //   .getState()
        //   .updateBalance(balance_3_5, balance_4_0, token, balance_dall_e);
        console.log(
          "剩余使用次数?",
          balance_3_5,
          balance_4_0,
          token,
          balance_dall_e,
        );
        const contentType = res.headers.get("content-type");
        console.log("[OpenAI] request response content type: ", contentType);

        if (code && code.length > 0 && !isNaN(parseInt(code))) {
          const intCode = parseInt(code);
          if (matchErrorCode(intCode).isError) {
            const message = await res.clone().text();
            console.log("response error with code", code, "message", message);
            options.onError?.(new Error(message), {
              code: parseInt(code),
            });
            await responseProcess(res, true);
            return;
          }
        }

        if (requestPayload.usage_type == 2) {
          const tmp = await res.clone().text();
          let responseJson;
          if (!(await responseProcess(res, false))) {
            return finish();
          }
          try {
            responseJson = JSON.parse(tmp);
          } catch (e) {
            console.warn(e);
            responseText = tmp;
            return finish();
          }
          if (responseJson.code !== 0) {
            responseText = responseJson.msg ?? "获取图片出错";
            return finish();
          }
          responseText = `![](${responseJson["data"][0]["url"]})`;
          return finish();
        }
        // o1-mini, o1-preview
        // if (
        //   requestPayload.usage_type === 10 ||
        //   requestPayload.usage_type === 11
        // ) {
        //   const tmp = await res.clone().text();
        //   let responseJson;
        //   if (!(await responseProcess(res, false))) {
        //     return finish();
        //   }
        //   try {
        //     responseJson = JSON.parse(tmp);
        //   } catch (e) {
        //     console.warn(e);
        //     responseText = tmp;
        //     return finish();
        //   }
        //   if (responseJson.code !== 0) {
        //     responseText = responseJson.msg ?? "获取数据是出错";
        //     return finish();
        //   }
        //   responseText = responseJson.text ?? "内容为空";
        //   return finish();
        // }

        if (contentType?.startsWith("text/plain")) {
          responseText = await res.clone().text();
          return finish();
        }

        const reader = res.body?.getReader();
        if (!reader) {
          // 不支持流式
          console.log("不支持流式调用");
          responseText = (await res.json()).text;
          await responseProcess(res, true);
          return finish();
        }
        let firstSuccess = false;
        const textDecoder = new TextDecoder();
        const throttleUpdateFun = throttle(
          100,
          (fullText: string, diff: string) => {
            options.onUpdate?.(fullText, diff);
          },
        );
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            await responseProcess(res, true);
            return finish();
          }
          const streamText = textDecoder.decode(value);
          responseText += streamText;
          // options.onUpdate?.(responseText, streamText);
          throttleUpdateFun(responseText, streamText);
          firstSuccess = true;
        }
        if (!firstSuccess) {
          responseText = "解析数据错误,请稍后再试";
          return finish();
        }
      } else {
        const res = await fetch(chatPath, chatPayload);
        clearTimeout(requestTimeoutId);

        const resJson = await res.json();
        const message = this.extractMessage(resJson);
        await responseProcess(res, true);
        options.onFinish(message);
      }
    } catch (e) {
      console.log("[Request] failed to make a chat request", e);
      options.onError?.(e as Error, { code: -1 });
    }
  }

  async usage() {
    const formatDate = (d: Date) =>
      `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d
        .getDate()
        .toString()
        .padStart(2, "0")}`;
    const ONE_DAY = 1 * 24 * 60 * 60 * 1000;
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const startDate = formatDate(startOfMonth);
    const endDate = formatDate(new Date(Date.now() + ONE_DAY));

    const [used, subs] = await Promise.all([
      fetch(
        this.path(
          `${OpenaiPath.UsagePath}?start_date=${startDate}&end_date=${endDate}`,
        ),
        {
          method: "GET",
          headers: getHeaders(),
        },
      ),
      fetch(this.path(OpenaiPath.SubsPath), {
        method: "GET",
        headers: getHeaders(),
      }),
    ]);

    if (used.status === 401) {
      throw new Error(Locale.Error.Unauthorized);
    }

    if (!used.ok || !subs.ok) {
      throw new Error("Failed to query usage from openai");
    }

    const response = (await used.json()) as {
      total_usage?: number;
      error?: {
        type: string;
        message: string;
      };
    };

    const total = (await subs.json()) as {
      hard_limit_usd?: number;
    };

    if (response.error && response.error.type) {
      throw Error(response.error.message);
    }

    if (response.total_usage) {
      response.total_usage = Math.round(response.total_usage) / 100;
    }

    if (total.hard_limit_usd) {
      total.hard_limit_usd = Math.round(total.hard_limit_usd * 100) / 100;
    }

    return {
      used: response.total_usage,
      total: total.hard_limit_usd,
    } as LLMUsage;
  }
}

export { OpenaiPath };
