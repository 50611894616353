import { ChatOptions, getHeaders, LLMUsage } from "@/app/client/api";
import { ChatSession, useChatStore } from "@/app/store";
import { match } from "ts-pattern";
import { ChatEnums } from "@/app/enums/chat";
import { UploadFileType } from "@/app/store/uploadFiles";
import { fetchTimeout } from "@/app/client/platforms/openai";
import { useSiteType } from "@/app/store/siteType";
import { LLMApi } from "@/app/client/classes";

export class WanxChatApi extends LLMApi {
  public constructor() {
    super();
  }

  async chat(options: ChatOptions) {
    const session = useChatStore
      .getState()
      .sessions.find((it) => it.conversationId === options.conversationId);
    if (!session) {
      options.onError?.(new Error("session not found"), { code: -1 });
      return;
    }
    const wanxOptions = session.wanxOptions!;

    match(wanxOptions.operation)
      .with(ChatEnums.Wanx.Operation.GENERATE, () => {
        this.textToImage(options, session);
      })
      .with(ChatEnums.Wanx.Operation.IMAGE_EDIT, () => {
        this.anyText(options, session);
      })
      .with(ChatEnums.Wanx.Operation.IMAGE_GENERATE, () => {
        this.anyText(options, session);
      })
      .with(ChatEnums.Wanx.Operation.TEXTURE, () => {
        this.texture(options, session);
      })
      .with(ChatEnums.Wanx.Operation.SEMANTIC, () => {
        this.semantic(options, session);
      })
      .otherwise(() => {
        throw new Error("Unsupported operation");
      });
  }

  usage(): Promise<LLMUsage> {
    throw new Error("Method not implemented.");
  }

  async textToImage(options: ChatOptions, session: ChatSession) {
    const message = options.messages.at(options.messages.length - 1)!.content;
    const wanxOptions = session.wanxOptions!;
    let img = session.pendingFileList?.find(
      (it) => it.fileType === UploadFileType.WANX_TEXT_BASE,
    )?.imageUrl;

    if (img) {
      img = useSiteType.getState().siteHost + img;
    }

    const requestPayload = {
      conversation_id: session.conversationId!,
      prompt: message,
      negative_prompt: wanxOptions.negativePrompt,
      ref_img: img,
      style: wanxOptions.style,
      size: wanxOptions.size,
      n: wanxOptions.n,
      seed: wanxOptions.seed,
      ref_strength: wanxOptions.ref_strength,
      ref_mode: wanxOptions.ref_mode,
      return_html_app_token: "1",
    };

    try {
      const resp = await fetchTimeout(
        fetch("/v1/asst/wanx/text2image", {
          headers: getHeaders(),
          method: "POST",
          body: JSON.stringify(requestPayload),
        }),
        60_000,
      );

      void this.finish(resp, options);
    } catch (e) {
      console.log("request text2image failed,", e);
      options.onError?.(e as Error, { code: -1 });
    }
  }

  async anyText(options: ChatOptions, session: ChatSession) {
    const message = options.messages.at(options.messages.length - 1)!.content;
    const wanxOptions = session.wanxOptions!;
    let baseImg = session.pendingFileList?.find(
      (it) => it.fileType === UploadFileType.WANX_TEXT_BASE,
    )?.imageUrl;
    let maskImg = session.pendingFileList?.find(
      (it) => it.fileType === UploadFileType.WANX_TEXT_MASK,
    )?.imageUrl;

    if (baseImg) {
      baseImg = useSiteType.getState().siteHost + baseImg;
    }
    if (maskImg) {
      maskImg = useSiteType.getState().siteHost + maskImg;
    }

    const requestPayload = {
      conversation_id: session.conversationId!,
      prompt: message,
      mask_image_url: maskImg,
      base_image_url:
        wanxOptions.operation === ChatEnums.Wanx.Operation.IMAGE_EDIT
          ? baseImg
          : undefined,
      appended_prompt:
        wanxOptions.additionalPrompt && wanxOptions.additionalPrompt !== ""
          ? wanxOptions.additionalPrompt
          : undefined,
      negative_prompt:
        wanxOptions.negativePrompt && wanxOptions.negativePrompt !== ""
          ? wanxOptions.negativePrompt
          : undefined,
      layout_priority: wanxOptions.layout_priority,
      text_position_revise: wanxOptions.text_position_revise,
      n: wanxOptions.n,
      steps: wanxOptions.steps,
      image_width: wanxOptions.image_width,
      image_height: wanxOptions.image_height,
      strength: wanxOptions.strength,
      cfg_scale: wanxOptions.cfg_scale,
      eta: wanxOptions.eta,
      seed: wanxOptions.seed,
      return_html_app_token: "1",
    };

    try {
      const resp = await fetchTimeout(
        fetch("/v1/asst/wanx/anytext", {
          headers: getHeaders(),
          method: "POST",
          body: JSON.stringify(requestPayload),
        }),
        60_000,
      );

      void this.finish(resp, options);
    } catch (e) {
      console.log("request anytext failed, ", e);
      options.onError?.(e as Error, { code: -1 });
    }
  }

  async texture(options: ChatOptions, session: ChatSession) {
    const message = options.messages.at(options.messages.length - 1)!.content;
    const wordArtOptions = session.wordArtOptions!;
    let baseImg = session.pendingFileList?.find(
      (it) => it.fileType === UploadFileType.WANX_TEXT_BASE,
    )?.imageUrl;
    let ttfUrl = session.pendingFileList?.find(
      (it) => it.fileType === UploadFileType.WANX_TTF_FILE,
    )?.imageUrl;

    if (baseImg) {
      baseImg = useSiteType.getState().siteHost + baseImg;
    }

    if (ttfUrl) {
      ttfUrl = useSiteType.getState().siteHost + ttfUrl;
    }

    const requestPayload = {
      conversation_id: session.conversationId!,
      text_type: wordArtOptions.text_type,
      image_url: baseImg,
      text_content: wordArtOptions.text_content,
      ttf_url: ttfUrl,
      font_name:
        wordArtOptions.font_name === ChatEnums.WordArt.Font._UPLOAD_FONT
          ? undefined
          : wordArtOptions.font_name,
      output_image_ratio: wordArtOptions.output_image_ratio,
      prompt: message,
      texture_style: wordArtOptions.texture_style,
      image_short_size: wordArtOptions.image_short_size,
      n: wordArtOptions.n,
      alpha_channel: wordArtOptions.alpha_channel,
      return_html_app_token: "1",
    };

    try {
      const resp = await fetchTimeout(
        fetch("/v1/asst/wordart/texture", {
          method: "POST",
          headers: getHeaders(),
          body: JSON.stringify(requestPayload),
        }),
        60_000,
      );

      void this.finish(resp, options);
    } catch (e) {
      console.log("request texture failed, ", e);
      options.onError?.(e as Error, { code: -1 });
    }
  }

  async semantic(options: ChatOptions, session: ChatSession) {
    const message = options.messages.at(options.messages.length - 1)!.content;
    const wordArdOptions = session.wordArtOptions!;
    let ttfFile = session.pendingFileList?.find(
      (it) => it.fileType === UploadFileType.WANX_TTF_FILE,
    )?.imageUrl;

    if (ttfFile) {
      ttfFile = useSiteType.getState().siteHost + ttfFile;
    }

    const requestPayload = {
      conversation_id: session.conversationId!,
      text: wordArdOptions.text_content,
      prompt: message,
      steps: wordArdOptions.steps,
      n: wordArdOptions.n,
      font_name:
        wordArdOptions.font_name === ChatEnums.WordArt.Font._UPLOAD_FONT
          ? undefined
          : wordArdOptions.font_name,
      ttf_url: ttfFile,
      output_image_ratio: wordArdOptions.output_image_ratio,
      return_html_app_token: "1",
    };

    try {
      const resp = await fetchTimeout(
        fetch("/v1/asst/wordart/semantic", {
          method: "POST",
          headers: getHeaders(),
          body: JSON.stringify(requestPayload),
        }),
        60_000,
      );

      void this.finish(resp, options);
    } catch (e) {
      console.log("request semantic error, ", e);
      options.onError?.(e as Error, { code: -1 });
    }
  }
}
