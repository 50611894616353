"use client";
import { useChatStore } from "@/app/store";
import { useImmer } from "use-immer";
import { ChatEnums } from "@/app/enums/chat";
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useTransition,
  type JSX,
} from "react";
import { css } from "@emotion/react";
import { Input, InputNumber, message, Modal, Popconfirm, Select } from "antd";
import { produce } from "immer";
import { UploadFileType, useUploadFileStore } from "@/app/store/uploadFiles";
import { PlusOutlined } from "@ant-design/icons";
import TextareaAutosize from "react-textarea-autosize";
import NextImage from "next/image";
import CloseIcon from "@/app/icons/chat/close-icon.png";
import SettingsIcon from "@/app/icons/settings.svg";
import { useDebouncedCallback } from "use-debounce";
import { actOnKeyDown, useMobileScreen } from "@/app/utils";
import { ImageCrop } from "@/app/components/chatComponents/crop";
import { EraseImageCanvas } from "@/app/components/chatComponents/draw";
import { useShallow } from "zustand/react/shallow";
import { useSiteType } from "@/app/store/siteType";
import {
  AdditionalSettingsPopUp,
  AdditionSettingItem,
  ChatInputAboveButtonsContainer,
  CommonPromptContainerBlock,
  CommonPromptInputBlock,
  CommonSelectBaseBlock,
} from "@/app/components/chatComponents/commonLib";
import SendIcon from "@/app/icons/chat/send.svg";
import { useBalanceStr } from "@/app/utils/userUsage";
import {
  useDeleteByType,
  useSubmit,
  useUpdatePendingFileByType,
} from "@/app/hooks/chatHooks";
import { useLayoutConfigStore } from "@/app/store/layoutConfig";
import { eventBus } from "@/app/utils/eventBus";
import { EventBus } from "@/app/enums/eventBusName";
import { SmallClickInput } from "@/app/components/chatComponents/v2/InputComponents";
import ChatPicIcon from "@/app/icons/layout_v2/chat/pic.svg";
import { SiteType } from "@/app/enums/siteType";
import { LayoutType } from "@/app/enums/layoutType";
import {
  InputMenus,
  InputMenusWrapper,
} from "@/app/components/chatComponents/v2/InputMenus";
import { MenuItem } from "@mui/joy";
import { ConversationIdContext } from "@/app/contexts/chatIdContext";

export const WanxImageGenerateInputBlock = function () {
  const usageStr = useBalanceStr();
  const [userInput, setUserInput] = useState("");
  const doSubmit = useSubmit();
  const layout = useLayoutConfigStore();

  const submit = useCallback(
    (ignoreEmptyInput: boolean) => {
      doSubmit(userInput, ignoreEmptyInput);
      setUserInput("");
    },
    [doSubmit, userInput],
  );

  const updateUserInput = useCallback((value: string) => {
    setUserInput(value);
  }, []);

  return (
    <CommonPromptContainerBlock usageStr={usageStr}>
      {layout.type === LayoutType.Layout_v1 && (
        <WanxImageGenerateSelect usageStr={usageStr} />
      )}
      {layout.type === LayoutType.Layout_v2 && (
        <ChatInputAboveButtonsContainer>
          <WanxImageGenerateSelect />
        </ChatInputAboveButtonsContainer>
      )}
      {layout.type === LayoutType.Layout_v1 && <WanxImageInput />}
      <WanxPromptInputBlock
        updatePromptsInput={updateUserInput}
        submit={submit}
        inputAboveArea={<WanxImageInput />}
      >
        {/*{layout.type === LayoutType.Layout_v2 && <WanxImageInput />}*/}
        {layout.type === LayoutType.Layout_v2 && (
          <WanxInputMenusV2></WanxInputMenusV2>
        )}
      </WanxPromptInputBlock>
    </CommonPromptContainerBlock>
  );
};

const WanxImageGenerateSelect = function (props: { usageStr?: string }) {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)!),
  );
  const site = useSiteType();
  const layout = useLayoutConfigStore();

  const [, startTransition] = useTransition();

  const wanxDebouncedStateUpdate = useDebouncedCallback(
    (options: ChatEnums.Wanx.Options) => {
      useChatStore.getState().updateCurrentSession((session1) => {
        session1.wanxOptions = options;
      }, conversationId);
    },
    100,
  );

  const wordArtDebouncedStateUpdate = useDebouncedCallback(
    (options: ChatEnums.WordArt.Options) => {
      useChatStore.getState().updateCurrentSession((session1) => {
        session1.wordArtOptions = options;
      }, conversationId);
    },
    100,
  );

  const [wanxOptions, updateWanxOptions] = useImmer(session.wanxOptions);

  const [wordArtOptions, updateWordArtOptions] = useImmer(
    session.wordArtOptions,
  );

  useEffect(() => {
    if (!session.wanxOptions) {
      const defaultOptions = {
        operation: ChatEnums.Wanx.Operation.GENERATE,
        style: ChatEnums.Wanx.Generate.Style.AUTO,
        size: ChatEnums.Wanx.Generate.Size.SIZE_1024_1024,
        n: ChatEnums.Wanx.Count.COUNT_1,
        ref_mode: ChatEnums.Wanx.Generate.Mode.REFONLY,
        seed: undefined,
        ref_strength: undefined,
        text_position_revise: undefined,
        strength: undefined,
        cfg_scale: undefined,
        eta: undefined,
        layout_priority: ChatEnums.Wanx.Mixture.Layout.VERTICAL,
        image_height: undefined,
        image_width: undefined,
        steps: undefined,
        additionalPrompt: "",
        negativePrompt: "",
      };
      useChatStore.getState().updateCurrentSession((session1) => {
        session1.wanxOptions = defaultOptions;
      }, conversationId);
      // wanxDebouncedStateUpdate(defaultOptions);
      updateWanxOptions(defaultOptions);
    }
  }, [session.wanxOptions, updateWanxOptions, conversationId]);

  useEffect(() => {
    if (!session.wordArtOptions) {
      const defaultOption = {
        text_type: ChatEnums.WordArt.Texture.TextType.TYPING_TEXT,
        font_name: undefined,
        output_image_ratio: ChatEnums.WordArt.Texture.Ratio.RATIO_1_1,
        texture_style: undefined,
        n: ChatEnums.WordArt.Count.COUNT_1,
        alpha_channel: false,
        steps: undefined,
      } as ChatEnums.WordArt.Options;
      useChatStore.getState().updateCurrentSession((session1) => {
        session1.wordArtOptions = defaultOption;
      }, conversationId);
      // wordArtDebouncedStateUpdate(defaultOption);
      updateWordArtOptions(defaultOption);
    }
  }, [session.wordArtOptions, updateWordArtOptions, conversationId]);

  const wanxOptionChange = {
    operation(value: ChatEnums.Wanx.Operation) {
      let output_image_ratio = wordArtOptions!.output_image_ratio;
      let seed = wanxOptions!.seed;
      if (
        value === ChatEnums.Wanx.Operation.TEXTURE &&
        !Object.values<string>(ChatEnums.WordArt.Texture.Ratio).includes(
          output_image_ratio ?? "",
        )
      ) {
        output_image_ratio = ChatEnums.WordArt.Texture.Ratio.RATIO_1_1;
      }
      if (
        value === ChatEnums.Wanx.Operation.SEMANTIC &&
        !Object.values<string>(ChatEnums.WordArt.Semantic.Ratio).includes(
          output_image_ratio ?? "",
        )
      ) {
        output_image_ratio = ChatEnums.WordArt.Semantic.Ratio.SIZE_1280_720;
      }
      if (value === ChatEnums.Wanx.Operation.GENERATE) {
        if (seed !== undefined && (seed <= 0 || seed >= 4294967290)) {
          seed = undefined;
        }
      }
      if (
        value === ChatEnums.Wanx.Operation.IMAGE_GENERATE ||
        value === ChatEnums.Wanx.Operation.IMAGE_EDIT
      ) {
        if (seed !== undefined && (seed < -1 || seed > 99999999)) {
          seed = undefined;
        }
      }
      updateWanxOptions((options) => {
        options!.operation = value;
        options!.seed = seed;
      });
      updateWordArtOptions((options) => {
        options!.output_image_ratio = output_image_ratio;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.operation = value;
            options.seed = seed;
          }),
        );
        wordArtDebouncedStateUpdate(
          produce(wordArtOptions!, (options) => {
            options.output_image_ratio = output_image_ratio;
          }),
        );
      });
    },
    style(value: ChatEnums.Wanx.Generate.Style) {
      updateWanxOptions((options) => {
        options!.style = value;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (option) => {
            option.style = value;
          }),
        );
      });
    },
    size(value: ChatEnums.Wanx.Generate.Size) {
      updateWanxOptions((options) => {
        options!.size = value;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.size = value;
          }),
        );
      });
    },
    n(value: ChatEnums.Wanx.Count) {
      updateWanxOptions((options) => {
        options!.n = value;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.n = value;
          }),
        );
      });
    },
    ref_mode(value: ChatEnums.Wanx.Generate.Mode) {
      updateWanxOptions((options) => {
        options!.ref_mode = value;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.ref_mode = value;
          }),
        );
      });
    },
    seed(value: number | null) {
      updateWanxOptions((options) => {
        options!.seed = value ?? undefined;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.seed = value ?? undefined;
          }),
        );
      });
    },
    ref_strength(value: number | null) {
      updateWanxOptions((options) => {
        options!.ref_strength = value ?? undefined;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.ref_strength = value ?? undefined;
          }),
        );
      });
    },
    layout_priority(value: ChatEnums.Wanx.Mixture.Layout) {
      updateWanxOptions((options) => {
        options!.layout_priority = value;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.layout_priority = value;
          }),
        );
      });
    },
    image_height(value: ChatEnums.Wanx.Mixture.Height) {
      updateWanxOptions((options) => {
        options!.image_height = value;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.image_height = value;
          }),
        );
      });
    },
    image_width(value: ChatEnums.Wanx.Mixture.Width) {
      updateWanxOptions((options) => {
        options!.image_width = value;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.image_width = value;
          }),
        );
      });
    },
    text_position_revise(value: boolean | null) {
      updateWanxOptions((options) => {
        options!.text_position_revise = value ?? undefined;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.text_position_revise = value ?? undefined;
          }),
        );
      });
    },
    strength(value: number | null) {
      updateWanxOptions((options) => {
        options!.strength = value ?? undefined;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.strength = value ?? undefined;
          }),
        );
      });
    },
    cfg_scale(value: number | null) {
      updateWanxOptions((options) => {
        options!.cfg_scale = value ?? undefined;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.cfg_scale = value ?? undefined;
          }),
        );
      });
    },
    eta(value: number | null) {
      updateWanxOptions((options) => {
        options!.eta = value ?? undefined;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.eta = value ?? undefined;
          }),
        );
      });
    },
    steps(value: number | null) {
      updateWanxOptions((options) => {
        options!.steps = value ?? undefined;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.steps = value ?? undefined;
          }),
        );
      });
    },
    additionalPrompt(value: string) {
      updateWanxOptions((options) => {
        options!.additionalPrompt = value;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.additionalPrompt = value;
          }),
        );
      });
    },
    negativePrompt(value: string) {
      updateWanxOptions((options) => {
        options!.negativePrompt = value;
      });
      startTransition(() => {
        wanxDebouncedStateUpdate(
          produce(wanxOptions!, (options) => {
            options.negativePrompt = value;
          }),
        );
      });
    },
  };

  const wanxOptionSelects = {
    style: () => {
      return (
        <Select
          value={wanxOptions?.style}
          key="style"
          style={{ height: 28, width: "100%" }}
          popupMatchSelectWidth={true}
          placement="topLeft"
          placeholder="风格"
          onChange={wanxOptionChange.style}
          options={[
            { value: ChatEnums.Wanx.Generate.Style.AUTO, label: "默认" },
            {
              value: ChatEnums.Wanx.Generate.Style.THREE_D_CARTOON,
              label: "3D 卡通",
            },
            { value: ChatEnums.Wanx.Generate.Style.ANIME, label: "动画" },
            {
              value: ChatEnums.Wanx.Generate.Style.OIL_PAINTING,
              label: "油画",
            },
            { value: ChatEnums.Wanx.Generate.Style.WATER_COLOR, label: "水彩" },
            { value: ChatEnums.Wanx.Generate.Style.SKETCH, label: "素描" },
            {
              value: ChatEnums.Wanx.Generate.Style.CHINESE_PAINTING,
              label: "中国画",
            },
            {
              value: ChatEnums.Wanx.Generate.Style.FLAT_ILLUSTRATION,
              label: "扁平插画",
            },
          ]}
        />
      );
    },
    size: () => {
      return (
        <Select
          value={wanxOptions?.size}
          key="size"
          style={{ height: 28, width: "100%" }}
          popupMatchSelectWidth={true}
          placement="topLeft"
          placeholder="图片尺寸"
          onChange={wanxOptionChange.size}
          options={[
            {
              value: ChatEnums.Wanx.Generate.Size.SIZE_720_1280,
              label: "720x1280",
            },
            {
              value: ChatEnums.Wanx.Generate.Size.SIZE_1024_1024,
              label: "1024x1024",
            },
            {
              value: ChatEnums.Wanx.Generate.Size.SIZE_1280_720,
              label: "1280x720",
            },
          ]}
        />
      );
    },
    n: () => {
      return (
        <Select
          value={wanxOptions?.n}
          key="n"
          style={{ height: 28, width: "100%" }}
          popupMatchSelectWidth={true}
          placement="topLeft"
          placeholder="图片数量"
          onChange={wanxOptionChange.n}
          options={[
            { value: ChatEnums.Wanx.Count.COUNT_1, label: "生成1张" },
            { value: ChatEnums.Wanx.Count.COUNT_2, label: "生成2张" },
            { value: ChatEnums.Wanx.Count.COUNT_3, label: "生成3张" },
            { value: ChatEnums.Wanx.Count.COUNT_4, label: "生成4张" },
          ]}
        />
      );
    },
    ref_mode: () => {
      return (
        <Select
          value={wanxOptions?.ref_mode}
          key="ref_mode"
          style={{ height: 28, width: "100%" }}
          popupMatchSelectWidth={true}
          placement="bottomLeft"
          placeholder="参考模式"
          onChange={wanxOptionChange.ref_mode}
          options={[
            { value: ChatEnums.Wanx.Generate.Mode.REFONLY, label: "参考风格" },
            { value: ChatEnums.Wanx.Generate.Mode.REPAINT, label: "参考内容" },
          ]}
        />
      );
    },
    seed: () => {
      let min = 0;
      if (
        wanxOptions?.operation === ChatEnums.Wanx.Operation.IMAGE_GENERATE ||
        wanxOptions?.operation === ChatEnums.Wanx.Operation.IMAGE_EDIT
      ) {
        min = -1;
      }
      let max = 99999999;
      if (wanxOptions?.operation === ChatEnums.Wanx.Operation.GENERATE) {
        max = 4294967290;
      }
      return (
        <InputNumber
          value={wanxOptions?.seed}
          key="seed"
          placeholder={"种子值(可选)"}
          style={{
            height: "28px",
            width: "100%",
          }}
          min={min}
          max={max}
          onChange={wanxOptionChange.seed}
        />
      );
    },
    ref_strength: () => {
      return (
        <InputNumber
          value={wanxOptions?.ref_strength}
          key="ref_strength"
          placeholder={"相似度(可选)"}
          style={{
            height: "28px",
            width: "100%",
          }}
          step={0.01}
          min={0}
          max={1.0}
          onChange={wanxOptionChange.ref_strength}
        />
      );
    },
    layout_priority: () => {
      return (
        <Select
          value={wanxOptions?.layout_priority}
          key="layout_priority"
          style={{ height: "28px", width: "100%" }}
          popupMatchSelectWidth={true}
          placement="topLeft"
          placeholder={"文本布局模式"}
          onChange={wanxOptionChange.layout_priority}
          options={[
            {
              value: ChatEnums.Wanx.Mixture.Layout.VERTICAL,
              label: "文本方向:从上到下",
            },
            {
              value: ChatEnums.Wanx.Mixture.Layout.HORIZONTAL,
              label: "文本方向:从左到右",
            },
          ]}
        />
      );
    },
    image_height: () => {
      return (
        <Select
          value={wanxOptions?.image_height}
          key="image_height"
          style={{ height: "28px", width: "100%" }}
          popupMatchSelectWidth={true}
          placement="topLeft"
          placeholder={"图片高度"}
          title={"图片高度"}
          onChange={wanxOptionChange.image_height}
          options={[
            {
              value: ChatEnums.Wanx.Mixture.Height.HEIGHT_256,
              label: "高:256",
            },
            {
              value: ChatEnums.Wanx.Mixture.Height.HEIGHT_320,
              label: "高:320",
            },
            {
              value: ChatEnums.Wanx.Mixture.Height.HEIGHT_384,
              label: "高:384",
            },
            {
              value: ChatEnums.Wanx.Mixture.Height.HEIGHT_448,
              label: "高:448",
            },
            {
              value: ChatEnums.Wanx.Mixture.Height.HEIGHT_512,
              label: "高:512",
            },
            {
              value: ChatEnums.Wanx.Mixture.Height.HEIGHT_576,
              label: "高:576",
            },
            {
              value: ChatEnums.Wanx.Mixture.Height.HEIGHT_640,
              label: "高:640",
            },
            {
              value: ChatEnums.Wanx.Mixture.Height.HEIGHT_704,
              label: "高:704",
            },
            {
              value: ChatEnums.Wanx.Mixture.Height.HEIGHT_768,
              label: "高:768",
            },
            { value: null, label: "高:未指定" },
          ]}
        />
      );
    },
    image_width: () => {
      return (
        <Select
          value={wanxOptions?.image_width}
          style={{ height: "28px", width: "100%" }}
          popupMatchSelectWidth={true}
          placement="topLeft"
          placeholder={"图片宽度"}
          title={"图片宽度"}
          onChange={wanxOptionChange.image_width}
          key="image_width"
          options={[
            { value: ChatEnums.Wanx.Mixture.Width.WIDTH_256, label: "宽:256" },
            { value: ChatEnums.Wanx.Mixture.Width.WIDTH_320, label: "宽:320" },
            { value: ChatEnums.Wanx.Mixture.Width.WIDTH_384, label: "宽:384" },
            { value: ChatEnums.Wanx.Mixture.Width.WIDTH_448, label: "宽:448" },
            { value: ChatEnums.Wanx.Mixture.Width.WIDTH_512, label: "宽:512" },
            { value: ChatEnums.Wanx.Mixture.Width.WIDTH_576, label: "宽:576" },
            { value: ChatEnums.Wanx.Mixture.Width.WIDTH_640, label: "宽:640" },
            { value: ChatEnums.Wanx.Mixture.Width.WIDTH_704, label: "宽:704" },
            { value: ChatEnums.Wanx.Mixture.Width.WIDTH_768, label: "宽:768" },
            { value: null, label: "宽:未指定" },
          ]}
        />
      );
    },
    text_position_revise: () => {
      return (
        <Select
          value={wanxOptions?.text_position_revise}
          key="text_position_revise"
          style={{
            height: "28px",
            width: "100%",
          }}
          popupMatchSelectWidth={true}
          placement="topLeft"
          placeholder="文字位置修正"
          onChange={wanxOptionChange.text_position_revise}
          options={[
            { value: true, label: "对文字位置修正" },
            { value: false, label: "不对文字位置修正" },
          ]}
        />
      );
    },
    strength: () => {
      return (
        <InputNumber
          value={wanxOptions?.strength}
          min={0}
          max={2}
          step={0.01}
          key="strength"
          placeholder={"文字控制力度(可选)"}
          style={{
            height: "28px",
            width: "100%",
          }}
          onChange={wanxOptionChange.strength}
        />
      );
    },
    cfg_scale: () => {
      return (
        <InputNumber
          value={wanxOptions?.cfg_scale}
          min={0.1}
          max={30}
          step={0.1}
          style={{
            height: "28px",
            width: "100%",
          }}
          placeholder={"CFG 强度参数(可选)"}
          onChange={wanxOptionChange.cfg_scale}
        />
      );
    },
    eta: () => {
      return (
        <InputNumber
          value={wanxOptions?.eta}
          min={0}
          max={1}
          step={0.01}
          placeholder={"DDIM 插值系数(可选)"}
          onChange={wanxOptionChange.eta}
          style={{
            height: "28px",
            width: "100%",
          }}
        />
      );
    },
    steps: () => {
      return (
        <InputNumber
          value={wanxOptions?.steps}
          min={1}
          max={100}
          step={1}
          placeholder="采样步数(可选)"
          onChange={wanxOptionChange.steps}
          style={{
            height: "28px",
            width: "100%",
          }}
        />
      );
    },
    additionalPrompt: () => {
      return (
        <TextareaAutosize
          value={wanxOptions?.additionalPrompt}
          placeholder={"附加提示语（可选）"}
          onInput={(e) =>
            wanxOptionChange.additionalPrompt(e.currentTarget.value)
          }
          style={{
            width: "100%",
            resize: "none",
            display: "block",
            boxSizing: "border-box",
          }}
          minRows={1}
          maxRows={4}
        />
      );
    },
    negativePrompt: () => {
      return (
        <TextareaAutosize
          value={wanxOptions?.negativePrompt}
          placeholder={"负面提示语（可选）"}
          onInput={(e) =>
            wanxOptionChange.negativePrompt(e.currentTarget.value)
          }
          style={{
            width: "100%",
            resize: "none",
            display: "block",
            boxSizing: "border-box",
          }}
          minRows={1}
          maxRows={4}
        />
      );
    },
  };

  const wordArtOptionChange = {
    text_type(value: ChatEnums.WordArt.Texture.TextType) {
      let textureStyle = wordArtOptions!.texture_style;
      if (
        value === ChatEnums.WordArt.Texture.TextType.TYPING_TEXT &&
        !Object.values<string>(ChatEnums.WordArt.Texture.Style.Custom).includes(
          textureStyle!,
        )
      ) {
        textureStyle = ChatEnums.WordArt.Texture.Style.Custom.MATERIAL;
      }

      updateWordArtOptions((options) => {
        options!.text_type = value;
        options!.texture_style = textureStyle;
      });
      startTransition(() => {
        wordArtDebouncedStateUpdate(
          produce(wordArtOptions!, (wordArtOptions) => {
            wordArtOptions.text_type = value;
            wordArtOptions.texture_style = textureStyle;
          }),
        );
      });
    },
    font_name(value: ChatEnums.WordArt.Font | null) {
      updateWordArtOptions((options) => {
        options!.font_name = value ?? undefined;
      });
      startTransition(() => {
        wordArtDebouncedStateUpdate(
          produce(wordArtOptions!, (wordArtOptions) => {
            wordArtOptions.font_name = value ?? undefined;
          }),
        );
      });
    },
    output_image_ratio(
      value: ChatEnums.WordArt.Texture.Ratio | ChatEnums.WordArt.Semantic.Ratio,
    ) {
      updateWordArtOptions((options) => {
        options!.output_image_ratio = value;
      });
      startTransition(() => {
        wordArtDebouncedStateUpdate(
          produce(wordArtOptions!, (wordArtOptions) => {
            wordArtOptions.output_image_ratio = value;
          }),
        );
      });
    },
    text_content(value: string) {
      updateWordArtOptions((options) => {
        options!.text_content = value;
      });
      startTransition(() => {
        wordArtDebouncedStateUpdate(
          produce(wordArtOptions!, (options) => {
            options.text_content = value;
          }),
        );
      });
    },
    texture_style(
      value:
        | ChatEnums.WordArt.Texture.Style.Preset
        | ChatEnums.WordArt.Texture.Style.Custom
        | null,
    ) {
      updateWordArtOptions((options) => {
        options!.texture_style = value ?? undefined;
      });
      startTransition(() => {
        wordArtDebouncedStateUpdate(
          produce(wordArtOptions!, (options) => {
            options.texture_style = value ?? undefined;
          }),
        );
      });
    },
    image_short_size(value: ChatEnums.WordArt.Texture.ShortSize) {
      updateWordArtOptions((options) => {
        options!.image_short_size = value;
      });
      startTransition(() => {
        wordArtDebouncedStateUpdate(
          produce(wordArtOptions!, (options) => {
            options.image_short_size = value;
          }),
        );
      });
    },
    n(value: ChatEnums.WordArt.Count) {
      updateWordArtOptions((options) => {
        options!.n = value;
      });
      startTransition(() => {
        wordArtDebouncedStateUpdate(
          produce(wordArtOptions!, (options) => {
            options.n = value;
          }),
        );
      });
    },
    alpha_channel(value: boolean) {
      updateWordArtOptions((options) => {
        options!.alpha_channel = value;
      });
      startTransition(() => {
        wordArtDebouncedStateUpdate(
          produce(wordArtOptions!, (options) => {
            options.alpha_channel = value;
          }),
        );
      });
    },
    steps(value: number | null) {
      updateWordArtOptions((options) => {
        options!.steps = value ?? undefined;
      });
      startTransition(() => {
        wordArtDebouncedStateUpdate(
          produce(wordArtOptions!, (options) => {
            options.steps = value ?? undefined;
          }),
        );
      });
    },
  };

  const wordArtOptionSelects = {
    text_type: () => {
      return (
        <Select
          value={wordArtOptions?.text_type}
          key="text_type"
          style={{ height: "28px", width: "100%" }}
          placement="topLeft"
          popupMatchSelectWidth={true}
          placeholder="模式"
          onChange={wordArtOptionChange.text_type}
          options={[
            {
              value: ChatEnums.WordArt.Texture.TextType.TYPING_TEXT,
              label: "文字输入",
            },
            {
              value: ChatEnums.WordArt.Texture.TextType.UPLOAD_IMG,
              label: "图片上传",
            },
          ]}
        />
      );
    },
    font_name: () => {
      return (
        <Select
          value={wordArtOptions?.font_name}
          key="font_name"
          style={{ height: "28px", width: "100%" }}
          placement="topLeft"
          popupMatchSelectWidth={true}
          placeholder="字体"
          onChange={wordArtOptionChange.font_name}
          options={[
            {
              value: ChatEnums.WordArt.Font.DONG_FANG_DA_KAI,
              label: "阿里妈妈东方大楷",
            },
            {
              value: ChatEnums.WordArt.Font.PUHUITI_M,
              label: "阿里巴巴普惠体",
            },
            { value: ChatEnums.WordArt.Font.SHUHEITI, label: "阿里巴巴数黑体" },
            { value: ChatEnums.WordArt.Font.JINBUTI, label: "钉钉进步体" },
            { value: ChatEnums.WordArt.Font.KUHEITI, label: "站酷酷黑体" },
            { value: ChatEnums.WordArt.Font.KUAILETI, label: "站酷快乐体" },
            { value: ChatEnums.WordArt.Font.WENYITI, label: "站酷文艺体" },
            { value: ChatEnums.WordArt.Font.LOGOTI, label: "站酷小薇LOGO体" },
            {
              value: ChatEnums.WordArt.Font.CANGERYUYANGTI_M,
              label: "站酷苍耳渔阳体",
            },
            { value: ChatEnums.WordArt.Font.SIYUANSONGTI_b, label: "思源宋体" },
            { value: ChatEnums.WordArt.Font.SIYUANHEITI_M, label: "思源黑体" },
            { value: ChatEnums.WordArt.Font.FANGZHENGKAITI, label: "方正楷体" },
            { value: ChatEnums.WordArt.Font._UPLOAD_FONT, label: "上传字体" },
          ]}
        />
      );
    },
    output_image_ratio: () => {
      let options:
        | {
            value:
              | ChatEnums.WordArt.Texture.Ratio
              | ChatEnums.WordArt.Semantic.Ratio;
            label: string;
          }[]
        | undefined = [];
      if (wanxOptions?.operation === ChatEnums.Wanx.Operation.TEXTURE) {
        options = [
          { value: ChatEnums.WordArt.Texture.Ratio.RATIO_1_1, label: "1:1" },
          { value: ChatEnums.WordArt.Texture.Ratio.RATIO_9_16, label: "9:16" },
          { value: ChatEnums.WordArt.Texture.Ratio.RATIO_16_9, label: "16:9" },
        ];
      } else if (wanxOptions?.operation === ChatEnums.Wanx.Operation.SEMANTIC) {
        options = [
          {
            value: ChatEnums.WordArt.Semantic.Ratio.SIZE_1280_720,
            label: "1280x720",
          },
          {
            value: ChatEnums.WordArt.Semantic.Ratio.SIZE_720_1280,
            label: "720x1280",
          },
          {
            value: ChatEnums.WordArt.Semantic.Ratio.SIZE_1024_1024,
            label: "1024x1024",
          },
        ];
      }

      return (
        <Select
          value={wordArtOptions?.output_image_ratio}
          key="output_image_ratio"
          style={{ height: "28px", width: "100%" }}
          placement="topLeft"
          popupMatchSelectWidth={true}
          onChange={wordArtOptionChange.output_image_ratio}
          options={options}
        />
      );
    },
    text_content: () => {
      let maxLength = undefined as number | undefined;
      let placeholder = "文字内容";
      if (
        wanxOptions?.operation === ChatEnums.Wanx.Operation.TEXTURE &&
        wordArtOptions?.text_type ===
          ChatEnums.WordArt.Texture.TextType.TYPING_TEXT
      ) {
        maxLength = 6;
        placeholder = "文字内容(不超过6字)";
      }

      return (
        <Input
          value={wordArtOptions?.text_content}
          key="text_content"
          styles={{
            input: {
              minHeight: "initial",
              height: "28px",
              minWidth: "160px",
            },
          }}
          maxLength={maxLength}
          style={{
            width: "100%",
          }}
          onInput={(e) =>
            wordArtOptionChange.text_content(e.currentTarget.value)
          }
          placeholder={placeholder}
        />
      );
    },
    texture_style: () => {
      let options: any[] | undefined = [];
      if (
        wordArtOptions?.text_type ===
        ChatEnums.WordArt.Texture.TextType.TYPING_TEXT
      ) {
        options = [
          {
            label: <span>自定义</span>,
            title: "自定义",
            options: [
              {
                value: ChatEnums.WordArt.Texture.Style.Custom.LIGHTING,
                label: "光影特效",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Custom.MATERIAL,
                label: "立体材质",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Custom.SCENE,
                label: "场景融合",
              },
            ],
          },
        ];
      } else if (
        wordArtOptions?.text_type ===
        ChatEnums.WordArt.Texture.TextType.UPLOAD_IMG
      ) {
        options = [
          {
            label: <span>自定义</span>,
            title: "自定义",
            options: [
              {
                value: ChatEnums.WordArt.Texture.Style.Custom.LIGHTING,
                label: "光影特效",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Custom.MATERIAL,
                label: "立体材质",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Custom.SCENE,
                label: "场景融合",
              },
            ],
          },
          {
            label: <span>预设</span>,
            title: "预设",
            options: [
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.WATERFALL,
                label: "瀑布流水",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.SNOW_PLATEAU,
                label: "雪域高原",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.FOREST,
                label: "原始森林",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.SKY,
                label: "天空遨游",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.CHINESE_BUILDING,
                label: "国风建筑",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.CARTOON,
                label: "奇幻卡通",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.LEGO,
                label: "乐高积木",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.FLOWER,
                label: "繁花盛开",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.ACRYLIC,
                label: "亚克力",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.MARBLE,
                label: "大理石",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.FELT,
                label: "绒线毛毯",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.OIL_PAINTING,
                label: "复古油画",
              },
              {
                value:
                  ChatEnums.WordArt.Texture.Style.Preset.WATERCOLOR_PAINTING,
                label: "水彩",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.CHINESE_PAINTING,
                label: "中国画",
              },
              {
                value:
                  ChatEnums.WordArt.Texture.Style.Preset
                    .CLABORATE_STYLE_PAINTING,
                label: "工笔画",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.CITY_NIGHT,
                label: "城市夜景",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.MOUNTAIN_LAKE,
                label: "湖光山色",
              },
              {
                value: ChatEnums.WordArt.Texture.Style.Preset.AUTUMN_LEAVES,
                label: "秋日落叶",
              },
            ],
          },
        ];
      }

      return (
        <Select
          value={wordArtOptions?.texture_style}
          key="texture_style"
          style={{ height: "28px", width: "100%" }}
          placement="topLeft"
          popupMatchSelectWidth={true}
          onChange={wordArtOptionChange.texture_style}
          options={options}
        />
      );
    },
    image_short_size: () => {
      return (
        <Select
          value={wordArtOptions?.image_short_size}
          popupMatchSelectWidth={true}
          key="image_short_size"
          placement="topLeft"
          style={{ height: "28px", width: "100%" }}
          onChange={wordArtOptionChange.image_short_size}
          options={[
            {
              value: ChatEnums.WordArt.Texture.ShortSize.SIZE_512,
              label: "512",
            },
            {
              value: ChatEnums.WordArt.Texture.ShortSize.SIZE_576,
              label: "576",
            },
            {
              value: ChatEnums.WordArt.Texture.ShortSize.SIZE_640,
              label: "640",
            },
            {
              value: ChatEnums.WordArt.Texture.ShortSize.SIZE_704,
              label: "704",
            },
            {
              value: ChatEnums.WordArt.Texture.ShortSize.SIZE_768,
              label: "768",
            },
            {
              value: ChatEnums.WordArt.Texture.ShortSize.SIZE_832,
              label: "832",
            },
            {
              value: ChatEnums.WordArt.Texture.ShortSize.SIZE_896,
              label: "896",
            },
            {
              value: ChatEnums.WordArt.Texture.ShortSize.SIZE_960,
              label: "960",
            },
            {
              value: ChatEnums.WordArt.Texture.ShortSize.SIZE_1024,
              label: "1024",
            },
            { value: null, label: "默认值" },
          ]}
        />
      );
    },
    n: () => {
      return (
        <Select
          value={wordArtOptions?.n}
          popupMatchSelectWidth={true}
          key="n"
          placement="topLeft"
          style={{ height: "28px", width: "100%" }}
          onChange={wordArtOptionChange.n}
          options={[
            { value: ChatEnums.WordArt.Count.COUNT_1, label: "生成1张" },
            { value: ChatEnums.WordArt.Count.COUNT_2, label: "生成2张" },
            { value: ChatEnums.WordArt.Count.COUNT_3, label: "生成3张" },
            { value: ChatEnums.WordArt.Count.COUNT_4, label: "生成4张" },
          ]}
        />
      );
    },
    alpha_channel: () => {
      return (
        <Select
          value={wordArtOptions?.alpha_channel}
          key="alpha_channel"
          placement="topLeft"
          style={{ height: "28px", width: "100%" }}
          onChange={wordArtOptionChange.alpha_channel}
          options={[
            { value: false, label: "否" },
            { value: true, label: "是" },
            { value: null, label: "默认" },
          ]}
        />
      );
    },
    steps: () => {
      return (
        <InputNumber
          value={wordArtOptions?.steps}
          min={1}
          max={100}
          step={1}
          placeholder="迭代次数(可选)"
          onChange={wordArtOptionChange.steps}
          style={{
            height: "28px",
            marginLeft: "10px",
            width: "100%",
          }}
        />
      );
    },
  };

  let selectAreas = <></>;
  if (wanxOptions?.operation === ChatEnums.Wanx.Operation.GENERATE) {
    selectAreas = (
      <div
        css={[
          css`
            margin-left: 10px;
          `,
          layout.type === LayoutType.Layout_v1 &&
            css`
              height: 28px;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              height: 100%;
              display: flex;
            `,
        ]}
      >
        <AdditionalSettingsPopUp>
          <AdditionSettingItem title={"风格"}>
            {wanxOptionSelects.style()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"图片尺寸"}>
            {wanxOptionSelects.size()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"图片数量"}>
            {wanxOptionSelects.n()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"负面提示词"}>
            {wanxOptionSelects.negativePrompt()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"风格参考模式"}>
            {wanxOptionSelects.ref_mode()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"种子值"}>
            {wanxOptionSelects.seed()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"相似度"}>
            {wanxOptionSelects.ref_strength()}
          </AdditionSettingItem>
        </AdditionalSettingsPopUp>
      </div>
    );
  } else if (
    wanxOptions?.operation === ChatEnums.Wanx.Operation.IMAGE_GENERATE
  ) {
    selectAreas = (
      <div
        css={[
          css`
            margin-left: 10px;
          `,
          layout.type === LayoutType.Layout_v1 &&
            css`
              height: 28px;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              height: 100%;
              display: flex;
            `,
        ]}
      >
        <AdditionalSettingsPopUp>
          <AdditionSettingItem title={"文本布局模式"}>
            {wanxOptionSelects.layout_priority()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"图片生成数量"}>
            {wanxOptionSelects.n()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"附加提示词"}>
            {wanxOptionSelects.additionalPrompt()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"负面提示词"}>
            {wanxOptionSelects.negativePrompt()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"文本位置修正"}>
            {wanxOptionSelects.text_position_revise()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"图片宽度"}>
            {wanxOptionSelects.image_width()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"图片高度"}>
            {wanxOptionSelects.image_height()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"采样步数"}>
            {wanxOptionSelects.steps()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"种子值"}>
            {wanxOptionSelects.seed()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"文字控制"}>
            {wanxOptionSelects.strength()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"CFG"}>
            {wanxOptionSelects.cfg_scale()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"DDIM eta"}>
            {wanxOptionSelects.eta()}
          </AdditionSettingItem>
        </AdditionalSettingsPopUp>
      </div>
    );
  } else if (wanxOptions?.operation == ChatEnums.Wanx.Operation.IMAGE_EDIT) {
    selectAreas = (
      <div
        css={[
          css`
            margin-left: 10px;
          `,
          layout.type === LayoutType.Layout_v1 &&
            css`
              height: 28px;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              height: 100%;
              display: flex;
            `,
        ]}
      >
        <AdditionalSettingsPopUp>
          <AdditionSettingItem title={"文本布局模式"}>
            {wanxOptionSelects.layout_priority()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"图片生成数量"}>
            {wanxOptionSelects.n()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"文本位置修正"}>
            {wanxOptionSelects.text_position_revise()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"采样步数"}>
            {wanxOptionSelects.steps()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"种子值"}>
            {wanxOptionSelects.seed()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"文字控制"}>
            {wanxOptionSelects.strength()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"CFG"}>
            {wanxOptionSelects.cfg_scale()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"DDIM eta"}>
            {wanxOptionSelects.eta()}
          </AdditionSettingItem>
        </AdditionalSettingsPopUp>
      </div>
    );
  } else if (wanxOptions?.operation === ChatEnums.Wanx.Operation.TEXTURE) {
    session.pendingFileList?.find(
      (it) => it.fileType === UploadFileType.WANX_TTF_FILE,
    );
    if (
      wordArtOptions?.text_type ===
      ChatEnums.WordArt.Texture.TextType.TYPING_TEXT
    ) {
      selectAreas = (
        <div
          css={[
            css`
              margin-left: 10px;
            `,
            layout.type === LayoutType.Layout_v1 &&
              css`
                height: 28px;
              `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                height: 100%;
                display: flex;
              `,
          ]}
        >
          <AdditionalSettingsPopUp>
            <AdditionSettingItem title={"模式"}>
              {wordArtOptionSelects.text_type()}
            </AdditionSettingItem>
            <AdditionSettingItem title={"文字内容"}>
              {wordArtOptionSelects.text_content()}
            </AdditionSettingItem>
            <AdditionSettingItem title={"图片生成数量"}>
              {wordArtOptionSelects.n()}
            </AdditionSettingItem>
            <AdditionSettingItem title={"字体"}>
              {wordArtOptionSelects.font_name()}
            </AdditionSettingItem>
            <AdditionSettingItem title={"图片输出比例"}>
              {wordArtOptionSelects.output_image_ratio()}
            </AdditionSettingItem>
            <AdditionSettingItem title={"主题"}>
              {wordArtOptionSelects.texture_style()}
            </AdditionSettingItem>
            <AdditionSettingItem title={"输出图片短边长度"}>
              {wordArtOptionSelects.image_short_size()}
            </AdditionSettingItem>
            <AdditionSettingItem title={"alpha 通道"}>
              {wordArtOptionSelects.alpha_channel()}
            </AdditionSettingItem>
          </AdditionalSettingsPopUp>
        </div>
      );
    } else if (
      wordArtOptions?.text_type ===
      ChatEnums.WordArt.Texture.TextType.UPLOAD_IMG
    ) {
      selectAreas = (
        <div
          css={[
            css`
              margin-left: 10px;
            `,
            layout.type === LayoutType.Layout_v1 &&
              css`
                height: 28px;
              `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                height: 100%;
                display: flex;
              `,
          ]}
        >
          <AdditionalSettingsPopUp>
            <AdditionSettingItem title="模式">
              {wordArtOptionSelects.text_type()}
            </AdditionSettingItem>
            <AdditionSettingItem title={"图片生成数量"}>
              {wordArtOptionSelects.n()}
            </AdditionSettingItem>
            <AdditionSettingItem title={"主题"}>
              {wordArtOptionSelects.texture_style()}
            </AdditionSettingItem>
            <AdditionSettingItem title={"输出图片短边长度"}>
              {wordArtOptionSelects.image_short_size()}
            </AdditionSettingItem>
          </AdditionalSettingsPopUp>
        </div>
      );
    }
  } else if (wanxOptions?.operation === ChatEnums.Wanx.Operation.SEMANTIC) {
    selectAreas = (
      <div
        css={[
          css`
            margin-left: 10px;
          `,
          layout.type === LayoutType.Layout_v1 &&
            css`
              height: 28px;
            `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              height: 100%;
              display: flex;
              align-items: center;
            `,
        ]}
      >
        <AdditionalSettingsPopUp>
          <AdditionSettingItem title={"图片生成数"}>
            {wordArtOptionSelects.n()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"文字内容"}>
            {wordArtOptionSelects.text_content()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"字体"}>
            {wordArtOptionSelects.font_name()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"迭代次数"}>
            {wordArtOptionSelects.steps()}
          </AdditionSettingItem>
          <AdditionSettingItem title={"图像比例"}>
            {wordArtOptionSelects.output_image_ratio()}
          </AdditionSettingItem>
        </AdditionalSettingsPopUp>
      </div>
    );
  }

  let operationSelectOptions: {
    value: ChatEnums.Wanx.Operation;
    label: string;
  }[];
  if (site.siteType === SiteType.EXAM) {
    operationSelectOptions = [
      { value: ChatEnums.Wanx.Operation.GENERATE, label: "图片生成" },
      // {
      //   value: ChatEnums.Wanx.Operation.IMAGE_GENERATE,
      //   label: "图文融合-文字生成",
      // },
      // {
      //   value: ChatEnums.Wanx.Operation.IMAGE_EDIT,
      //   label: "图文融合-文字编辑",
      // },
      { value: ChatEnums.Wanx.Operation.TEXTURE, label: "文字纹理生成" },
      // { value: ChatEnums.Wanx.Operation.SEMANTIC, label: "字体变形" },
    ];
  } else {
    operationSelectOptions = [
      { value: ChatEnums.Wanx.Operation.GENERATE, label: "图片生成" },
      {
        value: ChatEnums.Wanx.Operation.IMAGE_GENERATE,
        label: "图文融合-文字生成",
      },
      {
        value: ChatEnums.Wanx.Operation.IMAGE_EDIT,
        label: "图文融合-文字编辑",
      },
      { value: ChatEnums.Wanx.Operation.TEXTURE, label: "文字纹理生成" },
      // { value: ChatEnums.Wanx.Operation.SEMANTIC, label: "字体变形" },
    ];
  }
  return (
    <CommonSelectBaseBlock usageStr={props.usageStr}>
      <div
        css={[
          css`
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            width: 100%;
            height: 37px;
            scrollbar-width: none;

            ::-webkit-scrollbar {
              width: 0;
              height: 0;
            }
          `,
          layout.type === LayoutType.Layout_v2 &&
            css`
              height: 100%;
            `,
        ]}
      >
        <Select
          value={wanxOptions?.operation}
          key="operation"
          style={{
            height: "28px",
            width: "200px",
            marginTop:
              layout.type === LayoutType.Layout_v2 ? "auto" : undefined,
            marginBottom:
              layout.type === LayoutType.Layout_v2 ? "auto" : undefined,
          }}
          popupMatchSelectWidth={true}
          placement="topLeft"
          onChange={wanxOptionChange.operation}
          options={operationSelectOptions}
          css={
            layout.type === LayoutType.Layout_v2 &&
            css`
              margin-top: auto;
              margin-bottom: auto;
            `
          }
        />
        {selectAreas}
      </div>
    </CommonSelectBaseBlock>
  );
};

const WanxImageInput = function () {
  const conversationId = useContext(ConversationIdContext);
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)!),
  );
  const wanxOptions = session.wanxOptions;
  const wordArtOptions = session.wordArtOptions;

  const uploadFilesStore = useUploadFileStore();

  const [messages, messageContextHolder] = message.useMessage();

  const [showImageCrop, setShowImageCrop] = useState(false);

  const [showImageEditor, setShowImageEditor] = useState(false);

  const originalImage = useRef("");

  const layout = useLayoutConfigStore();

  const wanxOptionDebouncedStateUpdate = useDebouncedCallback(
    (option: ChatEnums.Wanx.Options) => {
      useChatStore.getState().updateCurrentSession((session1) => {
        session1.wanxOptions = option;
      }, conversationId);
    },
    100,
  );

  const ImageInputBlockStyle = css`
    width: 80px;
    height: 80px;
    margin-left: 8px;
    margin-top: 10px;
  `;
  const imageInputBlockStyleV2 = css`
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  `;

  const baseFile = (function () {
    return (
      uploadFilesStore
        .getPendingFileList(session.id)
        .find((it) => it.fileType === UploadFileType.WANX_TEXT_BASE) ?? {
        uploadId: -1,
        uuid: "",
        fileName: "",
        fileType: UploadFileType.WANX_TEXT_BASE,
      }
    );
  })();

  const maskFile = (function () {
    return (
      uploadFilesStore
        .getPendingFileList(session.id)
        .find((it) => it.fileType === UploadFileType.WANX_TEXT_MASK) ?? {
        uploadId: -1,
        uuid: "",
        fileName: "",
        fileType: UploadFileType.WANX_TEXT_MASK,
      }
    );
  })();

  const ttfFile = (function () {
    return (
      uploadFilesStore
        .getPendingFileList(session.id)
        .find((it) => it.fileType === UploadFileType.WANX_TTF_FILE) ?? {
        uploadId: -1,
        uuid: "",
        fileName: "",
        fileType: UploadFileType.WANX_TTF_FILE,
      }
    );
  })();

  const updatePendingFileByType = useUpdatePendingFileByType({
    onError(e) {
      void messages.error(e.message);
    },
  });

  const deleteImageByType = useDeleteByType();

  let inputElements = <></>;

  const ImageBase = function () {
    return (
      <div
        css={[
          ImageInputBlockStyle,
          layout.type === LayoutType.Layout_v2 && imageInputBlockStyleV2,
        ]}
      >
        <ImageInputBlock
          imageUrl={baseFile.imageUrl}
          titleStr="上传原图"
          onChange={(file) => {
            updatePendingFileByType(file, UploadFileType.WANX_TEXT_BASE);
          }}
          onDelete={() => {
            deleteImageByType(UploadFileType.WANX_TEXT_BASE);
          }}
        />
      </div>
    );
  };

  const ImageMask = function () {
    return (
      <div
        css={[
          ImageInputBlockStyle,
          layout.type === LayoutType.Layout_v2 && imageInputBlockStyleV2,
        ]}
      >
        <ImageInputBlock
          titleStr="上传遮罩图"
          imageUrl={maskFile.imageUrl}
          onChange={(file) => {
            updatePendingFileByType(file, UploadFileType.WANX_TEXT_MASK);
          }}
          onDelete={() => {
            deleteImageByType(UploadFileType.WANX_TEXT_MASK);
          }}
        />
      </div>
    );
  };

  const FontUpload = () => {
    return (
      <div
        css={[
          ImageInputBlockStyle,
          layout.type === LayoutType.Layout_v2 && imageInputBlockStyleV2,
        ]}
      >
        <FontInputBlock
          titleStr="上传TTF字体"
          fontUrl={ttfFile.imageUrl}
          onChange={(file) => {
            updatePendingFileByType(file, UploadFileType.WANX_TTF_FILE);
          }}
          onDelete={() => {
            deleteImageByType(UploadFileType.WANX_TTF_FILE);
          }}
        />
      </div>
    );
  };
  const WanxImageGenerateBlock = () => {
    const closeButtonStyle = css`
      width: 28px;
      height: 28px;
      position: absolute;
      cursor: pointer;
      top: -14px;
      right: -14px;
      display: none;
      label: close-btn-a;
    `;

    let blockContent: JSX.Element;
    if (maskFile.imageUrl && maskFile.imageUrl !== "") {
      blockContent = (
        <>
          <div
            style={{ width: "100%", height: "100%" }}
            onClick={() => {
              setShowImageEditor(true);
            }}
          >
            <img
              src={maskFile.imageUrl}
              alt={""}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <Popconfirm
            title={"删除图片"}
            description={"确定要删除图片吗？"}
            onConfirm={() => {
              void deleteImageByType(UploadFileType.WANX_TEXT_MASK);
            }}
            okText={"确定"}
            cancelText={"取消"}
          >
            <div css={closeButtonStyle}>
              <NextImage
                src={CloseIcon}
                alt={""}
                css={css`
                  width: 100%;
                  height: 100%;
                `}
              />
            </div>
          </Popconfirm>
        </>
      );
    } else {
      blockContent = (
        <p
          css={[
            css`
              text-align: center;
              color: var(--black);
              font-size: 14px;
              line-height: 14px;
              margin: auto;
            `,
          ]}
          onClick={() => {
            setShowImageEditor(true);
          }}
        >
          {layout.type === LayoutType.Layout_v1 && "点击开始绘图"}
          {layout.type === LayoutType.Layout_v2 && "开始绘图"}
        </p>
      );
    }

    return (
      <div
        css={[
          ImageInputBlockStyle,
          layout.type === LayoutType.Layout_v2 && imageInputBlockStyleV2,
        ]}
      >
        <div
          css={[
            css`
              width: 80px;
              height: 80px;
              border-style: dashed;
              border-width: 1px;
              border-color: var(--border-in-light);
              border-radius: 5px;
              background-color: var(--white);
              display: flex;
              flex-direction: column;
              cursor: pointer;
              transition: all 0.2s ease-in-out;
              position: relative;

              &:hover .css-${closeButtonStyle.name} {
                display: block;
              }
            `,
            layout.type === LayoutType.Layout_v2 &&
              css`
                width: 40px;
                height: 40px;
                border: none;
              `,
          ]}
        >
          {blockContent}
        </div>
        <Modal
          open={showImageEditor}
          footer={null}
          closable={false}
          width={(wanxOptions?.image_width ?? 512) + 48}
          destroyOnClose={true}
        >
          <EraseImageCanvas
            type={"mark"}
            onEditCancel={() => {
              setShowImageEditor(false);
            }}
            onEditComplete={(file) => {
              setShowImageEditor(false);
              void updatePendingFileByType(file, UploadFileType.WANX_TEXT_MASK);
            }}
            size={{
              w: wanxOptions?.image_width ?? 512,
              h: wanxOptions?.image_height ?? 512,
            }}
          >
            <div
              css={css`
                margin-top: 8px;
                display: flex;
                justify-content: center;
              `}
            >
              <Select
                value={wanxOptions?.image_width}
                style={{ height: "28px", marginLeft: "10px", width: "180px" }}
                popupMatchSelectWidth={true}
                placement="topLeft"
                title="图片宽度"
                placeholder="图片宽度"
                key="image_width"
                onChange={(value) => {
                  wanxOptionDebouncedStateUpdate(
                    produce(wanxOptions!, (option) => {
                      option.image_width = value;
                    }),
                  );
                }}
                options={[
                  {
                    value: ChatEnums.Wanx.Mixture.Width.WIDTH_256,
                    label: "宽:256",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Width.WIDTH_320,
                    label: "宽:320",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Width.WIDTH_384,
                    label: "宽:384",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Width.WIDTH_448,
                    label: "宽:448",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Width.WIDTH_512,
                    label: "宽:512",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Width.WIDTH_576,
                    label: "宽:576",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Width.WIDTH_640,
                    label: "宽:640",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Width.WIDTH_704,
                    label: "宽:704",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Width.WIDTH_768,
                    label: "宽:768",
                  },
                ]}
              />
              <Select
                value={wanxOptions?.image_height}
                key="image_height"
                style={{ height: "28px", marginLeft: "10px", width: "180px" }}
                popupMatchSelectWidth={true}
                placement="topLeft"
                placeholder="图片高度"
                title="图片高度"
                onChange={(value) => {
                  wanxOptionDebouncedStateUpdate(
                    produce(wanxOptions!, (option) => {
                      option.image_height = value;
                    }),
                  );
                }}
                options={[
                  {
                    value: ChatEnums.Wanx.Mixture.Height.HEIGHT_256,
                    label: "高:256",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Height.HEIGHT_320,
                    label: "高:320",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Height.HEIGHT_384,
                    label: "高:384",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Height.HEIGHT_448,
                    label: "高:448",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Height.HEIGHT_512,
                    label: "高:512",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Height.HEIGHT_576,
                    label: "高:576",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Height.HEIGHT_640,
                    label: "高:640",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Height.HEIGHT_704,
                    label: "高:704",
                  },
                  {
                    value: ChatEnums.Wanx.Mixture.Height.HEIGHT_768,
                    label: "高:768",
                  },
                ]}
              />
            </div>
          </EraseImageCanvas>
        </Modal>
      </div>
    );
  };

  if (wanxOptions?.operation === ChatEnums.Wanx.Operation.GENERATE) {
    inputElements = (
      <>
        {(layout.type === LayoutType.Layout_v1 ||
          (layout.type === LayoutType.Layout_v2 && baseFile.imageUrl)) &&
          ImageBase()}
      </>
    );
  } else if (wanxOptions?.operation === ChatEnums.Wanx.Operation.IMAGE_EDIT) {
    inputElements = (
      <>
        {(layout.type === LayoutType.Layout_v1 ||
          (layout.type === LayoutType.Layout_v2 && baseFile.imageUrl)) &&
          ImageBase()}
        {(layout.type === LayoutType.Layout_v1 ||
          (layout.type === LayoutType.Layout_v2 && maskFile.imageUrl)) &&
          ImageMask()}
      </>
    );
  } else if (
    wanxOptions?.operation === ChatEnums.Wanx.Operation.IMAGE_GENERATE
  ) {
    inputElements = <>{WanxImageGenerateBlock()}</>;
  } else if (wanxOptions?.operation === ChatEnums.Wanx.Operation.TEXTURE) {
    if (
      wordArtOptions?.text_type ===
      ChatEnums.WordArt.Texture.TextType.UPLOAD_IMG
    ) {
      inputElements = <>{ImageBase()}</>;
    } else if (
      wordArtOptions?.text_type ===
      ChatEnums.WordArt.Texture.TextType.TYPING_TEXT
    ) {
      if (wordArtOptions.font_name === ChatEnums.WordArt.Font._UPLOAD_FONT) {
        inputElements = <>{FontUpload()}</>;
      }
    }
  } else if (wanxOptions?.operation === ChatEnums.Wanx.Operation.SEMANTIC) {
    if (wordArtOptions?.font_name === ChatEnums.WordArt.Font._UPLOAD_FONT) {
      inputElements = <>{FontUpload()}</>;
    }
  }

  useEffect(() => {
    const uploadFile = function (file: File) {
      updatePendingFileByType(file, UploadFileType.WANX_TEXT_BASE);
    };
    const uploadMaskFile = function (file: File) {
      updatePendingFileByType(file, UploadFileType.WANX_TEXT_MASK);
    };
    const uploadTTFFile = function (file: File) {
      updatePendingFileByType(file, UploadFileType.WANX_TTF_FILE);
    };
    eventBus.on(EventBus.FileInput.ImageInput.Action.ACTION_INPUT, uploadFile);
    eventBus.on(
      EventBus.FileInput.ImageInput.Action.ACTION_INPUT_MASK,
      uploadMaskFile,
    );
    eventBus.on(
      EventBus.FileInput.ImageInput.Action.ACTION_INPUT_TTF,
      uploadTTFFile,
    );

    return () => {
      eventBus.off(
        EventBus.FileInput.ImageInput.Action.ACTION_INPUT,
        uploadFile,
      );
      eventBus.off(
        EventBus.FileInput.ImageInput.Action.ACTION_INPUT_MASK,
        uploadMaskFile,
      );
      eventBus.off(
        EventBus.FileInput.ImageInput.Action.ACTION_INPUT_TTF,
        uploadTTFFile,
      );
    };
  }, [updatePendingFileByType]);

  return (
    <div
      css={[
        layout.type === LayoutType.Layout_v1 &&
          css`
            display: flex;
            margin-left: -8px;
          `,
        layout.type === LayoutType.Layout_v2 &&
          css`
            display: flex;
            width: 100%;
            box-sizing: border-box;
            padding-left: 8px;
          `,
      ]}
    >
      {messageContextHolder}
      {inputElements}
    </div>
  );
};

const WanxPromptInputBlock = function (
  props: React.PropsWithChildren<{
    updatePromptsInput: (str: string) => void;
    submit: (ignoreEmptyInput: boolean) => void;
    inputAboveArea?: React.ReactNode;
  }>,
) {
  const [inputs, updateInputs] = useImmer({
    prompt: "",
  });

  const conversationId = useContext(ConversationIdContext);

  const [messages, messageContextHolder] = message.useMessage();
  const session = useChatStore(
    useShallow((state) => state.currentSession(conversationId)!),
  );
  const isMobileScreen = useMobileScreen();
  const layout = useLayoutConfigStore();

  const textAreaStyle = css`
    resize: none;
    background-color: #f0f1f5;
    border-color: #e5e5e5;
    color: #333;
    box-sizing: border-box;
    font-size: 15px;
    padding: 7px 20px;
    border-width: 1px;
    border-radius: 4px;
    width: 100%;
  `;

  const optionsChange = {
    prompt(value: string) {
      updateInputs((inputs) => {
        inputs.prompt = value;
      });
      props.updatePromptsInput(value);
    },
  };

  const submit = async function () {
    if (!inputs.prompt || inputs.prompt === "") {
      void messages.error("提示词不能为空");
      return;
    }

    const originalImage = session.pendingFileList?.find(
      (it) => it.fileType === UploadFileType.WANX_TEXT_BASE,
    );
    const maskImage = session.pendingFileList?.find(
      (it) => it.fileType === UploadFileType.WANX_TEXT_MASK,
    );

    if (
      wanxOperation === ChatEnums.Wanx.Operation.IMAGE_EDIT ||
      wanxOperation === ChatEnums.Wanx.Operation.IMAGE_GENERATE
    ) {
      if (!maskImage) {
        void messages.error("请上传遮罩图");
        return;
      }
      if (wanxOperation === ChatEnums.Wanx.Operation.IMAGE_EDIT) {
        if (!originalImage) {
          void messages.error("请上传原图");
          return;
        }
      }
    } else if (wanxOperation === ChatEnums.Wanx.Operation.TEXTURE) {
      if (
        session.wordArtOptions?.text_type ===
        ChatEnums.WordArt.Texture.TextType.TYPING_TEXT
      ) {
        if (
          !session.wordArtOptions.text_content ||
          session.wordArtOptions.text_content === ""
        ) {
          void messages.error("请输入文字内容");
          return;
        }
        if (session.wordArtOptions.text_content.length > 6) {
          void messages.error("文字内容超过6字");
          return;
        }
        const ttfFile = session.pendingFileList?.find(
          (it) => it.fileType === UploadFileType.WANX_TTF_FILE,
        );
        if (
          session.wordArtOptions.font_name ===
          ChatEnums.WordArt.Font._UPLOAD_FONT
        ) {
          if (!ttfFile) {
            void messages.error("请上传TTF字体");
            return;
          }
        }
        if (session.wordArtOptions.font_name === undefined) {
          if (!ttfFile) {
            void messages.error("请选择一种预设字体或上传字体");
            return;
          }
        }
      } else if (
        session.wordArtOptions?.text_type ===
        ChatEnums.WordArt.Texture.TextType.UPLOAD_IMG
      ) {
        if (!originalImage) {
          void messages.error("请上传图片");
          return;
        }
      }
    } else if (wanxOperation === ChatEnums.Wanx.Operation.SEMANTIC) {
    }

    updateInputs({ prompt: "" });
    props.submit(false);
  };

  const wanxOperation = session.wanxOptions?.operation;

  let placeholder = "输入对生成图片的描述";
  let maxLength = undefined as number | undefined;
  const inputDisabled = false;
  if (
    wanxOperation === ChatEnums.Wanx.Operation.TEXTURE ||
    wanxOperation === ChatEnums.Wanx.Operation.SEMANTIC
  ) {
    placeholder = "输入对生成图片的描述，不超过200字";
    maxLength = 200;
  }

  let inputBlock = <></>;

  if (layout.type === LayoutType.Layout_v1) {
    if (isMobileScreen) {
      inputBlock = (
        <>
          <div
            css={css`
              min-width: calc(100% + 6px);
              min-height: 40px;
              margin-bottom: 7px;
              border-radius: 4px;
            `}
          >
            <Input
              type={"textarea"}
              style={{ display: "flex", minHeight: "40px" }}
              styles={{
                input: {
                  flexGrow: 1,
                  display: "block",
                  textAlign: "initial",
                },
              }}
              suffix={
                <SendIcon
                  css={css`
                    width: 20px;
                    height: 20px;
                  `}
                  onClick={submit}
                />
              }
              onChange={(e) => optionsChange.prompt(e.target.value)}
              placeholder={"向“思宝”发送消息"}
              onPressEnter={submit}
            ></Input>
          </div>
        </>
      );
    } else {
      inputBlock = (
        <>
          <div
            css={css`
              flex-grow: 1;
              flex-shrink: 1;
              min-width: 0;
            `}
          >
            <div>
              <TextareaAutosize
                value={inputs.prompt}
                placeholder={placeholder}
                maxLength={maxLength}
                disabled={inputDisabled}
                maxRows={4}
                minRows={1}
                css={textAreaStyle}
                onChange={(e) => optionsChange.prompt(e.target.value)}
                onKeyDown={(e) => {
                  actOnKeyDown(e, { submit });
                }}
                enterKeyHint="send"
              />
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              flex-shrink: 0;
              margin-left: 5px;
              margin-bottom: 4px;
            `}
          >
            <div
              css={css`
                color: white;
                width: 106px;
                height: 42px;
                border-radius: 8px;
                background-image: linear-gradient(to top, #1fd6cc, #0c74ed);
                text-align: center;
                line-height: 42px;
                margin-top: auto;
                margin-bottom: auto;
                cursor: pointer;
                user-select: none;

                :hover {
                  opacity: 0.75;
                }
              `}
              onClick={submit}
            >
              发送
            </div>
          </div>
        </>
      );
    }
  } else if (layout.type === LayoutType.Layout_v2) {
    inputBlock = (
      <>
        <CommonPromptInputBlock
          updatePrompts={optionsChange.prompt}
          submit={submit}
          inputAboveArea={props.inputAboveArea}
        >
          {props.children}
        </CommonPromptInputBlock>
      </>
    );
  }

  return (
    <div
      css={[
        css`
          display: flex;
          width: 100%;
          padding-top: 10px;
          margin-right: -6px;
          padding-right: 6px;
        `,
        layout.type === LayoutType.Layout_v2 &&
          css`
            height: 100%;
            padding-top: 0;
          `,
      ]}
    >
      {messageContextHolder}
      {inputBlock}
    </div>
  );
};

export const ImageInputBlock = function (props: {
  imageUrl?: string;
  titleStr?: string;
  onChange?: (file: File) => void;
  onDelete?: () => void;
}) {
  const layout = useLayoutConfigStore();

  const closeButtonStyle = css`
    width: 28px;
    height: 28px;
    position: absolute;
    cursor: pointer;
    top: -14px;
    right: -14px;
    display: none;
    label: close-btn;
  `;
  const closeBtnStyleV2 = css`
    width: 18px;
    height: 18px;
    position: absolute;
    cursor: pointer;
    top: -9px;
    right: -9px;
    display: none;
    label: close-btn;
  `;

  const onInputFileChange = function (e: ChangeEvent<HTMLInputElement>) {
    const target = e.target;
    const file = target.files![0];
    if (props.onChange) {
      props.onChange(file);
    }
    target.value = "";
  };

  const onDeleteImage = function () {
    if (props.onDelete) {
      props.onDelete();
    }
  };

  let content: JSX.Element;
  if (props.imageUrl && props.imageUrl !== "") {
    content = (
      <>
        <img
          src={props.imageUrl}
          alt=""
          style={{ width: "100%", height: "100%" }}
        />
        <Popconfirm
          title={"删除图片"}
          description={"确定要删除图片吗？"}
          onConfirm={onDeleteImage}
          okText={"确定"}
          cancelText={"取消"}
        >
          {layout.type === LayoutType.Layout_v1 && (
            <div css={closeButtonStyle}>
              <NextImage
                alt={""}
                src={CloseIcon}
                css={css`
                  width: 100%;
                  height: 100%;
                `}
              />
            </div>
          )}
          {layout.type === LayoutType.Layout_v2 && (
            <div css={closeBtnStyleV2}>
              <NextImage
                alt={""}
                src={CloseIcon}
                css={css`
                  width: 100%;
                  height: 100%;
                `}
              />
            </div>
          )}
        </Popconfirm>
      </>
    );
  } else {
    content = (
      <>
        {layout.type === LayoutType.Layout_v1 && (
          <>
            <PlusOutlined
              css={css`
                width: 24px;
                height: 24px;
                box-sizing: border-box;
                font-size: 16px;
                padding: 4px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 14px;
              `}
            />
            <p
              css={css`
                color: var(--black);
                text-align: center;
                font-size: 14px;
                line-height: 14px;
                margin: 8px 0 0;
              `}
            >
              {props.titleStr}
            </p>
          </>
        )}
        <input
          type="file"
          css={css`
            display: none;
          `}
          accept="image/*"
          onChange={onInputFileChange}
        />
      </>
    );
  }

  return (
    <label
      css={[
        css`
          display: flex;
          flex-direction: column;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          position: relative;

          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        `,
        layout.type === LayoutType.Layout_v1 &&
          css`
            width: 80px;
            height: 80px;
            border-style: dashed;
            border-width: 1px;
            border-color: var(--border-in-light);
            border-radius: 5px;
            background-color: var(--white);

            &:hover .css-${closeButtonStyle.name} {
              display: block;
            }
          `,
        layout.type === LayoutType.Layout_v2 &&
          css`
            width: 40px;
            height: 40px;
            background-color: #f1f5f7;

            &:hover .css-${closeBtnStyleV2.name} {
              display: block;
            }
          `,
      ]}
    >
      {content}
    </label>
  );
};

export const FontInputBlock = function (
  props: React.PropsWithChildren<{
    fontUrl?: string;
    titleStr?: string;
    onChange?: (file: File) => void;
    onDelete?: () => void;
  }>,
) {
  const onFileChange = function (e: ChangeEvent<HTMLInputElement>) {
    const target = e.target;
    const file = target.files![0];
    if (props.onChange) {
      props.onChange(file);
    }
    target.value = "";
  };

  const onDelete = function () {
    if (props.onDelete) {
      props.onDelete();
    }
  };

  const closeBtnStyle = css`
    width: 28px;
    height: 28px;
    position: absolute;
    cursor: pointer;
    top: -14px;
    right: -14px;
    display: none;
    label: close-btn;
  `;

  let content: JSX.Element;

  if (props.fontUrl && props.fontUrl !== "") {
    content = (
      <>
        <div
          css={css`
            font-size: 40px;
            text-align: center;
            line-height: 80px;
          `}
        >
          Aa
        </div>
        <Popconfirm title="确定要删除字体？" onConfirm={onDelete}>
          <div css={closeBtnStyle}>
            <NextImage
              alt={""}
              src={CloseIcon}
              css={css`
                width: 100%;
                height: 100%;
                object-fit: contain;
              `}
            />
          </div>
        </Popconfirm>
      </>
    );
  } else {
    content = (
      <>
        <PlusOutlined
          css={css`
            font-size: 28px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 12px;
          `}
        />
        <div
          css={css`
            font-size: 12px;
            color: #999;
            margin-top: 4px;
            text-align: center;
          `}
        >
          {props.titleStr}
        </div>
        <input
          type="file"
          accept="font/ttf"
          css={css`
            display: none;
          `}
          onChange={onFileChange}
        />
      </>
    );
  }

  return (
    <label
      css={css`
        width: 80px;
        height: 80px;
        border-width: 1px;
        border-style: dashed;
        border-color: #999;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        position: relative;

        &:hover .css-${closeBtnStyle.name} {
          display: block;
        }
      `}
    >
      {content}
    </label>
  );
};

export const AdditionalSettings = function (props: React.PropsWithChildren) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const iconStyle = css`
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    label: icon;
  `;

  const textStyle = css`
    color: var(--black);
    font-size: 14px;
    display: block;
    line-height: 28px;
    margin-right: auto;
    margin-left: 2px;
    user-select: none;
    label: text;
  `;

  const titleStyle = css`
    & .ant-modal-title {
      text-align: center;
      font-size: 18px;
    }

    & .ant-modal-body {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    label: title;
  `;

  return (
    <>
      <div
        css={css`
          width: 104px;
          min-width: 104px;
          height: 28px;
          border-radius: 8px;
          border-style: solid;
          border-width: 1px;
          border-color: #ddd;
          display: flex;
          cursor: pointer;
          margin-left: 10px;
          background-color: var(--white);

          &:hover {
            border-color: #0057cc;
          }

          &:hover .css-${iconStyle.name} path {
            stroke: #0057cc !important;
          }

          &:hover .css-${textStyle.name} {
            color: #0057cc;
          }
        `}
        onClick={() => void setIsModalOpen(true)}
      >
        <SettingsIcon css={iconStyle}></SettingsIcon>
        <span css={textStyle}>更多设置</span>
      </div>

      <Modal
        title={"更多设置"}
        open={isModalOpen}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        css={titleStyle}
      >
        {props.children}
      </Modal>
    </>
  );
};

export const SettingsItem = function (
  props: React.PropsWithChildren<{ label: string }>,
) {
  return (
    <div
      css={css`
        display: flex;
        min-height: 28px;
        margin-top: 8px;
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
      `}
    >
      <p
        css={css`
          color: #333;
          font-size: 16px;
          margin: 0 5px 0 0;
          line-height: 28px;
          height: 28px;
          flex: 3;
          text-align: right;
        `}
      >
        {props.label}
      </p>
      <div
        css={css`
          flex: 7;
          margin-left: -5px;
        `}
      >
        {props.children}
      </div>
    </div>
  );
};

export const MaskImageDisplayBlock = function (props: {
  imageUrl?: string;
  titleStr?: string;
  onEdit?: () => void | Promise<void>;
  onDelete?: () => void | Promise<void>;
}) {
  const layout = useLayoutConfigStore();

  const closeButtonStyle = css`
    width: 28px;
    height: 28px;
    position: absolute;
    cursor: pointer;
    top: -14px;
    right: -14px;
    display: none;
    label: close-btn;
  `;

  const onDeleteImage = function () {
    if (props.onDelete) {
      props.onDelete();
    }
  };

  const onChangeImage = function (
    _: MouseEvent<HTMLDivElement | HTMLImageElement>,
  ) {
    if (props.onEdit) {
      props.onEdit();
    }
  };

  let content: JSX.Element;

  if (props.imageUrl) {
    content = (
      <>
        <div
          css={css`
            width: 100%;
            height: 100%;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAASFBMVEUAAAD////h4eH+/v79/f37+/v6+vr5+fnm5ubk5OTg4ODf39/39/fp6eno6Ojn5+fl5eXj4+Pi4uLe3t7d3d3c3Nzb29v///9R1MU4AAAAGHRSTlP//////////////////////////////wDNEy7qAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAaElEQVQYlZ3OQQrCMBAF0DdptAGhev8zulBcaBQbF2kV3AjOYuA/PsxEAZFrC7SUB7C7PICUr6C0nkXyNb8hL1dKXSE6rFmMYB6HusV9E7E0p9SQjm94lhtK++OPD0Rfucsch9Me5+kFl+UUWcTFFFsAAAAASUVORK5CYII=");
            background-repeat: repeat;
          `}
        >
          <img
            src={props.imageUrl}
            alt={""}
            css={css`
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
              display: block;
              pointer-events: visible;
            `}
            onClick={onChangeImage}
          />
        </div>
        <Popconfirm
          title={"删除图片"}
          description={"确定要删除图片吗"}
          okText={"确定"}
          cancelText={"取消"}
          onConfirm={onDeleteImage}
        >
          <div css={closeButtonStyle}>
            <NextImage
              src={CloseIcon}
              alt={""}
              css={css`
                width: 100%;
                height: 100%;
              `}
            />
          </div>
        </Popconfirm>
      </>
    );
  } else {
    content = (
      <>
        <div
          css={css`
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
          `}
          onClick={onChangeImage}
        >
          {layout.type === LayoutType.Layout_v1 && (
            <>
              <div
                css={css`
                  width: 24px;
                  height: 24px;
                  box-sizing: border-box;
                  font-size: 16px;
                  padding: 4px;
                  margin-left: auto;
                  margin-right: auto;
                  margin-top: 14px;
                `}
              />
              <p
                css={css`
                  color: var(--black);
                  text-align: center;
                  font-size: 14px;
                  line-height: 14px;
                  margin: 8px 0 0;
                `}
              >
                {props.titleStr}
              </p>
            </>
          )}
        </div>
      </>
    );
  }

  return (
    <div
      css={[
        css`
          display: flex;
          flex-direction: column;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          position: relative;
        `,
        layout.type === LayoutType.Layout_v1 &&
          css`
            width: 80px;
            height: 80px;
            border-style: dashed;
            border-width: 1px;
            border-color: var(--border-in-light);
            border-radius: 5px;
            background-color: var(--white);

            &:hover .css-${closeButtonStyle.name} {
              display: block;
            }
          `,
        layout.type === LayoutType.Layout_v2 &&
          css`
            width: 40px;
            height: 40px;
            background-color: #f1f5f7;
            border-radius: 4px;
          `,
      ]}
    >
      {content}
    </div>
  );
};

const WanxInputMenusV2 = function (props: React.PropsWithChildren<{}>) {
  const conversationId = useContext(ConversationIdContext);
  const wanxOperation = useChatStore(
    useShallow(
      (state) => state.currentSession(conversationId)?.wanxOptions?.operation,
    ),
  );
  const wordArtOption = useChatStore(
    useShallow((state) => state.currentSession(conversationId)?.wordArtOptions),
  );

  let displayArea: React.ReactNode = false;
  if (wanxOperation === ChatEnums.Wanx.Operation.GENERATE) {
    displayArea = (
      <>
        <InputMenusWrapper left>
          <InputMenus>
            <MenuItem>
              <WanxInputButtonOriginalImageV2 />
            </MenuItem>
          </InputMenus>
        </InputMenusWrapper>
      </>
    );
  } else if (wanxOperation === ChatEnums.Wanx.Operation.IMAGE_EDIT) {
    displayArea = (
      <>
        <InputMenusWrapper left>
          <InputMenus>
            <MenuItem>
              <WanxInputButtonOriginalImageV2 />
            </MenuItem>
            <MenuItem>
              <WanxInputButtonMaskImageV2 />
            </MenuItem>
          </InputMenus>
        </InputMenusWrapper>
      </>
    );
  } else if (wanxOperation === ChatEnums.Wanx.Operation.TEXTURE) {
    if (
      wordArtOption?.text_type === ChatEnums.WordArt.Texture.TextType.UPLOAD_IMG
    ) {
      displayArea = (
        <>
          <InputMenusWrapper left>
            <InputMenus>
              <MenuItem>
                <WanxInputButtonOriginalImageV2 />
              </MenuItem>
            </InputMenus>
          </InputMenusWrapper>
        </>
      );
    } else if (
      wordArtOption?.text_type ===
      ChatEnums.WordArt.Texture.TextType.TYPING_TEXT
    ) {
      if (wordArtOption?.font_name === ChatEnums.WordArt.Font._UPLOAD_FONT) {
        displayArea = (
          <>
            <InputMenusWrapper left>
              <InputMenus>
                <MenuItem>
                  <WanxInputButtonTTFFileV2 />
                </MenuItem>
              </InputMenus>
            </InputMenusWrapper>
          </>
        );
      }
    }
  } else if (wanxOperation === ChatEnums.Wanx.Operation.SEMANTIC) {
    if (wordArtOption?.font_name === ChatEnums.WordArt.Font._UPLOAD_FONT) {
      displayArea = (
        <>
          <InputMenusWrapper left>
            <InputMenus>
              <MenuItem>
                <WanxInputButtonTTFFileV2 />
              </MenuItem>
            </InputMenus>
          </InputMenusWrapper>
        </>
      );
    }
  }

  return displayArea;
};

const WanxInputButtonOriginalImageV2 = function () {
  const uploadByType = useUpdatePendingFileByType();

  const upload = useCallback(
    (fileList: File[]) => {
      const file = fileList[0];
      if (file) {
        uploadByType(file, UploadFileType.WANX_TEXT_BASE);
      }
    },
    [uploadByType],
  );

  return (
    <SmallClickInput title={"上传原图"} accept={"image/*"} onChange={upload}>
      <ChatPicIcon
        css={css`
          width: 20px;
          height: 20px;
          margin-top: auto;
          margin-bottom: auto;

          path {
            fill: #4b5562;
          }
        `}
      />
    </SmallClickInput>
  );
};

const WanxInputButtonMaskImageV2 = function () {
  const uploadByType = useUpdatePendingFileByType();

  const upload = useCallback(
    (fileList: File[]) => {
      const file = fileList[0];
      if (file) {
        uploadByType(file, UploadFileType.WANX_TEXT_MASK);
      }
    },
    [uploadByType],
  );

  return (
    <SmallClickInput title="上传遮罩图" accept={"image/*"} onChange={upload}>
      <ChatPicIcon
        css={css`
          width: 20px;
          height: 20px;
          margin-top: auto;
          margin-bottom: auto;

          path {
            fill: #4b5562;
          }
        `}
      />
    </SmallClickInput>
  );
};

const WanxInputButtonTTFFileV2 = function () {
  const uploadByType = useUpdatePendingFileByType();

  const upload = useCallback(
    (fileList: File[]) => {
      const file = fileList[0];
      if (file) {
        uploadByType(file, UploadFileType.WANX_TTF_FILE);
      }
    },
    [uploadByType],
  );

  return (
    <SmallClickInput title="上传字体文件" accept={"font/ttf"} onChange={upload}>
      <ChatPicIcon
        css={css`
          width: 20px;
          height: 20px;
          margin-top: auto;
          margin-bottom: auto;

          path {
            fill: #4b5562;
          }
        `}
      />
    </SmallClickInput>
  );
};
