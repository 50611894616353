"use client";
import { match } from "ts-pattern";

export const virtualCoinAmountTransform = function (amount: string) {
  return match(amount)
    .with("10", () => {
      return {
        coin: "1050",
        description: "获得1000算粒，赠送50算粒",
        status: "normal",
      };
    })
    .with("20", () => {
      return {
        coin: "2300",
        description: "获得2000算粒，赠送300算粒",
        status: "normal",
      };
    })
    .with("50", () => {
      return {
        coin: "5700",
        description: "获得5000算粒，赠送700算粒",
        status: "normal",
      };
    })
    .with("100", () => {
      return {
        coin: "12000",
        description: "获得10000算粒，赠送2000算粒",
        status: "normal",
      };
    })
    .with("200", () => {
      return {
        coin: "25000",
        description: "获得20000算粒，赠送5000算粒",
        status: "normal",
      };
    })
    .otherwise((it) => {
      return {
        coin: "未知档位",
        description: "未知档位" + it,
        status: "error",
      };
    });
};
