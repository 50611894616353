"use client";
/// <reference types="mdast-util-to-hast" />
/// <reference types="mdast-util-directive" />

import { visit } from "unist-util-visit";
import { Root } from "mdast";
import {
  ContainerDirective,
  LeafDirective,
  TextDirective,
} from "mdast-util-directive";
import { h } from "hastscript";
import { ChatMessage } from "@/app/store";

export default function showAsFile() {
  return (tree: Root) => {
    visit(tree, function (node) {
      if (
        (node as ContainerDirective).type === "containerDirective" ||
        (node as LeafDirective).type === "leafDirective" ||
        (node as TextDirective).type === "textDirective"
      ) {
        if (
          (node as ContainerDirective | LeafDirective | TextDirective).name !==
          "caie-file"
        ) {
          return;
        }
        if ((node as TextDirective).type !== "textDirective") {
          return;
        }
        if (
          (node as TextDirective).children.length !== 1 ||
          (node as TextDirective).children[0]?.type !== "text"
        ) {
          return;
        }
        const data = node.data || (node.data = {});
        const attributes =
          (node as ContainerDirective | LeafDirective | TextDirective)
            .attributes || {};
        const tagName = "caie-file";

        data.hName = tagName;
        data.hProperties = {};
        data.hProperties.caieId = h(tagName, attributes).properties.caieId;
      }
    });
  };
}

export function mdTransform(
  md: string,
): Pick<
  ChatMessage,
  "content" | "file_ids" | "transformedContent" | "isTransformed" | "file_list"
> {
  const fileIdList: number[] = [];
  const fileInfoList: { fileName: string; file_id: number }[] = [];
  return {
    content: md,
    file_ids: fileIdList,
    transformedContent: md,
    isTransformed: 4,
    file_list: fileInfoList,
  };
}

export function fileLinkTransform(props: {
  messageId?: string;
  conversationId?: string;
  role: string;
}) {
  return () => {
    return (tree: Root) => {
      visit(tree, (node) => {
        if (props.role !== "assistant") {
          return;
        }
        if (node.type === "link") {
          const urlStr = node.url;
          console.log(node);
          try {
            const url = new URL(urlStr);
            const schema = url.protocol;
            if (schema.toLowerCase() === "sandbox:") {
              node.url = `/v1/asst/sandbox/download?conversation_id=${encodeURIComponent(props.conversationId ?? "")}&message_uuid=${encodeURIComponent(props.messageId ?? "")}&file_name=${encodeURIComponent(urlStr)}`;
            }
          } catch (e: any) {
            if (e.name === "TypeError") {
              console.info(e);
              console.debug(node.url);
            }
          }
        }
      });
    };
  };
}

export const InternalLinkTransform = function () {
  return () => {
    return (tree: Root) => {
      visit(tree, (node) => {
        if (node.type === "link") {
          const urlStr = node.url;
          const title = node.title;
          try {
            const url = new URL(urlStr);
            const schema = url.protocol;
            const data = node.data || (node.data = {});
            console.log("schema", schema);
            if (schema.toLowerCase() === "caie-jump:") {
              data.hName = "caie-jump";
            }
          } catch (e) {
            if ((e as Error).name === "TypeError") {
              console.info(e);
              console.debug(urlStr);
            } else {
              console.warn(e);
            }
          }
        }
      });
    };
  };
};
