import { ChatOptions, LLMUsage } from "@/app/client/api";
import { responseProcess } from "@/app/utils/responseProcess";
import { matchErrorCode } from "@/app/client/errorCode";

export abstract class LLMApi {
  protected constructor() {}

  abstract chat(options: ChatOptions): Promise<void>;

  abstract usage(): Promise<LLMUsage>;

  async finish(resp: Response, options: ChatOptions) {
    const contentType = resp.headers.get("content-type");
    const messageUuid = resp.headers.get("Message_uuid") ?? "";
    const code = resp.headers.get("code");

    if (code && code.length > 0 && !isNaN(parseInt(code))) {
      const intCode = parseInt(code);
      if (matchErrorCode(intCode).isError) {
        const message = await resp.clone().text();
        console.log("response error with code", code, "message", message);
        options.onError?.(new Error(message), {
          code: intCode,
        });
        await responseProcess(resp, true);
        return;
      }
    }

    if (contentType?.startsWith("text/plain")) {
      const responseText = await resp.clone().text();
      options.onFinish(responseText, { messageUUID: messageUuid });
      await responseProcess(resp, true);
      return;
    }

    const reader = resp.body?.getReader();
    if (!reader) {
      console.log("不支持流式调用");
      const responseText = await resp.clone().text();
      options.onFinish(responseText, { messageUUID: messageUuid });
      await responseProcess(resp, true);
      return;
    }

    let responseText = "";
    const textDecoder = new TextDecoder();
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        options.onFinish(responseText, { messageUUID: messageUuid });
        await responseProcess(resp, true);
        return;
      }
      const streamText = textDecoder.decode(value);
      responseText += streamText;
      options.onUpdate?.(responseText, streamText);
    }
  }
}
