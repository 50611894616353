"use client";
export namespace EventBus {
  export namespace PromptInput {
    export enum Action {
      FOCUS = "EventBus.Prompt.Action.FOCUS",
      SET_INPUT = "EventBus.Prompt.Action.SET_INPUT",
    }
  }
  export namespace FileInput {
    export namespace Assistant {
      export enum Action {
        STATUS_ENABLE = "EventBus.FileInput.Assistant.Action.STATUS_ENABLE",
      }
    }
    export namespace ImageInput {
      export enum Action {
        ACTION_INPUT = "EventBus.FileInput.ImageInput.Action.ACTION_INPUT",
        ACTION_INPUT_MASK = "EventBus.FileInput.ImageInput.Action.ACTION_INPUT_MASK",
        ACTION_INPUT_TTF = "EventBus.FileInput.ImageInput.Action.ACTION_INPUT_TTF",
      }
    }
    export namespace Entry {
      export enum Action {
        ACTION_ORIGINAL_IMAGE = "EventBus.FileInput.Entry.Action.ACTION_ORIGINAL_IMAGE",
        ACTION_MASK_IMAGE = "EventBus.FileInput.Entry.Action.ACTION_MASK_IMAGE",
        ACTION_TTF_FILE = "EventBus.FileInput.Entry.Action.ACTION_TTF_FILE",
      }
    }
  }
  export namespace Global {
    export enum Status {
      LOADING = "EventBus.Global.Status.LOADING",
    }

    export namespace ChatBody {
      export enum Scroll {
        SCROLL_BOTTOM = "EventBus.Global.ChatBody.Scroll.SCROLL_BOTTOM",
      }
    }
    export namespace SideBar {
      export enum Status {
        OPEN_STATUS = "EventBus.Global.SideBar.Status.OPEN_STATUS",
      }
    }
  }
}

export type EventTypes = {
  [EventBus.PromptInput.Action.FOCUS]: void;
  [EventBus.PromptInput.Action.SET_INPUT]: string;

  [EventBus.Global.Status.LOADING]: boolean;

  [EventBus.Global.ChatBody.Scroll.SCROLL_BOTTOM]: void;

  [EventBus.FileInput.Assistant.Action.STATUS_ENABLE]: void;

  [EventBus.FileInput.ImageInput.Action.ACTION_INPUT]: File;
  [EventBus.FileInput.ImageInput.Action.ACTION_INPUT_MASK]: File;
  [EventBus.FileInput.ImageInput.Action.ACTION_INPUT_TTF]: File;

  [EventBus.FileInput.Entry.Action.ACTION_ORIGINAL_IMAGE]: boolean;
  [EventBus.FileInput.Entry.Action.ACTION_MASK_IMAGE]: boolean;
  [EventBus.FileInput.Entry.Action.ACTION_TTF_FILE]: boolean;
  [EventBus.Global.SideBar.Status.OPEN_STATUS]: boolean;
};
