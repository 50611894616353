"use client";
import { persist } from "zustand/middleware";
import { DEFAULT_API_HOST, StoreKey } from "../constant";
import { getClientConfig } from "../config/client";
import { v4 as uuidv4 } from "uuid";
import storage from "@/app/store/localStorage";
import { message } from "antd";
import axios from "axios";
import { createWithEqualityFn } from "zustand/traditional";
import isEqual from "react-fast-compare";
import { differenceInSeconds } from "date-fns";

const cache = storage<string>();
const UUID_STORAGE_KEY = "key_asst_uuid";
const TOKEN_STORAGE_KEY = "key_asst_token";

export interface AccessControlStore {
  token: string; // jwt token
  openaiUrl: string;
  codeLoading: boolean;
  submitLoading: boolean;
  fetchMobileCodeButtonText: string;
  uuid: string; // 前端生成的uuid
  captcha_url: string; // 图片验证码地址
  updateToken: (_: string) => void; // 修改jwt token
  updateOpenAiUrl: (_: string) => void;
  isAuthorized: () => boolean; // 是否登录

  refreshCaptchaUrl(force?: boolean): void; // 刷新验证码地址

  fetchMobileCode(mobile: string, captcha: string): void; // 获取手机验证码

  submitMobileLogin(mobile: string, code: string): void; // 提交手机验证码登录

  wechatLogin(uid: string, time: string, hash: string): void; // 微信扫码登录

  wechatReg(unionid: string, time: string, hash: string): void; // 微信扫码注册

  passwordLogin(username: string, password: string): void;

  getUUID(): string;

  getToken(): string;

  initState(): void;
}

const fetchState = 0; // 0 not fetch, 1 fetching, 2 done
const DEFAULT_OPENAI_URL =
  getClientConfig()?.buildMode === "export" ? DEFAULT_API_HOST : "/api/openai/";

let intervalId: string | number | NodeJS.Timeout | undefined = undefined;

export const useAccessStore = createWithEqualityFn<AccessControlStore>()(
  persist(
    (set, get) => ({
      token: "", //
      uuid: "",
      captcha_url: "",
      codeLoading: false,
      submitLoading: false,
      fetchMobileCodeButtonText: "获取验证码",
      openaiUrl: DEFAULT_OPENAI_URL,

      getUUID() {
        let currentUUID = get().uuid;
        if (!currentUUID) {
          currentUUID = cache.get(UUID_STORAGE_KEY) || "";
        }
        if (!currentUUID) {
          currentUUID = uuidv4();
          cache.set(UUID_STORAGE_KEY, currentUUID);
          set(() => ({ uuid: currentUUID }));
        }
        return currentUUID;
      },
      initState(): void {
        set(() => ({
          codeLoading: false,
          submitLoading: false,
          fetchMobileCodeButtonText: "获取验证码",
        }));
      },
      getToken(): string {
        let token = get().token;
        if (!token) {
          token = cache.get(TOKEN_STORAGE_KEY) || "";
        }
        return token;
      },
      updateToken(token: string) {
        console.log("设置token", token);
        cache.set(TOKEN_STORAGE_KEY, token);
        set(() => ({ token }));
      },
      updateOpenAiUrl(url: string) {
        set(() => ({ openaiUrl: url }));
      },
      isAuthorized() {
        const token = get().token;
        return token.length > 0;
      },
      // 刷新图形验证码地址
      refreshCaptchaUrl(force = false) {
        const uuid = get().getUUID();
        const timestamp = new Date().getTime();
        const url = get().captcha_url;
        if (!force) {
          try {
            if (url && url.length > 0) {
              const t = url.match(/&t=(\d*)$/)?.pop();
              if (t) {
                console.log("t", t);
                const tsInUrl = new Date(parseInt(t));
                if (Math.abs(differenceInSeconds(tsInUrl, Date.now())) < 2) {
                  return;
                }
              }
            }
          } catch (e) {
            console.warn(e);
          }
        }

        set({
          captcha_url: `/v1/asst/captcha?uuid=${uuid}&t=${timestamp}`,
        });
      },
      // 手机验证码登录
      submitMobileLogin(mobile: string, code: string) {
        set(() => ({ submitLoading: true }));
        const formData = new FormData();
        formData.append("mobile", mobile);
        formData.append("code", code);
        const that = this;
        fetch("/v1/asst/mobile/login", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.code === 0) {
              that.updateToken(data.token);
              message.success(data.msg || "登录成功");
              if (intervalId) {
                clearInterval(intervalId);
              }
            } else {
              console.log(data.msg);
              message.error(data.msg || "登录失败,请稍后再试");
            }
          })
          .catch((error) => console.error("Error:", error))
          .finally(() => {
            set(() => ({ submitLoading: false }));
          });
      },
      // 微信扫码登录
      wechatLogin(uid: string, time: string, hash: string) {
        const that = this;
        const formData = new FormData();
        formData.append("uid", uid);
        formData.append("time", time);
        formData.append("hash", hash);
        fetch("/v1/asst/wechat/login", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.code === 0) {
              that.updateToken(data.token);
              message.success(data.msg || "登录成功");
            } else {
              console.log(data.msg);
              message.error(data.msg || "登录失败,请稍后再试");
            }
          })
          .catch((error) => console.error("Error:", error));
      },
      // 微信扫码注册
      wechatReg(unionid: string, time: string, hash: string) {
        const formData = new FormData();
        formData.append("unionid", unionid);
        formData.append("time", time);
        formData.append("hash", hash);
        const that = this;
        fetch("/v1/asst/wechat/reg", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.code === 0) {
              set(() => ({ token: data.token }));
              that.updateToken(data.token);
              message.success(data.msg || "登录成功");
            } else {
              console.log(data.msg);
              message.error(data.msg || "登录失败,请稍后再试");
            }
          })
          .catch((error) => console.error("Error:", error));
      },
      // 获取验证码的方法
      fetchMobileCode(mobile: string, captcha: string) {
        set(() => ({ codeLoading: true }));
        // 获取当前页面的URL
        const page_url = window.location.href;
        // 获取来源页面
        const page_referer = document.referrer;
        const uuid = get().getUUID();
        // 构建表单数据
        const formData = new FormData();
        formData.append("uuid", uuid);
        formData.append("captcha", captcha);
        formData.append("mobile", mobile);
        formData.append("source", "assistant");
        formData.append("uuid", uuid);
        formData.append("page_url", page_url);
        formData.append("page_referer", page_referer);
        // 使用fetch发起POST请求
        fetch("/v1/asst/mobile/code", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            if (data.code != 0) {
              const msg = data.msg || "获取验证码失败,请稍后再试";
              set(() => ({ codeLoading: false }));
              void message.error(msg);
              get().refreshCaptchaUrl(true);
              return;
            }
            set(() => ({
              codeLoading: true,
              fetchMobileCodeButtonText: "60秒",
            }));
            let countdown = 60;
            intervalId = setInterval(() => {
              countdown -= 1;
              if (countdown <= 0) {
                set(() => ({
                  codeLoading: false,
                  fetchMobileCodeButtonText: "获取验证码",
                }));
                clearInterval(intervalId);
                return;
              }
              set({ fetchMobileCodeButtonText: `${countdown}秒` });
            }, 1000);
            message.success(data.ms || "发送成功");
          }) // 在控制台输出响应数据，你可以根据需要进行处理
          .catch((error) => {
            console.error("Error:", error);
            set(() => ({
              codeLoading: false,
              fetchMobileCodeButtonText: "获取验证码",
            }));
          });
      },
      passwordLogin(username: string, password: string) {
        const formData = new FormData();
        formData.append("login_name", username);
        formData.append("password", password);
        axios
          .post("/v1/asst/account/login", formData, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then((resp) => {
            if (resp.data.code === 0) {
              set(() => ({ token: resp.data.token }));
              this.updateToken(resp.data.token);
              void message.success(resp.data.msg || "登录成功");
            } else {
              console.log(resp.data.msg);
              void message.error(resp.data.msg || "登录失败，请稍后再试");
            }
          });
      },
    }),
    {
      name: StoreKey.Access,
      version: 3,
    },
  ),
  isEqual,
);
