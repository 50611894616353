"use client";

import { Channel } from "@/app/enums/channel";
import { useChannelStore } from "@/app/store/channel";

export enum Client {
  ANDROID = "android",
  IOS = "ios",
  WECHAT_MINIAPP = "wxMiniApp",
  H5 = "h5",
  PC = "pc",
  LENOVO_MINIAPP = "lxMiniApp",
}

const currentClient = function (channel?: Channel) {
  if (channel && channel === Channel.LENOVO) {
    return Client.LENOVO_MINIAPP;
  }
  return Client.PC;
};

export const getCurrentClient = function () {
  const channel = useChannelStore.getState().channel;
  return currentClient(channel);
};

export const useCurrentClient = function () {
  const channel = useChannelStore().channel;
  return currentClient(channel);
};
